import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {AccountMerge} from '@orion/models/account-merge';
import {AccountMergeService} from '@orion/services/account-merge.service';

export class AccountMergeDataSource implements DataSource<AccountMerge> {
  private accountMergeSubject = new BehaviorSubject<AccountMerge[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private accountMergeService: AccountMergeService) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<AccountMerge[] | ReadonlyArray<AccountMerge>> {
    return this.accountMergeSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.accountMergeSubject.complete();
    this.loadingSubject.complete();
  }

  loadAccountMergeRequests(
    offset: any,
    max: any,
    sort: string,
    order: string,
    page: number,
    filter = ''
  ) {
    this.loadingSubject.next(true);
    this.accountMergeService
      .list(offset, max, sort, order, page, filter)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((resp) => {
        this.accountMergeSubject.next(resp.data);
        this.resultsLengthSubject.next(resp.totalCount);
      });
  }
}
