<app-single-profile
  *ngIf="showProfile"
  [profileId]="profileId"
  (closeProfile)="hideProfile()"
></app-single-profile>
<div class="u-justify">
  <h1 class="page-title">LCTR Dashboard</h1>
    <form class="page-filters" [formGroup]="filtersForm">
      <!-- Date Filter   -->
      <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label>Created</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              matInput
              placeholder="Start date"
              formControlName="startDate"
            />
            <input
              matEndDate
              matInput
              placeholder="End date"
              formControlName="endDate"
              (dateChange)="applyDateRangeFilter($event)"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="page-action">
        <button
          mat-flat-button
          mat-button
          color="primary"
          (click)="downloadXMlFile()"
        >
          <span>Download goAML Report</span>
        </button>
      </div>
    </form>
  </div>
  <br>
<div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
  <div class="" >

  <table
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="transactionDate"
  matSortDisableClear
  matSortDirection="desc"
>
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
    <td mat-cell *matCellDef="let element">{{ element.position }}</td>
  </ng-container>

  <ng-container matColumnDef="transactionDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date</th>
    <td mat-cell *matCellDef="let element">{{ element.transactionDate | date }}</td>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Profile Name</th>
    <td mat-cell *matCellDef="let element">
      <div class="u-profile">
        <figure
          class="u-profile-photo"
          [style.backgroundImage]="
            'url(' +  element.avatarUrl + ')'
          "
        ></figure>
        <a
        >{{ element.firstName | titlecase }}
          {{ element.lastName | titlecase }}</a
        >
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
    <td mat-cell *matCellDef="let element" class="u-bold"
        [class.c-d]="element.amount > 0"
        [class.c-f]="element.amount < 0">{{tenantCurrency}} {{ element.amount | number: '1.0-0'}}</td>
  </ng-container>

  <ng-container matColumnDef="transactionType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Type</th>
    <td mat-cell *matCellDef="let element">
      <span class="status-label {{ element.transactionType | lowercase }}">
        {{ element.transactionType }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="country">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
    <td mat-cell *matCellDef="let element">
      {{ element.country }}
    </td>
  </ng-container>

  <ng-container matColumnDef="gender">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gender</th>
    <td mat-cell *matCellDef="let element">
      {{ element.gender | titlecase}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns; let i = index"
    (click)="showProfileDetail(row.userId)"
  ></tr>
  x
</table>
<mat-paginator
[length]="dataSource.resultsLength | async"
[pageSize]="50"
showFirstLastButtons
[pageSizeOptions]="[10, 50, 100, 200]"
></mat-paginator>
  </div>
</div>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
