import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Department} from "@orion/models";
import {MatSort} from "@angular/material/sort";
import {UntypedFormGroup} from "@angular/forms";
import {DepartmentService} from "@orion/services/department.service";
import {DepartmentsDataSource} from "@orion/pages/departments/departments-data-source";
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  @Input() isSearch: boolean;
  @Input() searchResults: any;
  displayedColumns: string[] = ['department', 'dateCreated'];
  dataSource: DepartmentsDataSource;
  resultsLength = 0;
  isLoading = true;
  view = '';
  department: Department;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtersForm: UntypedFormGroup;
  departmentId: string;

  constructor(private departmentService: DepartmentService) { }

  editDepartment(department: Department) {
    this.department = department;
    this.view = 'edit-department';
  }

  applyFilter(event: MatSelectChange) {
    const filterValue = event.value;
    this.paginator.pageIndex = 0;
    this.dataSource.loadDepartments(0, 50, 'dateCreated', 'desc', 0, JSON.stringify(this.filtersForm.value));
  }

  ngOnInit(): void {
    this.initData();
  }

  private initData() {
    this.dataSource = new DepartmentsDataSource(this.departmentService);
    this.dataSource.loadDepartments(0, 50, 'dateCreated', 'desc', 0, '');
  }

  closeCreateDepartment() {
    this.view = null;
    this.department = null;
  }

  refreshDepartments() {
    this.initData();
  }

}
