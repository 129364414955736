<div>
  <h1 class="page-title">Mobile Payments Updater</h1>

  <br />
  <div class=" xn-fadein-up xn-delay-2">
    <mat-card>
      <mat-card-content>
        <mat-tab-group>
          <mat-tab class="mat-label-details" aria-label="single-transaction" label="Single">
            <form class="single-trans-form" [formGroup]="singleTransactionForm">
              <mat-form-field appearance="outline">
                <mat-label>Provider ID</mat-label>
                <input formControlName="providerId" matInput>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Transaction Amount</mat-label>
                <input formControlName="amount" matInput>
              </mat-form-field>
              <div type="button" mat-raised-button class="procced-btn" color="primary" (click)="submitSingleTransaction()">
                <div>Proceed</div>
                <mat-icon color="proceed-icon">navigate_next</mat-icon>
              </div>
            </form>
          </mat-tab>
          <mat-tab class="mat-label-details" aria-label="batch-transactions" label="Batch">
            <div class="batch-trans-form">
              <h2 *ngIf="!isFileSelected">Upload Excel File</h2>
              <div class="choosen-file" *ngIf="isFileSelected">
                <span class="file-name">Filename:</span>
                <span>{{fileName}}</span>
              </div>
              <div *ngIf="!isFileSelected" class="uploadfilecontainer" (click)="getFile.click()" appDragDrop
                (onFileDropped)="onFileChange($event)">
                <div class="upload-text">
                  <div class="upload-icon-bg">
                    <mat-icon [inline]="true">cloud_upload</mat-icon>
                  </div>
                  <div>Drag and Drop</div>
                  <div>or <span class="browse-text">Browse</span></div>

                </div>
                <input hidden type="file" #getFile (change)="onFileChange($event)" />
              </div>
            </div>

          </mat-tab>

        </mat-tab-group>

      </mat-card-content>
    </mat-card>
  </div>
</div>
<!-- Checking transactions loader -->
<div class="loading-spinner-container" *ngIf="isLoading">
  <div class="spinner-container">
    <mat-spinner color="accent"></mat-spinner>
    <span class="spinner-text">
      Checking transactions...
    </span>
  </div>
</div>
<!-- Updating transactions loader -->
<div class="loading-spinner-container" *ngIf="isUpdating">
  <div class="spinner-container">
    <mat-spinner color="accent"></mat-spinner>
    <span class="spinner-text">
      Updating transactions...
    </span>
  </div>