import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-update',
  templateUrl: './task-update.component.html',
  styleUrls: ['./task-update.component.scss']
})
export class TaskUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
