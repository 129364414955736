
<h2 class="page-title">
  Mass Sender
</h2>
<mat-card>
  <mat-card-content>
    <div class="page-section">

        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Mails (Unselected Batch)" class="xmat-tab-label-container">
            <form class="sender-form" [formGroup]="form">
              <div class="error" *ngIf="isError">
                <p>{{message}}</p>
              </div>
              <div>
                <mat-form-field appearance="outline">
                  <mat-label>Subject</mat-label>
                  <input formControlName="subject" matInput>
                </mat-form-field>
              </div>

              <div>

                <mat-checkbox formControlName="sendForPreview">Send For Preview</mat-checkbox>

              </div>
              <br/>

              <div>
                <mat-form-field appearance="outline">
                  <mat-label>HTML Content</mat-label>
                  <textarea
                    formControlName="mailerContent"
                    rows="30"
                    matInput>

        </textarea>
                </mat-form-field>
              </div>
              <div class="u-justify actions">
                <button
                  type="button"
                  mat-stroked-button
                  (click)="clearForm()"
                  mat-flat-button
                  color="default"
                >
                  Clear
                </button>
                <button
                  type="button"
                  [disabled]="!form.valid"
                  mat-button
                  mat-flat-button
                  color="primary"
                  (click)="submitMailerFormData()"
                >
                  Send
                </button>
              </div>
            </form>
          </mat-tab>
          <mat-tab
            label="Bulk Emails"
          >
            <div class="sender-form">
              <div>
                <mat-form-field appearance="outline">
                  <mat-label>Subject</mat-label>
                  <input [(ngModel)]="subject" matInput>
                </mat-form-field>
              </div>

              <div>

                <mat-checkbox [(ngModel)]="sendForPreview">Send For Preview</mat-checkbox>

              </div>
              <br/>

              <mat-form-field appearance="outline">
                <mat-label>Mailer Content</mat-label>
                <textarea
                  [(ngModel)]="massMessageBody"
                  rows="30"
                  matInput></textarea>
              </mat-form-field>
              <div>

                <div class="ml-label">
                  <mat-label>Upload Recipient</mat-label>
                </div>
                <div>
                  <input
                    size="60"
                    type="file"
                    accept=".csv"
                    name="file"
                    (change)="uploadExcelFileForBulkMailer($event)"
                  />
                </div>

              </div>
              <div class="u-justify actions">
                <button
                  type="button"
                  mat-stroked-button
                  (click)="clearMailForm()"
                  mat-flat-button
                  color="default"
                >
                  Clear
                </button>
              </div>

            </div>
          </mat-tab>

          <mat-tab
            label="Bulk SMS"
          >
            <div class="sender-form">
              <mat-label class="ml-label">Enter SMS</mat-label>
          <mat-form-field class="form-border" appearance="">
            <textarea
            [(ngModel)]="messageBody"
              matTextareaAutosize
              rows="13"
              matInput></textarea>
          </mat-form-field>
              <!-- <mat-form-field appearance="">
                <mat-label>Enter SMS</mat-label>
                <textarea
                  [(ngModel)]="messageBody"
                  rows="30"
                  matInput></textarea>
              </mat-form-field> -->
              <div>
                <input
                  size="60"
                  type="file"
                  accept=".xlsx"
                  name="file"
                  (change)="uploadExcelFileAxeSMS($event)"
                />
              </div>
              <div class="u-justify actions">
                <button
                  type="button"
                  mat-stroked-button
                  (click)="clearForm()"
                  mat-flat-button
                  color="default"
                >
                  Clear
                </button>
                <button
                  type="button"
                  [disabled]="!form.valid"
                  mat-button
                  mat-flat-button
                  color="primary"
                  (click)="submitMailerFormData()"
                >
                  Send
                </button>
              </div>

            </div>


          </mat-tab>
        </mat-tab-group>


    </div>

  </mat-card-content>
</mat-card>

<br/>
<br/>
<br/>
