import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { merge } from 'rxjs';
import { Prospect } from '../../models';
import { ProspectService } from '../../services/prospect.service';
import { tap } from 'rxjs/operators';
import { ProspectsDataSource } from './prospects-data-source';
import { UtilitiesService } from '@orion/utilities/utilities.service';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.scss']
})
export class ProspectsComponent implements OnInit, AfterViewInit {
  @Input() isSearch: boolean;
  @Input() searchResults: any;
  displayedColumns: string[] = [
    'position',
    'dateCreated',
    'firstName',
    'accountType',
    'goal',
    'source',
    'phoneNumber',
  ];
  prospects: Prospect[] = [];
  resultsLength = 0;
  isLoading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtersForm: UntypedFormGroup;
  dataSource: ProspectsDataSource;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private prospectService: ProspectService,
    private utilitiesService: UtilitiesService,
  ) { }


  ngOnInit() {
    this.filtersForm = this.formBuilder.group({
      paymentStatus: [''],
      network: [''],
      startDate: [''],
      endDate: [''],
    });
    this.dataSource = new ProspectsDataSource(this.prospectService);
    this.dataSource.loadProspects(0, 50, 'dateCreated', 'desc', 0, '');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadProspectsPage()))
      .subscribe();
  }

  loadProspectsPage() {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadProspects(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      JSON.stringify(this.filtersForm.value)
    );
  }


  applyDateRangeFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadProspects(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

  applyPaymentStatusFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadProspects(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

  applyNetworkFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;

    this.dataSource.loadProspects(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

}
