import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {User} from '@orion/models';
import {AuthenticationService} from '@orion/services';
import { KeycloakService } from 'keycloak-angular';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appSecAccess]'
})
export class SecAccessDirective implements OnInit, OnDestroy{
  private subscription: Subscription[] = [];

  @Input() public appSecAccess: Array<string>;


  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authenticationService: AuthenticationService,
    private keycloakService: KeycloakService
  ) {
  }

  ngOnInit(): void {
    let loggedInUser = JSON.parse(localStorage.getItem("orionUser"));
    if(loggedInUser == null){
      return;
    }

    const roles = JSON.parse(localStorage.getItem("roles"));

    if (roles) {
      this.viewContainerRef.clear();
    }
    const idx = roles?.findIndex((element) => this.appSecAccess.indexOf(element) !== -1);
    if (idx < 0) {
      this.viewContainerRef.clear();
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }

    // this.subscription.push(
    //   this.authenticationService.orionUser.subscribe(res => {
    //      if (!res.roles) {
    //        this.viewContainerRef.clear();
    //      }
    //      const idx = res.roles?.findIndex((element) => this.appSecAccess.indexOf(element) !== -1);
    //      if (idx < 0) {
    //        this.viewContainerRef.clear();
    //      } else {
    //        this.viewContainerRef.createEmbeddedView(this.templateRef);
    //      }
    //   })
    // );

  }

  ngOnDestroy(): void {
    this.subscription.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

}