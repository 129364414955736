import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Account, Profile} from '../../models';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {AccountService} from '../../services/account.service';

export class InstitutionDataSource implements DataSource<Account>{
  private accountSubject = new BehaviorSubject<Account[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private accountService: AccountService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Account[] | ReadonlyArray<Account>> {
    return this.accountSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.accountSubject.complete();
    this.loadingSubject.complete();
  }

  loadAccounts(offset: any, max: any, sort: string, order: string, page: number, filter = '') {
    this.loadingSubject.next(true);
    this.accountService.getAllInstitutionalAccounts(offset, max, sort, order, page, filter).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(resp => {
      this.accountSubject.next(resp.data);
      this.resultsLengthSubject.next(resp.totalCount);
    });
  }
}
