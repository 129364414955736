import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import {OnboardingService} from "@orion/services/onboarding.service";
import {Onboarding} from "@orion/models/onboarding";

export class OnboardingDataSource implements DataSource<Onboarding> {
  private onboardingSubject = new BehaviorSubject<Onboarding[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private onboardingService: OnboardingService) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<Onboarding[] | ReadonlyArray<Onboarding>> {
    return this.onboardingSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.onboardingSubject.complete();
    this.loadingSubject.complete();
  }

  loadOnboardedClients(
    offset: any,
    max: any,
    sort: string,
    order: string,
    page: number,
    filter = ''
  ) {
    this.loadingSubject.next(true);
    this.onboardingService
      .getOnboarded(offset, max, sort, order, page, filter)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((resp) => {
          this.onboardingSubject.next(resp.data);
          this.resultsLengthSubject.next(resp.totalCount);
      });
  }

}
