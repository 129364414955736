import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AdvisorDashboardProfile } from '@orion/models/advisor-dashboard';
import { Profile } from '@orion/models/profile';
import { MatSort } from '@angular/material/sort';
import { ProfileService } from '@orion/services/profile.service';
import { UtilitiesService } from '@orion/utilities/utilities.service';
import { AdvisorUsersDashboardSource } from './single-advisor-data-source';
import { Observable, merge } from 'rxjs';
import { tap } from 'rxjs/operators'
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-single-advisor',
  templateUrl: './single-advisor.component.html',
  styleUrls: ['./single-advisor.component.scss'],
})
export class SingleAdvisorComponent implements OnInit  {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    public utilitiesService: UtilitiesService,
  ) { }

  displayedColumns: string[] = [
    'position',
    'dateCreated',
    'firstName',
    'noOfDeposits',
    'totalDeposits',
    'noOfWithdraws',
    'totalWithdraws',
    'initialAum',
    'initialBand',
    'totalAum',
    'currentBand',
    'lastDepositDate',
  ];

  @Input() showProfile = false;
  @Input() advisorDashboardProfile: AdvisorDashboardProfile
  @Input() advisorProfileId!: string;
  @Input() zIndex: number;

  @Output() hideProfile = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filtersForm: UntypedFormGroup;
  resetFiltersActive = false;
  profileDetails: Profile;
  advisorPassportUrl: any;
  dataSource: AdvisorUsersDashboardSource;
  liveImageUrl = environment.serverUrl;
  thumbNailBaseUrl = `${this.liveImageUrl}/api/v1/guest/passportphoto/`;
  clientProfileId = '';
  toggleTableView: boolean;
  isProspectStatus: boolean;
  showModal = false;
  isLoading = true;
  isDateFilter = false;
  startDate: string = '';
  endDate: string = '';
  selectedValue: string = '';
  tenantCurrency: String;

  ngOnInit(){
    this.tenantCurrency = this.utilitiesService.xnCurrencyLabel(localStorage.getItem('tenantId'))
    this.getAdvisorProfileById()
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadAdvisorUsersPage()))
      .subscribe();
  }


  loadAdvisorUsersPage() {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadAdvisoryUsers(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      JSON.stringify(this.filtersForm.value),
      this.advisorProfileId,
    );
  }


  getAdvisorProfileById()  {
    this.filtersForm = this.formBuilder.group({
      band: [''],
      startDate: [''],
      endDate: [''],
    });
    this.dataSource = new AdvisorUsersDashboardSource(this.profileService)
    this.dataSource.loadAdvisoryUsers(0, 50, 'dateCreated', 'desc', 0, '', this.advisorProfileId);
  }

  applyDateRangeFilter() {
    this.resetFiltersActive = true
    this.filtersForm.value.startDate = this.startDate;
    this.filtersForm.value.endDate = this.endDate;
    this.paginator.pageIndex = 0;
    this.dataSource.loadAdvisoryUsers(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value),
      this.advisorProfileId
    );
  }

  applyBandFilter(event: MatSelectChange) {
    this.resetFiltersActive = true
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadAdvisoryUsers(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value),
      this.advisorProfileId
    );
  }

  closeWindow() {
    this.showProfile = false;
    this.hideProfile.emit()
  }
  isNegativeAbusoluteAUM(aum: number): boolean {
    if ((aum.toString()).includes('-')) {
      return true;
    } else {
      return false;
    }
  }

  onChangeDays(days: any) {
    this.isDateFilter = true;
    this.resetFiltersActive = true
    this.selectedValue = "";
    if (days != 'range') {
      const number: number = parseInt(days);
      this.startDate = this.utilitiesService.getStartDate(number);
      this.endDate = this.utilitiesService.addDays(new Date(), 1);
      this.applyDateRangeFilter();
    }
  }

  onSetAllTime() {
    this.resetFilters()
  }

  setStartDate(e: string) {
    this.isDateFilter = true;
    if (e) {
      this.startDate = e;
      this.applyDateRangeFilter();
    }
  }

  setEndDate(e: string) {
    this.isDateFilter = true;
    if (e) {
      this.endDate = e;
      this.applyDateRangeFilter();
    }
  }

  onTableViewChange(event: any) {
    this.toggleTableView = event;
  }

  onTableViewClick(event: any){
    event.stopPropagation();
    this.toggleTableView = event;
  }


  showProfileDetail(profileId: string) {
    this.clientProfileId = profileId;
    this.isProspectStatus = false;
    this.showModal = true;
  }

  hideProfileModal() {
    this.showModal = false;
    this.clientProfileId = '';
  }

  resetFilters(){
    this.startDate = "";
    this.endDate = "";
    this.selectedValue = "all-time";
    this.isDateFilter = false;
    this.filtersForm.reset();
    this.getAdvisorProfileById()
    this.paginator.pageIndex = 0;
    this.resetFiltersActive = false
  }


 }
