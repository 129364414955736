export function isMtnNumber(number: number) {
    var nub = parseInt(number.toString().substring(0, 3));
    if ((nub >= 760 && nub <= 779) ||
        (nub >= 780 && nub <= 789) ||
        (nub >= 310 && nub <= 399)) {
        return true;
    } else {
        return false;
    }
}
