import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { RolesDataSource } from './roles-data-source';
import { MatSort } from '@angular/material/sort';
import { UntypedFormGroup } from '@angular/forms';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RoleService } from '../../services/role.service';
import { Role, UserRole } from '../../models';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";


@Component({
  selector: 'roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  @Input() isSearch: boolean;
  @Input() searchResults: any;
  displayedColumns: string[] = ['name'];
  dataSource: RolesDataSource;
  resultsLength = 0;
  isLoading = true;
  view = '';
  role: Role;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtersForm: UntypedFormGroup;
  userRoleId: string;

  constructor(private userRolesService: RoleService) { }

  editRole(role: Role) {
    this.role = role;
    this.view = 'edit-role';

  }

  applyFilter(event: MatSelectChange) {
    const filterValue = event.value;
    this.paginator.pageIndex = 0;

    this.dataSource.loadRoles(0, 50, 'dateCreated', 'desc', 0, JSON.stringify(this.filtersForm.value));
  }

  ngOnInit(): void {
    this.initData();
  }

  private initData() {
    this.dataSource = new RolesDataSource(this.userRolesService);
    this.dataSource.loadRoles(0, 50, 'dateCreated', 'desc', 0, '');
  }

  closeCreateRole() {
    this.view = null;
    this.role = null;
  }

  refreshRoles() {
    this.initData();
  }
}
