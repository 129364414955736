import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ProfileService } from "../../services/profile.service";
import { catchError, finalize } from "rxjs/operators";
import { AdvisorDashboardProfile } from "@orion/models/advisor-dashboard";

export class AdvisorDashboardSource implements DataSource<AdvisorDashboardProfile> {
  private advisorsSubject = new BehaviorSubject<AdvisorDashboardProfile[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private assignedUsersSubject = new BehaviorSubject(0);
  private assignedTotalAUMSubject = new BehaviorSubject(0);
  private advisorSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();
  public totalAssignedUsers = this.assignedUsersSubject.asObservable();
  public totalAssignedUsersAUM = this.assignedTotalAUMSubject.asObservable();
  public totalAdvisors = this.advisorSubject.asObservable();

  constructor(private profilesService: ProfileService) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<AdvisorDashboardProfile[] | ReadonlyArray<AdvisorDashboardProfile>> {
    return this.advisorsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.advisorsSubject.complete();
    this.loadingSubject.complete();
  }

  loadAdvisoryProfiles(
    offset: any,
    max: any,
    sort: string,
    order: string,
    page: number,
    filter = '',
  ) {
    this.loadingSubject.next(true);
    this.profilesService
      .getAllAdvisors(offset, max, sort, order, page, filter)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((resp) => {
        this.advisorsSubject.next(resp.data);
        this.resultsLengthSubject.next(resp.totalCount);
        this.assignedUsersSubject.next(resp.assignedUsers)
        this.assignedTotalAUMSubject.next(resp.assignedAUM)
        this.advisorSubject.next(resp.totalAdvisors)
      });
  }
}
