<div class="xn-container">
  <div class="flex-container">
    <div class="flex-child">
      <p class="xn-text" mat-dialog-title>{{data.title}}</p>
    </div>
    <div class="flex-child xn-minus-top">
      <button class="close-icon-button pointer" mat-dialog-close>
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="xn-box1">
    <div mat-dialog-actions class="xn-margintop">
      <button mat-flat-button color="primary" (click)="confirm()">{{data.confirmText}}</button>
      <button mat-flat-button mat-dialog-close class="default ml-22">{{data.cancelText}}</button>
    </div>
  </div>
</div>

