import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { KycRevertService } from '@orion/services/kyc-revert.service';
import { UtilitiesService } from '@orion/utilities/utilities.service';
export interface KycRevert {
  enabled: boolean;
  label: string;
  value?: string;
}
export interface KycRevertSection {
  type: string;
  reverts: KycRevert[];
}
@Component({
  selector: 'app-kyc-revert',
  templateUrl: './kyc-revert.component.html',
  styleUrls: ['./kyc-revert.component.scss'],
})
export class KycRevertComponent implements OnInit {
  @Output() close = new EventEmitter();
  isError = false;
  message!: string;
  @Input() profileId: string;
  isShowBioDataOther: boolean;
  isShowIncomeSourceOther: boolean;
  isShowAddressOther: boolean;
  kycRevertSections: KycRevertSection[] = [
    {
      type: 'Visual ID',
      reverts: [
        { enabled: false, label: 'Selfie photo doesn\'t match ID photo' },
        { enabled: false, label: 'Selfie photo doesn\'t match Passport photo' },
        { enabled: false, label: 'Selfie photo doesn\'t match Driver\'s Licence photo' },
        { enabled: false, label: 'Uploaded ID is improper or unclear' },
        { enabled: false, label: 'Uploaded Passport is improper or unclear' },
        { enabled: false, label: 'Uploaded Driver\'s Licence is improper or unclear' },
        { enabled: false, label: 'Uploaded selfie photo is improper or unclear' },
        { enabled: false, label: 'ID has expired' },
        { enabled: false, label: 'Passport has expired' },
        { enabled: false, label: 'Driver\'s Licence has expired' },
        { enabled: false, label: 'Other' },
      ],
    },
    {
      type: 'Address',
      reverts: [
        {
          enabled: false,
          label: 'Improper information'
        },
        {
          enabled: false,
          label: 'Other'
        }
      ],
    },
    {
      type: 'Source of Income',
      reverts: [
        {
          enabled: false,
          label: 'Improper information'
        },
        {
          enabled: false,
          label: 'Other',
        },
      ],
    },
    {
      type: 'Personal Information',
      reverts: [
        {
          enabled: false,
          label: 'Name does not match the name provided on the ID document',
        },
        {
          enabled: false,
          label: 'Date of birth does not match the birth date provided on the ID document',
        },
        {
          enabled: false,
          label: 'Date of birth is below the acceptable age',
        },
        {
          enabled: false,
          label: 'Gender provided does not match the gender on the ID document',
        },
        {
          enabled: false,
          label: 'Nationality does not match the nationality on the provided ID document',
        },
        {
          enabled: false,
          label: 'Other',
        },
      ],
    },
  ];
  serverObject = [];
  formGroup: UntypedFormGroup;
  showErrors = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private kycRevertService: KycRevertService,
    private utility: UtilitiesService
  ) {}
  onSubmit() {
    const formValues = this.formGroup.value;

    const kycSectionsWithValues: number[] = [];

    for (const [key, value] of Object.entries(formValues)) {
      const formControlName: string = key;
      const formControlValue: any = value;

      const formControlNameArray = formControlName.split('-');
      const sectionIndex = parseInt(formControlNameArray[0]);

      if (formControlValue != null && formControlValue != false){
        if (!kycSectionsWithValues.includes(sectionIndex)){
          kycSectionsWithValues.push(sectionIndex);
        }
      }
    }


    const payload: any[] = [];
    let reverts: any;
    let revertLabel: string;
    let selectedType: any;

    kycSectionsWithValues.forEach((kycSectionIndex) => {

      const kycSectionReverts: any[] = [];


      for (const [key, value] of Object.entries(formValues)) {
        const formControlName: string = key;
        const formControlValue: any = value;

        const formControlNameArray = formControlName.split('-');
        const formControlParentIndex = parseInt(formControlNameArray[0]);
        const formControlIndex = parseInt(formControlNameArray[1]);

        if (formControlValue != null && formControlValue != false){
          if (formControlParentIndex === kycSectionIndex){
            revertLabel = this.kycRevertSections[kycSectionIndex].reverts[formControlIndex].label;


            if (revertLabel !== 'Other'){
              kycSectionReverts.push(revertLabel);
              reverts = kycSectionReverts;
            } else {
              kycSectionReverts.push(formControlValue);
              reverts = kycSectionReverts.filter((e) => e !== true);
            }
          }
        }
      }

      selectedType = this.kycRevertSections[kycSectionIndex].type;

      payload.push(
        {
          type: this.kycRevertSections[kycSectionIndex].type,
          reverts
        }
      );


    });


    if ((this.formGroup.value['0-4-other'] == null || this.formGroup.value['0-4-other'] == '')  &&
      this.formGroup.value['0-4'] == true ||
      (this.formGroup.value['1-1-other'] == null || this.formGroup.value['1-1-other'] == '') &&
      this.formGroup.value['1-1'] == true ||
      (this.formGroup.value['2-1-other'] == null || this.formGroup.value['2-1-other'] == '') &&
      this.formGroup.value['2-1'] == true ||
      (this.formGroup.value['3-5-other'] == null || this.formGroup.value['3-5-other'] == '') &&
      this.formGroup.value['3-5']  == true) {
      alert('Please fill in other options to proceed');
      return;
    }

    if (!kycSectionsWithValues.length){
      alert('Please tick some options to proceed');
      return;
    }

    this.utility.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.kycRevertService.revertKyc(JSON.stringify(Object.fromEntries(payload.entries())), this.profileId).subscribe({
          next: (response) => {
            if (response.code == 200) {
              this.isError = false;
              this.utility.successAlert('KYC reverted Successfully');
              this.ngOnInit();
              this.close.emit();
            }
          },
          error: (error) => {
            this.utility.errorAlert(error.msg);
          }
        });
      }
    });

  }
  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});
    this.kycRevertSections.forEach((kycSection, kycSectionIndex) => {
      const sectionName = kycSectionIndex;
      kycSection.reverts.forEach((revert, revertIndex) => {
        const controlName = sectionName + '-' + revertIndex;
        if (revert.label === 'Other') {
          this.formGroup.addControl(controlName + '-other' , new UntypedFormControl());
        }
        this.formGroup.addControl(controlName, new UntypedFormControl());
      });
    });
  }
  closeKycRevertWindow() {
    this.close.emit();
  }
}
