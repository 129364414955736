import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Request} from '../models/request';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  private apiUrl = `${environment.serverUrl}/api/user-requests`;

  constructor(private http: HttpClient) { }

  getAll(): Observable<Request[]>{
    return this.http.get<Request[]>(`${this.apiUrl}/all`);
  }
}
