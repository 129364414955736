<div class="date-flex space-btn center u-pb-12">
    <div class="u-flex inputs">
      <div class="input">
        <div class="icon left">
          <div>
            <!-- <xn-icon name="calendar"></xn-icon> -->
          </div>
        </div>
        <div class="icon right">
          <div>
            <!-- <xn-icon name="chevron-down"></xn-icon> -->
          </div>
        </div>
        <div class="date-filter-label">Date filter</div>
        <select class="select-box" (change)="changeDate($event)">
          <option [value]="selectedValue ? selectedValue : 'all-time'" [selected]="selectedValue ? true : false">Since Assigned</option>
          <option value="1">Today</option>
          <option value="7">Last 7 days</option>
          <option value="30">Last 30 days</option>
          <option value="60">Last 60 days</option>
          <option value="90">Last 90 days</option>
          <option value="120">Last 120 days</option>
          <option value="range">Custom Range</option>
        </select>
      </div>
      <div class="start-date-input bg-input" *ngIf="value == 'range' && selectedValue == ''" >
        <div class="date-filter-label">Start Date</div>
      <div class="input date-picker" >
        <div class="input-display">
          <input
          type="text"
          (click)="startDatePicker.open()"
          (dateChange)="rangeStart($event)"
          [matDatepicker]="startDatePicker"
          placeholder="Select"
        />
        <mat-icon class="-icon">expand_more</mat-icon>
        </div>
        <div class="picker">
          <mat-datepicker #startDatePicker></mat-datepicker>
        </div>
      </div>
      </div>
      <div *ngIf="value == 'range' && selectedValue == ''" class="bg-input">
        <div class="date-filter-label">End Date</div>
      <div class="input date-picker" >
        <div class="input-display">
        <input
          type="text"
          (click)="endDatePicker.open()"
          (dateChange)="rangeEnd($event)"
          [matDatepicker]="endDatePicker"
          placeholder="Select"
        />
        <mat-icon class="-icon">expand_more</mat-icon>
        </div>
         
        <div class="picker">
          <mat-datepicker #endDatePicker></mat-datepicker>
        </div>
      </div>
      </div>
    </div>
    <div class="xn-value-tag">
        <div class="icon">
          <!-- <xn-icon name="calendar"></xn-icon> -->
        </div>
        <span>{{ startDate ? (startDate | date) + ' to' : '' }}  {{ endDate | date }}</span>
      </div>
  </div>
  