import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MassSenderService} from '@orion/services/mass-sender.service';
import {UtilitiesService} from '@orion/utilities/utilities.service';

@Component({
  selector: 'app-bulk-emails',
  templateUrl: './bulk-emails.component.html',
  styleUrls: ['./bulk-emails.component.scss']
})
export class BulkEmailsComponent implements OnInit {
  form: UntypedFormGroup;
  isError = false;
  message!: string;


  constructor(private massSenderService: MassSenderService,
              private formBuilder: UntypedFormBuilder,
              private utilities: UtilitiesService
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      subject: ['', [Validators.required, ]],
      sendForPreview: [''],
      sendForNoReply: [''],
      mailerContent: ['', [Validators.required, ]],
    });
  }

  submitMailFormData() {
    const formData = this.form.value;
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.massSenderService.sendMailer(formData)
          .subscribe((resp) => {
              if (resp.code === 200) {
                this.utilities.successAlert(`${resp.msg}`);
                this.isError = false;
                this.ngOnInit();
              } else {
                this.utilities.errorAlert(`${resp.msg}`);
                this.message = `${resp.msg}!`;
                this.isError = true;
                this.ngOnInit();
              }
            },
            error => {
              this.utilities.errorAlert(`${error}`);
              this.message = `${error}!`;
              this.isError = true;
              this.ngOnInit();
            }
          );
      }
    });
  }

  clearForm() {
    this.form.reset();
  }
}
