import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';

import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {Request} from '@orion/models';
import {RequestsService} from '../../services/requests.service';
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: "requests",
  templateUrl: "./requests.component.html",
  styleUrls: ["./requests.component.scss"],
})
export class RequestsComponent implements OnInit {
  isLoading = true;
  USER_REQUEST_DATA: Request[];

  // filters
  filtersForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private requestService: RequestsService
  ) {}

  get accountFilter() {
    return this.filtersForm.get("accountFilter");
  }

  get requestType() {
    return this.filtersForm.get("requestType");
  }

  get startDate() {
    return this.filtersForm.get("startDate");
  }

  get endDate() {
    return this.filtersForm.get("startDate");
  }

  // table
  displayedColumns: string[] = ["name", "type", "dateCreated", "status"];
  // @ts-ignore
  dataSource = new MatTableDataSource(this.USER_REQUEST_DATA);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //events
  requestView = false;
  showRequestDetails() {
    this.requestView = true;
  }

  hideRequestDetails(){
    this.requestView = false;
  }

  ngOnInit() {
    // tables
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // form
    this.filtersForm = this.formBuilder.group({
      requestType: [""],
      accountFilter: [""],
      startDate: [""],
      endDate: [""],
    });
    this.getAllUserRequests();
  }

  getAllUserRequests() {
    const resp = this.requestService.getAll();
    resp.subscribe(
      (userRequests) => {
        this.isLoading = false;
        this.dataSource.data = userRequests as Request[];
      },
      (error) => (this.isLoading = false)
    );
  }
}
