import {Directive, HostListener} from '@angular/core';
import {environment} from '../../environments/environment';

@Directive({
  selector: '[appNoRightClick]'
})
export class NoRightClickDirective {
  constructor() {
  }
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    if (environment.production) {
      event.preventDefault();
    }
  }
}
