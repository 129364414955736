<slideover [zIndex]="3" (closeWindow)="close.emit()">
   <ng-container *ngIf="!isEditNote">
      <div class="note-display">
         <div class="view-note u-justify">
            <h5>{{userNote.subject}}</h5>
            <span [ngClass]="(userNote.staffId == orionUser.id) && editButtonVisible ? 'posted-on-date' : '' ">{{userNote?.lastEditedBy !=
               null ? userNote?.staff + ' Edited on: ' + (userNote?.lastEditedOn | date) : userNote?.staff +' | '+ userNote?.postedOn }}</span>
            <mat-icon *ngIf="(userNote.staffId == orionUser.id) && editButtonVisible"
               (click)="showEditNote(userNote.subject, userNote.note, userNote.id)" class="edit-icon">edit</mat-icon>
         </div>
         <p class="note-p">{{userNote.note}}</p>
         <div class="seperator-line"></div>
      </div>
   </ng-container>
   <!-- Edit note form -->
   <ng-container class=" " *ngIf="isEditNote">
      <div class="u-justify">
         <span></span>
         <mat-icon (click)="hideEditNote()" class="close-icon">cancel</mat-icon>
      </div>
      <div class="edit-note-form">
         <form [formGroup]="editNoteForm" class="" (ngSubmit)="editComment(userNote.id)">
            <div>
               <mat-form-field appearance="fill">
                  <mat-label class="user-name">To do</mat-label>
                  <mat-select formControlName="editSubject" [value]="selected">
                     <mat-option value="Call">Call</mat-option>
                     <mat-option value="Email">Email</mat-option>
                     <mat-option value="Meeting">Meeting</mat-option>
                   </mat-select>
               </mat-form-field>
            </div>

            <div>
               <mat-form-field appearance="fill">
                  <mat-label class="user-name">Note</mat-label>
                  <textarea matInput matTextareaAutosize rows="10" placeholder="Enter notes..."
                     formControlName="editNote"></textarea>
               </mat-form-field>
            </div>
            <div class="u-justify ">
               <div></div>
               <button [disabled]="!(editNoteForm.value.editSubject && editNoteForm.value.editNote)" type="submit"
                  mat-button mat-flat-button color="primary">
                  Update
               </button>
            </div>
         </form>

      </div>
   </ng-container>

   <!-- Activity section -->
   <div class="comment-activity">
      <h5>Activity</h5>
      <ng-container *ngIf="userNote.replies.length != 0">
         <div class="comment-view" *ngFor="let reply of userNote.replies" (mouseover)="showEditButton(reply.lastEditedOn ? reply.lastEditedOn : reply.postedOnDate)">
            <div class="user-icon">
               <div class="border-line-left"></div>
               <span >{{reply.initials}}</span>
               <hr>
            </div>
            <div class="comment-details">
               <span class="comment-header"><span><span class="user-names">{{(reply.staff).toLowerCase()}}</span>
                     {{reply.lastEditedBy != null ? 'Edited on: ' + (reply.lastEditedOn | date) : reply.postedOn
                     }}</span><span *ngIf="reply.staffId == orionUser.id && editButtonVisibleComment"><mat-icon
                        (click)="showEditInput(reply.id, reply.note)" class="edit-icon">edit</mat-icon></span></span>
               <div>{{reply.note}}</div>
               <!-- Edit Comment -->
               <ng-container *ngIf="isEditComment && editCommentId == reply.id" >
                  <div class="comment-form">
                     <div class="u-justify">
                        <span></span>
                        <mat-icon (click)="hideEditInput()" class="close-icon">cancel</mat-icon>
                     </div>
                     <form [formGroup]="editCommentForm" (ngSubmit)="editComment(reply.id)" class="">
                        <div>
                           <mat-form-field appearance="none">
                              <textarea matTextareaAutosize formControlName="editComment" matInput></textarea>
                           </mat-form-field>
                        </div>
                        <div class="u-justify ">
                           <div></div>
                           <div class="comment-btn">
                              <!-- <mat-icon [ngClass]="commentForm.value.comment ? 'attach-icon' : 'attach-disabled-icon'"> attach_file</mat-icon> -->
                              <button [disabled]="!(editCommentForm.value.editComment)" type="submit" mat-button
                                 mat-flat-button color="primary">
                                 Update
                              </button>
                           </div>
                        </div>
                     </form>
                  </div>
               </ng-container>
            </div>
         </div>
      </ng-container>

      <ng-container *ngIf="userNote.replies.length == 0" class="center">
         <p class="note-p">No activity for this note...</p>
      </ng-container>

      <!-- Comment section -->
      <div class="comment-form">
         <form [formGroup]="commentForm" (ngSubmit)="submitComment()" class="">
            <div>
               <mat-form-field appearance="none">
                  <textarea matTextareaAutosize formControlName="comment" placeholder="Leave a comment..."
                     matInput></textarea>
               </mat-form-field>
            </div>
            <div class="u-justify ">
               <div></div>
               <div class="comment-btn">
                  <!-- <mat-icon [ngClass]="commentForm.value.comment ? 'attach-icon' : 'attach-disabled-icon'"> attach_file</mat-icon> -->
                  <button [disabled]="!(commentForm.value.comment)" type="submit" mat-button mat-flat-button
                     color="primary">
                     Comment
                  </button>
               </div>
            </div>
         </form>
      </div>
   </div>
</slideover>
