<div class="dialog-header">
    <h1 mat-dialog-title class="dialog-title">Validated data</h1>
  </div>
  <div class="user-count">Total Number of user to assign an advisor: <span>{{advisorData.length}}</span></div>
  <div mat-dialog-content>
    <table mat-table [dataSource]="advisorData" matSort matSortActive="dateCreated" matSortDisableClear
      matSortDirection="desc" class="xn-table">
  
      <!-- FUllname Column -->
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>User Fullname</th>
        <td mat-cell *matCellDef="let element">
          {{ element.firstName}} {{ element.lastName}}
        </td>
      </ng-container>

       <!-- FUllname Column -->
       <ng-container matColumnDef="userEmail">
        <th mat-header-cell *matHeaderCellDef>User Email</th>
        <td mat-cell *matCellDef="let element">
          {{ element.email}}
        </td>
      </ng-container>

       <!-- Adisor Column -->
       <ng-container matColumnDef="advisor">
        <th mat-header-cell *matHeaderCellDef>Advisor</th>
        <td mat-cell *matCellDef="let element">
          {{ element.advisor}}
        </td>
      </ng-container>
  
  
  
      <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns  sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    </table>
  </div>
  <div mat-dialog-actions class="u-justify">
    <button mat-stroked-button (click)="cancelUpdate()">Cancel</button>
    <button mat-raised-button (click)="proceedToUpdate()" color="primary">Assign Advisor</button>
  </div>