import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProfileService} from '@orion/services/profile.service';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {Profile} from '@orion/models';

@Component({
  selector: 'app-edit-profile-income-source',
  templateUrl: './edit-profile-income-source.component.html',
  styleUrls: ['./edit-profile-income-source.component.scss']
})
export class EditProfileIncomeSourceComponent implements OnInit {
  // tslint:disable-next-line:no-output-native
  @Output() close = new EventEmitter();
  editForm: UntypedFormGroup;
  @Input() profileInformation: Profile;
  @Input() profileEmail: string;
  isError = false;
  message!: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private utilities: UtilitiesService,
              private profileService: ProfileService) {

  }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      id: [''],
      source: [
        null,
        [
          Validators.required,
        ],
      ],
      employer: [
        null,
        [
          Validators.required,
        ],
      ],
      monthlyIncome: [
        null,
        [
          Validators.required,
        ],
      ],
      expectedMonthlyIncome: [
        null,
        [
          Validators.required,
        ],
      ],
    });
  }

  submitEditForm(): void {
    const incomeData = this.editForm.value;

    const formData = new FormData();
    formData.append('userId', this.profileEmail);
    formData.append('id', this.profileInformation.incomeSources[0].id);
    formData.append('sourceName', incomeData.employer);
    formData.append('sourceType', incomeData.source);
    formData.append('sourceIncome', incomeData.monthlyIncome);
    formData.append('expectedMonthly', incomeData.expectedMonthlyIncome);
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.profileService.updateProfileIncomeSource(formData).subscribe({
          next: (response) => {
            if (response.code === 200) {
              this.close.emit();
              this.isError = false;
              this.utilities.successAlert('Successfully Updated.');
              this.ngOnInit();
              window.location.reload();
            }

          },
          error: (error) => {
            this.isError = true;
            this.message = error.msg;
          }
        });
      }
    });

  }

}
