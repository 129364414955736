import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Profile, Request} from "@orion/models";
import {Referrals} from "@orion/models/referrals";

@Injectable({
  providedIn: 'root'
})
export class ReferralsService {

  private apiUrl = `${environment.serverUrl}/api/v1/users/referrals`;

  constructor(private http: HttpClient) { }

  getPromoCodes(): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/list-promo-codes`);
  }


  getReferrals(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/list-referrals?offset=${offset}&max=${max}&sort=${sort}&order=${order}&filter=`, {filter});
  }

  searchReferral(filter): Observable<Referrals[]> {
    let filterOption = filter ? filter : "''";
    return this.http.get<any>(`${this.apiUrl}/search/${filterOption}`);
  }
}
