import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MassSenderService {

  apiUrl = `${environment.serverUrl}/api/v1`;

  constructor(private http: HttpClient) { }

  sendMailer(formData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/emails/mass-emails`, formData);
  }

  uploadExcelFileAxeBulkMailList(formData: FormData): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/emails/mass-emails-csvupload`,
      formData,
      {}
    );
  }

  uploadExcelFileAxeBulkSmsList(formData: FormData): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/sms/send-bulk-sms-list`,
      formData,
      {}
    );
  }
}
