import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UtilitiesService } from '@orion/utilities/utilities.service';
import { AdvisoryUploadService } from '@orion/services/advisory-upload.service';
import { AdvisoryUploadDialogComponent } from '../advisory-upload-dialog/advisory-upload-dialog.component';
import { ProfileService } from '@orion/services/profile.service';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-advisory-upload',
  templateUrl: './advisory-upload.component.html',
  styleUrls: ['./advisory-upload.component.scss'],
})
export class AdvisoryUploadComponent implements OnInit  {
  @Input() showAdvisorUpload = false;
  @Input() zIndex: number;

  @Output() hideAdvisorUpload = new EventEmitter();

  isError = false;
  isFileSelected = false;
  isLoading = false;
  isUpdating = false;
  responseData: any;
  message!: string;
  fileName: string;
  fileToUpload: File = null;
  @ViewChild('getFile')
  myInputVariable: ElementRef;
  error: string;
  dragAreaClass: string;

  constructor(
    private dialog: MatDialog,
    private utilities: UtilitiesService,
    private advisoryUploadService: AdvisoryUploadService
    ) { }

    ngOnInit(): void {
      this.dragAreaClass = "dragarea";
    }

    closeWindow() {
      this.showAdvisorUpload = false;
      this.hideAdvisorUpload.emit()
    }

  // CSV file upload
  onFileChange(e) {
    if (e.target.files.length > 0) {
      this.fileToUpload = e.target.files[0];
      this.fileName = e.target.files[0].name;
      this.isFileSelected = true;
      this.uploadExcelFile();
    } else {
      this.fileToUpload = null;
    }
  }

  // Checking File data
  uploadExcelFile() {
    if (this.fileToUpload === null) {
      this.utilities.error2Alert('Please upload the CSV File');
    }
    const formData = new FormData();
    formData.append('file', this.fileToUpload);
    this.utilities.confirm2Alert().then((result) => {
      if (result.isDismissed) {
        window.location.reload();
      }
      else if (result.isConfirmed) {
        this.isLoading = true;
        this.advisoryUploadService
          .uploadExcelFile(formData)
          .subscribe(
            (response) => {
              if (response.code === 200) {
                const items: Array<any> = response.data;
                if (items.length > 0) {
                  this.isLoading = false;
                  this.responseData = [];
                  items.forEach((item) => {
                    this.responseData.push(item);
                  });
                  this.responseDialog(this.responseData);
                } else {
                  this.isLoading = false;
                  this.utilities.success2Alert('Failed to extract data!!').then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                  });
                }
                this.ngOnInit();
              } else {
                this.isLoading = false;
                this.utilities.error2Alert('Failed to upload file!!').then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
                this.ngOnInit();
              }
            },
            (error) => {
              this.isLoading = false;
              this.utilities.error2Alert(error);
              this.ngOnInit();
            }
          );
      }
    });
  }

  // Dialog to view advisor data
  responseDialog(data) {
    const dialogRef = this.dialog.open(
      AdvisoryUploadDialogComponent,
      {
        width: '1100px',
        data: {
          advisorData: data,
        },
        hasBackdrop: true,
      }
    );
    dialogRef.afterClosed().subscribe((updateProceed: boolean) => {
      if (updateProceed) {
        this.isUpdating = true;
        this.advisoryUploadService.assignAdvisors(this.responseData).subscribe((response) => {
          if (response.code === 200) {
            this.isUpdating = false;
            this.utilities.success2Alert('Advisors assigned successfully!!').then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          } else {
            this.isUpdating = false;
            this.utilities.error2Alert('Failed to assign advisors!!').then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
        });
      } else {
        window.location.reload();
      }
    })
  }


  onFileDrop(event: any) {
    this.onFileChange(event);
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.fileToUpload = event.dataTransfer.files[0];
      this.fileName = event.dataTransfer.files[0].name;
      this.isFileSelected = true;
      this.uploadExcelFile();
    }
  }

 }
