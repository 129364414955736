import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  @Input() class: string;
  @Input() progress: number;

  width = 70;
  strokeWidth = 4;
  radius = (this.width / 2) - (this.strokeWidth * 2);
  circumference = this.radius * 2 * Math.PI;
  dashArray = '' + this.circumference + ' ' + this.circumference;
  dashOffset = this.circumference
  viewBox = '0 0 ' + this.width + ' ' + this.width;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.dashOffset = this.circumference - this.progress / 100 * this.circumference
    }, 250);

    //uncomment this
    if (!this.progress) {
      this.progress = 0
    }

  }

}
