import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ProfileService} from "@orion/services/profile.service";
import {UtilitiesService} from "@orion/utilities/utilities.service";
import {Advisor} from "@orion/models/advisor";

@Component({
  selector: 'app-edit-advisor',
  templateUrl: './edit-advisor.component.html',
  styleUrls: ['./edit-advisor.component.scss']
})
export class EditAdvisorComponent implements OnInit {

  @Output() close = new EventEmitter();
  editForm: UntypedFormGroup;
  @Input() userId: any;
  @Input() advisorInfo: any;
  advisors: Advisor[];

  isError: boolean = false;
  message!: string;


  constructor(private formBuilder: UntypedFormBuilder,
              private profileService: ProfileService,
              private utilities: UtilitiesService) { }

  ngOnInit(): void {
    this.getAdvisors();

    this.editForm = this.formBuilder.group({

      advisor: [
        null,
        [
          Validators.required,
        ],
      ],
    });

  }

  submitEditForm(): void{
    let advisor = this.editForm.value;
    const formData = new FormData();
    formData.append("userId", this.userId);
    formData.append("advisorId", advisor.advisor);

    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.profileService.assignProfileToAdvisor(formData).subscribe({
          next: (response) =>{
            if(response.code == 200) {
              this.closeForm();
              this.isError = false;
              this.utilities.successAlert("Successful.");
              this.ngOnInit();
            }
          },
          error: (error) =>{

          }
        })
      }
    });

  }

  closeForm() {
    this.close.emit();
  }

  getAdvisors() {
    this.profileService.getAdvisors().subscribe((advisors) => {
      this.advisors = advisors;
    }, error => {});
  }
}
