import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AllocationProfile, GoalTarget } from '@orion/models';


@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  private apiUrl = `${environment.serverUrl}/api/v1/users`;

  constructor(
    private http: HttpClient
  ) {
  }

  countries(): any {
    return [
      { name: 'Albania', code: 'AL' },
      { name: 'Åland Islands', code: 'AX' },
      { name: 'Algeria', code: 'DZ' },
      { name: 'American Samoa', code: 'AS' },
      { name: 'Andorra', code: 'AD' },
      { name: 'Angola', code: 'AO' },
      { name: 'Anguilla', code: 'AI' },
      { name: 'Antarctica', code: 'AQ' },
      { name: 'Antigua and Barbuda', code: 'AG' },
      { name: 'Argentina', code: 'AR' },
      { name: 'Armenia', code: 'AM' },
      { name: 'Aruba', code: 'AW' },
      { name: 'Australia', code: 'AU' },
      { name: 'Austria', code: 'AT' },
      { name: 'Azerbaijan', code: 'AZ' },
      { name: 'Bahamas (the)', code: 'BS' },
      { name: 'Bahrain', code: 'BH' },
      { name: 'Bangladesh', code: 'BD' },
      { name: 'Barbados', code: 'BB' },
      { name: 'Belarus', code: 'BY' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Belize', code: 'BZ' },
      { name: 'Benin', code: 'BJ' },
      { name: 'Bermuda', code: 'BM' },
      { name: 'Bhutan', code: 'BT' },
      { name: 'Bolivia (Plurinational State of)', code: 'BO' },
      { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
      { name: 'Bosnia and Herzegovina', code: 'BA' },
      { name: 'Botswana', code: 'BW' },
      { name: 'Bouvet Island', code: 'BV' },
      { name: 'Brazil', code: 'BR' },
      { name: 'British Indian Ocean Territory (the)', code: 'IO' },
      { name: 'Brunei Darussalam', code: 'BN' },
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Burkina Faso', code: 'BF' },
      { name: 'Burundi', code: 'BI' },
      { name: 'Cabo Verde', code: 'CV' },
      { name: 'Cambodia', code: 'KH' },
      { name: 'Cameroon', code: 'CM' },
      { name: 'Canada', code: 'CA' },
      { name: 'Cayman Islands (the)', code: 'KY' },
      { name: 'Central African Republic (the)', code: 'CF' },
      { name: 'Chad', code: 'TD' },
      { name: 'Chile', code: 'CL' },
      { name: 'China', code: 'CN' },
      { name: 'Christmas Island', code: 'CX' },
      { name: 'Cocos (Keeling) Islands (the)', code: 'CC' },
      { name: 'Colombia', code: 'CO' },
      { name: 'Comoros (the)', code: 'KM' },
      { name: 'Congo (the Democratic Republic of the)', code: 'CD' },
      { name: 'Congo (the)', code: 'CG' },
      { name: 'Cook Islands (the)', code: 'CK' },
      { name: 'Costa Rica', code: 'CR' },
      { name: 'Croatia', code: 'HR' },
      { name: 'Cuba', code: 'CU' },
      { name: 'Curaçao', code: 'CW' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Czechia', code: 'CZ' },
      { name: 'Côte d\'Ivoire', code: 'CI' },
      { name: 'Denmark', code: 'DK' },
      { name: 'Djibouti', code: 'DJ' },
      { name: 'Dominica', code: 'DM' },
      { name: 'Dominican Republic (the)', code: 'DO' },
      { name: 'Ecuador', code: 'EC' },
      { name: 'Egypt', code: 'EG' },
      { name: 'El Salvador', code: 'SV' },
      { name: 'Equatorial Guinea', code: 'GQ' },
      { name: 'Eritrea', code: 'ER' },
      { name: 'Estonia', code: 'EE' },
      { name: 'Eswatini', code: 'SZ' },
      { name: 'Ethiopia', code: 'ET' },
      { name: 'Falkland Islands (the) [Malvinas]', code: 'FK' },
      { name: 'Faroe Islands (the)', code: 'FO' },
      { name: 'Fiji', code: 'FJ' },
      { name: 'Finland', code: 'FI' },
      { name: 'France', code: 'FR' },
      { name: 'French Guiana', code: 'GF' },
      { name: 'French Polynesia', code: 'PF' },
      { name: 'French Southern Territories (the)', code: 'TF' },
      { name: 'Gabon', code: 'GA' },
      { name: 'Gambia (the)', code: 'GM' },
      { name: 'Georgia', code: 'GE' },
      { name: 'Germany', code: 'DE' },
      { name: 'Ghana', code: 'GH' },
      { name: 'Gibraltar', code: 'GI' },
      { name: 'Greece', code: 'GR' },
      { name: 'Greenland', code: 'GL' },
      { name: 'Grenada', code: 'GD' },
      { name: 'Guadeloupe', code: 'GP' },
      { name: 'Guam', code: 'GU' },
      { name: 'Guatemala', code: 'GT' },
      { name: 'Guernsey', code: 'GG' },
      { name: 'Guinea', code: 'GN' },
      { name: 'Guinea-Bissau', code: 'GW' },
      { name: 'Guyana', code: 'GY' },
      { name: 'Haiti', code: 'HT' },
      { name: 'Heard Island and McDonald Islands', code: 'HM' },
      { name: 'Holy See (the)', code: 'VA' },
      { name: 'Honduras', code: 'HN' },
      { name: 'Hong Kong', code: 'HK' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Iceland', code: 'IS' },
      { name: 'India', code: 'IN' },
      { name: 'Indonesia', code: 'ID' },
      { name: 'Iran (Islamic Republic of)', code: 'IR' },
      { name: 'Iraq', code: 'IQ' },
      { name: 'Ireland', code: 'IE' },
      { name: 'Isle of Man', code: 'IM' },
      { name: 'Israel', code: 'IL' },
      { name: 'Italy', code: 'IT' },
      { name: 'Jamaica', code: 'JM' },
      { name: 'Japan', code: 'JP' },
      { name: 'Jersey', code: 'JE' },
      { name: 'Jordan', code: 'JO' },
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Kiribati', code: 'KI' },
      { name: 'Korea (the Democratic People\'s Republic of)', code: 'KP' },
      { name: 'Korea (the Republic of)', code: 'KR' },
      { name: 'Kuwait', code: 'KW' },
      { name: 'Kyrgyzstan', code: 'KG' },
      { name: 'Lao People\'s Democratic Republic (the)', code: 'LA' },
      { name: 'Latvia', code: 'LV' },
      { name: 'Lebanon', code: 'LB' },
      { name: 'Lesotho', code: 'LS' },
      { name: 'Liberia', code: 'LR' },
      { name: 'Libya', code: 'LY' },
      { name: 'Liechtenstein', code: 'LI' },
      { name: 'Lithuania', code: 'LT' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Macao', code: 'MO' },
      { name: 'Madagascar', code: 'MG' },
      { name: 'Malawi', code: 'MW' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Maldives', code: 'MV' },
      { name: 'Mali', code: 'ML' },
      { name: 'Malta', code: 'MT' },
      { name: 'Marshall Islands (the)', code: 'MH' },
      { name: 'Martinique', code: 'MQ' },
      { name: 'Mauritania', code: 'MR' },
      { name: 'Mauritius', code: 'MU' },
      { name: 'Mayotte', code: 'YT' },
      { name: 'Mexico', code: 'MX' },
      { name: 'Micronesia (Federated States of)', code: 'FM' },
      { name: 'Moldova (the Republic of)', code: 'MD' },
      { name: 'Monaco', code: 'MC' },
      { name: 'Mongolia', code: 'MN' },
      { name: 'Montenegro', code: 'ME' },
      { name: 'Montserrat', code: 'MS' },
      { name: 'Morocco', code: 'MA' },
      { name: 'Mozambique', code: 'MZ' },
      { name: 'Myanmar', code: 'MM' },
      { name: 'Namibia', code: 'NA' },
      { name: 'Nauru', code: 'NR' },
      { name: 'Nepal', code: 'NP' },
      { name: 'Netherlands (the)', code: 'NL' },
      { name: 'New Caledonia', code: 'NC' },
      { name: 'New Zealand', code: 'NZ' },
      { name: 'Nicaragua', code: 'NI' },
      { name: 'Niger (the)', code: 'NE' },
      { name: 'Nigeria', code: 'NG' },
      { name: 'Niue', code: 'NU' },
      { name: 'Norfolk Island', code: 'NF' },
      { name: 'Northern Mariana Islands (the)', code: 'MP' },
      { name: 'Norway', code: 'NO' },
      { name: 'Oman', code: 'OM' },
      { name: 'Pakistan', code: 'PK' },
      { name: 'Palau', code: 'PW' },
      { name: 'Palestine, State of', code: 'PS' },
      { name: 'Panama', code: 'PA' },
      { name: 'Papua New Guinea', code: 'PG' },
      { name: 'Paraguay', code: 'PY' },
      { name: 'Peru', code: 'PE' },
      { name: 'Philippines (the)', code: 'PH' },
      { name: 'Pitcairn', code: 'PN' },
      { name: 'Poland', code: 'PL' },
      { name: 'Portugal', code: 'PT' },
      { name: 'Puerto Rico', code: 'PR' },
      { name: 'Qatar', code: 'QA' },
      { name: 'Republic of North Macedonia', code: 'MK' },
      { name: 'Romania', code: 'RO' },
      { name: 'Russian Federation (the)', code: 'RU' },
      { name: 'Rwanda', code: 'RW' },
      { name: 'Réunion', code: 'RE' },
      { name: 'Saint Barthélemy', code: 'BL' },
      { name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SH' },
      { name: 'Saint Kitts and Nevis', code: 'KN' },
      { name: 'Saint Lucia', code: 'LC' },
      { name: 'Saint Martin (French part)', code: 'MF' },
      { name: 'Saint Pierre and Miquelon', code: 'PM' },
      { name: 'Saint Vincent and the Grenadines', code: 'VC' },
      { name: 'Samoa', code: 'WS' },
      { name: 'San Marino', code: 'SM' },
      { name: 'Sao Tome and Principe', code: 'ST' },
      { name: 'Saudi Arabia', code: 'SA' },
      { name: 'Senegal', code: 'SN' },
      { name: 'Serbia', code: 'RS' },
      { name: 'Seychelles', code: 'SC' },
      { name: 'Sierra Leone', code: 'SL' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Sint Maarten (Dutch part)', code: 'SX' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Solomon Islands', code: 'SB' },
      { name: 'Somalia', code: 'SO' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
      { name: 'South Sudan', code: 'SS' },
      { name: 'Spain', code: 'ES' },
      { name: 'Sri Lanka', code: 'LK' },
      { name: 'Sudan (the)', code: 'SD' },
      { name: 'Suriname', code: 'SR' },
      { name: 'Svalbard and Jan Mayen', code: 'SJ' },
      { name: 'Sweden', code: 'SE' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'Syrian Arab Republic', code: 'SY' },
      { name: 'Taiwan (Province of China)', code: 'TW' },
      { name: 'Tajikistan', code: 'TJ' },
      { name: 'Tanzania, United Republic of', code: 'TZ' },
      { name: 'Thailand', code: 'TH' },
      { name: 'Timor-Leste', code: 'TL' },
      { name: 'Togo', code: 'TG' },
      { name: 'Tokelau', code: 'TK' },
      { name: 'Tonga', code: 'TO' },
      { name: 'Trinidad and Tobago', code: 'TT' },
      { name: 'Tunisia', code: 'TN' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Turkmenistan', code: 'TM' },
      { name: 'Turks and Caicos Islands (the)', code: 'TC' },
      { name: 'Tuvalu', code: 'TV' },
      { name: 'Uganda', code: 'UG' },
      { name: 'Ukraine', code: 'UA' },
      { name: 'United Arab Emirates (the)', code: 'AE' },
      { name: 'United Kingdom of Great Britain and Northern Ireland (the)', code: 'GB' },
      { name: 'United States Minor Outlying Islands (the)', code: 'UM' },
      { name: 'United States of America (the)', code: 'US' },
      { name: 'Uruguay', code: 'UY' },
      { name: 'Uzbekistan', code: 'UZ' },
      { name: 'Vanuatu', code: 'VU' },
      { name: 'Venezuela (Bolivarian Republic of)', code: 'VE' },
      { name: 'Viet Nam', code: 'VN' },
      { name: 'Virgin Islands (British)', code: 'VG' },
      { name: 'Virgin Islands (U.S.)', code: 'VI' },
      { name: 'Wallis and Futuna', code: 'WF' },
      { name: 'Western Sahara', code: 'EH' },
      { name: 'Yemen', code: 'YE' },
      { name: 'Zambia', code: 'ZM' },
      { name: 'Zimbabwe', code: 'ZW' }
    ];
  }

  regions(): any {
    return [
      { name: 'Central', id: '1' },
      { name: 'South', id: '2' },
      { name: 'North', id: '3' },
      { name: 'East', id: '4' },
      { name: 'West', id: '5' },
    ];
  }

  districts(): any {
    return [
      {
        id: 'UG-314',
        name: 'Abim',
        region: 'North',
        size: '2346',
        size_units: 'sq. kms',
        counties: [
          {
            name: 'Labwor',
            sub_counties: [
              {
                name: 'Abim',
                parishes: [
                  {
                    name: 'Atunga',
                    sub_parishes: [
                      {
                        name: 'Oryotene',
                        village: [
                          {
                            name: ''
                          }
                        ]
                      },
                      {
                        name: 'Apelkere',
                        village: [
                          {
                            name: ''
                          }
                        ]
                      },
                      {
                        name: 'Atugo',
                        village: [
                          {
                            name: ''
                          }
                        ]
                      },
                      {
                        name: 'Otalabar',
                        village: [
                          {
                            name: ''
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: 'Kanu',
                    sub_parishes: [
                      {
                        name: 'Kanu Health Centre II',
                        village: [
                          {
                            name: ''
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: 'Arembwola',
                    sub_parishes: [
                      {
                        name: 'Arembwola Pr. Sch',
                        village: [
                          {
                            name: ''
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: 'Aninata',
                    sub_parishes: [
                      {
                        name: 'Aninata Pr. Sch',
                        village: [
                          {
                            name: ''
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                name: 'Alerek',
                parishes: [{
                  name: '',
                  sub_parishes: [{
                    name: '',
                    village: [{
                      name: ''
                    }]
                  }]
                }]
              },
              {
                name: 'Lotukei',
                parishes: [{
                  name: '',
                  sub_parishes: [{
                    name: '',
                    village: [{
                      name: ''
                    }]
                  }]
                }]
              },
              {
                name: 'Morulem',
                parishes: [{
                  name: '',
                  sub_parishes: [{
                    name: '',
                    village: [{
                      name: ''
                    }]
                  }]
                }]
              },
              {
                name: 'Nyakwae',
                parishes: [{
                  name: '',
                  sub_parishes: [{
                    name: '',
                    village: [{
                      name: ''
                    }]
                  }]
                }]
              },
              {
                name: 'Abim Town Council',
                parishes: [{
                  name: '',
                  sub_parishes: [{
                    name: '',
                    village: [{
                      name: ''
                    }]
                  }]
                }]
              }
            ]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-301',
        name: 'Adjumani',
        region: 'North',
        size: '2962',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-322',
        name: 'Agago',
        region: 'North',
        size: '3503',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-323',
        name: 'Alebtong',
        region: 'North',
        size: '1535',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-315',
        name: 'Amolatar',
        region: 'North',
        size: '1157',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-324',
        name: 'Amudat',
        region: 'North',
        size: '1626',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-216',
        name: 'Amuria',
        region: 'East',
        size: '2578',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-316',
        name: 'Amuru',
        region: 'North',
        size: '2578',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-302',
        name: 'Apac',
        region: 'North',
        size: '2947',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-303',
        name: 'Arua',
        region: 'North',
        size: '4343',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-217',
        name: 'Budaka',
        region: 'East',
        size: '408',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-218',
        name: 'Bududa',
        region: 'East',
        size: '316',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-201',
        name: 'Bugiri',
        region: 'East',
        size: '1038',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-235',
        name: 'Bugweri',
        region: 'East',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-420',
        name: 'Buhweju',
        region: 'East',
        size: '748',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-117',
        name: 'Buikwe',
        region: 'Central',
        size: '1206',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-219',
        name: 'Bukedea',
        region: 'East',
        size: '1032',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-118',
        name: 'Bukomansimbi',
        region: 'Central',
        size: '599',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-220',
        name: 'Bukwa',
        region: 'East',
        size: '524',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-225',
        name: 'Bulambuli',
        region: 'East',
        size: '693',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-416',
        name: 'Buliisa',
        region: 'West',
        size: '1108',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-401',
        name: 'Bundibugyo',
        region: 'West',
        size: '848',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-430',
        name: 'Bunyangabu',
        region: 'West',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-402',
        name: 'Bushenyi',
        region: 'West',
        size: '844',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-202',
        name: 'Busia',
        region: 'East',
        size: '734',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-221',
        name: 'Butaleja',
        region: 'East',
        size: '653',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-119',
        name: 'Butambala',
        region: 'Central',
        size: '403',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-233',
        name: 'Butebo',
        region: 'East',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-120',
        name: 'Buvuma',
        region: 'Central',
        size: '293',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-226',
        name: 'Buyende',
        region: 'East',
        size: '1379',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-317',
        name: 'Dokolo',
        region: 'North',
        size: '1004',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-121',
        name: 'Gomba',
        region: 'Central',
        size: '1664',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-304',
        name: 'Gulu',
        region: 'North',
        size: '3433',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'true'
      },
      {
        id: 'UG-403',
        name: 'Hoima',
        region: 'West',
        size: '3671',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-417',
        name: 'Ibanda',
        region: 'West',
        size: '969',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-203',
        name: 'Iganga',
        region: 'East',
        size: '1017',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-418',
        name: 'Isingiro',
        region: 'West',
        size: '2613',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-204',
        name: 'Jinja',
        region: 'East',
        size: '677',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-318',
        name: 'Kaabong',
        region: 'North',
        size: '7298',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-404',
        name: 'Kabale',
        region: 'West',
        size: '1680',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-405',
        name: 'Kabarole',
        region: 'West',
        size: '1810',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-213',
        name: 'Kaberamaido',
        region: 'East',
        size: '1342',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-427',
        name: 'Kagadi',
        region: 'West',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-428',
        name: 'Kakumiro',
        region: 'West',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-101',
        name: 'Kalangala',
        region: 'Central',
        size: '451',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-222',
        name: 'Kaliro',
        region: 'East',
        size: '778',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-122',
        name: 'Kalungu',
        region: 'Central',
        size: '812',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-102',
        name: 'Kampala',
        region: 'Central',
        size: '188',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'true'
      },
      {
        id: 'UG-205',
        name: 'Kamuli',
        region: 'East',
        size: '1514',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-413',
        name: 'Kamwenge',
        region: 'West',
        size: '2340',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-414',
        name: 'Kanungu',
        region: 'West',
        size: '1271',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-206',
        name: 'Kapchorwa',
        region: 'East',
        size: '352',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-236',
        name: 'Kapelebyong',
        region: 'East',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-126',
        name: 'Kasanda',
        region: 'Central',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-406',
        name: 'Kasese',
        region: 'West',
        size: '2943',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-207',
        name: 'Katakwi',
        region: 'East',
        size: '2306',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-112',
        name: 'Kayunga',
        region: 'Central',
        size: '1592',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-407',
        name: 'Kibaale',
        region: 'West',
        size: '4242',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-103',
        name: 'Kiboga',
        region: 'Central',
        size: '1592',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-227',
        name: 'Kibuku',
        region: 'East',
        size: '481',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-432',
        name: 'Kikuube',
        region: 'West',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-419',
        name: 'Kiruhura',
        region: 'West',
        size: '4555',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-421',
        name: 'Kiryandongo',
        region: 'West',
        size: '3595',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-408',
        name: 'Kisoro',
        region: 'West',
        size: '699',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-305',
        name: 'Kitgum',
        region: 'North',
        size: '3998',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-319',
        name: 'Koboko',
        region: 'North',
        size: '760',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-325',
        name: 'Kole',
        region: 'North',
        size: '1071',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-306',
        name: 'Kotido',
        region: 'North',
        size: '3620',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-208',
        name: 'Kumi',
        region: 'East',
        size: '1003',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-333',
        name: 'Kwania',
        region: 'North',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-228',
        name: 'Kween',
        region: 'East',
        size: '850',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-123',
        name: 'Kyankwanzi',
        region: 'Central',
        size: '2466',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-422',
        name: 'Kyegegwa',
        region: 'West',
        size: '1747',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-415',
        name: 'Kyenjojo',
        region: 'West',
        size: '2357',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-125',
        name: 'Kyotera',
        region: 'Central',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-326',
        name: 'Lamwo',
        region: 'North',
        size: '5598',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-307',
        name: 'Lira',
        region: 'North',
        size: '1327',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-229',
        name: 'Luuka',
        region: 'East',
        size: '648',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-104',
        name: 'Luwero',
        region: 'Central',
        size: '2216',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-124',
        name: 'Lwengo',
        region: 'Central',
        size: '1024',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-114',
        name: 'Lyantonde',
        region: 'Central',
        size: '883',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-223',
        name: 'Manafwa',
        region: 'East',
        size: '533',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-320',
        name: 'Maracha',
        region: 'North',
        size: '1572',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-105',
        name: 'Masaka',
        region: 'Central',
        size: '1158',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-409',
        name: 'Masindi',
        region: 'West',
        size: '3932',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-214',
        name: 'Mayuge',
        region: 'East',
        size: '1073',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-209',
        name: 'Mbale',
        region: 'East',
        size: '517',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-410',
        name: 'Mbarara',
        region: 'West',
        size: '1781',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-423',
        name: 'Mitooma',
        region: 'West',
        size: '542',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-115',
        name: 'Mityana',
        region: 'Central',
        size: '1542',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-308',
        name: 'Moroto',
        region: 'North',
        size: '3564',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-309',
        name: 'Moyo',
        region: 'North',
        size: '1902',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-106',
        name: 'Mpigi',
        region: 'Central',
        size: '1202',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-107',
        name: 'Mubende',
        region: 'Central',
        size: '4591',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-108',
        name: 'Mukono',
        region: 'Central',
        size: '1829',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-334',
        name: 'Nabilatuk',
        region: 'North',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-311',
        name: 'Nakapiripirit',
        region: 'North',
        size: '4233',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-116',
        name: 'Nakaseke',
        region: 'Central',
        size: '3461',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-109',
        name: 'Nakasongola',
        region: 'Central',
        size: '3303',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-230',
        name: 'Namayingo',
        region: 'East',
        size: '585',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-234',
        name: 'Namisindwa',
        region: 'East',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-224',
        name: 'Namutumba',
        region: 'East',
        size: '810',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-327',
        name: 'Napak',
        region: 'North',
        size: '4901',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-310',
        name: 'Nebbi',
        region: 'North',
        size: '1915',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-231',
        name: 'Ngora',
        region: 'East',
        size: '637',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-424',
        name: 'Ntoroko',
        region: 'West',
        size: '1239',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-411',
        name: 'Ntungamo',
        region: 'West',
        size: '2048',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-328',
        name: 'Nwoya',
        region: 'North',
        size: '4601',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-331',
        name: 'Omoro',
        region: 'North',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-329',
        name: 'Otuke',
        region: 'North',
        size: '1555',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-321',
        name: 'Oyam',
        region: 'North',
        size: '2190',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-312',
        name: 'Pader',
        region: 'North',
        size: '3294',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-332',
        name: 'Pakwach',
        region: 'North',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-210',
        name: 'Pallisa',
        region: 'East',
        size: '1030',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-110',
        name: 'Rakai',
        region: 'Central',
        size: '3245',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-429',
        name: 'Rubanda',
        region: 'West',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-425',
        name: 'Rubirizi',
        region: 'West',
        size: '1092',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-431',
        name: 'Rukiga',
        region: 'West',
        size: '',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-412',
        name: 'Rukungiri',
        region: 'West',
        size: '1435',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-111',
        name: 'Sembabule',
        region: 'Central',
        size: '2315',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-232',
        name: 'Serere',
        region: 'East',
        size: '1495',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-426',
        name: 'Sheema',
        region: 'West',
        size: '696',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-215',
        name: 'Sironko',
        region: 'East',
        size: '401',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-211',
        name: 'Soroti',
        region: 'East',
        size: '1366',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-212',
        name: 'Tororo',
        region: 'East',
        size: '1192',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-113',
        name: 'Wakiso',
        region: 'Central',
        size: '1882',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-313',
        name: 'Yumbe',
        region: 'North',
        size: '2318',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      },
      {
        id: 'UG-330',
        name: 'Zombo',
        region: 'North',
        size: '940',
        size_units: 'sq. kms',
        counties: [
          {
            id: '',
            name: '',
            sub_counties: [{
              name: '',
              parishes: [{
                name: '',
                sub_parishes: [{
                  name: '',
                  village: [{
                    name: ''
                  }]
                }]
              }]
            }]
          }
        ],
        townstatus: 'false'
      }];
  }

  successAlert(message: string): any {
    return Swal.fire({
      icon: 'success',
      text: `${message}`,
    });
  }

  success2Alert(message: string): any {
    return Swal.fire({
      icon: 'success',
      text: `${message}`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: true,
      confirmButtonText: 'Done'
    });
  }

  errorAlert(message: string): any {
    return Swal.fire({
      icon: 'error',
      text: `${message} - Can\nt complete this action`,
    });
  }
  error2Alert(message: string): any {
    return Swal.fire({
      icon: 'error',
      text: `${message}`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: 'Try again'
    });
  }

  error3Alert(message: string): any {
    return Swal.fire({
      icon: 'error',
      text: `${message}`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: 'Login'
    });
  }

  noNetworkConnection(): any {
    return Swal.fire({
      icon: 'info',
      showConfirmButton: false,
      html: 'Unable to establish connection!<br/><br/>' +
        `<a class="swal2-confirm swal2-styled" style="display: inline-block;">Reload Page</a>`
    });
  }

  confirmAlert(): any {
    return Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to complete this action?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Confirm'
    });
  }

  confirmAlert3(tile: string): any {
    return Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to complete this action?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Confirm'
    });
  }

  confirm2Alert(): any {
    return Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to upload this file?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Confirm',
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  confirm4Alert(title: string, message: string): any {
    return Swal.fire({
      icon: 'question',
      title: title,
      text: message,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Confirm',
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }


  confirmAlertRaw(): any {
    return confirm('Are you sure you want to complete this action?');
  }

  cancelAlert() {
    return Swal.fire('Changes are not effected!', '', 'info');
  }

  reloadPage() {
    location.reload();
  }

  setKycStatus(kycDetails: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/set-profile-kyc`, kycDetails);
  }

  futureInitialInvestmentVal(
    initialInvestment: number,
    rate: number,
    time: number
  ) {
    return initialInvestment * Math.pow(1 + rate, time);
  }

  diffPortfolio(
    targetPortfolio: number,
    futureInitialInvestmentVal: number
  ) {
    return targetPortfolio - futureInitialInvestmentVal;
  }

  getAnnualSavings(diffValue, averageRate, time) {
    return (diffValue * averageRate) / (Math.pow(1 + averageRate, time) - 1);
  }

  computeAnnualSavings(
    profile: AllocationProfile,
    goalTarget: GoalTarget
  ) {
    const averageRate = profile.averageRate;
    const futureValInitial = this.futureInitialInvestmentVal(
      goalTarget.initialInvestment,
      averageRate,
      goalTarget.timeHorizon
    );
    const diff = this.diffPortfolio(goalTarget.targetValue, futureValInitial);
    return this.getAnnualSavings(
      diff,
      averageRate,
      goalTarget.timeHorizon
    );
  }

  getMonthlySavings(annualSavings: number) {
    return annualSavings / 12;
  }

  formatDateWithTime(date: Date) {
    console.log(`datae___: ${date == null}`);
    if (date == null) { return; }


    return (
      [
         date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  formatDate(date: Date): string | undefined {
    console.log(`date::::${date}`);
    if (date == null) { return; }

    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-')
    );
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  numberFormatter(number) {
    if (number > 999 && number < 99999) {
      return `${(number / 1000).toFixed(1)}K`;
    } else if (number > 99999 && number < 999999) {
      return `${(number / 1000).toFixed(0)}K`;
    } else if (number > 999999 && number < 999999999) {
      return `${(number / 1000000).toFixed(1)}M`;
    } else if (number > 999999999) {
      return `${(number / 1000000000).toFixed(1)}B`;
    } else {
      return 0;
    }
  }

  displayDefault(number: any) {
    if(number == null || number == ''){
      return 0
    } else {
      return number
    }
  }

  removeFirstCharacter(number) {
    let str = number.toString();
    if(str.includes('-')){
      return str.substring(1);
    } else {
      return str
    }
  }

  // Get date
  getDate(s: string): string {
    let rawDate = new Date(s);
    let year = rawDate.getFullYear();
    let month = rawDate.getMonth() + 1;
    let day = rawDate.getDate();

    let formattedDate =
      year +
      '-' +
      month.toString().padStart(2, '0') +
      '-' +
      day.toString().padStart(2, '0');

    console.log(formattedDate);
    return formattedDate.toString();
  }

  getStartDate(number: number): string {
    const date = new Date();
    const backDate = date.setDate(date.getDate() - number);
    const backDateObj = new Date(backDate);
    return this.getDate(backDateObj.toString());
  }

  getDateToday(): string {
    let date = new Date();
    return this.getDate(date.toString());
  }

  getAllTimeStartDate(): string {
    let date = new Date(2022, 4, 13);
    return this.getDate(date.toString());
  }

  addDays(date: Date, days: number): string {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return this.getDate(result.toString());
  }

  // Currency mapping
  xnCurrencyLabel(tenantId: string) {
    switch (tenantId) {
      case 'KE':
        return 'KES';
      case 'UG':
        return 'UGX';
      default:
        return '';
    }
  }


  xnTenantID(tenantId: string) {
    switch (tenantId) {
      case 'KE':
        return true;
      case 'UG':
        return false;
      default:
        return null;
    }
  }

  removeCommas(value: any) {
    return value.replace(/,/g,'');
  }


  loadGoalCoverImg(goalRefNumber: number) {
    return `assets/images/goals/goal-${goalRefNumber}.jpg`;
  }


}
