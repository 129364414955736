<mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input
    type="number"
    [name]="fieldName"
    min="0"
    onInput="validity.valid||(value='');"
    [value]="value"
    [disabled]="isDisabled"
    (input)="onChange($event)"
    (blur)="touched()"
    matInput />
</mat-form-field>
