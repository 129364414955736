<div class="center-modal">
    <div class="center-content-box">
        <div class="center-header">
            <span class="center-header-txt">Select Country</span>
        </div>
        <div class="divider-line-2 "></div>
        <div class="content">
            <div class="text-confirm">Please note, we shall automatically log you into the selected country.</div>
            <div class="country-list">

            <div *ngFor="let country of countries" >
                <div class="country-item" (click)="switchCountry(country.code)">
                    <img src='../../../assets/icons/{{country.code}}.png' class="country-flag" />
                    <span class="text-country">{{country.name}}</span>
                </div>
                <!-- <ng-content></ng-content> -->
            </div>
            </div>
        </div>
    </div>