import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {AccountService} from '../../services/account.service';
import {
  Account,
  AccountGoal,
  AccountGoalAutoSave,
  AccountGoalTransaction,
  Allocation,
  AllocationProfile,
  GoalTarget, Profile,
  User
} from '../../models';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {saveAs} from 'file-saver';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '@orion/services';
import { MatSelectChange } from '@angular/material/select';
import {FormBuilder, FormGroup, } from '@angular/forms';


@Component({
  selector: 'app-single-goal',
  templateUrl: './single-goal.component.html',
  styleUrls: ['./single-goal.component.scss'],
})
export class SingleGoalComponent implements OnInit {

  constructor(private accountService: AccountService,
              private utilitiesService: UtilitiesService,
              private http: HttpClient,
              private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService) {
    this.authenticationService.orionUser.subscribe(
      (x) => (this.orionUser = x)
    );
  }
  @Input() zIndex: number;
  @Input() tenantCurrency: string;
  @Input() accountGoalId: string;
  @Input() accountId: string;
  @Input() profileDetails: Profile;
  @Input() accountType: string;
  @Output() closeGoal = new EventEmitter();
  isProgressLoading = false;

  accountGoal: AccountGoal;
  sortedAllocations: Allocation[];
  accountGoalAutoSave: AccountGoalAutoSave;
  isLoading = true;
  editStrategyView = false;
  errorMessage: string;
  showDeactivateButton = true;
  isLocked = false;
  monthlySavings: number;
  orionUser: User;
  endDate: string;
  startDate: string;
  filtersForm: FormGroup;
  downloadStatements = false;

  // deactivate(goalId: string) {
  //   const confirmed = confirm('Are you sure you want to deactivate this goal?');
  //   if (confirmed && this.accountGoal.currentValue === 0 && this.accountGoal.transactions[0].amount === 0) {
  //     this.isLoading = true;
  //     this.accountService.deactivateGoal(goalId).subscribe(resp => {
  //       console.log(resp);
  //       this.isLoading = false;
  //       this.showDeactivateButton = false;
  //       alert('The goal has been activated');
  //     }, error => {
  //       console.error('============unable to deactivate goal');
  //     });
  //   }
  // }
  reportType = 'account';
    isHidden = false;


  updateStrategy(newStrategy) {
    this.editStrategyView = false;
  }

  closeWindow() {
    this.closeGoal.emit();
  }

  ngOnInit() {
    this.accountService.getAccountGoal(this.accountGoalId).subscribe(resp => {
      this.accountGoal = resp;
      if (this.accountGoal.allocations) {
        this.sortedAllocations = this.accountGoal.allocations.sort((a, b) => a.fundRefCode - b.fundRefCode);
      }

      if (this.accountGoal.isWithdrawalLocked === true) {
        this.isLocked = true;
      }
      if (this.accountGoal.totalDeposits >= 10000) {
        this.downloadStatements = true;
      }
      if (this.accountGoal.goalTarget) {
        const profile = this.accountGoal.investmentProfile;
        const goalTarget = this.accountGoal.goalTarget;

        this.computeAnnualSavings(profile, goalTarget);
      }
      this.getAutoSaveDetails();
      this.isLoading = false;
    }, error => {
      alert('Error please reload page');
    });

    this.filtersForm = this.formBuilder.group({
      startDate: [null],
      endDate: [null]
    })
  }

  filterDeposits(summary: AccountGoalTransaction) {
    return summary.transactionType === 'DEPOSIT';
  }

  filterWithdrawals(summary: AccountGoalTransaction) {
    return summary.transactionType === 'WITHDRAWAL';
  }

  sumCurrentValue() {
    if (this.sortedAllocations.length <= 0) {
      return 0.0;
    }
    return this.sortedAllocations
      .map((a) => a.currentValue)
      // tslint:disable-next-line:only-arrow-functions
      .reduce(function(a, b) {
        return a + b;
      });
  }

  sumTotalInvested() {
    if (this.sortedAllocations.length <= 0) {
      return 0.0;
    }
    return this.sortedAllocations
      .map((a) => a.totalInvested)
      // tslint:disable-next-line:only-arrow-functions
      .reduce(function(a, b) {
        return a + b;
      });
  }

  sumGain() {
    if (this.sortedAllocations.length <= 0) {
      return 0.0;
    }
    return this.sortedAllocations
      .map((a) => a.gain)
      // tslint:disable-next-line:only-arrow-functions
      .reduce(function(a, b) {
        return a + b;
      });
  }


  initGoalWithdrawLock(accountGoalId: string) {

    const confirmed = confirm('Complete withdraw lock action?');
    if (confirmed && accountGoalId) {
      this.isLoading = true;
      this.accountService.initLock(accountGoalId).subscribe(resp => {

        this.isLoading = false;
        alert('Action complete');
      }, error => {

      });
    }
  }

  private computeAnnualSavings(
    profile: AllocationProfile,
    goalTarget: GoalTarget
  ) {
    const annualSavings = this.utilitiesService.computeAnnualSavings(profile, goalTarget);
    this.monthlySavings = this.utilitiesService.getMonthlySavings(annualSavings);
  }

  setStatus(status: string) {
    let s = '';

    switch (status) {
      case 'active':
        s = 'verified';
        break;

      default:
        s = status;
        break;
    }
    return s;
  }

  downloadStatement(goalId: string, userId: string, accountId: string) {
    let url = `${environment.serverUrl}/api/v1/users/generate-return-statement?accountId=${accountId}&goalId=${goalId}&userId=${userId}`;
    const options = {responseType: 'blob' as 'json'};
    let period = 'all'
    this.isProgressLoading = true;

    if (this.filtersForm.value.endDate) {
      period = 'range'
      url = `${url}&endDate=${this.endDate}`;
    }

    if (this.filtersForm.value.startDate) {
      period = 'range'
      url = `${url}&startDate=${this.startDate}`;
    }

    url = `${url}&period=${period}`;

    this.http.get(url, options).subscribe((data: Blob) => {
      this.isProgressLoading = false;
      this.saveBlobAsPDF(data, accountId);
    }, error => {
      alert('Something went wrong. Can\'t download statement');
      this.isProgressLoading = false;
    });
  }

  private saveBlobAsPDF(blobData: Blob, accountId: string) {
    const fileName = `XENO Investment Goal Statement - ${this.accountGoal.goalNumber} - ${new Date()}.pdf`;
    saveAs(blobData, fileName);
  }

  // Get AutoSave details
  getAutoSaveDetails(){
    this.accountService.getAccountGoalAutoSave(this.accountGoalId).subscribe(resp => {
      this.accountGoalAutoSave = resp;
    })

  }

  downloadPerformanceSummary(goalId: string, userId: string, accountId: string) {
    let url = `${environment.serverUrl}/api/v1/users/generate-performance-summary-statement?accountId=${accountId}&goalId=${goalId}&userId=${userId}`;
    const options = {responseType: 'blob' as 'json'};
    this.isProgressLoading = true;

    if ((this.filtersForm.value.endDate)) {
      url = url+"&endDate="+this.endDate
    }

    if ((this.filtersForm.value.startDate)) {
      url = url+"&startDate="+this.startDate
    }
    this.http.get(url, options).subscribe((data: Blob) => {
      this.isProgressLoading = false;
      this.saveBlobAsPDF(data, accountId);
    }, error => {
      alert('Something went wrong. Can\'t download statement');
      this.isProgressLoading = false;
    });
  }

  setDate(date:string){
    let newDate = new Date(date);
    let day = newDate.getDate().toString();
    let month = (newDate.getMonth() +1).toString();
    let year = newDate.getFullYear();

    return [year, month, day].join("-");
  }

  applyDateRangeFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.startDate = this.setDate(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.endDate = this.setDate(this.filtersForm.value.endDate);
    }
  }

}
