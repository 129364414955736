import {SecAccessDirective} from "@orion/directives/sec-access.directive";
import {NgModule} from "@angular/core";


import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { GoalModule } from "@orion/modules/goal/goal.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormField } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTabsModule } from "@angular/material/tabs";
import { SlideoverComponent } from "@orion/components/slideover/slideover.component";
import { FilterPipe } from "@orion/pipes/filter-pipe";


@NgModule({
  declarations: [
    SecAccessDirective, SlideoverComponent, FilterPipe],
  exports: [SecAccessDirective, SlideoverComponent, FilterPipe],
  imports: [
    CommonModule, 
         ]
})
export class SharedModule { }
