<div class="page-header xn-fadein-up">
  <h1 class="page-title">Referrals</h1>
  <form class="page-actions" [formGroup]="filtersForm">
    <div class="page-action">
      <mat-form-field appearance="fill">
        <mat-label>Promo Code</mat-label>
        <mat-select
          formControlName="promoCode"
          (selectionChange)="applyPromoCodeFilter($event)"
        >
          <mat-option value="ALL">ALL</mat-option>
          <mat-option *ngFor="let promoCode of promoCodes" value="{{promoCode}}">{{promoCode}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="page-action">
      <mat-form-field appearance="fill">
        <mat-label>Created</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            matInput
            placeholder="Start date"
            formControlName="startDate"
          />
          <input
            matEndDate
            matInput
            placeholder="End date"
            formControlName="endDate"
            (dateChange)="applyDateRangeFilter($event)"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </form>
</div>

<div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="dateCreated"
    matSortDisableClear
    matSortDirection="desc"
  >
    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
      <td mat-cell *matCellDef="let element">{{ element.position }}</td>
    </ng-container>

    <!-- dateCreated Column -->
    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dateCreated | date }}
      </td>
    </ng-container>

    <!-- firstName Column -->
    <ng-container matColumnDef="referredName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Referred Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.referredName | titlecase }}
      </td>
    </ng-container>

    <!--  Column -->
    <ng-container matColumnDef="referredAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Referred Amount</th>
      <td mat-cell *matCellDef="let element">
        {{ element.amount }}
      </td>
    </ng-container>

    <!-- kycStatus Column -->
    <ng-container matColumnDef="referralCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Referral Code</th>
      <td mat-cell *matCellDef="let element">
        {{ element.referralCode }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
    ></tr>
    x
  </table>

  <mat-paginator
    [length]="dataSource.resultsLength | async"
    [pageSize]="50"
    showFirstLastButtons
    [pageSizeOptions]="[10, 50, 100, 200]"
  ></mat-paginator>
</div>

<div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
