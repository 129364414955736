<slideover (closeWindow)="closeForm()" size="mini">
  <div class="page-section">
    <h2 class="page-subtitle huge">
      {{ capability ? "Update" : "Create" }} Capability
    </h2>
    <form class="edit-user-form" [formGroup]="form">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Url</mat-label>
          <input type="text" formControlName="url" matInput />
        </mat-form-field>
      </div>

      <div class="mat-checkbox-group">
        <input type="hidden" formControlName="configAttribute" />
        <div class="mat-checkbox-wrap" *ngFor="let role of allRoles">
          <mat-checkbox
            [checked]="setCheckbox(role.authority)"
            (change)="updateRoles(role.authority)"
            >{{ role.authority }}</mat-checkbox
          >
        </div>
      </div>
      <div class="u-justify actions">
        <button
          type="button"
          *ngIf="capability"
          mat-button
          mat-flat-button
          color="warn"
          (click)="deleteCapability(capability.id)"
        >
          Delete
        </button>
        <button
          type="button"
          [disabled]="!form.valid"
          mat-button
          [class.u-fill-w]="!capability"
          mat-flat-button
          color="primary"
          (click)="
            capability ? updateCapability(capability.id) : createCapability()
          "
        >
          {{ capability ? "Update" : "Create" }}
        </button>
      </div>
    </form>
  </div>
</slideover>
