import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => NumberInputComponent
      ),
      multi: true
    }
  ]

})
export class NumberInputComponent implements ControlValueAccessor {


  @Input()
  public parentForm: UntypedFormGroup;

  @Input()
  public fieldName: string;

  @Input()
  public label: string;

  public value: any;

  public changed: (value: number) => void;

  public touched: () => void;
  public isDisabled: boolean;

  get formField(): UntypedFormControl {
    return this.parentForm.get(this.fieldName) as UntypedFormControl;
  }

  constructor() { }

  public writeValue(value: number): void {
    this.value = value;
  }

  public onChange(event: Event): void {
    const value: number =
      Number((event.target as HTMLInputElement).value);

    this.changed(value);
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
