import {Component, OnInit} from '@angular/core';
import {DataExportService} from '../../app/pages/export/data-export.service';
import {ExportDataSource} from '@orion/pages/export/export-datasource';
import {UtilitiesService} from "@orion/utilities/utilities.service";
import {UntypedFormBuilder, UntypedFormGroup, } from '@angular/forms';
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {
  isLoading: boolean;
  displayedColumns: string[] = ['name', 'actions'];
  dataSource: ExportDataSource;
  filtersForm: UntypedFormGroup;
  startDate: string = '';
  endDate: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dataExportService: DataExportService,
              private utilitiesService: UtilitiesService) {
  }

  ngOnInit(): void {
    let date = new Date();
    date.setDate(date.getDate() - 7);
    this.endDate = this.utilitiesService.formatDate(new Date());
    this.startDate = this.utilitiesService.formatDate(date);
    if(this.endDate != null){
      this.filtersForm = this.formBuilder.group({
        startDate: [this.startDate],
        endDate: [this.endDate],
      });
    }
    this.loadData();
  }

  private loadData() {
    this.dataSource = new ExportDataSource(this.utilitiesService);
    this.dataSource.loadData();
  }

  applyDateRangeFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.startDate = this.utilitiesService.formatDate(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.endDate = this.utilitiesService.formatDate(this.filtersForm.value.endDate);
    }
  }

  downloadFiles(url, fileName) {
    if(this.filtersForm.invalid){
      this.utilitiesService.error2Alert('Invalid date range!!').then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      this.ngOnInit();
    }
    this.isLoading = true;
    let requestUrl = `${url}?startDate=${this.startDate}&endDate=${this.endDate}`
    this.dataExportService.downloadData(requestUrl).subscribe(blob => {
      this.isLoading = false;
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);
    }, error => {
      alert('Failed to download error');
      this.isLoading = false;
    });
  }
}
