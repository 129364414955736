import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Account} from '@orion/models';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {AccountService} from '@orion/services/account.service';

@Component({
  selector: 'app-sponsor-not-linked-transactions',
  templateUrl: './sponsor-not-linked-transactions.component.html',
  styleUrls: ['./sponsor-not-linked-transactions.component.scss']
})
export class SponsorNotLinkedTransactionsComponent implements OnInit {

  @Output() closeWindow = new EventEmitter();
  @Input() accountDetails: Account;
  transactionsWithErrorsCount: any;
  message!: string;
  isLoading = false;

  constructor(private utilities: UtilitiesService,
              private accountService: AccountService) {
  }

  ngOnInit(): void {
    this.getCountOfLinkedAccountsNotLinkedInTransactions(this.accountDetails.id);
  }

  closeForm() {
    this.closeWindow.emit();
  }

  fixErrors(accountId: string) {
    this.isLoading = true;
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.accountService.updateSponsorAccountNotLinkedInTransactions(accountId).subscribe(resp => {
          if (resp.code === 200) {
            this.isLoading = false;
            this.utilities.successAlert('Successfully Updated');
            this.closeForm();
          } else {
            this.utilities.errorAlert('Failed to Update the records');
            this.closeForm();
          }
        }, error => {
          this.utilities.errorAlert(error);
          this.isLoading = false;
        });
      }
    });
  }


  getCountOfLinkedAccountsNotLinkedInTransactions(accountId: string) {
    this.accountService.checkSponsorAccountNotLinkedInTransactions(accountId).subscribe(resp => {
      this.transactionsWithErrorsCount = resp;
    }, error => {
      this.utilities.errorAlert(error);
      this.isLoading = false;
    });
  }
}
