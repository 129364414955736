import { Component, OnInit } from '@angular/core';
import { User } from '@orion/models';
import { AuthenticationService } from '@orion/services';
import { Router } from '@angular/router';
import {KeycloakService} from "keycloak-angular";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  orionUser: User;
  showMenu = true;
  activeTenant: string;
  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private keycloakService: KeycloakService) { }

  ngOnInit() {
    this.authenticationService.orionUser.subscribe(x => { this.orionUser = x; });
    this.activeTenant = localStorage.getItem('tenantId');
  }

  logout() {
   this.authenticationService.logout();
  //   this.router.navigate(['/login']);
  }

  navigateToUsers() {
    this.router.navigate(['/users']);
  }

}
