<app-goal-details *ngIf="isGoalDetailsOpen" (close)="hideGoalDetailsModal()"></app-goal-details>
<!--<ng-container *ngIf="showModal">-->
<!--  <app-user-dash-single-profile [showProfile]="true" (hideProfile)="hideProfileModal()"-->
<!--                                [profileId]="clientProfileId" [isProspect]="isProspectStatus">-->
<!--  </app-user-dash-single-profile>-->
<!--</ng-container>-->


<!-- Single goal dash -->
<app-single-goal (closeGoal)="closeGoalDetails()"
                 *ngIf="singleGoal"
                 [accountId]="accountId"
                 [tenantCurrency]="tenantCurrency"
                 [profileDetails]="profileDetails"
                 [accountType]="accountType"
                 [accountGoalId]="goalId" [zIndex]="2">
</app-single-goal>


<div class="d-flex pb-1 pb-xxl-1 pb-xl-1 pb-lg-1 pb-md-1 pb-sm-1 nt103">
  <h1 class="header-tile me-auto">Goals</h1>
  <div class="xn-fadein-up filter-reset-btn">
    <form class="h-height" [formGroup]="filtersForm">
      <!-- Date Filter   -->
      <div class="d-flex  ps-5 ps-xxl-5 ps-xl-5 ps-lg-5 ps-md-5 ps-sm-5">

        <div class="page-action" *ngIf="resetFiltersActive">
          <button mat-raised-button color="primary" class="mt-1">
            <span (click)="resetFilters(true)">Reset filters</span>
          </button>
        </div>

        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Progress</mat-label>
            <mat-select formControlName="progress"
                        (selectionChange)="applyProgressFilter()">
              <mat-option value="ALL">All</mat-option>
              <mat-option value="0-25">0 - 25 %</mat-option>
              <mat-option value="25-50">25 - 50 %</mat-option>
              <mat-option value="50-75">50 - 75 %</mat-option>
              <mat-option value="75-100">75 - 100 %</mat-option>
<!--              <mat-option value="100-above">100 - above</mat-option>-->
            </mat-select>
          </mat-form-field>
        </div>
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Goals</mat-label>
            <mat-select
              formControlName="goal"
              (selectionChange)="applyGoalsFilter()">
              <mat-option value="{{g.goalCode}}" *ngFor="let g of goals">{{g.name | titlecase}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Date Created</mat-label>
            <mat-date-range-input [rangePicker]="dateCreatedPicker" [max]="tomorrow">
              <input matStartDate matInput placeholder="Start date" formControlName="startDate" />
              <input matEndDate matInput placeholder="End date" formControlName="endDate"
                     (dateChange)="applyDateCreatedDateFilter()"/>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="dateCreatedPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #dateCreatedPicker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Last Deposit</mat-label>
            <mat-date-range-input [rangePicker]="lastDepositPicker" [max]="tomorrow">
              <input matStartDate matInput placeholder="Last Deposit Start date" formControlName="lastDepositStartDate" />
              <input matEndDate matInput placeholder="Last Deposit End date" formControlName="lastDepositEndDate"
                     [max]="maxDate"
                     (dateChange)="applyLastDepositDateFilter()"/>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="lastDepositPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #lastDepositPicker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Funding</mat-label>
            <mat-select
              formControlName="funding"
              (selectionChange)="applyFundingFilter()">
              <mat-option value=""></mat-option>
              <mat-option value="">All</mat-option>
              <mat-option value="true">Funded</mat-option>
              <mat-option value="false">Not Funded</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>AutoSave</mat-label>
            <mat-select
              formControlName="autosave"
              (selectionChange)="applyAutoSaveFilter()">
              <mat-option value="ALL">All</mat-option>
              <mat-option value="true">On</mat-option>
              <mat-option value="false">Off</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Bands</mat-label>
            <mat-select
              formControlName="band"
              (selectionChange)="applyBandFilter()">
              <mat-option value="ALL">All</mat-option>
              <mat-option value="M0a">M0a</mat-option>
              <mat-option value="M0b">M0b</mat-option>
              <mat-option value="M1">M1</mat-option>
              <mat-option value="M2">M2</mat-option>
              <mat-option value="M3">M3</mat-option>
              <mat-option value="M4">M4</mat-option>
              <mat-option value="M5">M5</mat-option>
              <mat-option value="M6">M6</mat-option>
              <mat-option value="M7">M7</mat-option>
              <mat-option value="M8">M8</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Targets</mat-label>
            <mat-select
              formControlName="target"
              (selectionChange)="applyTargetsFilter()">
              <mat-option value="ALL">All</mat-option>
              <mat-option value="true">Set</mat-option>
              <mat-option value="false">Not Set</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </form>
  </div>
</div>




<div>

</div>


<mat-grid-list cols="5" gutterSize="20" rowHeight="150px">
  <mat-grid-tile>
    <mat-card class="xn-card-height mat-elevation-z0 card" >
      <div *ngIf="isLoadingCardData" class="lds-ring"><div></div><div></div><div></div><div></div></div>
      <ng-container  *ngIf="!isLoadingCardData">
          <div class="card-title ps-3 px-3">
            <div>Total # of Goals</div>
            <div class="cont" align="center">
              <mat-icon>group</mat-icon>
            </div>
          </div>
          <div class="total-card ps-3 px-3">{{totalNumberOfGoals ? (totalNumberOfGoals | number) : 0 }}</div>
      </ng-container>

    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile (click)="updateTableData('not-funded')">
    <mat-card  class="xn-card-height mat-elevation-z0 card">
      <div *ngIf="isLoadingCardData" class="lds-ring"><div></div><div></div><div></div><div></div></div>
      <ng-container *ngIf="!isLoadingCardData">
        <div class="card-title pt-3 ps-3">
          <div>Not Funded</div>
        </div>
        <div class="card-title px-3">
          <div class="total-card">
            <ng-container>{{totalNotFunded ? (totalNotFunded | number) : 0}}</ng-container>
          </div>
          <div>
            <app-progress [progress]="notFundedProgress ? notFundedProgress : '0'" *ngIf="!isLoadingCardData"></app-progress>
          </div>
        </div>
      </ng-container>

    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile (click)="updateTableData('funded')">
    <mat-card   class=" xn-card-height mat-elevation-z0 card">
      <div *ngIf="isLoadingCardData" class="lds-ring"><div></div><div></div><div></div><div></div></div>
      <ng-container *ngIf="!isLoadingCardData">
        <div class="card-title  pt-3 px-3">
          <div>Funded</div>
        </div>
        <div class="card-title ps-3">
          <div class="total-card">
            <ng-container>{{totalFunded ? (totalFunded | number) : 0}}</ng-container>
          </div>
          <div class="z-index">
            <app-progress [progress]="fundedProgress ? fundedProgress : '0'" *ngIf="!isLoadingCardData"></app-progress>
          </div>
        </div>
      </ng-container>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile (click)="updateTableData('achieved')">
    <mat-card class="xn-card-height mat-elevation-z0 card">
      <div *ngIf="isLoadingCardData" class="lds-ring"><div></div><div></div><div></div><div></div></div>
      <ng-container *ngIf="!isLoadingCardData">
      <div class="card-title pt-3 ps-3">
        <div>Achieved</div>
      </div>
      <div class="card-title px-3">
        <div class="total-card">
          <ng-container>{{totalAchieved ? (totalAchieved | number) : 0}}</ng-container>
        </div>
        <div>
          <app-progress [progress]="achievedProgress ? achievedProgress : '0'" *ngIf="!isLoadingCardData"></app-progress>
        </div>
      </div>
      </ng-container>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="xn-card-height mat-elevation-z0 card">
      <div *ngIf="isLoadingCardData" class="lds-ring"><div></div><div></div><div></div><div></div></div>
      <ng-container  *ngIf="!isLoadingCardData">
        <div class="card-title ps-3 px-3">
          <div>AUM</div>
          <div class="cont" align="center">
            <mat-icon>group</mat-icon>
          </div>
        </div>
        <div class="total-card ps-3 px-3">UGX {{fCash(totalAum)}}</div>

      </ng-container>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

<div class="xn-fadein-up xn-delay-2">
  <div class="mat-elevation-z8 xn-orion-table-container-staff">
<!--    <ng-container *ngIf="!dataSource?.loading$">-->
      <table mat-table  [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Position Column -->
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef> created </th>
          <td mat-cell *matCellDef="let element"> {{element.createdDate | date : 'MMM d, y'}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="goal">
          <th mat-header-cell *matHeaderCellDef> goal </th>
          <td mat-cell *matCellDef="let element">
            <div class="u-profile">
              <figure class="u-profile-photo"
                      (click)="showProfileDetail(element.userId, element.isProspect, element.goalId)"
                      [style.backgroundImage]="
                'url(' + loadGoalCoverImg(element.goalRefNumber) + ')'
              "></figure>
              <span class="ellipsis-container">
              <a (click)="showProfileDetail(element.userId, element.isProspect, element.goalId)" class="text-primary">
              {{separateCamelCase(element.goalType) | titlecase}}</a></span>
            </div>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef> user </th>
          <td mat-cell *matCellDef="let element">
            <a *ngIf="element.firstName  && element.lastName"
               (click)="showProfileDetail(element.userId, element.isProspect, element.goalId)" class="text-primary">
              {{element.firstName | titlecase}} {{element.lastName | titlecase}}</a>

            <span
              *ngIf="element.firstName === null && element.lastName === null"
              class="status-label no-name">
              {{'no user'}}

            </span>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="targets">
          <th mat-header-cell *matHeaderCellDef> targets </th>
          <td mat-cell *matCellDef="let element">
            <span
              *ngIf="element.targetAmount > 0"
              class="{{element.hasTargets === false ?
               'status-label has-no-target' :
               'status-label has-target'}}">
              {{element.hasTargets === false ?
              'not set' :
              'set'}}
            </span>
            <span
              *ngIf="element.targetAmount === 0"
              class="status-label no-name">
              {{'no target'}}

            </span>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="autoSave">
          <th mat-header-cell *matHeaderCellDef> autosave </th>
          <td mat-cell *matCellDef="let element">
              <span
                class="{{element.hasAutoSave === true ?
               'status-label has-autosave' :
               'status-label has-no-autosave'}}">
              {{element.hasAutoSave === true ?
                'on' :
                'off'}}
            </span>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="funding">
          <th mat-header-cell *matHeaderCellDef> funding </th>
          <td mat-cell *matCellDef="let element">
              <span
                class="{{element.isFunded === false ?
               'status-label not-funded' :
               'status-label funded'}}">
              {{element.isFunded === false ?
                'not funded' :
                'funded'}}
            </span>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="band">
          <th mat-header-cell *matHeaderCellDef> band </th>
          <td mat-cell *matCellDef="let element">
              <span
                class="status-label danger">
                <span class="xlabel">{{element.aumBand}}</span>
            </span>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="lastDeposit">
          <th mat-header-cell *matHeaderCellDef> last deposit </th>
          <td mat-cell *matCellDef="let element"><span *ngIf="element.lastDepositDate">
          {{element.lastDepositDate  | date : 'MMM d, y'}}
        </span>
            <span
              *ngIf="!element.lastDepositDate"
              class="status-label not-funded">
             no deposit
            </span>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="progress">
          <th mat-header-cell *matHeaderCellDef> progress</th>
          <td mat-cell *matCellDef="let element">
            <strong>{{element.achieved == null ?  0 : element.achieved}} %</strong>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator
        [length]="dataSource?.resultsLength | async"
        [pageSize]="100"
        showFirstLastButtons
        [pageSizeOptions]="[5, 10, 50, 100]"
      ></mat-paginator>
<!--    </ng-container>-->


  </div>
</div>

<br/>
<div class="nm-topx" *ngIf="!isSearch && dataSource?.loading$ | async">
  <div  class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>


<!--<div class="spinner-container" *ngIf="!isSearch && dataSource?.loading$ | async">-->
<!--  <mat-spinner color="accent"></mat-spinner>-->
<!--</div>-->

