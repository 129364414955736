import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ProfileService} from '@orion/services/profile.service';
import {UtilitiesService} from '@orion/utilities/utilities.service';

@Component({
  selector: 'upload-advisors',
  templateUrl: './upload-advisors.component.html',
  styleUrls: ['./upload-advisors.component.scss']
})
export class UploadAdvisorsComponent implements OnInit {
  @Output() close = new EventEmitter();
  uploaded: any;
  isUpload: boolean;
  isResult: boolean;
  fileToUpload: File;
  @ViewChild('advisorsDocLabel', {static: false}) advisorsDocLabel: ElementRef;

  constructor(private profileService: ProfileService,
              private utilitiesService: UtilitiesService) {
  }

  ngOnInit(): void {
    this.isUpload = true;
  }

  onFileSelect(e: any) {
    this.advisorsDocLabel.nativeElement.innerHTML = e.target.files[0].name;
    this.fileToUpload = e.target.files.item(0);
  }

  uploadAdvisors() {
    const formData = new FormData();
    formData.append('advisors', this.fileToUpload);
    this.utilitiesService.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.profileService.uploadAssignProfileToAdvisor(formData).subscribe({
          next: (response) => {
            if (response) {
              this.uploaded = response;
            }
          }
        });
      }
    });

  }

}
