import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ProfileService} from '@orion/services/profile.service';
import {UtilitiesService} from '@orion/utilities/utilities.service';


@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit {
  @Output() closeWindow = new EventEmitter();
  editForm: UntypedFormGroup;
  @Input() profileInformation: any;
  @Input() isProspect : boolean;
  isError = false;
  message!: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private utilities: UtilitiesService,
              private profileService: ProfileService) { }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      middleName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      profilePhoneNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      gender: [
        '',
        [
          Validators.required,
        ],
      ],
      nationality: [
        '',
        [
          Validators.required,
        ],
      ],
      countryCode: [
        '',
        [
          Validators.required,
        ],
      ],
      dateOfBirth: [
        '',
        [
          Validators.required,
        ],
      ]
    });
  }

  closePersonalEditWindow() {
    this.closeWindow.emit();
  }

  submitEditForm(): void {

      // Success 🎉

      const updatedFormInput = {
        id: this.profileInformation.id,
        countryCode: this.editForm.value.countryCode,
        dateOfBirth: this.setDate(this.editForm.value.dateOfBirth),
        email: this.editForm.value.email,
        firstName: this.editForm.value.firstName,
        gender: this.editForm.value.gender,
        lastName: this.editForm.value.lastName,
        middleName: this.editForm.value.middleName,
        nationality: this.editForm.value.nationality,
        phoneNumber: this.editForm.value.phoneNumber,
        profilePhoneNumber: this.editForm.value.profilePhoneNumber,
        isProspect: this.isProspect,
      };

      this.utilities.confirmAlert().then((result) => {
       if (result.isConfirmed) {
         this.profileService.updatePersonalInformation(updatedFormInput)
           .subscribe((response) => {

               this.utilities.successAlert('Successfully Updated Client Details');
               this.closeWindow.emit();
               this.isError = false;
               this.ngOnInit();
               window.location.reload();

             },
             error => {
               this.message = `Un Authorized Access! ${error}`;
               this.isError = true;
             }
           );
       }
     });

  }

  setDate(date:string){
    let dob = new Date(date);
    let day = dob.getDate().toString();
    let month = (dob.getMonth() +1).toString();
    let year = dob.getFullYear();

    return [year, month, day].join("-");
  }


}
