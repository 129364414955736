import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {Department} from '../../models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {DepartmentService} from "@orion/services/department.service";

export class DepartmentsDataSource implements DataSource<Department> {

  private departmentsSubject = new BehaviorSubject<Department[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private departmentService: DepartmentService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Department[] | ReadonlyArray<Department>> {
    return this.departmentsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.departmentsSubject.complete();
    this.loadingSubject.complete();
  }

  loadDepartments(offset: any, max: any, sort: string, order: string, page: number, filter = '') {
    this.loadingSubject.next(true);
    this.departmentService.list(offset, max, sort, order, page, filter).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(resp => {
      this.departmentsSubject.next(resp.data);
      this.resultsLengthSubject.next(resp.totalCount);
    });
  }
}
