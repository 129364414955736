            <mat-form-field appearance="outline">
              <mat-label>{{label}}</mat-label>
              <input
                type="text"
                [name]="fieldName"
                [value]="value"
                [type]="type"
                [disabled]="isDisabled"
                (input)="onChange($event)"
                (blur)="touched()"
                matInput />
            </mat-form-field>



