import {CollectionViewer , DataSource} from '@angular/cdk/collections';
import {Capability} from '@orion/models/capability';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CapabilityService} from '@orion/services/capability.service';
import {catchError, finalize} from 'rxjs/operators';

export class CapabilitiesDataSource implements DataSource<Capability>{
  private capabilitySubject = new BehaviorSubject<Capability[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

 constructor(private capabilityService: CapabilityService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Capability[] | ReadonlyArray<Capability>> {
    return this.capabilitySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.capabilitySubject.complete();
    this.loadingSubject.complete();
  }

  loadCapabilities(offset: any, max: any, sort: string, order: string, page: number, filter = '') {
    this.loadingSubject.next(true);
    this.capabilityService.listCapabilities(offset, max, sort, order, page, filter).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(resp => {
      this.capabilitySubject.next(resp.data);
      this.resultsLengthSubject.next(resp.totalCount);
    });
  }

}
