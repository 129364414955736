import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProfileService} from '@orion/services/profile.service';
import {UtilitiesService} from '@orion/utilities/utilities.service';

@Component({
  selector: 'app-edit-profile-address',
  templateUrl: './edit-profile-address.component.html',
  styleUrls: ['./edit-profile-address.component.scss']
})
export class EditProfileAddressComponent implements OnInit {
  @Output() close = new EventEmitter();
  editForm: UntypedFormGroup;
  @Input() profileInformation: any;
  @Input() profileEmail: string;
  isError = false;
  message!: string;
  countries = this.utility.countries();
  regions = this.utility.regions();
  districts = this.utility.districts();

  constructor(private formBuilder: UntypedFormBuilder,
              private utility: UtilitiesService,
              private profileService: ProfileService) {
  }

  ngOnInit(): void {

    this.editForm = this.formBuilder.group({
      id: [''],
      country: [
        null,
        [
          Validators.required,
        ],
      ],
      region: [
        null,
        [
          Validators.required,
        ],
      ],
      district: [
        null,
        [
          Validators.required,
        ],
      ],
      street: [
        null,
        [
          Validators.required,
        ],
      ],
      houseNumber: [
        null,
        [
          Validators.required,
        ],
      ],
      boxNumber: [
        null,
        [
          Validators.required,
        ],
      ],
    });

  }

  submitEditForm(): void {

    const address = this.editForm.value;
    // console.log("ADDRESS: " + JSON.stringify(address));
    const formData = new FormData();
    formData.append('userId', this.profileInformation.email);
    formData.append('id', this.profileInformation.address.id);
    formData.append('country', address.country);
    formData.append('region', address.region);
    formData.append('city', address.district);
    formData.append('street', address.street);
    formData.append('houseNumber', address.houseNumber);
    formData.append('boxNumber', address.boxNumber);

    this.utility.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.profileService.updateProfileAddress(formData).subscribe({
          next: (response) => {
            console.log(`address_edit:${JSON.stringify(response)}`);
            this.close.emit();
            this.isError = false;
            this.utility.successAlert(response);
            this.ngOnInit();
            window.location.reload();

          },
          error: (error) => {
            this.isError = true;
            this.message = error.message;
          }
        });
      }
    });


  }

  closeForm() {
    this.close.emit();
  }

}
