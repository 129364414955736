<app-single-profile
  *ngIf="showProfile && !isSearch"
  [profileId]="profileId"
  (closeProfile)="hideProfile()"
></app-single-profile>


<div>
  <h1 class="page-title">Profiles</h1>
  <br />
  <div class="page-header xn-fadein-up">
    <form class="page-actions" [formGroup]="filtersForm">
      <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label>Source</mat-label>
          <mat-select
            formControlName="sourceStatus"
            (selectionChange)="applySourceStatusFilter($event)"
          >
            <mat-option value="ALL">All</mat-option>
            <mat-option value="WEB">WEB</mat-option>
            <mat-option value="WEB_MTN">WEB_MTN</mat-option>
            <mat-option value="WEB_AIRTEL">WEB_AIRTEL</mat-option>
            <mat-option value="MTN">MTN</mat-option>
            <mat-option value="APP_ANDROID">APP ANDROID</mat-option>
            <mat-option value="APP_IOS">APP IOS</mat-option>
            <mat-option value="APP">APP</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- KYC Status Filter   -->
      <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label>KYC Status</mat-label>
          <mat-select
            formControlName="kycStatus"
            (selectionChange)="applyKycStatusFilter($event)"
          >
            <mat-option value="ALL">All</mat-option>
            <mat-option value="TODO">To do</mat-option>
            <mat-option value="SUBMITTED">Submitted</mat-option>
            <mat-option value="VERIFIED"
            >Verified</mat-option
            >
            <mat-option value="EXTERNAL"
            >External</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Funded Status Filter  -->
      <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label>Funded Status</mat-label>
          <mat-select
            formControlName="fundedStatus"
            (selectionChange)="applyFundedStatusFilter($event)"
          >
            <mat-option value="ALL">All</mat-option>
            <mat-option value="FUNDED">Funded</mat-option>
            <mat-option value="NOTFUNDED">Not Funded</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Advisor Filter   -->
      <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label>Advisor</mat-label>
          <mat-select
            formControlName="advisor"
            (selectionChange)="applyAdvisorFilter($event)"
          >
            <mat-option value="ALL">All</mat-option>
            <mat-option *ngFor="let advisor of advisors" value="{{ advisor.id }}">
              {{advisor.firstName}} {{advisor.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- KYC Reverted Status Filter   -->
      <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label> KYC Reverted</mat-label>
          <mat-select
            formControlName="kycRevertedStatus"
            (selectionChange)="applyKycRevertedStatusFilter($event)"
          >
            <mat-option value="ALL">All</mat-option>
            <mat-option value="YES">Yes</mat-option>
            <mat-option value="NO">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Date Filter   -->
      <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label>Created</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              matInput
              placeholder="Start date"
              formControlName="startDate"
            />
            <input
              matEndDate
              matInput
              placeholder="End date"
              formControlName="endDate"
              (dateChange)="applyDateRangeFilter($event)"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </form>
  </div>

</div>
<br />
<div class="xn-padding">
  <div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="dateCreated"
      matSortDisableClear
      matSortDirection="desc"
      class="xn-table"
    >
      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let element">{{ element.position }}</td>
      </ng-container>

      <!-- dateCreated Column -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dateCreated | date }}
        </td>
      </ng-container>

      <!-- firstName Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Names</th>
        <td mat-cell *matCellDef="let element">
          <div class="u-profile">
            <figure
              class="u-profile-photo {{ element.kycStatus }}"
              [style.backgroundImage]="
                'url(' + element.avatarUrl + ')'
              "
            ></figure>
            <a
              >{{ element.firstName | titlecase }}
              {{ element.lastName | titlecase }}</a
            >
          </div>
        </td>
      </ng-container>

      <!--  Column -->
      <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef>SOURCE</th>
        <td mat-cell *matCellDef="let element">
          {{ element.source | uppercase }}
        </td>
      </ng-container>

      <!-- kycStatus Column -->
      <ng-container matColumnDef="kycStatus">
        <th mat-header-cell *matHeaderCellDef>KYC</th>
        <td mat-cell *matCellDef="let element">
          <span class="status-label {{ element.kycStatus | lowercase }}">
            {{ setStatus(element.kycStatus) | lowercase }}
          </span>
        </td>
      </ng-container>

      <!-- Profile Status Column -->
  <!--    <ng-container matColumnDef="profileStatus">-->
  <!--      <th mat-header-cell *matHeaderCellDef>PROFILE</th>-->
  <!--      <td mat-cell *matCellDef="let element">-->
  <!--        <span class="status-label {{ element.status | lowercase }}">-->
  <!--          {{ element.status | titlecase }}-->
  <!--        </span>-->
  <!--      </td>-->
  <!--    </ng-container>-->

      <!-- fundedStatus Column -->
      <ng-container matColumnDef="fundedStatus">
        <th mat-header-cell *matHeaderCellDef>FUNDING</th>
        <td mat-cell *matCellDef="let element">
          <span
            class="status-label not-funded"
            *ngIf="element?.transactionTotal <= 0"
            >not funded</span
          >
          <span class="status-label funded" *ngIf="element?.transactionTotal > 0">
            funded</span
          >
        </td>
      </ng-container>

      <!-- XENO Advisor Column -->
      <ng-container matColumnDef="advisor">
        <th mat-header-cell *matHeaderCellDef>ADVISOR</th>
        <td mat-cell *matCellDef="let element">{{ element.advisors }}</td>
      </ng-container>

      <ng-container matColumnDef="profileTotalAUM" *appSecAccess="['ROLE_SUPERVISOR']">
        <th mat-header-cell *matHeaderCellDef>TOTAL AUM</th>
        <td mat-cell *matCellDef="let element"> {{tenantCurrency}} {{ element.profileTotalAUM | number: '1.0-0'}}</td>
      </ng-container>

      <!-- Profile Last Updated By    -->
      <ng-container matColumnDef="lastUpdatedBy" *appSecAccess="['ROLE_SUPER_ADMIN']">
        <th mat-header-cell *matHeaderCellDef>LAST UPDATED BY</th>
        <td mat-cell *matCellDef="let element">{{ element.lastUpdatedBy}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index"
        (click)="showProfileDetail(row.id)"
      ></tr>
    </table>

    <mat-paginator
      [length]="dataSource.resultsLength | async"
      [pageSize]="50"
      showFirstLastButtons
      [pageSizeOptions]="[10, 50, 100, 200]"
    ></mat-paginator>
  </div>
</div>

<div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
