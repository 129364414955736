<slideover (closeWindow)="close.emit()">
  <div class="page-inner-container">
    <div class="page-section">
      <h2 class="page-subtitle huge">
        {{ staff ? "Update" : "Create" }} Staff
      </h2>
      <form class="edit-user-form" [formGroup]="form" (submit)="submitStaff()">
        <div class="fields">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>User Name</mat-label>
              <input type="text" formControlName="username" matInput />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Email Address</mat-label>
              <input type="email" formControlName="email" matInput />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>First Name</mat-label>
              <input type="text" formControlName="firstName" matInput />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Last Name</mat-label>
              <input type="text" formControlName="lastName" matInput />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Middle Name</mat-label>
              <input type="text" formControlName="middleName" matInput />
            </mat-form-field>
          </div>


          <div>
            <mat-form-field appearance="outline">
              <mat-label>Referral Code</mat-label>
              <input type="text" formControlName="referralCode" matInput />
            </mat-form-field>
          </div>
          
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Gender</mat-label>
              <mat-select formControlName="gender">
                <mat-option value="Female">Female</mat-option>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Rather not say">Rather not say</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-container *ngIf="staff">
            <div>
              <div>
                <input type="file" class="form-control" name="file" (change)="onFileSelected($event)"
                  accept=".jpg,.png,.jpeg" placeholder="Upload Profile Image" />
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="staff">
            <div>
              <mat-form-field appearance="outline">
                <mat-label>Metting Link</mat-label>
                <input type="text" formControlName="meetingLink" matInput />
              </mat-form-field>
            </div>
          </ng-container>
          <div class="row">
            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5">
              <mat-form-field appearance="outline">
                <mat-label>Country Code</mat-label>
                <mat-select formControlName="countryCode" (selectionChange)="changed($event)">
                  <mat-option *ngFor="let countryCode of countryCodes" [value]="countryCode.dial_code">
                    {{countryCode.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7">
              <mat-form-field appearance="outline" class="x-w100">
                <mat-label>Phone number</mat-label>
                <input type="text" formControlName="telephone" matInput />
              </mat-form-field>
            </div>
          </div>
        </div>


        <div style="padding: 1rem 0">
          <h2 style="
              text-transform: uppercase;
              font-weight: bold;
              font-size: 14px;
              color: #4a4a95;
              letter-spacing: -0.5px;
            ">
            Departments
          </h2>
          <div class="mat-checkbox-group">
            <input type="hidden" formControlName="departments" />
            <div class="mat-checkbox-wrap" *ngFor="let department of allDepartments">
              <mat-checkbox [checked]="setDepartmentCheckbox(department.id)"
                (change)="updateDepartments(department.id)">{{ department.department }}</mat-checkbox>
            </div>
          </div>
        </div>

        <div>
          <h2 style="
              text-transform: uppercase;
              font-weight: bold;
              font-size: 14px;
              padding-bottom: 1rem;
              color: #4a4a95;
              letter-spacing: -0.5px;
            ">
            Options
          </h2>
          <div class="mat-set-options">
            <mat-checkbox formControlName="locked" (change)="onSetLock()">{{isLocked == true ? 'Unlock Account' : 'Lock account'}}</mat-checkbox>
            <mat-checkbox formControlName="isAdvisor" (change)="onSetAdvisor()">Advisor</mat-checkbox>
          </div>
        </div>


        <div class="u-justify actions">
          <button type="button" *ngIf="staff" mat-button mat-flat-button color="primary" (click)="deleteStaff(staff)">
            Delete
          </button>

          <button type="button" [disabled]="!form.valid" [class.u-fill-w]="!staff" mat-button mat-flat-button
            color="primary" (click)="submitStaff()">
            {{ staff ? "Update" : "Create" }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- {{ form.value | json }} -->
</slideover>
