import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Referrals} from "@orion/models/referrals";
import {MatSort} from "@angular/material/sort";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ReferralsDataSource} from "@orion/pages/referrals/referrals-data-source";
import {ReferralsService} from "@orion/services/referrals.service";
import {merge} from "rxjs";
import {tap} from "rxjs/operators";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss']
})
export class ReferralsComponent implements OnInit, AfterViewInit {
  @Input() isSearch: boolean;
  @Input() searchResults: any;
  displayedColumns: string[] = [
    'position',
    'dateCreated',
    'referredName',
    'referredAmount',
    'referralCode'
  ];
  referrals: Referrals[] = [];
  resultsLength = 0;
  isLoading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtersForm: UntypedFormGroup;
  dataSource: ReferralsDataSource;
  promoCodes = [];

  constructor(private formBuilder: UntypedFormBuilder, private referralsService: ReferralsService) { }

  ngOnInit(): void {
    this.filtersForm = this.formBuilder.group({
      promoCode: [''],
      startDate: [''],
      endDate: [''],
    });
    this.referralsService.getPromoCodes().subscribe((data) => {
      this.promoCodes = data;
    }, error => {return error;})
    this.dataSource = new ReferralsDataSource(this.referralsService);
    this.dataSource.loadReferrals(0, 50, 'dateCreated', 'desc', 0, '');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadReferralsPage()))
      .subscribe();
  }

  loadReferralsPage() {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadReferrals(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.filtersForm.value
    );
  }

  applyPromoCodeFilter(event: MatSelectChange) {
    const filterValue = event.value;
    this.paginator.pageIndex = 0;
    this.dataSource.loadReferrals(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      this.filtersForm.value
    );
  }

  applyDateRangeFilter(event: MatDatepickerInputEvent<Date>) {
    this.paginator.pageIndex = 0;
    this.dataSource.loadReferrals(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      this.filtersForm.value
    );
  }

}
