import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import { ProfileService } from '@orion/services/profile.service';
import { UtilitiesService } from '@orion/utilities/utilities.service';
import { User } from '@orion/models';
import { AuthenticationService } from '@orion/services';

@Component({
  selector: 'app-user-add-note',
  templateUrl: './user-add-note.component.html',
  styleUrls: ['./user-add-note.component.scss'],
})
export class UserAddNoteComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    private utilities: UtilitiesService,
    private authenticationService: AuthenticationService) { }


  @Output() close = new EventEmitter();
  noteForm: UntypedFormGroup;
  @Input() userId: any;
  @Input() advisorInfo: any;
  orionUser: User;

  ngOnInit(): void {
    this.authenticationService.orionUser.subscribe(x => { this.orionUser = x; });
    this.noteForm = this.formBuilder.group({
      subject: ['', [Validators.required]],
      note: ['', [Validators.required]],
    });

  }

  closeForm() {
    this.close.emit();
  }


  submitNote(){
    let note = this.noteForm.value;
    const noteDetails = {
      user: this.userId,
      staff: this.orionUser.id,
      parentId: null,
      subject: note.subject,
      note: note.note
    }
    this.utilities.confirm4Alert(`Are you sure you want to save this note:\n\n Subject: ${note.subject}`, `Please be aware that once a note is submitted, it cannot be removed.`).then((result) => {
      if (result.isDismissed) {
      }
      else if(result.isConfirmed){
        this.profileService.saveUserNote(noteDetails)
              .subscribe((response) => {
                if(response.code == 200){
                this.closeForm();
                this.utilities.success2Alert(`Note saved successfully!`).then((result) => {
                  // if (result.isConfirmed) {
                  // }
                });
                } else{
                  this.utilities.error2Alert(`Failed to save note!`).then((result) => {
                    // if (result.isConfirmed) {
                    // }
                  });
                }
              },
                error => alert(`Something went wrong: ${error}`));
      }
    })

  }
 }
