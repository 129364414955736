import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {AccountGoal, Allocation, User} from '../../models';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'edit-goal-strategy',
  templateUrl: './edit-goal-strategy.component.html',
  styleUrls: ['./edit-goal-strategy.component.scss']
})
export class EditGoalStrategyComponent implements OnInit {

  constructor(private accountService: AccountService,
              private formBuilder: UntypedFormBuilder
  ) {
  }

  get strategy() {
    return this.editForm.get('strategy');
  }

  get moneyMarkets() {
    return this.editForm.get('moneyMarkets');
  }

  get bonds() {
    return this.editForm.get('bonds');
  }

  get domesticEquities() {
    return this.editForm.get('domesticEquities');
  }

  get regionalEquities() {
    return this.editForm.get('regionalEquities');
  }

  get aggregate() {
    return (this.moneyMarkets.value + this.bonds.value + this.domesticEquities.value + this.regionalEquities.value).toFixed(2);
  }
  @Input() orionUser: User;
  @Input() investmentStrategy: string;
  @Input() goal: string;
  @Input() accountGoal: AccountGoal;
  @Input() goalAllocations: Allocation[];
  @Output() close = new EventEmitter();
  @Output() onUpdateStrategy = new EventEmitter();
  errorMsg = '';
  fundsTotal: number;
  isLoading = false;
  view = 'edit';
  isFetching = false;
  canSetCustomStrategy = true;

  editForm: UntypedFormGroup;
  profile: any;

  sortedAllocations = [];
  selectedStrategy: any;

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      strategy: [null, [Validators.required]],
      moneyMarkets: [0, [Validators.required, Validators.max(100)]],
      bonds: [0, [Validators.required, Validators.max(100)]],
      domesticEquities: [0, [Validators.required, Validators.max(100)]],
      regionalEquities: [0, [Validators.required, Validators.max(100)]],
    });
    this.updateForm(this.collectAllocations(this.goalAllocations));
  }


  updateForm(sortedAllocations = []): void {
    debugger;
    this.editForm.patchValue({
      moneyMarkets: sortedAllocations[0],
      bonds: sortedAllocations[1],
      domesticEquities: sortedAllocations[2],
      regionalEquities: sortedAllocations[3],
    });
  }

  onSubmit() {
    const prompt = confirm('To confirm press ok');
    if (prompt) {
      if (this.editForm.valid) {
        this.isLoading = true;
        // ---
        if (this.selectedStrategy == 'custom') {
          // create json map
          const x = {
            data: [
              {
                id: this.goalAllocations[0].allocationId,
                assetCode: this.goalAllocations[0].fundRefCode,
                percent: this.moneyMarkets.value
              },
              {
                id: this.goalAllocations[1].allocationId,
                assetCode: this.goalAllocations[1].fundRefCode,
                percent: this.bonds.value
              },
              {
                id: this.goalAllocations[2].allocationId,
                assetCode: this.goalAllocations[2].fundRefCode,
                percent: this.domesticEquities.value
              },
              {
                id: this.goalAllocations[3].allocationId,
                assetCode: this.goalAllocations[3].fundRefCode,
                percent: this.regionalEquities.value
              },
            ]
          };
          this.accountService.updateExistingAllocationsOfGoal(x).subscribe(t => {
            this.isLoading = false;
            alert(t.msg);
          }, error => {
            this.isLoading = false;

            alert('Failed to update allocation');

          });


        }
        else {

          this.accountService.createOrUpdateAllocationsOfGoal(this.selectedStrategy, this.accountGoal.id)
            .subscribe(t => {
              this.isLoading = false;
              alert(t.msg);
            }, error => {
              this.isLoading = false;
              alert('Failed to update allocation');

            });
        }
        // ---
        setTimeout(() => {
          this.view = 'preview';
          this.isLoading = false;
        }, 1000);
      } else {
        this.errorMsg = 'Please correct errors before submitting';
      }
    }
  }

  closeWindow() {
    this.onUpdateStrategy.emit({
      strategy: 'custom',
      moneyMarkets: '0',
      bondMarkets: '0',
      regionalEquities: '0',
      domesticEquities: ''
    });
  }

  strategyChange(value: string) {
    this.selectedStrategy = value;
    if (value === 'custom') { return; }
    this.isFetching = true;
    this.accountService.getInvestmentStrategy(value, this.accountGoal.code).subscribe(t => {
      if (t.code == 200) {
        this.profile = t.profile.allocations;
        this.sortedAllocations = t.profile.sortedAllocations;
        this.updateForm(t.profile.sortedAllocations);
        this.isFetching = false;
      }
    }, error => {

    });
  }

  collectAllocations(allocations: Allocation[] = []): any[] {
    return allocations.map((obj) => obj.allocation);
  }

}
