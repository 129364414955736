<slideover [zIndex]="3" (closeWindow)="close.emit()">
  <div class="page-section">
    <h2 class="page-subtitle huge">Edit Address</h2>
    <div class="error" *ngIf="isError">
      <p>{{message}}</p>
    </div>

    <form class="edit-user-form"
          (ngSubmit)="submitEditForm()"
          [formGroup]="editForm">
      <div class="fields">

        <div>
            <app-text-input
              [parentForm]="editForm"
              label="Plot/Flat/House Number"
              fieldName="houseNumber"
              [ngModel]="profileInformation.address.houseNumber"
              formControlName="houseNumber">
            </app-text-input>
        </div>
        <div>
          <app-text-input
            [parentForm]="editForm"
            label="Road/ Street"
            fieldName="street"
            [ngModel]="profileInformation.address.street"
            formControlName="street">
          </app-text-input>
        </div>

        <div>
          <app-text-input
            [parentForm]="editForm"
            label="P.O.Box"
            fieldName="boxNumber"
            [ngModel]="profileInformation.address.boxNumber"
            formControlName="boxNumber">
          </app-text-input>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Region</mat-label>
            <mat-select
              formControlName="region"
              [ngModel]="profileInformation.address.region">
              <mat-option
                *ngFor="let region of regions"
                value="{{region.name}}">{{region.name}}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div>

          <mat-form-field appearance="outline">
            <mat-label>District</mat-label>
            <mat-select
              formControlName="district"
              [ngModel]="profileInformation.address.district">
              <mat-option
                *ngFor="let district of districts"
                value="{{district.name}}">{{district.name}}</mat-option>
            </mat-select>
          </mat-form-field>


        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select
              formControlName="country"
              [ngModel]="profileInformation.address.country">
              <mat-option
                *ngFor="let country of countries"
                value="{{country.name}}">{{country.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="u-justify actions">
          <button
            type="submit"
            mat-button
            mat-flat-button
            color="primary">
            Update
          </button>
        </div>
      </div>
    </form>
  </div>
</slideover>
