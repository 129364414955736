<ng-container *ngIf="showModal">
    <app-single-advisor [showProfile]="true" (hideProfile)="hideProfileModal()" [advisorProfileId]="advisorProfileId"
        [advisorDashboardProfile]="advisorDashboardProfile">
    </app-single-advisor>
</ng-container>
<ng-container *ngIf="showModal">
    <div class="overlay">
    </div>
</ng-container>

<ng-container *ngIf="showAdvisorModal">
    <app-advisory-upload [showAdvisorUpload]="true" (hideAdvisorUpload)="hideAdvisorUploadModal()" [zIndex]="2">
    </app-advisory-upload>
</ng-container>

<div class="header-section-top">
    <h1 class="header-tile">Advisors</h1>
    <div class="u-justify xn-fadein-up">
        <form class="page-actions" [formGroup]="filtersForm">
            <!-- Date Filter   -->
            <!-- <div class="page-action">
                <mat-form-field appearance="fill">
                    <mat-label>Created</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate matInput placeholder="Start date" formControlName="startDate" />
                        <input matEndDate matInput placeholder="End date" formControlName="endDate"
                            (dateChange)="applyDateRangeFilter($event)" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div> -->
        </form>
        <button mat-raised-button color="primary" class="button-assign">
            <span (click)="showAdvisorUploadModa()">Assign Advisors</span>
        </button>
    </div>

</div>
<br />
<div class="xn-fadein-up">
    <mat-grid-list cols="4" gutterSize="20" rowHeight="150px">
        <mat-grid-tile>
            <mat-card class="card xn-card-height">
                <mat-card-content class="card-content">
                    <div class="card-title">
                        <div>Assigned Users</div>
                        <div class="cont" align="center">
                            <mat-icon>group</mat-icon>
                        </div>
                    </div>
                    <div class="total-card">{{dataSource?.totalAssignedUsers ? (this.utilitiesService.displayDefault((dataSource?.totalAssignedUsers | async) | number)) : 0}}</div>
         </mat-card-content>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-card class="card xn-card-height">
                <mat-card-content class="card-content">
                    <div class="card-title">
                        <div>Assigned Users AUM</div>
                        <div class="cont" align="center">
                            <mat-icon>money</mat-icon>
                        </div>
                    </div>
                    <div class="total-card">{{tenantCurrency}} {{dataSource?.totalAssignedUsersAUM ?
                        (this.utilitiesService.numberFormatter((dataSource?.totalAssignedUsersAUM) | async)) : 0}}
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-card class="card xn-card-height">
                <mat-card-content class="card-content">
                    <div class="card-title">
                        <div>Advisors</div>
                        <div class="cont" align="center">
                            <mat-icon>group</mat-icon>
                        </div>
                    </div>
                    <div class="total-card">{{dataSource.totalAdvisors ? (this.utilitiesService.displayDefault((dataSource?.totalAdvisors | async) | number)) : 0}}</div>
                </mat-card-content>
              </mat-card>
        </mat-grid-tile>
        <!-- <mat-grid-tile>
            <mat-card class="card xn-card-height">
                <div class="card-title">
                    <div>Unassigned</div>
                    <div class="cont" align="center">
                        <mat-icon>group</mat-icon>
                    </div>
                </div>
                <div class="total-card">{{advisoryDashboardStats?.unAssigned? (advisoryDashboardStats?.unAssigned | number) : 0}}</div>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-card class="card xn-card-height">
                <div class="card-title">
                    <div>AUM Unassigned</div>
                    <div class="cont" align="center">
                        <mat-icon>money</mat-icon>
                    </div>
                </div>
                <div class="total-card">{{tenantCurrency}} {{advisoryDashboardStats?.unAssignedAum ?
                    (this.utilitiesService.numberFormatter(advisoryDashboardStats?.unAssignedAum)) :
                    0}}</div>
            </mat-card>
        </mat-grid-tile> -->
    </mat-grid-list>

</div>
<br />
<div class="xn-padding ">
    <div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="dateCreated" matSortDisableClear
            class="xn-table">
            <!-- Position Column -->
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                <td mat-cell *matCellDef="let element">{{ element.position }}</td>
            </ng-container>

            <!-- dateCreated Column -->
            <!-- <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Joined</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.dateCreated | date }} <span class="join-time">- {{ element.dateCreated | date:'shortTime'
                        }}</span>
                </td>
            </ng-container> -->

            <!-- firstName Column -->
            <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Advisor</th>
                <td mat-cell *matCellDef="let element">
                    <div class="u-profile">
                        <a>{{ element.firstName | titlecase }}
                            {{ element.lastName | titlecase }}</a>
                    </div>
                </td>
            </ng-container>

            <!--  Assigned Users Column -->
            <ng-container matColumnDef="assignedUsers">
                <th mat-header-cell *matHeaderCellDef>Assigned Users</th>
                <td mat-cell *matCellDef="let element">
                    {{ (element.totalUsers) | number }}
                </td>
            </ng-container>
            <!-- Advisor Deposits Column -->
            <ng-container matColumnDef="advisorDeposits">
                <th mat-header-cell *matHeaderCellDef>Deposits</th>
                <td mat-cell *matCellDef="let element"><span class="currency-code">{{tenantCurrency}}</span> <span class="deposits">{{
                        element.totalDeposit | number: '1.0-0'}}</span> </td>
            </ng-container>

            <!-- Advisor withdraws Column -->
            <ng-container matColumnDef="advisorWithdraws">
                <th mat-header-cell *matHeaderCellDef>Withdraws</th>
                <td mat-cell *matCellDef="let element"><span class="currency-code">{{tenantCurrency}}</span> <span class="withdraws">{{
                        element.totalWithdraws | number: '1.0-0'}}</span></td>
            </ng-container>

             <!--  Advisor AUM Column -->
             <ng-container matColumnDef="advisorAUM">
                <th mat-header-cell *matHeaderCellDef>Current AUM</th>
                <td mat-cell *matCellDef="let element">
                    <span class="currency-code">{{tenantCurrency}}</span> {{ (element.totalAum) | number: '1.0-0'}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
                (click)="showAdvisorProfileDetail(row.id, row)"></tr>
        </table>
        <mat-paginator [length]="dataSource.resultsLength | async" [pageSize]="10" showFirstLastButtons
            [pageSizeOptions]="[5, 10, 50, 100, 200]"></mat-paginator>
    </div>
</div>


<div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner color="accent"></mat-spinner>
</div>
