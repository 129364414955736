<div class="bkg index-{{ zIndex }}">
    <div class="content-box {{ size }}">
      <button class="close-button" (click)="closeSlideover()"></button>
  
      <h1 class="page-title">{{ title }}</h1>
  
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  