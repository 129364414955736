<h2 class="page-title">
  Bulk Emails with Unselected Batch
</h2>
<br>
<mat-card>
  <mat-card-content>
    <div class="page-section">
      <form class="sender-form" [formGroup]="form">
        <div class="error" *ngIf="isError">
          <p>{{message}}</p>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Subject</mat-label>
            <input formControlName="subject" matInput>
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox formControlName="sendForPreview">Send for Preview</mat-checkbox>
        </div>

        <div>
          <mat-checkbox formControlName="sendForNoReply">No Reply Email</mat-checkbox>
        </div>
        <br/>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>HTML Content</mat-label>
            <textarea
              formControlName="mailerContent"
              rows="20"
              matInput>
             </textarea>
          </mat-form-field>
        </div>
        <div class="u-justify actions">
          <button
            type="button"
            mat-stroked-button
            (click)="clearForm()"
            mat-flat-button
            color="default"
          >
            Clear
          </button>
          <button
            type="button"
            [disabled]="!form.valid"
            mat-button
            mat-flat-button
            color="primary"
            (click)="submitMailFormData()"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
<br/>
<br/>
<br/>
