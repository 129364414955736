import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {catchError, finalize, map, mergeMap, tap} from 'rxjs/operators';
import {LctrDashboard} from '@orion/models/lctr-dashboard';
import {LctrDashboardService} from '@orion/services/lctr-dashboard.service';
import {ProfileService} from "@orion/services/profile.service";

export class LctrDashboardDataSource implements DataSource<LctrDashboard> {
  private lctrDashboardSubject = new BehaviorSubject<LctrDashboard[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private lctrDashboardService: LctrDashboardService, private profileService: ProfileService) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<LctrDashboard[] | ReadonlyArray<LctrDashboard>> {
    return this.lctrDashboardSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lctrDashboardSubject.complete();
    this.loadingSubject.complete();
  }

  loadLctrDashboard(
    offset: any,
    max: any,
    sort: string,
    order: string,
    page: number,
    filter = ''
  ) {
    this.loadingSubject.next(true);
    this.lctrDashboardService
      .getAllTransactions(offset, max, sort, order, page, filter)
      .pipe(
        catchError(() => of({ data: [], totalCount: 0 })),
        finalize(() => this.loadingSubject.next(false)),
        tap((resp) => {
          this.lctrDashboardSubject.next(resp.data);
          this.resultsLengthSubject.next(resp.totalCount);
        }),
        mergeMap((resp) => {
          const avatarRequests = resp.data.map(transaction => this.fetchUserAvatar(transaction.email, this.lctrDashboardSubject));
          return combineLatest([of(resp.data), combineLatest(avatarRequests)]);
        })
      )
      .subscribe(([transactionData, avatarUrls]) => {
        transactionData.forEach((transaction: any, index: any) => {
          transaction.avatarUrl = avatarUrls[index];
        });
        this.lctrDashboardSubject.next(transactionData);
      });
  }

  private fetchUserAvatar(email: string, subject: BehaviorSubject<any[]>): Observable<string> {
    const usersDataSnapshot = subject.value;
    const userIndex = usersDataSnapshot.findIndex((e) => e['email'] === email);
    if (userIndex !== -1) {
      usersDataSnapshot[userIndex].loadingAvatar = true;
      subject.next(usersDataSnapshot);
    }

    return this.profileService.getPassportImage(email).pipe(
      map(resp => URL.createObjectURL(resp)),
      tap((imageUrl) => {
        if (userIndex !== -1) {
          usersDataSnapshot[userIndex].avatarUrl = imageUrl;
          usersDataSnapshot[userIndex].loadingAvatar = false;
          subject.next(usersDataSnapshot);
        }
      }),
      catchError(error => {
        console.error('Error fetching avatar:', error);
        if (userIndex !== -1) {
          usersDataSnapshot[userIndex].loadingAvatar = false;
          subject.next(usersDataSnapshot);
        }
        return of(null); // Return null if avatar fetch fails
      })
    );
  }
}
