<slideover [zIndex]="2" (closeWindow)="close.emit()">
  <div class="page-inner-container">

    <div class="page-section">
      <h2 class="page-subtitle huge">Edit Beneficiary</h2>
      <div class="error" *ngIf="isError">
        <p>{{message}}</p>
      </div>
      <form class="edit-user-form"
            (ngSubmit)="submitEditForm()"
            [formGroup]="editForm">
        <div class="fields">

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="First Name"
              fieldName="firstName"
              [ngModel]="profileAccountBeneficiaryInformation.firstName"
              formControlName="firstName">
            </app-text-input>
          </div>
          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Middle Name"
              fieldName="middleName"
              [ngModel]="profileAccountBeneficiaryInformation.middleName"
              formControlName="middleName">
            </app-text-input>
          </div>

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Last Name"
              fieldName="lastName"
              [ngModel]="profileAccountBeneficiaryInformation.lastName"
              formControlName="lastName">
            </app-text-input>
          </div>

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Relationship"
              fieldName="relationship"
              [ngModel]="profileAccountBeneficiaryInformation.relationShip"
              formControlName="relationShip">
            </app-text-input>
          </div>

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Percentage"
              fieldName="percentage"
              [ngModel]="profileAccountBeneficiaryInformation.percentage"
              formControlName="percentage">
            </app-text-input>
          </div>

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Country Code"
              fieldName="countryCode"
              [ngModel]="profileAccountBeneficiaryInformation.countryCode"
              formControlName="countryCode">
            </app-text-input>
          </div>

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Telephone"
              fieldName="telephone"
              [ngModel]="profileAccountBeneficiaryInformation.telephone"
              formControlName="telephone">
            </app-text-input>
          </div>

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Email"
              fieldName="email"
              [ngModel]="profileAccountBeneficiaryInformation.email"
              formControlName="email">
            </app-text-input>
          </div>




          <div class="u-justify actions">
            <button
              type="submit"
              mat-button
              mat-flat-button
              color="primary">
              Update
            </button>
          </div>
        </div>
      </form>

    </div>
  </div>
</slideover>
