<slideover [zIndex]="2" (closeWindow)="closeWindow.emit()">
  <div class="page-inner-container">

    <div class="page-section">
      <h2 class="page-subtitle huge">Manage Rights</h2>
      <form class="edit-user-form"
            [formGroup]="editForm">
        <div class="fields">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Select Member</mat-label>
              <mat-select formControlName="id">
                <mat-option
                  *ngFor="let member of accountDetails.representatives"
                  value="{{member.id}}">{{member.firstName}} {{member.lastName}} - (Representative)
                </mat-option>
                <mat-option
                  *ngFor="let member of accountDetails.members"
                  value="{{member.id}}">{{member.firstName}} {{member.lastName}}- (Member)
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Rights</mat-label>
              <mat-select formControlName="right">
                <mat-option value="Representative">Representative</mat-option>
                <mat-option value="signatory">Signatory</mat-option>
                <mat-option value="member">Member</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="u-justify actions">
          <button
            type="button"
            mat-button
            mat-flat-button
            (click)="submitForm()"
            color="primary">
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</slideover>

