import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  apiUrl = `${environment.serverUrl}/api/v1/users`;

  constructor(private http: HttpClient) { }


  getResults(): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/onboarding`);
  }

  getOnboarded(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/onboarding?offset=${offset}&max=${max}&sort=${sort}&order=${order}&filter=`, {filter});
  }

  sendNotification(type): Observable<any> {
  return this.http.post(`${this.apiUrl}/onboarding/send-notification?=${type}`, {});
  }

  downloadCSV(): any {
    return this.http.get(`${this.apiUrl}/onboarding/export-data`, {responseType: 'blob'});
  }

  getResultsBySource(source: any) {
    return this.http.get<any>(`${this.apiUrl}/onboarding/${source}`);
  }
}
