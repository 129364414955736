import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Profile } from "../../models";
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import { ProfileService } from "../../services/profile.service";
import {catchError, finalize, map, mergeMap, tap} from "rxjs/operators";

export class ProfilesDataSource implements DataSource<Profile> {
  private profilesSubject = new BehaviorSubject<Profile[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private profilesService: ProfileService) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<Profile[] | ReadonlyArray<Profile>> {
    return this.profilesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.profilesSubject.complete();
    this.loadingSubject.complete();
  }

  loadProfiles(
    offset: any,
    max: any,
    sort: string,
    order: string,
    page: number,
    filter = ''
  ) {
    this.loadingSubject.next(true);
    this.profilesService
      .getAll(offset, max, sort, order, page, filter)
      .pipe(
        catchError(() => of({ data: [], totalCount: 0 })),
        finalize(() => this.loadingSubject.next(false)),
        tap((resp) => {
          this.profilesSubject.next(resp.data);
          this.resultsLengthSubject.next(resp.totalCount);
        }),
        mergeMap((resp) => {
          const avatarRequests = resp.data.map(user => this.fetchUserAvatar(user.email, this.profilesSubject));
          return combineLatest([of(resp.data), combineLatest(avatarRequests)]);
        })
      )
      .subscribe(([userData, avatarUrls]) => {
        userData.forEach((user: any, index: any) => {
          user.avatarUrl = avatarUrls[index];
        });
        this.profilesSubject.next(userData);
      });
  }

  private fetchUserAvatar(email: string, subject: BehaviorSubject<any[]>): Observable<string> {
    const usersDataSnapshot = subject.value;
    const userIndex = usersDataSnapshot.findIndex((e) => e.email === email);
    if (userIndex !== -1) {
      usersDataSnapshot[userIndex].loadingAvatar = true;
      subject.next(usersDataSnapshot);
    }

    return this.profilesService.getPassportImage(email).pipe(
      map(resp => URL.createObjectURL(resp)),
      tap((imageUrl) => {
        if (userIndex !== -1) {
          usersDataSnapshot[userIndex].avatarUrl = imageUrl;
          usersDataSnapshot[userIndex].loadingAvatar = false;
          subject.next(usersDataSnapshot);
        }
      }),
      catchError(error => {
        console.error('Error fetching avatar:', error);
        if (userIndex !== -1) {
          usersDataSnapshot[userIndex].loadingAvatar = false;
          subject.next(usersDataSnapshot);
        }
        return of(null); // Return null if avatar fetch fails
      })
    );
  }


}
