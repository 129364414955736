import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Job} from '@orion/models/job';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {JobsService} from '@orion/services/jobs.service';
import {ConfirmDialogService} from '@orion/shared/confirm-modal/confirm-dialog.service';
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'app-task-create',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.scss']
})
export class TaskCreateComponent implements OnInit {

  @Input() job: any;
  // tslint:disable-next-line:no-output-native
  @Output() close = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onComplete = new EventEmitter();
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private jobService: JobsService,
              private dialogService: ConfirmDialogService,
              private snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3)
        ],
      ],
      description: [
        '',
        [
          Validators.required,
          Validators.minLength(3)
        ],
      ],
      cronExpression: [
        '',
        [
          Validators.required
        ]
      ],
      taskClass: [
        '',
        [
          Validators.required,
          Validators.minLength(3)
        ],
      ],
      extraParams: ['',
        [
          Validators.required
        ],
       ],
      startOnStartup: ['',
      [
        Validators.required
      ]],

    });
    if (this.job != null) {
         this.form.get('name').setValue(this.job.name);
         this.form.get('description').setValue(this.job.description);
         this.form.get('startOnStartup').setValue(!!this.job.startOnStartup);
         this.form.get('cronExpression').setValue(this.job.cronExpression);
         this.form.get('taskClass').setValue(this.job.taskClass);
         this.form.get('extraParams').setValue(this.job.extraParams);
       }
  }


  submitTask() {
    if (this.job) {
      if (confirm('Are you sure you want to complete this action?')) {
        if (this.job.id && this.form.valid) {
          this.jobService.updateJob(this.job.id, this.form.value)
            .subscribe(() => {
                this.openSnackBar('✔️Updated Successfully!');
                this.onUpdateComplete();

                this.close.emit();
              },
              error => {
                this.openSnackBar('❌ something went wrong. unable to complete action');
              });
        }
    }

      } else {
        if (this.form.value) {
           const createRequest: Job = {
             ...this.form.value
           };

           if (confirm('Are you sure you want to complete this action?')) {
             this.jobService.createJob(createRequest)
               .subscribe(() => {
                   this.openSnackBar('✔️Created Successfully!');
                   this.onUpdateComplete();
                   this.close.emit();
                 },
                 (error) =>  this.openSnackBar('❌ something went wrong. unable to complete action')
               );
          }
        }
      }
  }


  onUpdateComplete() {
    this.onComplete.emit();
  }

  deleteJob(job: any) {
    if (confirm('Delete Job')) {
      console.log(`my id delete ${job.id}`)
        if (job.id) {
            this.jobService.deleteJob(job.id)
                .subscribe(() => {
                        this.openSnackBar('✔️Successfully deleted!');
                        this.onUpdateComplete();
                        this.close.emit();
                    },
                    error => {
                        this.openSnackBar('❌ something went wrong. unable to complete action');
                        this.onUpdateComplete();
                        this.close.emit();
                    });
        }
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 8000,
      verticalPosition: 'top'
    });
  }

}
