<h1 class="page-title">Activate USSD Account</h1>
<br>
<form class="xn-fadein-up xn-delay-2" (ngSubmit)="submitForm()" [formGroup]="form">
  <app-text-input [parentForm]="form" label="Provider ID" fieldName="providerId" formControlName="providerId">
  </app-text-input>
  <div>
    <div>

    </div>
    <div></div>
    <div class="u-justify actions">
      <button type="submit" mat-button mat-flat-button color="primary">
        Submit
      </button>
    </div>
  </div>
</form>