import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MassSenderService} from "@orion/services/mass-sender.service";
import {UtilitiesService} from "@orion/utilities/utilities.service";

@Component({
  selector: 'app-mass-sender',
  templateUrl: './mass-sender.component.html',
  styleUrls: ['./mass-sender.component.scss']
})
export class MassSenderComponent implements OnInit {
  form: UntypedFormGroup;
  mailForm: UntypedFormGroup;
  selectedUserForm: UntypedFormGroup;
  isError: boolean = false;
  message!: string;
  messageBody: string = "";
  massMessageBody: string = "";
  subject: string = "";
  sendForPreview = "";
  fileToUpload: File;
  fileToUploadMass: File;
  report: any = {};
  massReport: any = {};
 // profiles: Profile[];
 //  filteredProfiles: any[] = this.profiles;
  isHidden: boolean;


  constructor(private massSenderService: MassSenderService,
              private formBuilder: UntypedFormBuilder,
              private utilities: UtilitiesService
              ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      subject: ['', [Validators.required,]],
      sendForPreview: [''],
      mailerContent: ['', [Validators.required,]],
    });

    this.selectedUserForm = this.formBuilder.group({
      subject: ['', [Validators.required,]],
      email: ['', [Validators.required,]],
      mailerContent: ['', [Validators.required,]],
    });



  }


  // onInputChange(event: any) {
  //   const searchInput = event.target.value.toLowerCase();
  //
  //   this.filteredProfiles = this.profiles.filter(({ email }) => {
  //     let emails = email.toLowerCase();
  //     return emails.includes(searchInput);
  //   });
  // }
  //
  // onOpenChange(searchInput: any) {
  //   searchInput.value = "";
  //   this.filteredProfiles = this.profiles;
  // }

  submitMailerFormData() {
    let formData = this.form.value;
      this.utilities.confirmAlert().then((result) => {
        if (result.isConfirmed) {
          this.massSenderService.sendMailer(formData)
            .subscribe((resp) => {
              if(
                resp.code == 200
              ) {
                this.utilities.successAlert(`${resp.msg}`);
                this.isError = false;
                this.ngOnInit();
              }
              },
              error => {
                this.message = `${error}!`;
                this.isError = true;
              }
            );
        }
      });
  }


  uploadExcelFileForBulkMailer(e) {
    this.fileToUploadMass = e.target.files.item(0);
    if (this.fileToUploadMass == null) {
      return;
    }

      const formData = new FormData();
      formData.append("subject", this.subject);
      formData.append("sendForPreview", this.sendForPreview);
      formData.append("file", this.fileToUploadMass);
      formData.append("mailerContent", this.massMessageBody);
       debugger;
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.massSenderService
          .uploadExcelFileAxeBulkMailList(formData)
          .subscribe(
            (response) => {
              if (response.code === 200) {
                this.massReport = response;
                this.utilities.successAlert('Successfully Sent!');
                this.isError = false;
                this.ngOnInit();
              }
              else {
                this.utilities.errorAlert('Failed to send email,please check the html template!');
                this.isError = true;
                this.ngOnInit();
              }
            },
            (error) => {
              this.message = `${error}!`;
              this.isError = true;
            }
          );
      }
    });

  }


  uploadExcelFileAxeSMS(e) {
    this.fileToUpload = e.target.files.item(0);
    if (this.fileToUpload == null) {
      return;
    }

    const formData = new FormData();
    formData.append("file", this.fileToUpload);
    formData.append("messageBody", this.messageBody);
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.massSenderService
          .uploadExcelFileAxeBulkSmsList(formData)
          .subscribe(
            (d) => {

              this.report = d;
            },
            (error) => {
            }
          );
      }
    });


  }

  clearForm() {
    this.form.reset();
  }

  clearMailForm() {
    this.mailForm.reset();
  }
}
