import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: "slideover",
  templateUrl: "./slideover.component.html",
  styleUrls: ["./slideover.component.scss"],
})
export class SlideoverComponent implements OnInit {
  @Input() title: string;
  @Input() zIndex: number;
  @Input() size: string;
  @Output() closeWindow = new EventEmitter();

  constructor() { }

  closeSlideover() {
    this.closeWindow.emit();
    document.getElementsByTagName("body")[0].classList.remove("disable-scroll");
  }

  ngOnInit(): void {
    document.getElementsByTagName("body")[0].classList.add("disable-scroll");
  }
}
