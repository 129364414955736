<slideover [zIndex]="2" (closeWindow)="closeKycRevertWindow()">
  <div class="page-inner-container">

    <h2 class="page-subtitle huge">KYC Revert</h2>
    <div class="error" *ngIf="isError">
      <p>{{message}}</p>
    </div>


    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="kyc-revert">
      <h4>*Tick reasons for rejection and submit</h4>
      <section
        *ngFor="let section of kycRevertSections; let i = index"
        class="u-pb-24"
      >
        <h3 class="page-subtitle" style="margin-bottom: 20px; margin-top: 20px;">{{ section.type }}</h3>
        <div class="xn-checkbox-group"
             *ngFor="let option of section.reverts; let k = index">
          <div
            class="container mt-10"
          >

            <label class="checkbox">

              <mat-checkbox
                formControlName="{{ i }}-{{ k }}"
              >
              <span>{{ option.label }}</span>
              </mat-checkbox>
            </label>
          </div>

          <div class="mt-30" *ngIf="option.label == 'Other' && formGroup.controls[i+'-'+k].value">
            <mat-form-field appearance="outline">
              <mat-label>Reason</mat-label>
              <textarea autofocus formControlName="{{ i }}-{{ k }}-other" matInput  rows="4"></textarea>
            </mat-form-field>
          </div>

        </div>

      </section>

      <div class="u-justify actions">
        <button
          type="submit"
          mat-button
          mat-flat-button
          color="primary">
          Submit
        </button>
      </div>

    </form>


  </div>
</slideover>
