<app-single-profile
  *ngIf="showProfile"
  [profileId]="profileId"
  (closeProfile)="hideProfile()"
></app-single-profile>
<app-reject-account-merge
  *ngIf="rejectMergeForm"
  [accountMergeId]="accountMergeId"
  (close)="closeRejectWindow()"
>
</app-reject-account-merge>
<div class="">
  <div class="header-view">
    <h1 class="page-title">Account Merge Requests</h1>
    <div class="page-header xn-fadein-up">
      <form class="page-actions" [formGroup]="filtersForm">
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Status</mat-label>
            <mat-select
              formControlName="status"
              (selectionChange)="applyStatusFilter($event)"
            >
              <mat-option value="all">ALL</mat-option>
              <mat-option value="APPROVED">APPROVED</mat-option>
              <mat-option value="PENDING">PENDING</mat-option>
              <mat-option value="REJECTED">REJECTED</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
      </form>
    </div>
  </div>
 
<div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
  <div class="">

  <table
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="mergeRequestDate"
  matSortDisableClear
  matSortDirection="desc"
>
  <!-- Position Date Column -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
    <td mat-cell *matCellDef="let element">{{ element.position }}</td>
  </ng-container>

  <ng-container matColumnDef="mergeRequestDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Date</th>
    <td mat-cell *matCellDef="let element">{{ element.mergeRequestDate | date }} <span class="join-time">- {{ element.mergeRequestDate | date:'shortTime' }}</span></td>
  </ng-container>

  <!-- Account Column -->
  <ng-container matColumnDef="mergeAccount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
    <td mat-cell *matCellDef="let element">
      {{ element.mergeAccount }}
    </td>
  </ng-container>

  <!-- Reply Date Column -->
  <ng-container matColumnDef="mergeReplyDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Reply Date</th>
    <td mat-cell *matCellDef="let element">{{ element.mergeReplyDate | date }}</td>
  </ng-container>

  <!--  Status Column -->
  <ng-container matColumnDef="mergeStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let element">
      <span class="status-label {{ element.mergeStatus | lowercase }}">
        {{ element.mergeStatus | lowercase }}
      </span>
    </td>
  </ng-container>

  <!-- New Profile Column -->
  <ng-container matColumnDef="newProfile">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>New Profile</th>
    <td mat-cell *matCellDef="let element" (click) = "showProfileDetails(element.newProfileId)">
      <a>{{ element.newProfile }}</a>
    </td>
  </ng-container>

  <!-- Old Profile Column -->
  <ng-container matColumnDef="previousProfile">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Old Profile</th>
    <td mat-cell *matCellDef="let element" (click) = "showProfileDetails(element.previousProfileId)">
      <a>{{ element.previousProfile }}</a>
    </td>
  </ng-container>

  <!-- Rejection Reason Column -->
  <ng-container matColumnDef="mergeRejectionReason">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Rejection Reason</th>
    <td mat-cell *matCellDef="let element">
      {{ element.mergeRejectionReason }}
    </td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon [matMenuTriggerFor]="menu" *ngIf="element.mergeStatus == 'PENDING'">more_vert</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="approve(element.id,'APPROVED')"><mat-icon>check</mat-icon> Approve</button>
        <button mat-menu-item (click)="rejectMerge(element)"><mat-icon>cancel</mat-icon> Reject</button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns; let i = index"
  ></tr>
  x
</table>
<mat-paginator
    [length]="dataSource.resultsLength | async"
    [pageSize]="50"
    showFirstLastButtons
    [pageSizeOptions]="[10, 50, 100, 200]"
  ></mat-paginator>
  </div>

 
</div>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
