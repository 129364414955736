import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as Highcharts from 'highcharts';

let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);


export interface Fund {
  id: string;
  fundDescription: string;
  fundRefCode: number;
  fundName: string;
  fundCode: string;
  assetName: string;
}

export interface Allocation {
  percentageValue: number;
  fund: Fund;
}


@Component({
  selector: 'app-goal-details',
  templateUrl: './goal-details.component.html',
  styleUrls: ['./goal-details.component.scss']
})
export class GoalDetailsComponent implements OnInit, AfterViewInit {

  @Output() close = new EventEmitter();
  imageType: string;
  fileType: string;
  idPreview = false;
  passportUrl: any;
  allocations: Allocation[];

  isLoading = true;

  public options: any;

  constructor() { }

  ngOnInit(): void {
    this.allocations = [
      {
        "percentageValue": 16.2,
        "fund": {
          "id": "c9d46652-54c3-4623-be59-a2eaa7b1d778",
          "fundCode": "XUMMF",
          "fundName": "XENO UGANDA MONEY MARKET FUND",
          "fundDescription": "Your MONEY MARKETS holdings are invested in the XENO Uganda Money Market Fund which is an actively managed investment portfolio that is comprised of a mix of short term transferable securities including cash or near cash deposits, government securities, a",
          "fundRefCode": 0,
          "assetName": "Money Markets"
        }
      },
      {
        "percentageValue": 65,
        "fund": {
          "id": "51118f6a-b042-4814-a381-289cd7734ddb",
          "fundCode": "XUBF",
          "fundName": "XENO UGANDA BOND FUND",
          "fundDescription": "Your BOND holdings are invested through the XENO Uganda Bond Fund which is an actively managed portfolio that is comprised of a mix of transferable securities including cash or near cash deposits, government securities, corporate bonds issued by government",
          "fundRefCode": 1,
          "assetName": "Bonds"
        }
      },
      {
        "percentageValue": 5.6,
        "fund": {
          "id": "7434745a-527b-4646-9d1d-4f5ff36fdbaf",
          "fundCode": "XUDEF",
          "fundName": "XENO UGANDA DOMESTIC EQUITY FUND",
          "fundDescription": "Your DOMESTIC EQUITIES holdings are invested through the XENO Uganda Domestic Equity Fund which is an actively managed portfolio that is comprised of a mix of transferable securities including cash or near cash deposits and securities listed on approved s",
          "fundRefCode": 2,
          "assetName": "Domestic Equities"
        }
      },
      {
        "percentageValue": 13.2,
        "fund": {
          "id": "8a53e30f-b620-4fca-8c3a-d47160761c63",
          "fundCode": "XUREF",
          "fundName": "XENO UGANDA REGIONAL EQUITY FUND",
          "fundDescription": "Your REGIONAL EQUITIES holdings are invested through the XENO Uganda Regional Equity Fund which is an actively managed portfolio that is comprised of a mix of transferable securities including cash or near cash deposits and securities listed on approved s",
          "fundRefCode": 3,
          "assetName": "Regional Equities"
        }
      }
    ];

  }

  ngAfterViewInit() {
    this.drawPie();
  }


  showFilePreview(imageType: string, fileType: string) {
    this.imageType = imageType;
    this.fileType = fileType;
    this.idPreview = true;
  }


  drawPie() {
    this.isLoading = false;

    this.options = {
      chart: {
        plotBackgroundColor: null,
        backgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        spacing: [0,0,0,0],
        style: {
          'fontFamily': 'DM Sans',
          'fontSize': '0.75rem'
        }
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          size: '90%',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            distance: -20,
            formatter: function () {
              if (this.y > 10) {
                return Highcharts.numberFormat(this.point.percentage, 1) + ' %'
              }
            },
            style: {
              "color": "white",
              "fontSize": "0.625rem",
              "textOutline": "none"
            }
          },
          showInLegend: true
        }
      },
      legend: {
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginBottom: 18,
        itemStyle: { 'fontWeight': 'normal' }
      },
      series: [{
        type: 'pie',
        name: 'Portfolio Allocation',
        innerSize: '75%',
        data: [
          {
            y: this.allocations[0].percentageValue,
            name: this.allocations[0].fund.assetName,
            color: 'rgb(105, 209, 113)'
          },
          {
            y: this.allocations[1].percentageValue,
            name: this.allocations[1].fund.assetName,
            color: 'rgb(67, 131, 237)'
          },
          {
            y: this.allocations[2].percentageValue,
            name: this.allocations[2].fund.assetName,
            color: 'rgb(255, 189, 60)'
          },
          { y: this.allocations[3].percentageValue, name: this.allocations[3].fund.assetName, color: 'rgb(252, 96, 84)' }
        ]
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 767
          },
          chartOptions: {
            plotOptions: {
              pie: {
                size: "75%"
              }
            }
          }
        }]
      }

    };
    Highcharts.chart("planPieChart", this.options);
  }



}
