import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

// angular material
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';

import {MatSortModule} from '@angular/material/sort';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';


import {TopBarComponent} from './components/layout/top-bar/top-bar.component';
import {SideMenuComponent} from './components/layout/side-menu/side-menu.component';
import {DashboxComponent} from './components/layout/dashbox/dashbox.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {LoginComponent} from './pages/login/login.component';
import {InstitutionsComponent} from './pages/institutions/institutions.component';
import {RequestsComponent} from './pages/requests/requests.component';
import {CommunicationsComponent} from './pages/communications/communications.component';
import {SingleAccountComponent} from './pages/single-account/single-account.component';
import {SingleRequestComponent} from './pages/single-request/single-request.component';
import {IndividualsComponent} from './pages/individuals/individuals.component';
import {ProfilesComponent} from './pages/profiles/profiles.component';
import {SingleProfileComponent} from './pages/single-profile/single-profile.component';
import { SlideoverUserDashComponent } from './components/slideover-user-dash/slideover-user-dash.component';
import {fakeBackendProvider} from './helpers/fake-backend';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './helpers/jwt.interceptor';
import {ErrorInterceptor} from './helpers/error.interceptor';
import {FilePreviewComponent} from './components/file-preview/file-preview.component';
import {UsersComponent} from './pages/users/users.component';
import {SingleUserComponent} from './pages/single-user/single-user.component';
import {CreateUserComponent} from './pages/create-user/create-user.component';
import {EditProfileComponent} from './pages/edit-profile/edit-profile.component';
import {EditVisualIdComponent} from './pages/edit-visual-id/edit-visual-id.component';
import {NoRightClickDirective} from './helpers/NoRightClickDirective';
import {ExportComponent} from './pages/export/export.component';
import {ProgressComponent} from './components/progress/progress.component';
import {RolesComponent} from './pages/roles/roles.component';
import {CreateRoleComponent} from './pages/create-role/create-role.component';
import {PopoverComponent} from './components/popover/popover.component';
import {AuthComponent} from './auth/auth.component';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {AccessLevelsComponent} from './pages/access-levels/access-levels.component';
import {CreateAccessLevelComponent} from './pages/create-access-level/create-access-level.component';
import {ViewCapabilitiesComponent} from './modules/user/view-capabilities/view-capabilities.component';
import {CreateCapabilityComponent} from './modules/user/create-capability/create-capability.component';
import {ViewStaffComponent} from './modules/user/view-staff/view-staff.component';
import {CreateStaffComponent} from './modules/user/create-staff/create-staff.component';
import {SecAccessDirective} from './directives/sec-access.directive';

import {EditCompanyDocComponent} from './pages/edit-company-doc/edit-company-doc.component';
import {TasksComponent} from './pages/tasks/tasks.component';
import {TaskCreateComponent} from './pages/tasks/task-create/task-create.component';
import {TaskUpdateComponent} from './pages/tasks/task-update/task-update.component';
import {PersonalInformationComponent} from './pages/profiles/sub-modules/personal-information/personal-information.component';
import {TextInputComponent} from './shared/text-input/text-input.component';
import {SelectInputComponent} from './shared/select-input/select-input.component';
import {CalenderInputComponent} from './shared/calender-input/calender-input.component';

import {MatExpansionModule} from '@angular/material/expansion';
import {ConfirmModalComponent} from './shared/confirm-modal/confirm-modal.component';
import {DialogSuccessComponent} from './pages/tasks/dialog-success/dialog-success.component';

import {ScheduleComponent} from './pages/tasks/dialog-success/schedule/schedule.component';
import {UploadMembersComponent} from './pages/upload-members/upload-members.component';
import {UploadMemberPhoneNumbersComponent} from './pages/upload-member-phone-numbers/upload-member-phone-numbers.component';
import {EditProfileIncomeSourceComponent} from './pages/profiles/sub-modules/edit-profile-income-source/edit-profile-income-source.component';
import {NumberInputComponent} from './shared/number-input/number-input.component';
import {EditProfileAddressComponent} from './pages/profiles/sub-modules/edit-profile-address/edit-profile-address.component';
import {EditProfileBankDetailsComponent} from './pages/profiles/sub-modules/edit-profile-bank-details/edit-profile-bank-details.component';
import {EditProfileAccountBeneficiaryComponent} from './pages/profiles/sub-modules/edit-profile-account-beneficiary/edit-profile-account-beneficiary.component';
import {ReferralsComponent} from './pages/referrals/referrals.component';
import {ReplacePipePipe} from './pipes/replace-pipe.pipe';
import {EditAccountSummaryComponent} from './pages/single-account/edit-account-summary/edit-account-summary.component';
import {EditRepresentativesComponent} from './pages/single-account/edit-representatives/edit-representatives.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {XnTabsComponent} from './shared/xn-tabs/xn-tabs.component';
import {XnTabComponent} from './shared/xn-tab/xn-tab.component';
import {MassSenderComponent} from './pages/mass-sender/mass-sender.component';
import {DepartmentsComponent} from './pages/departments/departments.component';
import {CreateDepartmentComponent} from './pages/create-department/create-department.component';
import {CreateAdvisorComponent} from './pages/profiles/sub-modules/create-advisor/create-advisor.component';
import {EditAdvisorComponent} from './pages/profiles/sub-modules/edit-advisor/edit-advisor.component';
import {ManageRightsComponent} from './pages/manage-rights/manage-rights.component';
import {OnboardingComponent} from './pages/onboarding/onboarding.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { UploadAdvisorsComponent } from './pages/upload-advisors/upload-advisors.component';
import { KycRevertComponent } from './pages/single-profile/kyc-revert/kyc-revert.component';
import { ActivateUssdAccountComponent } from './pages/activate-ussd-account/activate-ussd-account.component';
import { BulkSmsComponent } from './pages/bulk-sms/bulk-sms.component';
import { BulkEmailsWithBatchComponent } from './pages/bulk-emails-with-batch/bulk-emails-with-batch.component';
import { BulkEmailsComponent } from './pages/bulk-emails/bulk-emails.component';
import { SponsorNotLinkedTransactionsComponent } from './pages/sponsor-not-linked-transactions/sponsor-not-linked-transactions.component';
import { AccountMergeComponent } from './pages/account-merge/account-merge.component';
import { RejectAccountMergeComponent } from './pages/account-merge/sub-modules/reject-account-merge/reject-account-merge.component';
import { LctrDashboardComponent } from './pages/lctr-dashboard/lctr-dashboard.component';
import {CapitalizePipe} from '@orion/pipes/capitalize.pipe';
import { ProspectsComponent } from './pages/prospects/prospects.component';
import { LttUpdaterComponent } from './pages/ltt-updater/ltt-updater.component';
import { LttDialogComponent } from './pages/ltt-dialog/ltt-dialog.component';
import { UploadRepresentativesComponent } from './pages/upload-representatives/upload-representatives.component';
import { UsersDashboardComponent } from './pages/users-dasboard/users-dashboard.component';
import { UserDashSingleProfileComponent } from './pages/user-dash-single-profile/user-dash-single-profile.component';
import { ExpandableViewComponent } from './components/expandable-view/expandable-view.component'
import { UserExpandedTabsComponent } from './pages/user-dash-single-profile/user-expanded-tabs/user-expanded-tabs.component'
import { AdvisoryUploadComponent } from './pages/advisory-upload/advisory-upload.component';
import { AdvisoryUploadDialogComponent } from './pages/advisory-upload-dialog/advisory-upload-dialog.component';
import { AdvisorDashboardComponent } from './pages/advisor-dashboard/advisor-dashboard.component';
import { SingleAdvisorComponent } from './pages/advisor-dashboard/single-advisor/single-advisor.component';
import { MyUsersComponent } from './pages/assigned-users/assigned-users.component';
import { UserAddNoteComponent } from './pages/user-dash-single-profile/user-add-note/user-add-note.component';
import { ViewAdvisorNoteComponent } from './pages/user-dash-single-profile/view-advisor-note/view-advisor-note.component';
import { BandSummaryComponent } from './pages/advisor-dashboard/band-summary/band-summary.component';
import { XnDateFilterComponent } from './shared/xn-date-filter/xn-date-filter.component';
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatMenuModule} from "@angular/material/menu";
import {MatInputModule} from "@angular/material/input";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTabsModule} from "@angular/material/tabs";
import {MatCardModule} from "@angular/material/card";
import {MatListModule} from "@angular/material/list";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatChipsModule} from "@angular/material/chips";
import {initializeKeycloak} from "@orion/init/keycloak-init.factory";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import { CountrySelectorComponent } from './components/country-selector/country-selector/country-selector.component';
import { CountrySwitcherComponent } from './components/country-switcher/country-switcher.component';
import { DepositConfirmationComponent } from './pages/deposit-confirmation/deposit-confirmation.component';
import { GoalModule } from './modules/goal/goal.module';
import { FilterPipe } from './pipes/filter-pipe';
import { SharedModule } from './shared/shared.module';
import { ResetPinComponent } from './pages/reset-pin/reset-pin.component';
import { DisableProfileComponent } from "./pages/user-dash-single-profile/micro/disable-profile/disable-profile.component";


@NgModule({
    declarations: [
        AppComponent,
        TopBarComponent,
        SideMenuComponent,
        DashboxComponent,
        DashboardComponent,
        UsersDashboardComponent,
        LoginComponent,
        InstitutionsComponent,
        RequestsComponent,
        CommunicationsComponent,
        SingleAccountComponent,
        SingleRequestComponent,
        IndividualsComponent,
        ProfilesComponent,
        SingleProfileComponent,
        SlideoverUserDashComponent,
        FilePreviewComponent,
        UsersComponent,
        SingleUserComponent,
        CreateUserComponent,
        EditProfileComponent,
        EditVisualIdComponent,
        ExportComponent,
        NoRightClickDirective,
        ProgressComponent,
        RolesComponent,
        CreateRoleComponent,
        PopoverComponent,
        AuthComponent,
        MainLayoutComponent,
        AccessLevelsComponent,
        CreateAccessLevelComponent,
        ViewCapabilitiesComponent,
        CreateCapabilityComponent,
        ViewStaffComponent,
        CreateStaffComponent,
        CapitalizePipe,
        EditCompanyDocComponent,
        TasksComponent,
        TaskCreateComponent,
        TaskUpdateComponent,
        PersonalInformationComponent,
        TextInputComponent,
        SelectInputComponent,
        CalenderInputComponent,
        ConfirmModalComponent,
        DialogSuccessComponent,
        ScheduleComponent,
        UploadMembersComponent,
        UploadMemberPhoneNumbersComponent,
        // SecAccessDirective,
        EditProfileIncomeSourceComponent,
        NumberInputComponent,
        EditProfileAddressComponent,
        EditProfileBankDetailsComponent,
        EditProfileAccountBeneficiaryComponent,
        ReferralsComponent,
        ReplacePipePipe,
        EditAccountSummaryComponent,
        EditRepresentativesComponent,
        XnTabsComponent,
        XnTabComponent,
        DepartmentsComponent,
        CreateDepartmentComponent,
        ManageRightsComponent,
        CreateAdvisorComponent,
        EditAdvisorComponent,
        MassSenderComponent,
        OnboardingComponent,
        UploadAdvisorsComponent,
        KycRevertComponent,
        ActivateUssdAccountComponent,
        BulkSmsComponent,
        BulkEmailsWithBatchComponent,
        BulkEmailsComponent,
        SponsorNotLinkedTransactionsComponent,
        AccountMergeComponent,
        RejectAccountMergeComponent,
        LctrDashboardComponent,
        ProspectsComponent,
        LttUpdaterComponent,
        LttDialogComponent,
        UploadRepresentativesComponent,
        UserDashSingleProfileComponent,
        ExpandableViewComponent,
        UserExpandedTabsComponent,
        AdvisoryUploadComponent,
        AdvisoryUploadDialogComponent,
        AdvisorDashboardComponent,
        SingleAdvisorComponent,
        MyUsersComponent,
        UserAddNoteComponent,
        ViewAdvisorNoteComponent,
        BandSummaryComponent,
        XnDateFilterComponent,
        CountrySelectorComponent,
        CountrySwitcherComponent,
        DepositConfirmationComponent,
        ResetPinComponent
    ],
    imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSortModule,
    MatButtonModule,
    MatToolbarModule,
    MatSelectModule,
    MatIconModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatDatepickerModule,
    MatDialogModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatTabsModule,
    MatListModule,
    MatCardModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    PdfViewerModule,
    MatGridListModule,
    MatCheckboxModule,
    MatChipsModule,
    KeycloakAngularModule,
    GoalModule,
    SharedModule,
    DisableProfileComponent
],
    providers: [
      {
        provide: APP_INITIALIZER,
        useFactory: initializeKeycloak,
        multi: true,
        deps: [KeycloakService]
      },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
       //  fakeBackendProvider
    ],
    bootstrap: [AppComponent],
    exports: [
    //  SecAccessDirective
    ]
})
export class AppModule {
}
