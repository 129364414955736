<slideover [zIndex]="2" (closeWindow)="closeWindow.emit()">
  <div class="page-inner-container">
    <div class="spinner-container fill-container" *ngIf="isLoading">
      <mat-spinner color="accent" [diameter]="70"></mat-spinner>
    </div>
    <div class="page-section" *ngIf="accountDetails != null">
      <h2 class="page-subtitle huge">{{ accountDetails.institutionName | titlecase }}</h2>
      <div class="edit-user-form">
        <div class="u-justify actions">
          <table class="xn-table mat-elevation-z8 is-summary">
            <tbody>
            <tr>
              <td>Account Name</td>
              <td>{{ accountDetails.accountName | titlecase }}</td>
            </tr>
            <tr>
              <td>Account Type</td>
              <td>{{ accountDetails.accountType | titlecase }}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>{{ accountDetails.accountNumber }}</td>
            </tr>
            <tr>
              <td>Total Goal Transactions Not Linked</td>
              <td>{{ transactionsWithErrorsCount.totalGoalTransactionsWithErrors }}</td>
            </tr>
            <tr>
              <td>Total Fund Transactions Not Linked</td>
              <td>{{ transactionsWithErrorsCount.totalFundTransactionsWithErrors }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="u-justify actions">
          <button
            type="submit"
            mat-button
            mat-flat-button
            color="primary"
            (click)="fixErrors(accountDetails.id)"> Link Account
          </button>
        </div>
      </div>
    </div>
  </div>
</slideover>

