<app-single-user
  *ngIf="singleUserView"
  (closeUserView)="hideUserDetails()"
  [userId]="userId"
></app-single-user>
<create-user
  *ngIf="createUserView"
  (closeCreateView)="hideCreateUser()"
></create-user>

<div class="page-header xn-fadein-up xn-margin-top-negative76">
  <h1 class="page-title">Users</h1>
  <div class="page-actions">
    <button
      mat-flat-button
      mat-button
      color="primary"
      (click)="showCreateUser()"
    >
      <span>Create User</span>
    </button>
  </div>
</div>

<div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="dateCreated"
    matSortDisableClear
    matSortDirection="desc"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">
        <div class="u-profile">
          <a>{{ row.firstName }} {{ row.lastName }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Roles</th>
      <td mat-cell *matCellDef="let row">{{ row.userRoles }}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="showUserDetails(row.id)"
    ></tr>
  </table>

  <mat-paginator
    [length]="dataSource.resultsLength | async"
    [pageSize]="50"
    showFirstLastButtons
    [pageSizeOptions]="[10, 50, 100, 200]"
  ></mat-paginator>
</div>

<div class="spinner-container" *ngIf="dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
