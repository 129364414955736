import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GoalRoutingModule } from './goal-routing.module';
import { GoalComponent } from './goal.component';
import { GoalDetailsComponent } from './goal-details/goal-details.component';
import { GoalPerformanceChartComponent } from './goal-performance-chart/goal-performance-chart.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatGridListModule} from "@angular/material/grid-list";
import { ProgressComponent } from './shared/progress/progress.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTableModule} from "@angular/material/table";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTabsModule} from "@angular/material/tabs";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {SharedModule} from "@orion/shared/shared.module";
import {AppModule} from "@orion/app.module";
import { EditGoalStrategyComponent } from '@orion/pages/edit-goal-strategy/edit-goal-strategy.component';
import { SingleGoalComponent } from '@orion/pages/single-goal/single-goal.component';


@NgModule({
  declarations: [
    GoalComponent,
     ProgressComponent, GoalDetailsComponent, GoalPerformanceChartComponent, EditGoalStrategyComponent, SingleGoalComponent],
  exports: [
    EditGoalStrategyComponent, SingleGoalComponent
  ],
  imports: [
    CommonModule,
    GoalRoutingModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    MatGridListModule,
    MatPaginatorModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSelectModule,
    PdfViewerModule,
    SharedModule
  ]
})
export class GoalModule { }
