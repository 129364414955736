<ng-container *appSecAccess="['ROLE_SUPER_ADMIN']">
<single-request *ngIf="requestView" (closeWindow)="hideRequestDetails()" requestType="accountMerge"></single-request>

<div *ngIf="!isLoading">
    <div class="page-header xn-fadein-up">
        <h1 class="page-title">Requests</h1>
        <form class="page-actions" [formGroup]="filtersForm">

            <div class="page-action">
                <mat-form-field appearance="fill">
                    <mat-label>Select Type</mat-label>
                    <mat-select formControlName="requestType">
                        <mat-option value="all" selected>All</mat-option>
                        <mat-option value="identity-verification">Identity Verification</mat-option>
                        <mat-option value="company-verification">Company Verification</mat-option>
                        <mat-option value="account-merge">Account Merge</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="page-action">
                <mat-form-field appearance="fill">
                    <mat-label>Filter By</mat-label>
                    <mat-select formControlName="accountFilter">
                        <mat-option value="all">All</mat-option>
                        <mat-option value="date-created">Date Created</mat-option>
                        <mat-option value="open">Open</mat-option>
                        <mat-option value="approved">Approved</mat-option>
                        <mat-option value="rejected">Rejected</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="page-action"
                *ngIf="accountFilter.value == 'date-enrolled' || accountFilter.value == 'date-created'">
                <mat-form-field appearance="fill">
                    <mat-label>Select Date Range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate matInput placeholder="Start date" formControlName="startDate">
                        <input matEndDate matInput placeholder="End date" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </th>
                <td mat-cell *matCellDef="let row"> {{row.dateCreated}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Profile </th>
                <td mat-cell *matCellDef="let row">
                    <div class="u-profile">
                        <figure class="u-profile-photo"></figure>
                        <span>{{row.name}}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Request Type </th>
                <td mat-cell *matCellDef="let row"> <a>{{row.type}}</a></td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row">
                    <span class="status-label {{row.status | lowercase }}">{{row.status | titlecase}}</span>
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
            <td mat-cell *matCellDef="let row">
                <a routerLink="/single-request" mat-button mat-flat-button color="primary">View</a>
            </td>
        </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showRequestDetails()"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[25, 100]"></mat-paginator>
    </div>
</div>

<div class="spinner-container" *ngIf="isLoading">
    <mat-spinner color="accent"></mat-spinner>
</div>

</ng-container>

<ng-container *appSecAccess="!['ROLE_SUPER_ADMIN']">
  <div class="z-box">
    <div>
      <mat-spinner></mat-spinner>
      <br/>
      <br/>
      <h1>Un authorized</h1>
      <br/>
    </div>

  </div>
</ng-container>
