import {CollectionViewer , DataSource} from '@angular/cdk/collections';
import {Staff} from '@orion/models/staff';
import {BehaviorSubject, Observable, of, combineLatest} from 'rxjs';
import {UserService} from '@orion/services/user.service';
import {catchError, finalize, map, mergeMap, tap} from 'rxjs/operators';
import { ProfileService } from '@orion/services/profile.service';


export class StaffDataSource implements DataSource<Staff>{
  private staffSubject = new BehaviorSubject<Staff[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private staffService: UserService, private profileService: ProfileService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Staff[] | ReadonlyArray<Staff>> {
    return this.staffSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.staffSubject.complete();
    this.loadingSubject.complete();
  }

  loadStaff(offset: any, max: any, sort: string, order: string, page: number, filter = '') {
    this.loadingSubject.next(true);
    this.staffService.list(offset, max, sort, order, page, filter).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false)),
      tap((resp) => {
        this.staffSubject.next(resp.data);
        this.resultsLengthSubject.next(resp.totalCount);
      }),
      mergeMap((resp) => {
        const avatarRequests = resp.data.map(user => this.fetchUserAvatar(user.id));
        return combineLatest([of(resp.data), combineLatest(avatarRequests)]);
      })
    ).subscribe(([staffData, avatarUrls]) => {
      staffData.forEach((user: any, index: any) => {
        user.avatarUrl = avatarUrls[index];
      });
      this.staffSubject.next(staffData);
    });
  }

  private fetchUserAvatar(staffId: string): Observable<string> {
    const usersDataSnapshot = this.staffSubject.value;
    const userIndex = usersDataSnapshot.findIndex((e) => e.id === staffId);
    if (userIndex !== -1) {
      usersDataSnapshot[userIndex].loadingAvatar = true;
      this.staffSubject.next(usersDataSnapshot);
    }

    return this.staffService.getStaffImage(staffId).pipe(
      map(resp => URL.createObjectURL(resp)),
      tap((imageUrl) => {
        console.error('ImageURl:', imageUrl);
        if (userIndex !== -1) {
          usersDataSnapshot[userIndex].avatarUrl = imageUrl;
          // console.log(`user data snapshot ${JSON.stringify(usersDataSnapshot)}`)
          usersDataSnapshot[userIndex].loadingAvatar = false;
          this.staffSubject.next(usersDataSnapshot);
        }
      }),
      catchError(error => {
        console.error('Error fetching avatar:', error);
        if (userIndex !== -1) {
          usersDataSnapshot[userIndex].loadingAvatar = false;
          this.staffSubject.next(usersDataSnapshot);
        }
        return of(null); // Return null if avatar fetch fails
      })
    );
  }

}
