import { Component, OnInit } from '@angular/core';

export interface Role {
  id: string;
  name: string;
  userCount: number;
}

const ROLES_DATA: Role[] = [
  { id: '1', name: 'Admin', userCount: 5 },
  { id: '2', name: 'Supervisor', userCount: 2 },
  { id: '3', name: 'Manager', userCount: 3 },
  { id: '4', name: 'Employee', userCount: 100 },
];


@Component({
  selector: 'access-levels',
  templateUrl: './access-levels.component.html',
  styleUrls: ['./access-levels.component.scss']
})
export class AccessLevelsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'userCount']
  dataSource = ROLES_DATA;
  view: string = '';
  roleId: string = '';
  roleName: string = '';

  constructor() { }

  editRole(id: string, name: string) {
    this.roleId = id;
    this.roleName = name;
    this.view = 'edit-role';
  }


  ngOnInit(): void {
  }

}