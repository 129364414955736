import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {UserService} from '../../services';
import {RoleService} from '../../services/role.service';
import {Role} from '../../models';

@Component({
  selector: 'create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Output() onComplete = new EventEmitter();
  @Input() role: Role;
  view = '';

  // form
  constructor(private formBuilder: UntypedFormBuilder,
              private rolesService: RoleService,
              private userService: UserService) {
  }
  roleForm: UntypedFormGroup;


  closeWindow() {
    this.close.emit();
  }

  onUpdateComplete() {
    this.onComplete.emit();
  }

  submitRole() {
    if (this.roleForm.valid) {
      if (this.role && this.role.id != null) {
        // we are updating
        this.rolesService.updateRole(this.role.id, this.roleForm.value)
          .subscribe(() => {
              this.onUpdateComplete();
              this.closeWindow();
            },
            error =>{return error;});

      } else {
        // we are creating new
        this.rolesService.saveRole(this.roleForm.value)
          .subscribe(() => {
              this.onUpdateComplete();
              this.closeWindow();
            },
            (error) => {return error;}
          );
      }
    }
  }

  deleteRole(role) {
    const confirmation = confirm('Are you sure you want to delete this role?');
    if (confirmation && role) {
      this.rolesService.deleteRole(role.id)
        .subscribe(() => {},
          (error) => {return error;});
    }
  }

  get name() {
    return this.roleForm.get('role');
  }

  ngOnInit(): void {

    this.roleForm = this.formBuilder.group({
      authority: [
        this.role && this.role.authority !== '' ? this.role.authority : '',
        [
          Validators.required,
          Validators.minLength(3)
        ],
      ],
    });
  }
}

