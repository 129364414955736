<app-single-goal *ngIf="goalDetailsView"
                 [accountId]="id"
                 [profileDetails]="profileDetails"
                 [accountType]="accountType"
                 [tenantCurrency]="tenantCurrency"
                 [accountGoalId]="accountGoalId" (closeGoal)="goalDetailsView = false"
  [zIndex]="2"></app-single-goal>
<app-user-dash-single-profile *ngIf="showProfile" [profileId]="profileId" (hideProfile)="hideProfile()"
  [zIndex]="4"></app-user-dash-single-profile>

<app-edit-account-summary [accountDetails]="accountDetails" *ngIf="editAccountSummary"
  (closeWindow)="editAccountSummary = false">

</app-edit-account-summary>


<app-manage-rights [accountDetails]="accountDetails" *ngIf="manageRights" (closeWindow)="manageRights = false">

</app-manage-rights>

<app-edit-representatives [accountDetails]="accountDetails" *ngIf="editRepresentatives"
  (close)="editRepresentatives = false">
</app-edit-representatives>

<app-edit-company-doc [accountDetails]="accountDetails" *ngIf="editDocView"
  (close)="editDocView = false"></app-edit-company-doc>


<app-upload-members [accountDetails]="accountDetails" *ngIf="uploadMembersView" (close)="uploadMembersView = false">
</app-upload-members>


<app-upload-representatives [accountDetails]="accountDetails" *ngIf="uploadRepresentaivesView"
  (close)="uploadRepresentaivesView = false">
</app-upload-representatives>

<!--<app-upload-member-phone-numbers-->
<!--  [accountDetails]="accountDetails"-->
<!--  *ngIf="uploadMembersPhoneNumberView"-->
<!--  (close)="uploadMembersPhoneNumberView = false"-->
<!-- >-->
<!--</app-upload-member-phone-numbers>-->

<app-file-preview *ngIf="idPreview" (closeWindow)="closeFilePreview()" [imageType]="imageType" [fileType]="fileType"
  [accountId]="id" [profileEmail]="accountDetails.institutionName" [fileUrl]="institutionDocumentUrl" [idURLFile]="idImageUrl">
</app-file-preview>

<app-sponsor-not-linked-transactions [accountDetails]="accountDetails" *ngIf="checkSponsorLinkedErrors"
  (closeWindow)="checkSponsorLinkedErrors = false">
</app-sponsor-not-linked-transactions>

<slideover (closeWindow)="closeAccountWindow()">
  <div class="spinner-container fill-container" *ngIf="isLoading">
    <mat-spinner color="accent" [diameter]="70"></mat-spinner>
  </div>


  <ng-container *ngIf="accountDetails?.accountType == 'PERSONAL' || accountDetails?.accountType == 'JOINT'">
    <div class="page-inner-container" *ngIf="accountDetails != null">
      <!-- account summary -->
      <div class="page-section xn-fadein-up">
        <div class="u-justify is-table-header">
          <h2 class="page-subtitle huge">
            <span>{{ accountDetails.accountName | titlecase }}</span>
          </h2>
          <!--        <ng-container>-->
          <!--          <button-->
          <!--            mat-button-->
          <!--            mat-flat-button-->
          <!--            color="primary"-->
          <!--            (click)="editDocView = true"-->
          <!--          >-->
          <!--            Edit-->
          <!--          </button>-->
          <!--        </ng-container>-->
        </div>
        <table class="xn-table mat-elevation-z8 is-summary">
          <tbody>
            <tr>
              <td>Individual Name</td>
              <td>{{ accountDetails.accountName }}</td>
            </tr>
            <tr>
              <td>Account Type</td>
              <td>{{ accountDetails.accountType | titlecase }}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>{{ accountDetails.accountNumber }}</td>
            </tr>

            <tr>
              <td>Date Created</td>
              <td>{{ accountDetails.dateCreated | date }}</td>
            </tr>
            <tr>
              <td>Account Status</td>
              <td>
                <span class="status-label not-funded" *ngIf="accountDetails?.netInvested <= 0">not funded</span>
                <span class="status-label funded" *ngIf="accountDetails?.netInvested > 0">
                  funded</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <!-- goals summary -->
      <div class="page-section xn-fadein-up xn-delay-2">
        <h2 class="page-subtitle">Goals</h2>
        <table class="xn-table mat-elevation-z8">
          <thead>
            <tr>
              <th>Goal Name</th>
              <th>Goal Number</th>
              <th>Net Invested</th>
              <th>Current Value</th>
              <th>Investment Strategy</th>
            </tr>
          </thead>

          <tbody>
            <tr (click)="showGoalDetails(accountGoal.id)" *ngFor="let accountGoal of accountDetails.goals">
              <td>
                <a>{{ accountGoal.goalTitle }}</a>
              </td>
              <td>{{ accountGoal.goalNumber }}</td>
              <td>{{tenantCurrency}} {{ accountGoal.netInvested | number:'1.0-0'}}</td>
              <td>{{tenantCurrency}} {{ accountGoal.currentValue | number:'1.0-0'}}</td>
              <td>{{ accountGoal.investmentStrategy}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>



  <ng-container *ngIf="accountDetails != null">
    <div class="page-inner-container"
      *ngIf="accountDetails.accountType == 'POOLED' || accountDetails.accountType == 'SPONSOR' || accountDetails.accountType == 'LINKED'">
      <!-- account summary -->
      <div class="page-section xn-fadein-up">
        <div class="u-justify is-table-header">
          <h2 class="page-subtitle huge">
            <span>{{ accountDetails.institutionName | titlecase }}</span>
          </h2>

          <ng-container>
            <div>
              <button mat-button mat-flat-button color="primary" [matMenuTriggerFor]="menu">
                Manage
                <mat-icon>settings</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editAccountSummary = true">Details</button>
                <button mat-menu-item (click)="manageRights = true">Rights</button>
                <button mat-menu-item (click)="checkSponsorLinkedErrors = true">Check Errors</button>
              </mat-menu>
            </div>

          </ng-container>
        </div>
        <table class="xn-table mat-elevation-z8 is-summary">
          <tbody>
            <tr>
              <td>Institution Name</td>
              <td>
                <span [ngClass]="!accountDetails.institutionName ? 'status-label no-name' : ''">
                  {{ accountDetails.institutionName ? accountDetails.institutionName : 'Missing name' }}
                </span>
              </td>
            </tr>

            <tr>
              <td>Registration Number</td>
              <td>
                <span [ngClass]="!accountDetails.regNumber ? 'status-label no-name' : ''">
                  {{ accountDetails.regNumber ? accountDetails.regNumber : 'Missing Registration Number' }}
                </span>
              </td>
            </tr>

            <tr>
              <td>Account Type</td>
              <td>{{ accountDetails.accountType | titlecase }}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>{{ accountDetails.accountNumber }}</td>
            </tr>
            <tr>
              <td>Sponsor Code</td>
              <td>
                <span [ngClass]="!accountDetails.sponsorCode ? 'status-label no-name' : ''">
                  {{ accountDetails.sponsorCode ? accountDetails.sponsorCode : 'Missing Code' }}
                </span>
              </td>
            </tr>
            <tr>
              <td>Date Created</td>
              <td>{{ accountDetails.dateCreated | date }}</td>
            </tr>
            <tr>
              <td>Account Status</td>
              <td>
                <span *ngIf="!isFunded" class="status-label inactive">Not Funded</span>
                <span *ngIf="isFunded" class="status-label active">Funded</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Institution Documents -->
      <div class="page-section">
        <div class="u-justify is-table-header">
          <h2 class="page-subtitle">Institution Document</h2>
          <ng-container *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
            <button mat-button mat-flat-button color="primary" (click)="editDocView = true">
              Edit
            </button>
            <!--            <button-->
            <!--              mat-button-->
            <!--              mat-flat-button-->
            <!--              color="primary"-->
            <!--              (click)="downloadCertificateFile(accountDetails.accountId, accountDetails.institutionName, accountDetails.certificateFile)"-->
            <!--              *ngIf="accountDetails.certificateFile"-->
            <!--            >-->
            <!--              Download-->
            <!--            </button>-->
          </ng-container>

        </div>

        <table class="xn-table mat-elevation-z8 is-summary">
          <tbody>
            <tr>
              <td>Certificate of Registration</td>
              <td>
                <p>
                  <button class="image-button" (click)="showFilePreview('certificate', 'Image')"
                    *ngIf="fileType == 'Image'">
                    <img [src]="institutionDocumentUrl" alt="" />
                  </button>
                  <pdf-viewer *ngIf="fileType == 'PDF'" [src]="institutionDocumentUrl" [original-size]="false"
                    [show-all]="true" [render-text]="true" [external-link-target]="'blank'" [autoresize]="true"
                    [show-borders]="false" (click)="showFilePreview('certificate', 'PDF')"
                    style="width: 100%; height: 200px;"></pdf-viewer>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- goals summary -->
      <div class="page-section xn-fadein-up xn-delay-2">
        <h2 class="page-subtitle">Goals</h2>
        <table class="xn-table mat-elevation-z8">
          <thead>
            <tr>
              <th>Goal Name</th>
              <th>Goal Number</th>
              <th>Net Invested</th>
              <th>Current Value</th>
              <th>Investment Strategy</th>
            </tr>
          </thead>

          <tbody>
            <tr (click)="showGoalDetails(accountGoal.id)" *ngFor="let accountGoal of accountDetails.goals">
              <td>
                <a>{{ accountGoal.goalTitle }}</a>
              </td>
              <td>{{ accountGoal.goalNumber }}</td>
              <td>{{tenantCurrency}} {{ accountGoal.netInvested | number: '1.0-0'}}</td>
              <td>{{tenantCurrency}} {{ accountGoal.currentValue | number: '1.0-0'}}</td>
              <!--            <td>{{ accountGoal.investmentStrategy }}</td>-->
              <td>
                <span [ngClass]="accountGoal.investmentStrategy ? 'status-label active' : ''">
                  {{ accountGoal.investmentStrategy ? accountGoal.investmentStrategy : '' }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- representatives -->
      <div class="page-section xn-fadein-up xn-delay-3" *ngIf="
        accountDetails.accountType == 'POOLED' ||
        accountDetails.accountType == 'SPONSOR'
      ">
        <div class="u-justify is-table-header">
          <h2 class="page-subtitle">Representatives</h2>
          <ng-container *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_UPLOAD_MEMBERS']">
            <button mat-button mat-flat-button color="primary" (click)="uploadRepresentaivesView = true">
              Add representatives
            </button>
          </ng-container>

        </div>
        <table class="xn-table mat-elevation-z8">
          <thead>
            <tr>
              <th>Profile</th>
              <th>E-mail</th>
              <th>Phone Number</th>
              <th>KYC Status</th>
              <th>Rights</th>
              <th *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">Actions</th>
            </tr>
          </thead>

          <tbody *ngFor="let representative of accountDetails.representatives">
            <tr>
              <td (click)="showProfileDetails(representative.id)">
                <div class="u-profile">
                  <figure class="u-profile-photo" [style.backgroundImage]="
                'url(' + representative.avatarUrl + ')'
              ">

                  </figure>
                  <span><a>{{representative.firstName}} {{representative.middleName}}
                      {{representative.lastName}}</a></span>
                </div>
              </td>
              <td>{{representative.username}}</td>
              <td>{{representative.telephone}}</td>
              <td>
                <span class="status-label {{ representative.kycStatus | lowercase }}">
                  {{ setStatus(representative.kycStatus) | lowercase }}
                </span>
              </td>
              <td>
                <span class="status-label is-representative">
                  {{representative.role | lowercase}}</span>
              </td>
              <td *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                <ng-container *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="removeMemberFromGroup(representative)">
                      Remove from Group
                    </button>
                  </mat-menu>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ng-container *ngIf="accountDetails.accountType == 'SPONSOR' ||
        accountDetails.accountType == 'LINKED'">
        <div class="page-section xn-fadein-up xn-delay-4">
          <div class="u-justify is-table-header">
            <h2 class="page-subtitle">Members</h2>
            <ng-container *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_UPLOAD_MEMBERS']">
              <button mat-button mat-flat-button color="primary" (click)="uploadMembersView = true">
                Add members
              </button>
            </ng-container>

          </div>
          <mat-tab-group animationDuration="0ms">
            <mat-tab label="Active">
              <table class="xn-table mat-elevation-z8" *ngIf="accountDetails?.members?.length > 0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Joined</th>
                    <th>Account Number</th>
                    <th>Net Invested</th>
                    <th>Value at Date</th>
                    <th>KYC Status</th>
                    <th>Account Status</th>
                    <th *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">Actions</th>
                  </tr>
                </thead>
                <tbody
                  *ngFor="let i = index; let member of accountDetails.members | myFilter: filterLinkedActiveMembers"
                  [attr.data-index]="i">
                  <tr>
                    <td>{{i + 1}}</td>
                    <td (click)="showProfileDetails(member.id)"><a>{{member.firstName}} {{member.middleName}}
                        {{member.lastName}}</a></td>
                    <td>{{member.dateCreated | date}}</td>
                    <td>{{member.accountNumber}}</td>
                    <td>{{tenantCurrency}} {{member.netInvestedValue | number: '1.0-0'}}</td>
                    <td>{{tenantCurrency}} {{member.netCurrentValue | number: '1.0-0'}}</td>
                    <td>
                      <span class="status-label {{ member.kycStatus | lowercase }}">
                        {{ setStatus(member.kycStatus) | lowercase }}
                      </span>
                    </td>
                    <td>
                      <span *ngIf="member.hide === false" class="status-label active">active</span>
                      <span *ngIf="member.hide === true" class="status-label inactive">inactive</span>
                    </td>
                    <td *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                      <ng-container *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                        <button mat-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="removeMemberFromGroup(member)">
                            Remove from Group
                          </button>
                        </mat-menu>
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="accountDetails?.members?.length == 0" style="text-align: center;margin-top: 40px;">
                No Active Members
              </p>
            </mat-tab>
            <mat-tab label="Inactive">
              <table class="xn-table mat-elevation-z8 no-table-hover pointer"
                *ngIf="accountDetails?.members?.length > 0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Joined</th>
                    <th>Net Invested</th>
                    <th>Value at Date</th>
                    <th>Verification</th>
                  </tr>
                </thead>
                <tbody *ngFor="let members of accountDetails.members | myFilter: filterLinkedInActiveMembers">
                  <tr>
                    <td>{{members.firstName}} {{members.middleName}} {{members.lastName}}</td>
                    <td>{{members.dateCreated | date}}</td>
                    <td>{{tenantCurrency}} {{members.netInvestedValue | number: '1.0-0'}}</td>
                    <td>{{tenantCurrency}} {{members.netCurrentValue | number: '1.0-0'}}</td>
                    <td>
                      <span *ngIf="members.enabled === true" class="status-label active">Complete</span>
                      <span *ngIf="members.enabled === false" class="status-label inactive">Incomplete</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center;margin-top: 40px;">
                      <span *ngIf="accountDetails?.members?.length <= 0">No institution members</span>
                    </td>
                  </tr>
                </tbody>
              </table>

            </mat-tab>
          </mat-tab-group>
        </div>
      </ng-container>
    </div>
  </ng-container>

</slideover>
