import {Injectable} from "@angular/core";
import {Observable, of, throwError} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, map} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class GoalDashboardService {
  apiUrl = `${environment.serverUrl}/api/v1/advisory-insight`;
  goalApiUrl = `${environment.serverUrl}/api/v1/guest`;

  constructor(private http: HttpClient) {
  }


  list(
    offset: any,
    lastDepositStartDate: any,
    lastDepositEndDate: any,
    max: any,
    sortOrder: any,
    startDate: any,
    endDate: any,
    autosave: any,
    aumBand: any,
    target: any,
    funded: any,
    sortBy: any,
    goalType: any,
    progress: any,
    achieved: any): Observable<any> {
    console.log(offset)
    console.log(max)
    console.log(sortOrder)
    console.log(startDate)
    console.log(endDate)
    console.log(aumBand)
    console.log(funded)
    console.log(lastDepositStartDate)
    console.log(lastDepositEndDate)
    console.log(autosave)
    console.log(target)
    console.log(sortBy)
    console.log(goalType)
    console.log(progress)
    console.log(achieved)


    const params: HttpParams = new HttpParams()
      .set('offset', offset)
        .set('max', max)
        .set('sortOrder', sortOrder)
        .set('startDate', startDate)
        .set('endDate', endDate)
        .set('aumBand', aumBand)
        .set('funded', funded)
        .set('lastDepositStartDate', lastDepositStartDate)
        .set('lastDepositEndDate', lastDepositEndDate)
        .set('autosave', autosave)
        .set('target', target)
        .set('sortBy', sortBy)
        .set('progress', progress)
         .set('goalType', goalType)
      .set('achieved', achieved);

    return this.http.get<any>(`${this.apiUrl}/goal-progress-dashboard`, { params });
  }


  goalsList(): Observable<any> {
    return this.http.get(`${this.goalApiUrl}/goals`).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error fetching data:', error);
        throw error;
      })
    );
  }


  graphTickValue(max: number, min: number, axisMarks: number) {
    let n = (max - min) / axisMarks;
    return parseInt(n.toString());
  }

  goalCardData(offset: any, max: any, sortOrder: any): Observable<any> {
   //  const cardData = {
   //    "code": 200,
   //    "msg": "Successfully fetch data",
   //    "data": {
   //      "total": 0,
   //      "totalAum": null,
   //      "totalFunded": 0,
   //      "totalNotFunded": 0,
   //      "totalAchieved": 0,
   //      "totalNotAchieved": 0,
   //      "notFundedPercentage": null,
   //      "fundedPercentage": null,
   //      "achievedPercentage": null
   //    }
   //  };
   // return of(cardData);
    return this.http.get(`${this.apiUrl}` + `/goal-progress-stat?offset=${offset}&max=${max}&sortOrder=${sortOrder}`);
  }


  goalCardList(
    offset: any,
    lastDepositStartDate: any,
    lastDepositEndDate: any,
    max: any,
    sortOrder: any,
    startDate: any,
    endDate: any,
    autosave: any,
    aumBand: any,
    target: any,
    funded: any,
    sortBy: any,
    goalType: any,
    progress: any,
    achieved: any): Observable<any> {
    console.log(offset)
    console.log(max)
    console.log(sortOrder)
    console.log(startDate)
    console.log(endDate)
    console.log(aumBand)
    console.log(funded)
    console.log(lastDepositStartDate)
    console.log(lastDepositEndDate)
    console.log(autosave)
    console.log(target)
    console.log(sortBy)
    console.log(progress)
    console.log(goalType)
    console.log(achieved)


    const params: HttpParams = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('aumBand', aumBand)
      .set('funded', funded)
      .set('offset', offset)
      .set('max', max)
      .set('sortOrder', sortOrder)
      .set('lastDepositStartDate', lastDepositStartDate)
      .set('lastDepositEndDate', lastDepositEndDate)
      .set('autosave', autosave)
      .set('target', target)
      .set('sortBy', sortBy)
      .set('progress', progress)
      .set('goalType', goalType)
      .set('achieved', achieved);

    return this.http.get<any>(`${this.apiUrl}/goal-progress-stat`, { params });
  }


}
