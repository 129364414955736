<button class="menu-toggle" (click)="showMenu = !showMenu" [class.is-open]="showMenu">
  <span></span>
  <span></span>
  <span></span>
</button>
<div class="xn-menu-wrap" *ngIf="orionUser" [class.is-visible]="showMenu">
  <a routerLinkActive="active" routerLink="" class="logo">
    <figure></figure>
  </a>
  <ul class="xn-menu">
    <!--    <li class="xn-menu-item">-->
    <!--      <a routerLinkActive="active" routerLink="/on-boarding">-->
    <!--        <mat-icon color="">manage_accounts</mat-icon>-->
    <!--        <span>Onboarding</span>-->
    <!--      </a>-->
    <!--    </li>-->
    <!-- <li class="xn-menu-item" *ngIf="activeTenant == 'KE'">
      <a routerLinkActive="active" routerLink="/user-dashboard">
        <mat-icon color="">people_outline</mat-icon>
        <span>Users</span>
      </a>
    </li> -->

    <li class="xn-menu-item">
      <a class="tools">
        <span class="tools-icon-tab">
        <mat-icon color="">supervised_user_circle</mat-icon>
        <span>Users</span>
        </span>
        <mat-icon class="tools-icon" color="">chevron_right</mat-icon>
        <ul class="xn-submenu">
          <li class="xn-menu-item">
            <a routerLinkActive="active" routerLink="/user-dashboard">
              <mat-icon color="">people_outline</mat-icon>
              <span>All</span>
            </a>
          </li>
          <li class="xn-menu-item" *ngIf="orionUser.isAdvisor">
            <a routerLinkActive="active" routerLink="/assigned-users">
              <mat-icon color="">people_outline</mat-icon>
              <span>Assigned Users</span>
            </a>
          </li>
          </ul>
      </a>
    </li>


   <!-- <li class="xn-menu-item" *ngIf="!orionUser.isAdvisor">
     <a routerLinkActive="active" routerLink="/user-dashboard">
       <mat-icon color="">people_outline</mat-icon>
       <span>Users</span>
     </a>
   </li>
   <li class="xn-menu-item" *ngIf="orionUser.isAdvisor">
     <a routerLinkActive="active" routerLink="/assigned-users">
       <mat-icon color="">people_outline</mat-icon>
       <span>Assigned Users</span>
     </a>
   </li> -->
    <ng-container>
      <li class="xn-menu-item" *appSecAccess="['ROLE_ADVISOR,','ROLE_ADVISOR_SUPERVISOR']">
        <a routerLinkActive="active" routerLink="/advisor-dashboard">
          <mat-icon color="">people_outline</mat-icon>
          <span>Advisors</span>
        </a>
      </li>
    </ng-container>
    <!-- <li class="xn-menu-item">
      <a routerLinkActive="active" routerLink="/prospects">
        <mat-icon color="">star</mat-icon>
        <span>Prospects</span>
      </a>
    </li> -->
    <!-- <li class="xn-menu-item">
      <a routerLinkActive="active" routerLink="/profiles">
        <mat-icon color="">assignment_ind</mat-icon>
        <span>Profiles</span>
      </a>
    </li> -->
    <!--    <li class="xn-menu-item">-->
    <!--      <a routerLinkActive="active" routerLink="/individuals">-->
    <!--        <mat-icon color="">favorite</mat-icon>-->
    <!--        <span>Individuals</span>-->
    <!--      </a>-->
    <!--    </li>-->

    <li class="xn-menu-item">
      <a routerLinkActive="active" routerLink="/institutions">
        <mat-icon color="">apartment</mat-icon>
        <span>Institutions</span>
      </a>
    </li>
  <li class="xn-menu-item" *appSecAccess="['ROLE_ADVISOR_SUPERVISOR']">
    <a routerLinkActive="active" routerLink="/goals">
      <img src="assets/icons/flag.svg" alt="flag">
      <span class="px-xxl-4 px-xl-4 px-lg-4 px-md-4 px-sm-4 px-4">Goals</span>
    </a>
  </li>


    <!--    <li class="xn-menu-item">-->
    <!--      <a routerLinkActive="active" routerLink="/referrals">-->
    <!--        <mat-icon color="">assignment_ind</mat-icon>-->
    <!--        <span>Referrals</span>-->
    <!--      </a>-->
    <!--    </li>-->

    <!--    <li class="xn-menu-item" *appSecAccess="['ROLE_USER']">-->
    <!--      <a routerLinkActive="active" routerLink="/requests">-->
    <!--        <mat-icon color="">pan_tool</mat-icon>-->
    <!--        <span>Requests</span>-->
    <!--      </a>-->
    <!--    </li>-->
    <ng-container >
    <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_SUPERVISOR', 'SEND_MAIL', 'ROLE_COMPLIANCE', 'ROLE_OPS']">
      <a class="tools">
        <span class="tools-icon-tab">
        <mat-icon color="">settings</mat-icon>
        <span>Tools</span>
        </span>
        <mat-icon class="tools-icon" color="">chevron_right</mat-icon>
        <ul class="xn-submenu">
          <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_SUPERVISOR']">
            <a routerLinkActive="active" routerLink="/dashboard">
              <mat-icon color="">manage_accounts</mat-icon>
              <span>Dashboard</span>
            </a>
          </li>
          <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_OPS']">
            <a routerLinkActive="active" routerLink="/deposit-confirmation">
              <mat-icon color="">send</mat-icon>
              <span>Deposit Confirmation</span>
            </a>
          </li>
          <li class="xn-sub-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'SEND_MAIL']">
            <a class="tools">
              <span class="tools-icon-tab">
              <mat-icon color="">mail</mat-icon>
              <span>Mass Sender</span>
                </span>
              <mat-icon class="tools-icon" color="">chevron_right</mat-icon>
            </a>
            <ul class="xn-sub-submenu">
              <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'SEND_MAIL']">
                <a routerLinkActive="active" routerLink="/bulk-emails">
                  <mat-icon color="">mail</mat-icon>
                  <span>Mails (Unselected Batch)</span>
                </a>
              </li>
              <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'SEND_MAIL']">
                <a routerLinkActive="active" routerLink="/bulk-emails-with-batch">
                  <mat-icon color="">mail</mat-icon>
                  <span>Bulk Emails (With Batch)</span>
                </a>
              </li>
              <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'SEND_MAIL']">
                <a routerLinkActive="active" routerLink="/bulk-sms">
                  <mat-icon color="">textsms</mat-icon>
                  <span>Bulk SMS</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
            <a routerLinkActive="active" routerLink="/account-merge">
              <mat-icon color="">account_circle</mat-icon>
              <span>Account Merge</span>
            </a>
          </li>

          <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
            <a routerLinkActive="active" routerLink="/lctr-dashboard">
              <mat-icon color="">attach_money</mat-icon>
              <span>LCTR Dashboard</span>
            </a>
          </li>

          <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_OPS']">
            <a routerLinkActive="active" routerLink="/activate-ussd-account">
              <mat-icon color="">assignment_ind</mat-icon>
              <span>Activate USSD</span>
            </a>
          </li>

          <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_OPS']">
            <a routerLinkActive="active" routerLink="/exports">
              <mat-icon color="">cloud_download</mat-icon>
              <span>Exports</span>
            </a>
          </li>
          <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_OPS']">
            <a routerLinkActive="active" routerLink="/ltt-updater">
              <mat-icon color="">refresh</mat-icon>
              <span>LTT Updater</span>
            </a>
          </li>



          <li class="xn-sub-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN']">
            <a class="tools">
              <span class="tools-icon-tab">
                <mat-icon color="">admin_panel_settings</mat-icon>
                <span>Admin</span>
                </span>
              <mat-icon class="tools-icon" color="">chevron_right</mat-icon>
            </a>
            <ul class="xn-sub-submenu">
              <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN']">
                <a routerLinkActive="active" routerLink="/staff">
                  <mat-icon color="">account_circle</mat-icon>
                  <span>Staff</span>
                </a>
              </li>

              <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN']">
                <a routerLinkActive="active" routerLink="/tasks">
                  <mat-icon color="">task</mat-icon>
                  <span>Tasks</span>
                </a>
              </li>
              <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN']">
                <a routerLinkActive="active" [routerLink]="['partners']">
                  <mat-icon color="">groups</mat-icon>
                  <span>Partners</span>
                </a>
              </li>
              <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN']">
                <a routerLinkActive="active" routerLink="/roles">
                  <mat-icon color="">lock</mat-icon>
                  <span>Roles</span>
                </a>
              </li>
              <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN']">
                <a routerLinkActive="active" routerLink="/departments">
                  <mat-icon color="">lock</mat-icon>
                  <span>Departments</span>
                </a>
              </li>
              <li class="xn-menu-item" *appSecAccess="['ROLE_SUPER_ADMIN']">
                <a routerLinkActive="active" routerLink="/capabilities">
                  <mat-icon color="">verified_user</mat-icon>
                  <span>Capabilities</span>
                </a>
              </li>
            </ul>
          </li>

        </ul>
      </a>
    </li>
    </ng-container>

  </ul>

  <div class="xn-menu-item user-menu">
    <a (click)="logout()">
      <mat-icon color="">power_settings_new</mat-icon>
      <span>Logout</span>
    </a>
  </div>
</div>
