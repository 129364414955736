import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Account} from "@orion/models";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UtilitiesService} from "@orion/utilities/utilities.service";
import {AccountService} from "@orion/services/account.service";

@Component({
  selector: 'app-edit-account-summary',
  templateUrl: './edit-account-summary.component.html',
  styleUrls: ['./edit-account-summary.component.scss']
})
export class EditAccountSummaryComponent implements OnInit {
  @Output() closeWindow = new EventEmitter();
  @Input() accountDetails: Account;
  isError: boolean = false;
  message!: string;
  editForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private utilities: UtilitiesService,
              private accountService: AccountService) { }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      institutionName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      regNumber: [''],
    });
  }

  submitEditForm(): void {

    // Success 🎉

    let updatedFormInput = {
      id: this.accountDetails.id,
      institutionName: this.editForm.value.institutionName,
      regNumber: this.editForm.value.regNumber,
    };
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.accountService.updateInstitutionalInformation(updatedFormInput)
          .subscribe((response) => {
            if(response.code == 200) {
              this.utilities.successAlert("Successful.");
              this.closeForm();
              this.isError = false;
              this.ngOnInit();
            }

            },
            error => {
              this.message = `UnAuthorized Access!`;
              this.isError = true;
            }
          );
      }
    })
  }

  closeForm() {
    this.closeWindow.emit();
  }

}
