<slideover (closeWindow)="close.emit()">
  <popover
    *ngIf="view == 'success'"
    title="Role created successfully"
    buttonLabel="Close"
    (onClick)="close.emit()"
  ></popover>

  <div class="page-inner-container">
    <div class="page-section">
      <h2 class="page-subtitle">{{ role ? "Edit" : "Create" }} Role</h2>
      <form class="edit-user-form" [formGroup]="roleForm">
        <div></div>
        <mat-form-field appearance="outline">
          <mat-label>Role Title</mat-label>
          <input type="text" formControlName="authority" matInput />
        </mat-form-field>

        <div class="u-justify actions">
          <button
            type="button"
            mat-button
            mat-flat-button
            color="warn"
            (click)="deleteRole(role)"
            *ngIf="role"
          >
            Delete Role
          </button>

          <button
            type="button"
            [disabled]="!roleForm.valid"
            mat-button
            mat-flat-button
            color="primary"
            (click)="submitRole()"
          >
            {{ role ? "Update" : "Create" }} Role
          </button>
        </div>
      </form>
    </div>
  </div>
</slideover>
