
<div class="export-date-filter">
  <h1 class="page-title">Exports</h1>
 <!-- Date Filter   -->
 <form class="" [formGroup]="filtersForm">
  <div class="date-filter">
    <mat-form-field appearance="fill">
      <mat-label>Date-Filter</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          matInput
          placeholder="Start date"
          formControlName="startDate"
        />
        <input
          matEndDate
          matInput
          placeholder="End date"
          formControlName="endDate"
          (dateChange)="applyDateRangeFilter($event)"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
</form>

</div>
<br>
<div class="">
  <table mat-table [dataSource]="dataSource" class="">
    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>NAME</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>ACTIONS</th>
      <td mat-cell *matCellDef="let element;">
        <button mat-flat-button
                color="primary"
                (click)="downloadFiles(element.url, element.fileName)">
          <mat-icon>attachment</mat-icon>
          Download
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
    ></tr>
  </table>

  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner color="accent"></mat-spinner>
  </div>
  <div class="spinner-container" *ngIf="isLoading">
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div>
<mat-paginator
[length]="dataSource.resultsLength | async"
[pageSize]="50"
showFirstLastButtons
[pageSizeOptions]="[10, 50, 100, 200]"
></mat-paginator>

</div>
