<div class="tab-header u-fadeInUp {{ format }}">
  <button
    *ngFor="let tab of tabs; last as isLast"
    class="tab-button"
    [class.is-active]="tab.active"
    (click)="onSelectTab(tab)"
    [class.has-byline]="tab.byline"
    [class.is-last]="isLast"
  >
    <div>
      <span class="u-fs-15">{{ tab.label }}</span>
      <span>{{ tab.byline }}</span>
    </div>
  </button>
</div>
<ng-content></ng-content>
