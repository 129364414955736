import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CapabilityService {

  private apiUrl = `${environment.serverUrl}/api`;
  constructor(private http: HttpClient) { }

  listCapabilities(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get(`${this.apiUrl}/requestMap`, {params});
  }

  saveCapability(formData): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/users/saveRequestMap`, formData);
  }

  updateCapability(capabilityId: string, formData: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/v1/users/updateRequestMap/${capabilityId}`, formData);
  }

  deleteCapability(capabilityId): Observable<any> {
    return this.http.delete(`${this.apiUrl}/capability/${capabilityId}`);
  }
}
