<ng-container *appSecAccess="['ROLE_SUPER_ADMIN']">
  <create-department *ngIf="view == 'create-department' || view == 'edit-department'" (close)="closeCreateDepartment()"
    (onComplete)="refreshDepartments()" [department]="department"></create-department>


  <div class="header-view">
    <h1 class="page-title">Department</h1>
    <button mat-flat-button mat-button color="primary" (click)="view = 'create-department'">
      <span>Create Department</span>
    </button>

  </div>

<br>
  <div class="xn-fadein-up xn-delay-2">
    <div class="mat-elevation-z8 xn-orion-table-container-dept">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Name Column -->
        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef>Department</th>
          <td mat-cell *matCellDef="let element">{{ element.department }}</td>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
          <th mat-header-cell *matHeaderCellDef>Date Created</th>
          <td mat-cell *matCellDef="let element">{{ element.dateCreated | date }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let department; columns: displayedColumns" (click)="editDepartment(department)"></tr>
      </table>
    </div>
    <div *ngIf="dataSource.resultsLength | async">
      <mat-paginator
      [length]="dataSource.resultsLength | async"
      [pageSize]="50"
      showFirstLastButtons
      [pageSizeOptions]="[10, 50, 100, 200]"
    ></mat-paginator>
  
    </div>
  </div>

  <div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
    <mat-spinner color="accent"></mat-spinner>
  </div>
</ng-container>

<ng-container *appSecAccess="!['ROLE_SUPER_ADMIN']">
  <div class="z-box">
    <div>
      <mat-spinner></mat-spinner>
      <br />
      <br />
      <h1>Un authorized</h1>
      <br />
    </div>

  </div>
</ng-container>