import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { UtilitiesService } from '@orion/utilities/utilities.service';

@Component({
  selector: 'xn-date-filter',
  templateUrl: './xn-date-filter.component.html',
  styleUrls: ['./xn-date-filter.component.scss'],
})
export class XnDateFilterComponent implements OnInit {
  @Output() onChangeDays = new EventEmitter();
  @Output() onRangeStart = new EventEmitter();
  @Output() onRangeEnd = new EventEmitter();
  @Output() onSetAllTime = new EventEmitter();

  @Input() startDate!: string;
  @Input() endDate!: string;
  @Input() selectedValue!: string;

  value: string = '';

  constructor(private utilities: UtilitiesService,) {}

  ngOnInit(): void {}

  changeDate(event: Event) {
    const value: string = (<HTMLInputElement>event.target).value;
    this.value = value;
    if (value != 'range' && value != 'all-time') {
      const number: number = parseInt(value);
      this.onChangeDays.emit(number - 1);
    }

    if (value == 'all-time') {
      this.onSetAllTime.emit();
    }

    if (value == 'range'){
      this.selectedValue = '';
    }
  }

  rangeStart(e: MatDatepickerInputEvent<Date>) {
    let date: any = e.value;
    this.onRangeStart.emit(this.utilities.getDate(date.toString()));
  }

  rangeEnd(e: MatDatepickerInputEvent<Date>) {
    let date: any = e.value;
    this.onRangeEnd.emit(this.utilities.getDate(date.toString()));
  }
}
