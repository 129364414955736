import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LttUpdaterService {

  apiUrl = `${environment.serverUrl}/api/v1`;

  constructor(private http: HttpClient) {
  }

 uploadExcelFile(formData: FormData): Observable<any> {
   return this.http.post(
     `${this.apiUrl}/ltt-updater/upload-file`,
      formData,
      {}
    );
  }

  updateMobilePaymentTransactions(transactions): Observable<any> {
    console.log(transactions);
    return this.http.post(
      `${this.apiUrl}/ltt-updater/update-transactions`,
      transactions,
      {}
    );
  }

  // uploadExcelFile(formData: FormData): Observable<any> {
  //   return this.http.post(
  //     `${this.apiUrl}/ltt-updater/add-mobile-payments`,
  //     formData,
  //     {}
  //   );
  // }

  updateSingleTransaction(formData: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/ltt-updater/update-single-transaction`,
      formData,
      {}
    )
  }
}
