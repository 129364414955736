<div class="u-justify">
  <h1 class="page-title">Prospects</h1>
  <form class="page-filters" [formGroup]="filtersForm">
    <!-- Date Filter   -->
    <div class="page-action">
      <mat-form-field appearance="fill">
        <mat-label>Created</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate matInput placeholder="Start date" formControlName="startDate" />
          <input matEndDate matInput placeholder="End date" formControlName="endDate"
            (dateChange)="applyDateRangeFilter($event)" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <!-- Payment Status Filter   -->
    <!-- <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label>Payment Status</mat-label>
          <mat-select
            formControlName="paymentStatus"
            (selectionChange)="applyPaymentStatusFilter($event)"
          >
            <mat-option value="ALL">All</mat-option>
            <mat-option value="FAILED">Failed</mat-option>
            <mat-option value="SUBMITTED">Not Started</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

    <!-- Network Filter  -->
    <div class="page-action">
      <mat-form-field appearance="fill">
        <mat-label>Network</mat-label>
        <mat-select formControlName="network" (selectionChange)="applyNetworkFilter($event)">
          <mat-option value="ALL">All</mat-option>
          <mat-option value="MTN">MTN</mat-option>
          <mat-option value="AIRTEL">Airtel</mat-option>
          <mat-option value="OTHER">Other</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
<br />
<mat-grid-list cols="4" gutterSize="20" rowHeight="150px">
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <div>
        <div>
          <div>
            <h3 class="mat-card-title">Total Prospects</h3>
          </div>
          <div>
            <h1 class="mat-card-title-2">{{dataSource.resultsLength | async | number: '1.0-0'}}</h1>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div class="cont" align="center">
            <mat-icon>group</mat-icon>
          </div>
        </div>
      </div>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <div>
        <div>
          <div>
            <h3 class="mat-card-title">Total MTN</h3>
          </div>
          <div>
            <h1 class="mat-card-title-2">{{dataSource.MTNResultsLength | async | number: '1.0-0'}}</h1>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div class="cont" align="center">
            <mat-icon>group</mat-icon>
          </div>
        </div>
      </div>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <div>
        <div>
          <div>
            <h3 class="mat-card-title">Total Airtel</h3>
          </div>
          <div>
            <!-- <h1>0</h1> -->
            <h1 class="mat-card-title-2">{{dataSource.AirtelResultsLength | async | number: '1.0-0'}}</h1>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="cont" align="center">
            <mat-icon>group</mat-icon>
          </div>
        </div>
      </div>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <div>
        <div>
          <div>
            <h3 class="mat-card-title">Total Others</h3>
          </div>

          <div>
            <h1 class="mat-card-title-2"> {{dataSource.OthersResultsLength | async | number: '1.0-0'}}</h1>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="cont" align="center">
            <mat-icon>group</mat-icon>
          </div>
        </div>
      </div>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
<br />
<div class="xn-padding">
  <div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="dateCreated" matSortDisableClear
      matSortDirection="desc" class="xn-table">
      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let element ">{{ element.position }}</td>
      </ng-container>

      <!-- dateCreated Column -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dateCreated | date}}
        </td>
      </ng-container>

      <!-- names Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>Names</th>
        <td mat-cell *matCellDef="let element">
          <div class="u-profile">
            <figure class="u-profile-photo"></figure>
            <a>{{ element.firstName | titlecase }}
              {{ element.lastName | titlecase }}</a>
          </div>
        </td>
      </ng-container>

      <!--  account -->
      <ng-container matColumnDef="accountType">
        <th mat-header-cell *matHeaderCellDef>Account</th>
        <td mat-cell *matCellDef="let element">
          {{ element.accountType }}
        </td>
      </ng-container>

      <!-- goal Column -->
      <ng-container matColumnDef="goal">
        <th mat-header-cell *matHeaderCellDef>GOAL</th>
        <td mat-cell *matCellDef="let element">
          {{ element.goal | uppercase }}
        </td>
      </ng-container>

      <!-- goal Column -->
      <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef>SOURCE</th>
        <td mat-cell *matCellDef="let element">
          {{ element.source | uppercase }}
        </td>
      </ng-container>

      <!-- network Column -->
      <!-- <ng-container matColumnDef="network">
        <th mat-header-cell *matHeaderCellDef>NETWORK</th>
        <td mat-cell *matCellDef="let element">
          {{ element.network | uppercase }}
        </td>
      </ng-container> -->

      <!-- phoneNumber Column -->
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>PHONE NUMBER</th>
        <td mat-cell *matCellDef="let element">
          {{ element.phoneNumber}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    </table>

    <mat-paginator [length]="dataSource.resultsLength | async" [pageSize]="50" showFirstLastButtons
      [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
  </div>
</div>


<div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>