<div class="d-flex align-items-center justify-content-center" *ngIf="!isLoading">
  <img src="assets/icons/notify.svg" alt="notify icon"/>
</div>

<ng-container *ngIf="isLoading">
  <div class="d-flex align-items-center justify-content-center">
    <img src="assets/icons/loader.svg" alt="notify icon"/>
    <div class="text-center">
      Please wait...
    </div>

  </div>


</ng-container>

<ng-container *ngIf="!isLoading">
  <div class="d-flex align-items-center justify-content-center">
    <h5 class="pt-xxl-4 pt-xl-4 pt-lg-4 pt-md-4 pt-sm-4 xn-title px-xxl-5 px-xl-5 px-lg-5 px-md-5 px-sm-5 text-wrap text-center"
        *ngIf="view == 'preview'">Are you sure you would like to deactivate<br/>
      <span class="text-capitalize">{{profileDetails.firstName | titlecase}} {{profileDetails.lastName | titlecase}}</span>'s profile?</h5>



    <h5 class="pt-xxl-4 pt-xl-4 pt-lg-4 pt-md-4 pt-sm-4 xn-title px-xxl-5 px-xl-5 px-lg-5 px-md-5 px-sm-5 text-wrap text-center"
        *ngIf="view == 'success'"><span class="text-capitalize">{{profileDetails.firstName | titlecase}} {{profileDetails.lastName | titlecase}}</span>'s
      profile has been<br/> deactivated.</h5>

    <h5 class="pt-xxl-4 pt-xl-4 pt-lg-4 pt-md-4 pt-sm-4 xn-title px-xxl-5 px-xl-5 px-lg-5 px-md-5 px-sm-5 text-wrap text-center"
        *ngIf="view == 'error'">Oops, looks like;</h5>

  </div>

  <ng-container *ngIf="isOpenWindow">
    <ng-container *ngIf="isSuccessWindow">
      <div class="d-flex align-items-center justify-content-start">
        <p class="pt-xxl-4 pt-xl-4 pt-lg-4 pt-md-4 pt-sm-4 ps-xxl-3 ps-xl-3 ps-lg-3 px-md-3 px-sm-3">
          <img src="assets/icons/double-check.svg" class="px-xxl-4 px-xl-4 px-lg-4 px-md-4 px-sm-4" *ngIf="isSuccess">
        <span class="text-dark">The client has no funded goals</span></p>
      </div>
      <div class="d-flex align-items-center justify-content-start">
        <p class="pt-xxl-3 pt-xl-3 pt-lg-3 pt-md-3 pt-sm-3 ps-xxl-3 ps-xl-3 ps-lg-3 px-md-3 px-sm-3">
          <img src="assets/icons/double-check.svg" class="px-xxl-4 px-xl-4 px-lg-4 px-md-4 px-sm-4" *ngIf="isSuccess">
          <span class="text-dark">The client is not part of any groups</span></p>
      </div>

    </ng-container>

    <ng-container *ngIf="isErrorWindow">
      <ng-container *ngIf="isServerError">
        <p class="text-center">{{message}}</p>
      </ng-container>
      <ng-container *ngIf="!isServerError">
        <div class="d-flex align-items-center justify-content-start" *ngFor="let msg of messages">
            <p class="pt-xxl-4 pt-xl-4 pt-lg-4 pt-md-4 pt-sm-4 ps-xxl-3 ps-xl-3 ps-lg-3 px-md-3 px-sm-3">
              <img src="assets/icons/error.svg" class="px-xxl-4 px-xl-4 px-lg-4 px-md-4 px-sm-4" *ngIf="isError">
              <span class="{{isError ? 'text-secondary' : 'text-dark'}}">{{msg}}</span></p>
        </div>

      </ng-container>


    </ng-container>



  </ng-container>






  <div class="d-flex align-items-center justify-content-center">
    <ng-container *ngIf="view == 'preview'">
      <div class="d-flex mb-3 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5">
        <div class="p-2 mx-5">
          <button type="button" class="btn btn-outline-dark btn-lg pt-3 pb-3 px-5 rounded-4 mx-4" (click)="closeModal()">Cancel</button>
        </div>
        <div class="ms-auto p-2 mx-5">
          <button type="button" class="btn btn-primary btn-lg pt-3 pb-3 px-5 rounded-4 mx-4" (click)="confirm()">Confirm</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="view == 'success'">
      <button type="button" class="btn btn-primary btn-lg pt-3 pb-3 px-5 rounded-4 mx-4 mb-3 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5"
              (click)="closeModal()">Done</button>
    </ng-container>

    <ng-container *ngIf="view == 'error'">
      <button type="button" class="btn btn-outline-dark btn-lg pt-3 pb-3 px-5 rounded-4 mx-4 mb-3 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5"
              (click)="closeModal()">Cancel</button>
    </ng-container>

  </div>
</ng-container>


