<slideover [zIndex]="2" (closeWindow)="close.emit()">
  <div class="page-inner-container">
    <div class="page-section" *ngIf="isUpload">
      <h2 class="page-subtitle">Upload File to Assign Advisors to Profiles</h2>

      <div class="import-member-form">
        <div class="xn-input-file" [class.is-default]="fileToUpload == null">
          <label class="control-label">Select CSV</label>
          <input
            type="file"
            accept=".csv"
            class="custom-file-input"
            id="customFile"
            name="myfile"
            (change)="onFileSelect($event)"
          />
          <label class="file-label" for="customFile" #advisorsDocLabel>></label>
          <figure class="icon"></figure>
        </div>
        <div class="u-justify actions">
          <button
            type="submit"
            mat-button
            mat-flat-button
            color="primary"
            (click)="uploadAdvisors()">
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</slideover>
