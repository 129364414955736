import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvisoryUploadService {

  apiUrl = `${environment.serverUrl}/api/v1`;

  constructor(private http: HttpClient) {
  }

 uploadExcelFile(formData: FormData): Observable<any> {
   return this.http.post(
     `${this.apiUrl}/upload-advisor-file`,
      formData,
      {}
    );
  }

  assignAdvisors(advisors): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/attach-advisor-to-user`,
      advisors,
      {}
    );
  }


}
