import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Department} from "@orion/models";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DepartmentService} from "@orion/services/department.service";

@Component({
  selector: 'create-department',
  templateUrl: './create-department.component.html',
  styleUrls: ['./create-department.component.scss']
})
export class CreateDepartmentComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Output() onComplete = new EventEmitter();
  @Input() department: Department;
  view = '';

  // form
  constructor(private formBuilder: UntypedFormBuilder,
              private departmentService: DepartmentService) {
  }
  departmentForm: UntypedFormGroup;


  closeWindow() {
    this.close.emit();
  }

  onUpdateComplete() {
    this.onComplete.emit();
  }

  submitDepartment() {
    if (this.departmentForm.valid) {
      if (this.department && this.department.id != null) {
        // we are updating
        this.departmentService.updateDepartment(this.department.id, this.departmentForm.value)
          .subscribe(() => {
              this.onUpdateComplete();
              this.closeWindow();
            },
            error => {return error;});

      } else {
        // we are creating new
        this.departmentService.saveDepartment(this.departmentForm.value)
          .subscribe(() => {
              this.onUpdateComplete();
              this.closeWindow();
            },
            (error) => {return error;}
          );
      }
    }
  }

  deleteDepartment(department) {
    const confirmation = confirm('Are you sure you want to delete this department?');
    if (confirmation && department) {
      this.departmentService.deleteDepartment(department.id)
        .subscribe(() => {},
          (error) => {return error;});
    }
  }

  get name() {
    return this.departmentForm.get('department');
  }

  ngOnInit(): void {


    this.departmentForm = this.formBuilder.group({
      department: [
        this.department && this.department.department !== '' ? this.department.department : '',
        [
          Validators.required,
        ],
      ],
    });
  }

}
