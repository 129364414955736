import {CollectionViewer , DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {GoalDashboardService} from "@orion/modules/goal/goal-dashboard.service";


export class GoalsDataSource implements DataSource<any>{
  private goalSubject = new BehaviorSubject<any[]>([]);
  public goalStatCardSubject = new BehaviorSubject<any[]>([]);
  public goalDataSubject = new BehaviorSubject<any[]>([])
  private resultsLengthSubject = new BehaviorSubject(0);
  private totalAumSubject = new BehaviorSubject(0);
  private totalFundedSubject = new BehaviorSubject(0);
  private totalNotFundedSubject = new BehaviorSubject(0);
  private totalAchievedSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();
  public goalData = this.goalDataSubject.asObservable();
  private statCardLoadingSubject = new BehaviorSubject<boolean>(false);
  public statCardLoading$ = this.statCardLoadingSubject.asObservable();

  constructor(private goalService: GoalDashboardService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<any[] | ReadonlyArray<any>> {
    return this.goalSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.goalSubject.complete();
    this.loadingSubject.complete();
  }

  loadGoals(offset: any,
            lastDepositStartDate: any,
            lastDepositEndDate: any,
            max: any,
            sortOrder: any,
            startDate: any,
            endDate: any,
            autosave: any,
             aumBand: any,
            target: any,
             funded: any,
            sortBy: any,
            goalType: any,
            progress: any,
            achieved: any,
            isLoadingSubject: boolean) {
    this.loadingSubject.next(isLoadingSubject);
    this.goalService.list(
      offset,
      lastDepositStartDate,
      lastDepositEndDate,
      max,
      sortOrder,
      startDate,
      endDate,
      autosave,
      aumBand,
      target,
      funded,
      sortBy,
      goalType,
      progress,
      achieved).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(resp => {
      console.log(`sssssss:=>${JSON.stringify(resp)}`);
      this.goalSubject.next(resp['result']);
      this.resultsLengthSubject.next(resp['total']);
      this.goalDataSubject.next(resp);
    });
  }


  loadFilteredStatCardData(offset: any,
                      lastDepositStartDate: any,
                      lastDepositEndDate: any,
                      max: any,
                      sortOrder: any,
                      startDate: any,
                      endDate: any,
                      autosave: any,
                      aumBand: any,
                      target: any,
                      funded: any,
                      sortBy: any,
                      goalType: any,
                      progress: any,
                           achieved: any) {
    this.goalService.goalCardList(
      offset,
      lastDepositStartDate,
      lastDepositEndDate,
      max,
      sortOrder,
      startDate,
      endDate,
      autosave,
      aumBand,
      target,
      funded,
      sortBy,
      goalType,
      progress,
      achieved
    ).pipe(
      catchError(() => of([])),
      finalize(() => this.statCardLoadingSubject.next(false))
    ).subscribe(resp => {
      this.goalStatCardSubject.next(resp);
    });
  }






}
