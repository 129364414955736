import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, combineLatest, from, Observable, of, Subject} from "rxjs";
import {ProfileService} from "../../services/profile.service";
import {catchError, finalize, map, mergeMap, switchMap, tap} from "rxjs/operators";
import {UserDashboardProfiles} from "@orion/models/user-dashboard";

export class UserDashboardSource implements DataSource<UserDashboardProfiles> {
  private userssSubject = new BehaviorSubject<UserDashboardProfiles[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private userAvatarSubject = new BehaviorSubject<string>("");
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();
  public userAvatar = this.userAvatarSubject.asObservable();

  constructor(private profilesService: ProfileService) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<UserDashboardProfiles[] | ReadonlyArray<UserDashboardProfiles>> {
    return this.userssSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.userssSubject.complete();
    this.loadingSubject.complete();
  }

  // Did this

  // Modify loadUserProfiles method to load avatars along with profiles
  loadUserProfiles(
    offset: any,
    max: any,
    sort: string,
    order: string,
    page: number,
    filter = '',
    tableFilter: any,
  ) {
    this.loadingSubject.next(true);
    this.profilesService
      .getAllUsers(offset, max, sort, order, page, filter, tableFilter)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false)),
        tap((resp) => {
          this.userssSubject.next(resp.data);
          this.resultsLengthSubject.next(resp.totalCount);
        }),
        // Fetch avatars for loaded user profiles
        mergeMap((resp) => {
          const avatarRequests = resp.data.map(user => this.fetchUserAvatar(user.email));
          return combineLatest([of(resp.data), combineLatest(avatarRequests)]);
        })
      )
      .subscribe(([userData, avatarUrls]) => {
        // Combine user data with avatar URLs
        userData.forEach((user:any, index: any) => {
          user.avatarUrl = avatarUrls[index];
        });
        this.userssSubject.next(userData);
      });
  }


  fetchUserAvatar(username: string): Observable<string> {
    let usersDataSnapshot = this.userssSubject.value;
    let userIndex = usersDataSnapshot.findIndex((e) => e.email === username);
    if (userIndex !== -1) {
      usersDataSnapshot[userIndex].loadingAvatar = true;
      this.userssSubject.next(usersDataSnapshot);
    }

    return this.profilesService.getPassportImage(username).pipe(
      map(resp => URL.createObjectURL(resp)),
      tap((imageUrl) => {
        if (userIndex !== -1) {
          usersDataSnapshot[userIndex].avatarUrl = imageUrl;
          usersDataSnapshot[userIndex].loadingAvatar = false;
          this.userssSubject.next(usersDataSnapshot);
        }
      }),
      catchError(error => {
        console.error('Error fetching avatar:', error);
        if (userIndex !== -1) {
          usersDataSnapshot[userIndex].loadingAvatar = false;
          this.userssSubject.next(usersDataSnapshot);
        }
        return of(null); // Return null if avatar fetch fails
      })
    );
  }

}
