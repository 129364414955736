import { Component, OnInit, Input } from '@angular/core';
import {User} from '../../../models';
import {AuthenticationService} from '../../../services';

@Component({
  selector: 'app-dashbox',
  templateUrl: './dashbox.component.html',
  styleUrls: ['./dashbox.component.scss']
})
export class DashboxComponent implements OnInit {
  orionUser: User;

  constructor(private authenticationService: AuthenticationService) {
    authenticationService.orionUser.subscribe(x => this.orionUser = x);
  }

  ngOnInit() {
    // console.log(this.orionUser)
  }

}
