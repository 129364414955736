<slideover (closeWindow)="closeWindow()">
  <div class="spinner-container fill-container" *ngIf="isLoading">
    <mat-spinner color="accent" [diameter]="70"></mat-spinner>
  </div>

  <div class="page-inner-container xn-fadein-up" *ngIf="!isLoading">
    <div class="page-section">
      <div class="u-justify is-table-header">
        <h2 class="page-subtitle">User Summary</h2>
        <div>
          <button
            class="actions-button"
            mat-button
            [matMenuTriggerFor]="menu"
            mat-flat-button
            color="primary"
          >
            <mat-icon matPrefix>create</mat-icon>
            <span>Actions</span>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="resetUserLogin()">
              <span>Reset Login Information</span>
            </button>
            <button mat-menu-item>
              <span>Download activity log</span>
            </button>
            <button mat-menu-item (click)="editUser()">
              <span>Edit User</span>
            </button>
            <button mat-menu-item (click)="deactiviateUser()">
              <span>Deactivate User</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <table
        class="xn-table mat-elevation-z8 is-summary"
        *ngIf="view != 'edit'"
      >
        <tbody>
          <tr>
            <td>Name</td>
            <td>
              <span
                >{{ userDetails.firstName }} {{ userDetails.lastName }}</span
              >
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <span>{{ userDetails.username }}</span>
            </td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>
              <span>{{ userDetails.telephone }}</span>
            </td>
          </tr>
          <tr>
            <td>Role</td>
            <td>
              <span>{{ userDetails.roles }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <p *ngIf="message" class="u-ptb-16 xn-fadein-up message">User updated</p>
      <form
        *ngIf="view == 'edit'"
        class="edit-user-form xn-fadein-up"
        [formGroup]="userForm"
      >
        <div class="fields">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>First Name</mat-label>
              <input type="text" formControlName="firstName" matInput />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Last Name</mat-label>
              <input type="text" formControlName="lastName" matInput />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Email Address</mat-label>
              <input type="email" formControlName="username" matInput />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Phone number</mat-label>
              <input type="text" formControlName="telephone" matInput />
            </mat-form-field>
          </div>
          <div>
            <mat-label>Role</mat-label>
            <input type="hidden" formControlName="userRoles" />
            <br />
            <div *ngFor="let userRole of userDetails.roles">
              <mat-checkbox (click)="updateUserRoles(userRole.id, $event)">{{
                userRole.authority
              }}</mat-checkbox>
            </div>
          </div>
        </div>
        <div class="u-justify actions">
          <button
            type="button"
            mat-button
            mat-flat-button
            color="warn"
            (click)="cancelEditUser()"
          >
            Cancel
          </button>
          <button
            type="button"
            [disabled]="!userForm.valid"
            mat-button
            mat-flat-button
            color="primary"
            (click)="submitEditUser()"
          >
            Update
          </button>
        </div>
      </form>
    </div>

    <!--    <div class="page-section">-->
    <!--      <h2 class="page-subtitle">Activity Log</h2>-->
    <!--      <table class="xn-table mat-elevation-z8 no-table-hover">-->
    <!--        <thead>-->
    <!--          <tr>-->
    <!--            <th>Date</th>-->
    <!--            <th>Time</th>-->
    <!--            <th>Activity</th>-->
    <!--          </tr>-->
    <!--        </thead>-->

    <!--        <tbody>-->
    <!--          <tr>-->
    <!--            <td>23 Aug 2020</td>-->
    <!--            <td>GMT 12:00</td>-->
    <!--            <td>Log in</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>21 Aug 2020</td>-->
    <!--            <td>GMT 23:00</td>-->
    <!--            <td>Update password</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>23 Aug 2020</td>-->
    <!--            <td>GMT 12:00</td>-->
    <!--            <td>Delete goal</td>-->
    <!--          </tr>-->
    <!--        </tbody>-->
    <!--      </table>-->
    <!--    </div>-->
  </div>
</slideover>
