import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilitiesService } from '@orion/utilities/utilities.service';

@Component({
  selector: 'app-ltt-dialog',
  templateUrl: './ltt-dialog.component.html',
  styleUrls: ['./ltt-dialog.component.scss']
})
export class LttDialogComponent implements OnInit {

  transactionsData: any
  tenantCurrency: string;
  displayedColumns: string[] = [
    'providerID',
    'amount',
    'transactionDate',
    'currentStatus',
    'investmentStatus',
    'matchingStatus'
  ];

  constructor(
    private dialogRef: MatDialogRef<LttDialogComponent>,
    public utilitiesService: UtilitiesService,
    @Inject(MAT_DIALOG_DATA) data: { transactionsData: any }
  ) {
    dialogRef.disableClose = true;
    this.transactionsData = data ? data.transactionsData : '';
  }

  ngOnInit() {
    this.tenantCurrency = this.utilitiesService.xnCurrencyLabel(localStorage.getItem('tenantId'))
    
  }

  cancelUpdate(): void {
    this.dialogRef.close(false);
  }

  proceedToUpdate(): void {
    this.dialogRef.close(true);
  }

}