import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MassSenderService} from '@orion/services/mass-sender.service';
import {UtilitiesService} from '@orion/utilities/utilities.service';

@Component({
  selector: 'app-bulk-emails-with-batch',
  templateUrl: './bulk-emails-with-batch.component.html',
  styleUrls: ['./bulk-emails-with-batch.component.scss']
})
export class BulkEmailsWithBatchComponent implements OnInit {
  form: UntypedFormGroup;
  isError = false;
  message!: string;
  fileToUpload: File = null;
  @ViewChild('myInput')
  myInputVariable: ElementRef;


  constructor(private massSenderService: MassSenderService,
              private formBuilder: UntypedFormBuilder,
              private utilities: UtilitiesService
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      subject: ['', [Validators.required, ]],
      sendForPreview: [''],
      sendForNoReply: [''],
      massMessageBody: ['', [Validators.required, ]],
    });
  }


  uploadExcelFileForBulkMailer() {
    if (this.fileToUpload === null) {
      this.isError = true;
      this.message = `Please upload the CSV File!`;
    }
    this.isError = false;
    const formData = new FormData();
    formData.append('subject', this.form.get('subject').value);
    formData.append('sendForPreview', this.form.get('sendForPreview').value);
    formData.append('sendForNoReply', this.form.get('sendForNoReply').value);
    formData.append('file', this.fileToUpload);
    formData.append('mailerContent', this.form.get('massMessageBody').value);
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.massSenderService
          .uploadExcelFileAxeBulkMailList(formData)
          .subscribe(
            (response) => {
              if (response.code === 200) {
                this.utilities.successAlert('Emails Successfully Sent!');
                this.isError = false;
                this.message = `Emails Successfully Sent!!`;
                this.ngOnInit();
              } else {
                this.utilities.errorAlert('Failed to send email, Please check the html template!!');
                this.isError = true;
                this.message = `Failed to send email, Please check the html template!!`;
                this.ngOnInit();
              }
            },
            (error) => {
              this.message = `${error}!`;
              this.message = `${error.msg}!`;
              this.isError = true;
              this.ngOnInit();
            }
          );
      }
    });
  }

  onFileChange(e) {
    if (e.target.files.length > 0) {
      this.fileToUpload = e.target.files[0];
    } else {
      this.fileToUpload = null;
    }
  }

  clearForm() {
    this.form.reset();
    this.myInputVariable.nativeElement.value = '';
  }
}
