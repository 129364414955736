import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { merge } from 'rxjs';
import { Profile } from '../../models';
import { ProfileService } from '../../services/profile.service';
import { tap } from 'rxjs/operators';
import { ProfilesDataSource } from './profiles-data-source';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '@orion/services';
import { UtilitiesService } from '@orion/utilities/utilities.service';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss'],
})
export class ProfilesComponent implements OnInit, AfterViewInit {
  @Input() isSearch: boolean;
  @Input() searchResults;
  roles: any = [];
  displayedColumns: string[] = [
    'position',
    'dateCreated',
    'firstName',
    'source',
    'kycStatus',
    'fundedStatus',
    'advisor',
  ];
  profiles: Profile[] = [];
  resultsLength = 0;
  isLoading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtersForm: UntypedFormGroup;
  showProfile = false;
  profileId: string;
  dataSource: ProfilesDataSource;
  thumbNailBaseUrl = `${environment.serverUrl}/api/v1/guest/passportphoto/`;
  kycStatus: string;
  advisors: any;
  tenantCurrency: string;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    private utilitiesService: UtilitiesService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.orionUser.subscribe(res => {
      if (res.roles?.includes('ROLE_SUPERVISOR')) {
        this.displayedColumns.push('profileTotalAUM');
      }

      if (res.roles?.includes('ROLE_SUPER_ADMIN')) {
        // this.displayedColumns.splice(5, 0, 'profileStatus');
        this.displayedColumns.push('lastUpdatedBy');
      }
    });
  }

  setStatus(status: string) {
    let s = '';

    switch (status) {
      case 'active':
        s = 'verified';
        break;

      default:
        s = status;
        break;
    }
    return s;
  }

  ngOnInit() {
    this.tenantCurrency = this.utilitiesService.xnCurrencyLabel(localStorage.getItem('tenantId'))
    this.filtersForm = this.formBuilder.group({
      profileStatus: [''],
      sourceStatus: [''],
      kycStatus: [''],
      fundedStatus: [''],
      kycRevertedStatus: [''],
      advisor: [''],
      startDate: [''],
      endDate: [''],
    });
    this.dataSource = new ProfilesDataSource(this.profileService);
    this.dataSource.loadProfiles(0, 50, 'dateCreated', 'desc', 0, '');
    this.profileService.getAdvisors().subscribe((advisors) => {
      this.advisors = advisors;
    });


  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadProfilesPage()))
      .subscribe();
  }

  loadProfilesPage() {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadProfiles(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      JSON.stringify(this.filtersForm.value)
    );
  }


  applyProfileStatusFilter(event: MatSelectChange) {
    const filterValue = event.value;
    this.paginator.pageIndex = 0;
    this.dataSource.loadProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

  applyDateRangeFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

  applyKycRevertedStatusFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }

    this.paginator.pageIndex = 0;
    this.dataSource.loadProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

  applySourceStatusFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }

    this.paginator.pageIndex = 0;
    this.dataSource.loadProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

  applyKycStatusFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

  applyAdvisorFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;

    this.dataSource.loadProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

  applyFundedStatusFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;

    this.dataSource.loadProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

  showProfileDetail(profileId: string) {
    this.showProfile = true;
    this.profileId = profileId;
  }

  hideProfile() {
    this.showProfile = false;
    this.profileId = null;
  }
}
