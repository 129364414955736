import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  Form,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "single-request",
  templateUrl: "./single-request.component.html",
  styleUrls: ["./single-request.component.scss"],
})
export class SingleRequestComponent implements OnInit {
  @Output() closeWindow = new EventEmitter();
  @Input() requestType: string;
  requestStatus = "open";

  closeRequest() {
    this.closeWindow.emit();
  }

  filePreview = false;
  closeFilePreview() {
    this.filePreview = false;
  }

  showFilePreview() {
    this.filePreview = true;
  }

  cancelReject() {
    this.requestStatus = "open";
  }

  approveReject() {
    let confirmation = confirm(
      "Are you sure you want to reject this request?"
    );
    if (confirmation) {
      this.requestStatus = "rejected";
    }
  }

  rejectRequest() {
    this.requestStatus = "showRejectForm";
  }

  approveRequest() {
    let confirmation = confirm(
      "Are you sure you want to approve this request?"
    );
    if (confirmation) {
      this.requestStatus = "approved";
    }
  }

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {}

  requestForm: UntypedFormGroup;

  get rejectMessage(){
    return this.requestForm.get('rejectMessage');
  }
  onSubmit(){
    //do your submission magic here
  }

  ngOnInit() {
    this.requestForm = this.formBuilder.group({
      userName: [''], 
      requestStatus: [this.requestStatus], 
      rejectMessage: ['']
    });
    
  }
}
