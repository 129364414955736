import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => TextInputComponent
      ),
      multi: true
    }
  ]
})
export class TextInputComponent implements ControlValueAccessor{

  @Input() public parentForm: UntypedFormGroup;

  @Input() public fieldName: string;

  @Input() public label: string;

  public value: string;

  @Input() public type: string;

  public changed: (value: string) => void;

  public touched: () => void;
  public isDisabled: boolean;

  get formField(): UntypedFormControl {
    return this.parentForm.get(this.fieldName) as UntypedFormControl;
  }

  constructor() { }

  public writeValue(value: string): void {
    this.value = value;
  }

  public onChange(event: Event): void {
    const value: string =
      (event.target as HTMLInputElement).value;

    this.changed(value);
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
