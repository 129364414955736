import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UtilitiesService } from '@orion/utilities/utilities.service';
import { MatSort } from '@angular/material/sort';
import { ProfileService } from '@orion/services/profile.service';
import { Observable, merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '@orion/models';
import { AuthenticationService } from '@orion/services';
import { AdvisorUsersDashboardSource } from '../advisor-dashboard/single-advisor/single-advisor-data-source';
import { AdvisorUserDashboardStats } from '@orion/models/advisor_dash_stats';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-assigned-users',
  templateUrl: './assigned-users.component.html',
  styleUrls: ['./assigned-users.component.scss'],
})

export class MyUsersComponent implements OnInit, AfterViewInit {
  @Input() isSearch: boolean;
  @Input() advisorProfileId: any;
  @Input() searchResults: any;
  @Input() hideSummaryCards: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    public utilitiesService: UtilitiesService,
  ) {
    authenticationService.orionUser.subscribe((x) => (this.orionUser = x));
   }

  advisorisplayedColumns: string[] = [
    'position',
    'dateCreated',
    'firstName',
    'noOfDeposits',
    'totalDeposits',
    'noOfWithdraws',
    'totalWithdraws',
    'initialAum',
    'initialBand',
    'totalAum',
    'currentBand',
    'lastDepositDate',
  ];

  roles: any = [];
  resultsLength = 0;
  filtersForm: UntypedFormGroup;
  advisorControl = new UntypedFormControl('');
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  advisorUsersDashboardStats?: AdvisorUserDashboardStats;

  showProfile = false;
  resetFiltersActive = false;
  getDate: string;
  profileId: string;
  orionUser: User;
  advisorDataSource: AdvisorUsersDashboardSource;
  liveImageUrl = environment.serverUrl;
  // thumbNailBaseUrl = `${environment.serverUrl}/api/v1/guest/passportphoto/`;
  thumbNailBaseUrl = `${this.liveImageUrl}/api/v1/guest/passportphoto/`;
  isLoading = true;
  showModal = false;
  clientProfileId = '';
  isProspectStatus: boolean;
  toggleTableView: boolean;
  filteredOptions: Observable<string[]>;
  isDateFilter = false;
  startDate: string = '';
  endDate: string = '';
  selectedValue: string = '';
  tenantCurrency: string;



  ngOnInit() {
    this.tenantCurrency = this.utilitiesService.xnCurrencyLabel(localStorage.getItem('tenantId'))
    this.loadDashboard();
  }

  loadDashboard(){
    this.filtersForm = this.formBuilder.group({
      band: [''],
      startDate: [''],
      endDate: [''],
    });
    this.advisorDataSource = new AdvisorUsersDashboardSource(this.profileService)
      this.advisorDataSource.loadAdvisoryUsers(0, 50, 'dateCreated', 'desc', 0, '', (this.advisorProfileId ? this.advisorProfileId : this.orionUser.id));
      this.getAdvisorUserDashSummary(this.advisorProfileId ? this.advisorProfileId : this.orionUser.id);
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadAdvisorUsersPage()
      ))
      .subscribe();
  }


  loadAdvisorUsersPage() {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.advisorDataSource.loadAdvisoryUsers(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      JSON.stringify(this.filtersForm.value),
      (this.advisorProfileId ? this.advisorProfileId : this.orionUser.id),
    );
  }
  onChangeDays(days: any) {
    this.resetFiltersActive = true
    this.isDateFilter = true;
    this.selectedValue = "";
    if (days != 'range') {
      const number: number = parseInt(days);
      this.startDate = this.utilitiesService.getStartDate(number);
      this.endDate = this.utilitiesService.addDays(new Date(), 1);
      this.applyDateRangeFilter();
    }
  }

  onSetAllTime() {
    this.resetFilters()
  }

  setStartDate(e: string) {
    this.isDateFilter = true;
    if (e) {
      this.startDate = e;
      this.applyDateRangeFilter();
    }
  }

  setEndDate(e: string) {
    this.isDateFilter = true;
    if (e) {
      this.endDate = e;
      this.applyDateRangeFilter();
    }
  }


  setStatus(status: string) {
    let s = '';

    switch (status) {
      case 'active':
        s = 'verified';
        break;

      default:
        s = status;
        break;
    }
    return s;
  }

  setFundedStatus(status: string) {
    let s = '';

    switch (status) {
      case 'not_funded':
        s = 'Not Funded';
        break;

      default:
        s = 'Funded';
        break;
    }
    return s;
  }

  isNegativeAbusoluteAUM(aum: number): boolean {
    if ((aum.toString()).includes('-')) {
      return true;
    } else {
      return false;
    }
  }

  onTableViewChange(event: any) {
    this.toggleTableView = event;
  }

  onTableViewClick(event: any){
    event.stopPropagation();
    this.toggleTableView = event;
  }


  showProfileDetail(profileId: string, isProspect: boolean) {
    this.clientProfileId = profileId;
    this.isProspectStatus = isProspect;
    this.showModal = true;
  }


  applyDateRangeFilter() {
    this.resetFiltersActive = true
    this.filtersForm.value.startDate = this.startDate;
    this.filtersForm.value.endDate = this.endDate;
    this.paginator.pageIndex = 0;
    this.advisorDataSource.loadAdvisoryUsers(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value),
      (this.advisorProfileId ? this.advisorProfileId : this.orionUser.id)
    );
    this.getAdvisorUserDashSummary(this.advisorProfileId ? this.advisorProfileId : this.orionUser.id);
  }

  applyBandFilter(event: MatSelectChange) {
    this.resetFiltersActive = true
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.advisorDataSource.loadAdvisoryUsers(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value),
      (this.advisorProfileId ? this.advisorProfileId : this.orionUser.id)
    );
    this.getAdvisorUserDashSummary(this.advisorProfileId ? this.advisorProfileId : this.orionUser.id);
  }


  showProfileModal(profileId: string) {
    this.clientProfileId = profileId;
    this.showModal = true;

  }

  getStartDate(filter: string){
    this.getDate = filter;
    console.log(`my date ${this.getDate}`)
  }

  hideProfileModal() {
    this.showModal = false;
    this.clientProfileId = '';
  }

  getAdvisorUserDashSummary(advisorId: string){
    this.profileService.getAdvisorUserDashbordStats(advisorId).subscribe((dashboardStats) => {
      this.advisorUsersDashboardStats = dashboardStats.data;
    });
  }

  resetFilters(){
    this.startDate = "";
    this.endDate = "";
    this.selectedValue = "all-time";
    this.isDateFilter = false;
    this.filtersForm.reset();
    this.loadDashboard()
    this.paginator.pageIndex = 0;
    this.resetFiltersActive = false
  }

 }
