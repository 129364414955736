<ng-container *appSecAccess="['ROLE_SUPER_ADMIN']">
<create-capability
  *ngIf="view == 'create-capability' || view == 'edit-capability'"
  [capability]="selectedCapability"
  (close)="closeEditView()"
></create-capability>


  
<div class="header-view">
  <h1 class="page-title">Capabilities</h1>
  <button
  mat-flat-button
  mat-button
  color="primary"
  (click)="view = 'create-capability'"
>
  <span>Create Capability</span>
</button>

</div>
<br>
<div class="xn-fadein-up xn-delay-2">
  <div class="mat-elevation-z8 xn-orion-table-container-cap">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Name -->
      <ng-container matColumnDef="configAttributes">
        <th mat-header-cell *matHeaderCellDef>Roles</th>
        <td mat-cell *matCellDef="let element">
          <strong>{{ element.configAttribute }}</strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef>Url</th>
        <td mat-cell *matCellDef="let element">
          <strong>{{ element.url }}</strong>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let capability; columns: displayedColumns"
        (click)="editCapability(capability)"
      ></tr>
    </table>
  </div>
  <div *ngIf="dataSource.resultsLength | async">
    <mat-paginator
    [length]="dataSource.resultsLength | async"
    [pageSize]="50"
    showFirstLastButtons
    [pageSizeOptions]="[10, 50, 100, 200]"
  ></mat-paginator>

  </div>
</div>

<div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
</ng-container>
<ng-container *appSecAccess="!['ROLE_SUPER_ADMIN']">
  <div class="z-box">
    <div>
      <mat-spinner></mat-spinner>
      <br/>
      <br/>
      <h1>Un authorized</h1>
      <br/>
    </div>

  </div>
</ng-container>
