<app-file-preview *ngIf="filePreview" (closeWindow)="closeFilePreview()"></app-file-preview>

<slideover (closeWindow)="closeRequest()">
    <div class="page-inner-container">
        <form [formGroup]="requestForm" (submit)="onSubmit()">

            <input type="hidden" formControlName="userName" />
            <input type="hidden" formControlName="requestStatus">

            <div class="page-section">
                <h2 class="page-subtitle">Request Summary</h2>
                <table class="xn-table mat-elevation-z8 is-summary">
                    <tbody>
                        <tr>
                            <td>Initiated by</td>
                            <td>
                                <div class="u-ptb-16">
                                    <a class="u-profile" target="_blank" routerLink="/single-profile">
                                        <figure class="u-profile-photo"></figure>
                                        <span>James Lubinga</span>
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>arthur&#64;google.com</td>
                        </tr>
                        <tr>
                            <td>Phone Number</td>
                            <td>+256 777564805</td>
                        </tr>
                        <tr>
                            <td>Request Type</td>
                            <td>Update Visual Identification</td>
                        </tr>
                        <tr>
                            <td>Date Created</td>
                            <td>23 Aug 2020</td>
                        </tr>
                        <tr>
                            <td>Request Status</td>
                            <td>
                                <span class="status-label u-txt-bold {{requestStatus}}">
                                    <span *ngIf="requestStatus != 'showRejectForm'">{{requestStatus | titlecase}}</span>
                                    <span *ngIf="requestStatus == 'showRejectForm'"></span>
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="requestStatus == 'approved' || requestStatus == 'rejected'">
                            <td>{{requestStatus | titlecase}} by</td>
                            <td>Aeko Ongodia</td>
                        </tr>
                        <tr *ngIf="requestStatus == 'rejected'">
                            <td>Reject reason</td>
                            <td>{{rejectMessage.value}}</td>
                        </tr>
                        <tr *ngIf="requestStatus == 'approved' || requestStatus == 'rejected'">
                            <td>Date {{requestStatus}}</td>
                            <td>23 Aug 2020, GMT 10:00</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="page-section" *ngIf="requestStatus != 'approved'">
                <h2 class="page-subtitle">Visual Identification - <span>Old</span></h2>
                <table class="xn-table mat-elevation-z8 is-summary">
                    <tbody>
                        <tr>
                            <td>ID Type</td>
                            <td>Passport</td>
                        </tr>
                        <tr>
                            <td>ID Number</td>
                            <td>9AUGX2001</td>
                        </tr>
                        <tr>
                            <td>File</td>
                            <td>
                                <p>
                                    <button class="image-button" (click)="showFilePreview()">
                                        <img src="../../../assets/images/1711-1-National-ID-1-671x403.jpg" alt="">
                                    </button>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="page-section">
                <h2 class="page-subtitle u-txt-green">Visual Identification - New</h2>
                <table class="xn-table mat-elevation-z8 is-summary">
                    <tbody>
                        <tr>
                            <td>ID Type</td>
                            <td><span class="u-txt-bold">National ID</span></td>
                        </tr>
                        <tr>
                            <td>ID Number</td>
                            <td><span class="u-txt-bold">UAXA1212999A</span></td>
                        </tr>
                        <tr>
                            <td>File</td>
                            <td>
                                <p>
                                    <button class="image-button" (click)="showFilePreview()">
                                        <img src="../../../assets/images/kyrgiz.jpg" alt="">
                                    </button>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="request-alert" *ngIf="requestStatus == 'rejected'">
                <p>Reject message has been submitted.</p>
            </div>

            <div class="rejection-form xn-fadein-up" *ngIf="requestStatus == 'showRejectForm'">
                <mat-form-field appearance="outline">
                    <mat-label>Reason for rejection</mat-label>
                    <textarea autofocus matInput #message rows="3" formControlName="rejectMessage"></textarea>
                </mat-form-field>
                <div class="u-justify request-actions">
                    <button type="button" mat-button mat-flat-button color="primary" (click)="cancelReject()">
                        <span>Cancel</span>
                    </button>
                    <button type="button" mat-button mat-flat-button color="warn" (click)="approveReject()">
                        <span>Submit Reject</span>
                    </button>
                </div>
            </div>

            <div class="u-justify request-actions" *ngIf="requestStatus != 'rejected' && requestStatus != 'approved'">
                <button type="button" mat-button mat-flat-button color="warn" (click)="rejectRequest()"
                    [disabled]="requestStatus == 'showRejectForm'">
                    <span>Reject</span>
                    <mat-icon>thumb_down</mat-icon>
                </button>
                <button type="button" mat-button mat-flat-button color="primary" (click)="approveRequest()"
                    [disabled]="requestStatus == 'showRejectForm'">
                    <span>Approve</span>
                    <mat-icon>thumb_up</mat-icon>
                </button>
            </div>

        </form>
    </div>
</slideover>
