<ng-container *appSecAccess="['ROLE_SUPER_ADMIN']">
  <create-staff *ngIf="view == 'create-staff' || view == 'edit-staff'" (close)="closeEditView()" (closeReload)="closeEditViewReload()"
    (onComplete)="refreshStaff()" [staff]="selectedStaff"></create-staff>

    <div class="header-view">
      <h1 class="page-title">Staff</h1>
      <button mat-flat-button mat-button color="primary" (click)="view = 'create-staff'">
        <span>Create Staff</span>
      </button>

    </div>
   
  <br>
  <div class="xn-padding">
    <!-- <div class="mat-elevation-z8 xn-orion-table-container-staff"> -->
      <div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
      <table mat-table [dataSource]="dataSource" class="xn-table">
        <!-- Name -->
        <!-- <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <strong>{{ element.firstName + " " + element.lastName }}</strong>
          </td>
        </ng-container> -->

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Names</th>
          <td mat-cell *matCellDef="let element">
              <div class="u-profile">
                    <div class="user-display-icon">
                      <ng-container *ngIf="element.loadingAvatar; else avatarLoaded">
                        <div class="loader-0">
                        </div> 
                      </ng-container>
                      <ng-template #avatarLoaded>
                      <figure class="user-profile-photo" [ngStyle]="{'background-image': 'url(' + (element.avatarUrl) + ')'}"></figure>
                      </ng-template>
                      <span class="user_initials">{{ element.firstName ? (element.firstName[0] | titlecase) :
                          ''}}{{ element.lastName ? (element.lastName[0] | titlecase) : ''}}</span>
                  </div>
                  <a> <span class="user-name-label">{{ element.firstName | titlecase }}
                          {{ element.lastName | titlecase }}</span></a>
              </div>
          </td>
      </ng-container>

        <!-- Roles -->
        <!-- <ng-container matColumnDef="roles">
          <th mat-header-cell *matHeaderCellDef>Roles</th>
          <td mat-cell *matCellDef="let element">
            <span >{{ staffRoles }}</span>
          </td>
        </ng-container> -->
        <ng-container matColumnDef="roles">
          <th mat-header-cell *matHeaderCellDef>Roles</th>
          <td mat-cell *matCellDef="let element">
            <span *ngFor="let role of element.roles; let isLast = last">{{ role.authority }}{{ isLast ? "" : ", "
              }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="departments">
          <th mat-header-cell *matHeaderCellDef>Departments</th>
          <td mat-cell *matCellDef="let element">
            <span *ngFor="let department of element.departments; let isLast = last">{{ department.department }}{{ isLast
              ? "" : ", " }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="accountLocked">
          <th mat-header-cell *matHeaderCellDef>Account Lock Status</th>
          <td mat-cell *matCellDef="let element">
            <span
              class="{{element.accountLocked === false ?
               'status-label is-not-locked' :
               'status-label is-locked'}}">
              {{element.accountLocked === false ?
              'Active' :
              'Locked'}}
            </span>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let staff; columns: displayedColumns" (click)="editStaff(staff)"></tr>
      </table>
    </div>
    <div *ngIf="dataSource.resultsLength | async">
      <mat-paginator
      [length]="dataSource.resultsLength | async"
      [pageSize]="50"
      showFirstLastButtons
      [pageSizeOptions]="[10, 50, 100, 200]"
    ></mat-paginator>
  
    </div>
  
  </div>

  <div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
    <mat-spinner color="accent"></mat-spinner>
  </div>

</ng-container>

<ng-container *appSecAccess="!['ROLE_SUPER_ADMIN']">
  <div class="z-box">
    <div>
      <mat-spinner></mat-spinner>
      <br />
      <br />
      <h1>Un authorized</h1>
      <br />
    </div>

  </div>
</ng-container>