
<ng-container *appSecAccess="['ROLE_SUPER_ADMIN']">
<create-role
  *ngIf="view == 'create-role' || view == 'edit-role'"
  (close)="closeCreateRole()"
  (onComplete)="refreshRoles()"
  [role]="role"
></create-role>


<div class="header-view">
  <h1 class="page-title">Role</h1>
    <button
      mat-flat-button
      mat-button
      color="primary"
      (click)="view = 'create-role'"
    >
      <span>Create Role</span>
    </button>
</div>

<br>
<div class="xn-fadein-up xn-delay-2">
  <div class="mat-elevation-z8 xn-orion-table-container-role">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.authority }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let role; columns: displayedColumns"
        (click)="editRole(role)"
      ></tr>
    </table>
   
  </div>
  <div *ngIf="dataSource.resultsLength | async">
    <mat-paginator
    [length]="dataSource.resultsLength | async"
    [pageSize]="50"
    showFirstLastButtons
    [pageSizeOptions]="[10, 50, 100, 200]"
  ></mat-paginator>

  </div>

</div>

<div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
</ng-container>

<ng-container *appSecAccess="!['ROLE_SUPER_ADMIN']">
  <div class="z-box">
    <div>
      <mat-spinner></mat-spinner>
      <br/>
      <br/>
      <h1>Un authorized</h1>
      <br/>
    </div>

  </div>
</ng-container>
