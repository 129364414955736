import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {
  Form,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from "../../services";
import {User, UserRole} from "../../models";

@Component({
  selector: 'create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {

  // form
  constructor(private formBuilder: UntypedFormBuilder,
    private userService: UserService) {
  }

  @Output() closeCreateView = new EventEmitter();
  @Output() userId: string;
  @Input() userRole: UserRole;
  userForm: UntypedFormGroup;



  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      telephone: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      gender: [
        '',
        [
          Validators.required,
        ],
      ],
      role: ['', [Validators.required]],
    });
  }

  closeWindow() {
    this.closeCreateView.emit();
  }

  submitUserForm() {
    const confirmation = confirm('Are you sure you want to create this user?');
    if (confirmation && this.userForm.valid) {
      // do your submit logic here
      this.userService.saveUser(this.userForm.value).subscribe(resp => {
        alert('user created...');
      }, error => {
        alert('an error occured while saving user...');
      });
    }
  }

  deleteUserForm() {
    const confirmation = confirm('Are you sure you want to delete this user?');
  if (confirmation){
    this.userDelete(this.userId);
   }
  }

  userDelete(userId: string) {
    this.userService.deleteUser(userId)
      .subscribe(() => {},
        (error) => {return error;});
  }
}
