<slideover [zIndex]="2" (closeWindow)="close.emit()">
  <form>
    <div class="fields">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input type="text" formControlName="firstName" matInput />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Middle Name</mat-label>
          <input type="text" formControlName="middleName" matInput />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input type="text" formControlName="lastName" matInput />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender">
            <mat-option value="female">Female</mat-option>
            <mat-option value="male">Male</mat-option>
            <mat-option value="rather-not-say">Rather not say</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Email Address</mat-label>
          <input type="email" formControlName="emailAddress" matInput />
        </mat-form-field>
      </div>


    </div>
  </form>
</slideover>
