<create-access-level
  *ngIf="view == 'create-access-level' || view == 'edit-access-level'"
  (close)="view = ''"
></create-access-level>

<div class="page-header xn-fadein-up">
  <h1 class="page-title static">Access Levels</h1>

  <div class="page-actions">
    <button
      mat-flat-button
      mat-button
      color="primary"
      (click)="view = 'create-access-level'"
    >
      <span>Create Access Level</span>
    </button>
  </div>
</div>

<div class="xn-fadein-up xn-delay-2">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- User Count Column -->
      <ng-container matColumnDef="userCount">
        <th mat-header-cell *matHeaderCellDef>Users</th>
        <td mat-cell *matCellDef="let element">{{ element.userCount }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="editRole(row.id, row.name)"
      ></tr>
    </table>
  </div>
</div>
