import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  Form,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from '../../services';
import { User } from '../../models';

@Component({
  selector: 'app-single-user',
  templateUrl: './single-user.component.html',
  styleUrls: ['./single-user.component.scss'],
})
export class SingleUserComponent implements OnInit {

  @Input() userId: string;
  userDetails: User;
  isLoading = true;
  selectedUserRoles = [];


  // form
  constructor(private formBuilder: UntypedFormBuilder,
              private userService: UserService) { }
  @Output() closeUserView = new EventEmitter();

  view = 'view';

  message = '';
  userForm: UntypedFormGroup;

  closeWindow() {
    this.closeUserView.emit();
  }

  resetUserLogin() {
    const confirmation = confirm(
      'Are you sure you want to reset logins? Clicking \'OK\' will generate a new password and send it to the user'
    );

    if (confirmation) {
      alert('Login information reset and sent to \'arthur@google.com\'');
    }
  }

  deactiviateUser() {
    const confirmation = confirm(
      'Are you sure you want to deactivate this user?'
    );
    if (confirmation) {
      alert('User has been deactivated.');
      this.closeWindow();
    }
  }
  editUser() {
    this.view = 'edit';

    this.userDetails.roles.forEach(role => {
      this.selectedUserRoles.push(role.id);
    });


  }

  cancelEditUser() {
    this.view = 'view';
  }
  submitEditUser() {
    const confirmation = confirm('Are you sure you want to update this user?');

    if (confirmation && this.userForm.valid) {

      // do your submit logic here
      // this.userService.updateUser(this.userForm.value).subscribe(resp => {
      //   this.view = 'view';
      //   // this.submitUserForm();
      //   this.message = 'User updated';
      // });
      //

      setTimeout(() => {
        this.message = '';
      }, 3000);
    }
  }

  ngOnInit(): void {



    this.userForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      username: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      telephone: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      userRoles: [
        '',
        [Validators.required]],
    });

    // this.userService.getUserById(this.userId).subscribe(resp => {
    //   this.userDetails = resp;
    //   this.setDefaultValues(this.userDetails);
    //   this.isLoading = false;
    // }, error => {
    //   console.log('Error fetching user...');
    // });
  }
  setDefaultValues(userDetails: User) {
    this.userForm.setValue({
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      username: userDetails.username,
      telephone: userDetails.telephone,
      userRoles: userDetails.roles
    });
  }

  updateUserRoles(id: string, e) {
    const index = this.selectedUserRoles.findIndex(element => element === id);

    if (id) {
      if (index < 0) {
        this.selectedUserRoles.push(id);
      } else {
        // e.target.querySelector('.mat-checkbox-input').checked = false;
        e.target.closest('.mat-checkbox').querySelector('input').checked = false;
        this.selectedUserRoles.splice(index, 1);
      }
    }
  }

  setUserRoleStatus(id: string) {
    if (this.selectedUserRoles.find(element => element === id)) {
      return true;
    }
  }
}
