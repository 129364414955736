import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Prospect } from "../../models";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ProspectService } from "../../services/prospect.service";
import { catchError, finalize } from "rxjs/operators";

export class ProspectsDataSource implements DataSource<Prospect> {
  private prospectsSubject = new BehaviorSubject<Prospect[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private MTNResultsLengthSubject = new BehaviorSubject(0);
  private AirtelResultsLengthSubject = new BehaviorSubject(0);
  private OthersResultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();
  public MTNResultsLength = this.MTNResultsLengthSubject.asObservable();
  public AirtelResultsLength = this.AirtelResultsLengthSubject.asObservable();
  public OthersResultsLength = this.OthersResultsLengthSubject.asObservable();

  constructor(private prospectsService: ProspectService) { }

  connect(
    collectionViewer: CollectionViewer
  ): Observable<Prospect[] | ReadonlyArray<Prospect>> {
    return this.prospectsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.prospectsSubject.complete();
    this.loadingSubject.complete();
  }

  loadProspects(
    offset: any,
    max: any,
    sort: string,
    order: string,
    page: number,
    filter = ''
  ) {
    this.loadingSubject.next(true);
    this.prospectsService
      .getAll(offset, max, sort, order, page, filter)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((resp) => {
        this.prospectsSubject.next(resp.data);
        this.MTNResultsLengthSubject.next(resp.totalMtn);
        this.AirtelResultsLengthSubject.next(resp.totalAirtel);
        this.OthersResultsLengthSubject.next(resp.totalOthers);
        this.resultsLengthSubject.next(resp.totalCount);
      });
  }
}
