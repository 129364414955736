<mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input
    type="date"
    [name]="fieldName"
    [value]="value | date: 'yyyy-MM-dd'"
    [disabled]="isDisabled"
    (input)="onChange($event)"
    (blur)="touched()"
    matInput />
</mat-form-field>
