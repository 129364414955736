import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MassSenderService} from '@orion/services/mass-sender.service';
import {UtilitiesService} from '@orion/utilities/utilities.service';

@Component({
  selector: 'app-bulk-sms',
  templateUrl: './bulk-sms.component.html',
  styleUrls: ['./bulk-sms.component.scss']
})
export class BulkSmsComponent implements OnInit {
  form: UntypedFormGroup;
  isError = false;
  message!: string;
  fileToUpload: File = null;
  @ViewChild('myInput')
  myInputVariable: ElementRef;


  constructor(private massSenderService: MassSenderService,
              private formBuilder: UntypedFormBuilder,
              private utilities: UtilitiesService
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      messageBody: ['', [Validators.required, ]],
    });
  }

  uploadExcelFileAxeSMS() {
    if (this.fileToUpload === null) {
      this.isError = true;
      this.message = `Please upload the Excel File!`;
    }
    this.isError = false;
    const formData = new FormData();
    formData.append('file', this.fileToUpload);
    formData.append('messageBody', this.form.get('messageBody').value);
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.massSenderService
          .uploadExcelFileAxeBulkSmsList(formData)
          .subscribe(
            (response) => {
              if (response.code === 200) {
                this.utilities.successAlert(`${response.msg}`);
                this.isError = false;
                this.message = `SMS Successfully Sent!!`;
                this.ngOnInit();
              }
              else {
                this.utilities.errorAlert('Failed to send sms, Please check the Excel file template!!');
                this.isError = true;
                this.message = `Failed to send sms, Please check the Excel file template!!`;
                this.ngOnInit();
              }
            },
            (error) => {
              this.message = `${error}!`;
              this.message = `${error.msg}!`;
              this.isError = true;
              this.ngOnInit();
            }
          );
      }
    });
  }

  onFileChange(e) {
    if (e.target.files.length > 0) {
      this.fileToUpload = e.target.files[0];
    } else {
      this.fileToUpload = null;
    }
  }

  clearForm() {
    this.form.reset();
  }
}
