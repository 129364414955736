import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  private apiUrl = `${environment.serverUrl}/api`;

  constructor(private http: HttpClient) { }

  list(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get<any>(`${this.apiUrl}/department`, { params });
  }

  getDepartments(): Observable<any> {
    return this.http.get(`${this.apiUrl}/department`);
  }

  saveDepartment(formData): Observable<any> {
    return this.http.post(`${this.apiUrl}/department/`, formData);
  }


  updateDepartment(departmentId: string, formData: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/department/${departmentId}`, formData);
  }

  deleteDepartment(departmentId): Observable<any> {
    return this.http.delete(`${this.apiUrl}/department/${departmentId}`);
  }
}
