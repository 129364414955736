import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { AdvisorUser, AdvisorUserBandSummary } from "@orion/models/advisor-users";
import { ProfileService } from "@orion/services/profile.service";

export class AdvisorUsersDashboardSource implements DataSource<AdvisorUser> {
  private advisorUsersSubject = new BehaviorSubject<AdvisorUser[]>([]);
  private advisorUsersBandSummarySubject = new BehaviorSubject<AdvisorUserBandSummary[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public advisorBandSummary = this.advisorUsersBandSummarySubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();
  public isloadingData: boolean;

  constructor(private profilesService: ProfileService) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<AdvisorUser[] | ReadonlyArray<AdvisorUser>> {
    return this.advisorUsersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.advisorUsersSubject.complete();
    this.loadingSubject.complete();
  }

  loadAdvisoryUsers(
    offset: any,
    max: any,
    sort: string,
    order: string,
    page: number,
    filter = '',
    id: string) {
    this.isloadingData = true;
    this.loadingSubject.next(true);
    this.profilesService
      .getAdvisorById(offset, max, sort, order, page, filter, id)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((resp) => {
        this.advisorUsersSubject.next(resp.data);
        this.advisorUsersBandSummarySubject.next(resp.bandSummary);
        this.resultsLengthSubject.next(resp.totalCount);
        this.isloadingData = false;
      });
  }
}
