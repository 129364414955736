<slideover (closeWindow)="closeWindow()">
  <div class="page-inner-container">
    <div class="page-section">
      <h2 class="page-subtitle huge">Create User</h2>
      <form class="edit-user-form" [formGroup]="userForm">
        <div class="fields">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>First Name</mat-label>
              <input type="text" formControlName="firstName" matInput />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Last Name</mat-label>
              <input type="text" formControlName="lastName" matInput />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Email Address</mat-label>
              <input type="email" formControlName="email" matInput />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Phone number</mat-label>
              <input type="text" formControlName="telephone" matInput />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Role</mat-label>
              <mat-select formControlName="role">
                <mat-option value="ROLE_SUPER">Super Admin</mat-option>
                <mat-option value="ROLE_MANAGER">Manager</mat-option>
                <mat-option value="ROLE_STAFF">Staff</mat-option>
                <mat-option value="ROLE_EXPORT_DATA">Data Exporter</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Gender</mat-label>
              <mat-select formControlName="gender">
                <mat-option value="female">Female</mat-option>
                <mat-option value="male">Male</mat-option>
                <mat-option value="rather_not_say">Rather not say</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="u-justify actions">


          <button
            type="button"
            [disabled]="!userForm.valid"
            mat-button
            mat-flat-button
            color="primary"
            (click)="submitUserForm()"
          >
            Create User
          </button>
        </div>
      </form>
    </div>
  </div>
</slideover>
