import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LctrDashboardService {

  apiUrl = `${environment.serverUrl}/api/v1/users`;

  constructor(private http: HttpClient) {
  }

  getAllTransactions(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get<any>(`${this.apiUrl}/lctr-dashboard`, {params});
  }

  downloadGoAMLXMLFile(filters: string): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('filter', filters);
    return this.http.get(`${this.apiUrl}/export-goAML-report`, {params, responseType: 'text'});
  }
}
