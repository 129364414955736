<app-single-account
  *ngIf="singleAccountView"
  (closeWindow)="closeAccountDetails()"
  [id]="accountId"
></app-single-account>

  <div class="u-justify nb-5">
    <h1 class="page-title">Institutions</h1>
      <form class="page-filters" [formGroup]="filtersForm">
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Account Type</mat-label>
            <mat-select formControlName="accountType"
                        (selectionChange)="applyFilter($event)">
              <mat-option value="" class="uppercase">All</mat-option>
              <mat-option value="POOLED" class="uppercase">Pooled</mat-option>
              <mat-option value="SPONSOR" class="uppercase">Sponsor</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Institution Type</mat-label>
            <mat-select formControlName="accountCategory"
                        (selectionChange)="applyFilter($event)">
              <mat-option value="GENERAL" selected>All</mat-option>
              <mat-option value="SACCO">SACCO</mat-option>
              <mat-option value="RETIREMENTS_BENEFIT_SCHEME">Retirement Benefits Scheme</mat-option>
              <mat-option value="INVESTMENT_CLUBS">Investment Club</mat-option>
              <mat-option value="FAMILY">Family</mat-option>
              <mat-option value="CORPORATE">Corporate</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Status</mat-label>
            <mat-select formControlName="accountStatus"
                        (selectionChange)="applyFilter($event)">
              <mat-option value="all">All</mat-option>
              <mat-option value="funded">Funded</mat-option>
              <mat-option value="not-funded">Not Funded</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="page-action">
          <mat-form-field appearance="fill">
            <mat-label>Created</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                matInput
                placeholder="Start date"
                formControlName="startDate"
              />
              <input
                matEndDate
                matInput
                placeholder="End date"
                formControlName="endDate"
                (dateChange)="applyDateRangeFilter($event)"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </form>
  </div>
  <div class="">

  <div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
    <table mat-table [dataSource]="dataSource"
           matSort
           matSortActive="dateCreated"
           matSortDisableClear
           matSortDirection="desc">
      <ng-container matColumnDef="institutionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Institution</th>
        <td mat-cell *matCellDef="let row">
        <span [ngClass]="!row.institutionName ? 'status-label no-name' : ''">
             {{ row.institutionName ? row.institutionName : noInstitutionDetails }}
        </span>


        </td>
      </ng-container>

      <ng-container matColumnDef="accountNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Account Number
        </th>
        <td mat-cell *matCellDef="let row">
          <a>{{ row.accountNumber }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="accountType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Type</th>
        <td mat-cell *matCellDef="let row">{{ row.accountType }}</td>
      </ng-container>

      <ng-container matColumnDef="accountCategory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Institution Type</th>
        <td mat-cell *matCellDef="let row">{{ row.accountCategory |  replace: '_' : ' '}}</td>
      </ng-container>

      <ng-container matColumnDef="representative">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Representative
        </th>
        <td mat-cell *matCellDef="let row">{{ row.accountName }}</td>
      </ng-container>

      <!-- funding Column -->
      <ng-container matColumnDef="funding">
        <th mat-header-cell *matHeaderCellDef>FUNDING</th>
        <td mat-cell *matCellDef="let row">
        <span
          class="status-label not-funded"
          *ngIf="row?.netInvested < 1"
        >not funded</span
        >
          <span class="status-label funded" *ngIf="row?.netInvested > 1">
          funded</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="sponsorCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sponsor Code</th>
        <td mat-cell *matCellDef="let row">{{ row.sponsorCode }}</td>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let row">{{ row.dateCreated | date }} <span class="join-time">- {{ row.dateCreated | date:'shortTime' }}</span></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="showAccountDetails(row.id)"
      ></tr>
    </table>
    <mat-paginator
    [length]="dataSource.resultsLength | async"
    [pageSize]="50"
    showFirstLastButtons
    [pageSizeOptions]="[10, 50, 100, 200]"
></mat-paginator>

<!--    <mat-paginator [pageSizeOptions]="[25, 100]"></mat-paginator>-->

  </div>

  </div>



<div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
