<div class="spinner-container bkg-blue fill-wh" *ngIf="isLoading">
    <mat-spinner color="accent" [diameter]="60"></mat-spinner>
</div>

<div class="wrap" *ngIf="!isLoading">
    <div class="form">
        <figure class="logo"></figure>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="error-bg" *ngIf="isError">
            <span class="error-message">{{errorMessage}}</span>
            </div>
            <div class="login-form-inputs">
                <!-- Country selection -->
                <div class="form-row login-input">
                    <div class="icon-view">
                        <div class="icon-bg">
                            <img src='../../../assets/icons/{{selectedCountryCode}}.png' class="selected-country-icon" />
                        </div>
                    </div>
                    <mat-form-field class="login-input-icon" appearance="">
                        <mat-label>Country</mat-label>
                        <mat-select matNativeControl required class="input-sections" formControlName="country"
                            (selectionChange)="selectChangeHandler($event)">
                            <mat-option *ngFor="let country of countries" [value]="country">
                                <span class="country-list">
                                    <img src='../../../assets/icons/{{country.countryCode}}.png' class="country-icon" />
                                    {{ country.viewValue }}
                                </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <!-- Username input-->
                <div class="form-row login-input">
                    <div class="icon-view">
                        <div class="icon-bg">
                        <mat-icon color="">perm_identity</mat-icon>
                        </div>
                    </div>
                    <mat-form-field class="login-input-icon" appearance="">
                        <mat-label>Username</mat-label>
                        <input matInput formControlName="username" class="input-sections">
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <!-- Password input -->
                <div class="form-row login-input">
                    <div class="icon-view">
                        <div class="icon-bg">
                            <mat-icon color="">lock</mat-icon>
                        </div>
                    </div>
                    <mat-form-field class="login-input-icon" appearance="">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="password" class="input-sections">
                    </mat-form-field>
                </div>
            </div>
            <div class="form-footer">
                <button mat-flat-button color="primary">Login</button>
            </div>
        </form>
    </div>
</div>