import { KeycloakService } from "keycloak-angular";
import { environment } from "../../environments/environment.prod";

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak
          .init({
            config: {
              url: environment.keycloak.issuer,
              realm: environment.keycloak.realm,
              clientId: environment.keycloak.clientId
            },
            initOptions: {
              onLoad: 'check-sso',
              redirectUri: environment.keycloak.redirectUrl,
              pkceMethod: 'S256'
            },
            bearerExcludedUrls: [],
            // enableBearerInterceptor: true,
            // bearerPrefix: 'Bearer'
          })
          .then((authenticated) => {
            resolve(authenticated);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
}
