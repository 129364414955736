import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Job} from '@orion/models/job';
import {Account} from "@orion/models";


@Injectable({
  providedIn: 'root'
})
export class JobsService {

  private apiUrl = `${environment.serverUrl}/api/v1/task`;

  constructor(private http: HttpClient) { }

  getAll(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams =  new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get<any>(`${this.apiUrl}`, {params});
  }

  searchTask(filter = ''): Observable<Job[]> {
    return this.http.get<any>(`${this.apiUrl}/profiles/search-task/${filter}`);
  }

  getAllTasks(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}`);
  }

  search(filter = ''): Observable<Job[]> {
    return this.http.get<any>(`${this.apiUrl}/search/${filter}`);
  }

  createJob(formData): Observable<any> {
    return this.http.post(`${this.apiUrl}`, formData);
  }

  updateJob(taskId: string, formData: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/${taskId}`, formData);
  }

  scheduleJob(taskId: string): Observable<any>{
    return this.http.get(`${this.apiUrl}/schedule/${taskId}`);
  }

  unscheduleJob(taskId: string): Observable<any>{
    return this.http.get(`${this.apiUrl}/unschedule/${taskId}`);
  }

  startJobByName(taskName: string): Observable<any>{
    return this.http.get(`${this.apiUrl}/run-name/${taskName}`);
  }

  startJob(taskId: string): Observable<any>{
    return this.http.get(`${this.apiUrl}/run-id/${taskId}`);
  }

  stopJob(taskId: string): Observable<any>{
    return this.http.get(`${this.apiUrl}/stop/${taskId}`);
  }

  deleteJob(taskId): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${taskId}`);
  }
}
