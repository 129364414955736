import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {Role} from '../../models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {RoleService} from "../../services/role.service";

export class RolesDataSource implements DataSource<Role> {

  private rolesSubject = new BehaviorSubject<Role[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private roleService: RoleService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Role[] | ReadonlyArray<Role>> {
    return this.rolesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.rolesSubject.complete();
    this.loadingSubject.complete();
  }

  loadRoles(offset: any, max: any, sort: string, order: string, page: number, filter = '') {
    this.loadingSubject.next(true);
    this.roleService.list(offset, max, sort, order, page, filter).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(resp => {
      this.rolesSubject.next(resp.data);
      this.resultsLengthSubject.next(resp.totalCount);
    });
  }
}
