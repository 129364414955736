<div class="page-header xn-fadein-up xn-margin-top-negative76">
  <form class="page-actions" [formGroup]="filtersForm">
    <div class="page-action">
      <mat-form-field appearance="fill">
      <mat-label class="xn-label">
        Source</mat-label>
        <mat-select  formControlName="source"
                 (selectionChange)="applyFilter($event, 'source')"
        >
          <mat-option value="WEB">Web</mat-option>
          <mat-option value="APP_IOS">App-iOS</mat-option>
          <mat-option value="APP_ANDROID">App-android</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="page-action">
      <mat-form-field appearance="fill">
      <mat-label class="xn-label">
        Registration
      </mat-label>
        <mat-select formControlName="registration"
                    (selectionChange)="applyFilter($event, 'registration')">
          <mat-option value="complete">Complete</mat-option>
          <mat-option value="not_complete">Not Complete</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="page-action">
      <mat-form-field appearance="fill">

      <mat-label class="xn-label">
        Profiling</mat-label>
        <mat-select formControlName="profiling"
                    (selectionChange)="applyFilter($event, 'profiling')">
          <mat-option value="complete">Complete</mat-option>
          <mat-option value="not_complete">Not Complete</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="page-action">
      <mat-form-field appearance="fill">

      <mat-label class="xn-label">
        Verification</mat-label>
        <mat-select formControlName="verification"
                    (selectionChange)="applyFilter($event, 'verification')">
          <mat-option value="not_started">Not Started</mat-option>
          <mat-option value="in_progress">In Progress</mat-option>
          <mat-option value="submitted">Submitted</mat-option>
          <mat-option value="verified">Verified</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="page-action">
      <mat-form-field appearance="fill">

      <mat-label class="xn-label">
        Funding</mat-label>
        <mat-select formControlName="funding"
                    (selectionChange)="applyFilter($event, 'funding')">
          <mat-option value="funded">Funded</mat-option>
          <mat-option value="not_funded">Not Funded</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="page-action">
      <mat-form-field appearance="fill">
      <mat-label class="xn-label">
       Filter
      </mat-label>
            <input matInput [matDatepicker]="picker" formControlName="dateFilter"
                   (dateChange)="applyDateFilter($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

      </mat-form-field>
    </div>
  </form>
  <div class="page-actions">
    <div class="page-action">
    </div>
    <div class="page-action">
    </div>
  </div>

</div>
<mat-grid-list cols="5" gutterSize="20" rowHeight="150px">
<mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <div>
        <div>
          <div>
            <h3 class="mat-card-title">All</h3>
          </div>

          <div>
            <h1 class="mat-card-title-2">{{cardResult?.all ?
              cardResult?.all.toLocaleString() :
              0}}</h1>
          </div>
        </div>
      </div>

      <div>
        <div>
            <div class="cont" align="center">
              <mat-icon>group</mat-icon>
            </div>
        </div>
      </div>



    </mat-card>
</mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <div>
        <div>
          <div>
            <h3 class="mat-card-title">Registered</h3>
          </div>

          <div>
            <h1 class="mat-card-title-2">{{cardResult?.registered ?
              cardResult?.registered.toLocaleString() :
              0}}</h1>
          </div>
          <div>
            <p class="xn-grey">
              {{percentageChange?.registered ?
              percentageChange?.registered :
              0}}%</p>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div class="cont" align="center">
            <mat-icon>app_registration</mat-icon>
          </div>
        </div>
      </div>

    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <div>
        <div>
          <div>
            <h3 class="mat-card-title">Profiled</h3>
          </div>

          <div>
            <h1 class="mat-card-title-2">{{cardResult?.profiled ?
              cardResult?.profiled.toLocaleString() :
              0}}</h1>
          </div>
          <div>
            <p class="xn-grey">{{percentageChange?.profiled ?
              percentageChange?.profiled :
              0}}%</p>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div class="cont" align="center">
            <mat-icon>contact_page</mat-icon>
          </div>
        </div>
      </div>



    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <div>
        <div>
          <div>
            <h3 class="mat-card-title">Verified</h3>
          </div>

          <div>
            <h1 class="mat-card-title-2">{{cardResult?.verified ?
              cardResult?.verified.toLocaleString() :
              0}}</h1>
          </div>
          <div>
            <p class="xn-grey">{{percentageChange?.verified ?
              percentageChange?.verified :
              0}}%</p>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div class="cont" align="center">
            <mat-icon>verified</mat-icon>
          </div>
        </div>
      </div>



    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="xn-mat-card xn-card-height">
      <div>
        <div>
          <div>
            <h3 class="mat-card-title">Funded</h3>
          </div>

          <div>
            <h1 class="mat-card-title-2">{{cardResult?.funded ? cardResult?.funded.toLocaleString() : 0}}</h1>
          </div>
          <div>
            <p class="xn-grey">{{percentageChange?.funded ? percentageChange?.funded : 0}}%</p>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div class="cont" align="center">
            <mat-icon>money</mat-icon>
          </div>
        </div>
      </div>



    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

<br/>
<div class="page-header xn-fadein-up">
  <div class="page-actions">

  </div>
</div>
<mat-card>
  <div class="xn-padding">
    <div class="u-justify is-table-header">
      <h2 class="page-subtitle huge">
        Onboarded
      </h2>
      <div>

        <div class="page-action">
          <button
            mat-button
            mat-raised-button
            color="primary"
            [matMenuTriggerFor]="menu"
          >Send
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="sendNotifications('EMAIL')">Email</button>
            <button mat-menu-item (click)="sendNotifications('SMS')">SMS</button>
          </mat-menu>
        </div>
      </div>
    </div>
<div>
  <table mat-table [dataSource]="dataSource" style="margin-top: -20px;" class="mat-elevation-z0">

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element;let i = index"> {{i + 1}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
    </ng-container>

    <!-- Source Column -->
    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef> Source </th>
      <td mat-cell *matCellDef="let element"> {{element.source.name}} </td>
    </ng-container>

    <!-- Registration Column -->
    <ng-container matColumnDef="registration">
      <th mat-header-cell *matHeaderCellDef> Registration </th>
      <td mat-cell *matCellDef="let element">
         <span class="status-label {{element.registration.name === 'Not Complete' ? 'incomplete' : 'complete'}}">
          {{element.registration.name}}
         </span>
      </td>
    </ng-container>

    <!-- Profiling Column -->
    <ng-container matColumnDef="profiling">
      <th mat-header-cell *matHeaderCellDef> Profiling </th>
      <td mat-cell *matCellDef="let element">
          <span class="status-label  {{element.profiling.name === 'Not Complete' ? 'incomplete' : 'complete'}}">
          {{element.profiling.name}}
          </span>
      </td>
    </ng-container>

    <!-- Verification Column -->
    <ng-container matColumnDef="verification">
      <th mat-header-cell *matHeaderCellDef> Verification </th>
      <td mat-cell *matCellDef="let element">
          <span class="status-label {{element.verification.name === 'Not Started' ? 'not-started' :
                                      element.verification.name === 'In Progress' ? 'in-progress' :
                                      element.verification.name === 'Submitted' ? 'submitted' :
                                      'verified'}}">
            {{element.verification.name}} </span></td>
    </ng-container>

    <!-- Funding Column -->
    <ng-container matColumnDef="funding">
      <th mat-header-cell *matHeaderCellDef> Funding</th>
      <td mat-cell *matCellDef="let element"> <span class="status-label  {{element.funding.name === 'Funded' ? 'funded' : 'not-funded'}}">{{element.funding.name}}</span> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <br/>
  <div class="u-justify">
    <div>
      <div class="page-action">
        <button
          class="xn-right-margin-7"
          mat-raised-button
          mat-button
          (click)="exportCSV()"
        >
          <mat-icon>download</mat-icon> Export: CSV &nbsp;&nbsp;&nbsp;
        </button>
      </div>

    </div>
    <div>
      <mat-paginator
        [length]="dataSource.resultsLength | async"
        [pageSize]="50"
        showFirstLastButtons
        [pageSizeOptions]="[10, 50, 100, 200]"
      >
      </mat-paginator>
    </div>
    <div align="center">
      <div class="spinner-container" *ngIf="dataSource.loading$ | async">
        <mat-spinner color="accent"></mat-spinner>
      </div>
    </div>


  </div>
</div>
  </div>
</mat-card>
