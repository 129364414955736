import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {XnTabsComponent} from "@orion/shared/xn-tabs/xn-tabs.component";

@Component({
  selector: 'xn-tab',
  templateUrl: './xn-tab.component.html',
  styleUrls: ['./xn-tab.component.scss']
})
export class XnTabComponent implements OnInit {
  @Input() label: string = '';
  @Input() byline: string = '';
  @Input() enabled: boolean = true;
  @Output() onClick = new EventEmitter();
  active: boolean = false;
  constructor(tabs: XnTabsComponent) {
    tabs.addTab(this);
  }

  ngOnInit(): void {
  }

}
