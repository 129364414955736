<slideover [zIndex]="2" (closeWindow)="close.emit()">
  <div class="page-section">
    <h2 class="page-subtitle huge">Edit Income Source</h2>
    <div class="error" *ngIf="isError">
      <p>{{message}}</p>
    </div>

    <form class="edit-user-form"
          (ngSubmit)="submitEditForm()"
          [formGroup]="editForm">
      <div class="fields">

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Source of Income</mat-label>
            <mat-select formControlName="source">
              <mat-option value="employment">Employment</mat-option>
              <mat-option value="business">Business</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <app-text-input
            [parentForm]="editForm"
            label="Name"
            fieldName="employer"
            [ngModel]="profileInformation.incomeSources[0].employer"
            formControlName="employer">
          </app-text-input>
        </div>

        <div>
          <app-number-input
            [parentForm]="editForm"
            label="Monthly Income"
            fieldName="monthlyIncome"
            [ngModel]="profileInformation.incomeSources[0].monthlyIncome"
            formControlName="monthlyIncome">
          </app-number-input>
        </div>

        <div>
          <app-number-input
            [parentForm]="editForm"
            label="Expected Monthly Income"
            fieldName="expectedMonthlyIncome"
            [ngModel]="profileInformation.incomeSources[0].expectedMonthlyIncome"
            formControlName="expectedMonthlyIncome">
          </app-number-input>
        </div>

      <div class="u-justify actions">
        <button
          type="submit"
          mat-button
          mat-flat-button
          color="primary">
          Update
        </button>
      </div>
        </div>
    </form>
  </div>
</slideover>

