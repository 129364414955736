import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {AuthenticationService} from '../services';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import {environment} from "../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard extends KeycloakAuthGuard {

  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }


  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: environment.keycloak.redirectUrl,
      });
    }

    return this.authenticated;
  }

}
