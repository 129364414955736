import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {User} from "@orion/models";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatSort} from "@angular/material/sort";
import {AuthenticationService} from "@orion/services";
import {merge} from "rxjs";
import {tap} from "rxjs/operators";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {UserAdvisor} from "@orion/models/user_advisor";
import {DashboardDataSource} from "@orion/pages/dashboard/dashboard-data-source";
import {DashboardService} from "@orion/services/dashboard.service";
import {ProfileService} from "@orion/services/profile.service";
import {environment} from "../../../environments/environment";
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";
import { UtilitiesService } from '@orion/utilities/utilities.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  orionUser: User;
  filtersForm: UntypedFormGroup;
  cardResult: any;
  userAdvisors: UserAdvisor[] = [];
  resultsLength = 0;
  isLoading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: DashboardDataSource;
  showProfile = false;
  profileId: string;
  tenantCurrency: string;
  // thumbNailBaseUrl = `${environment.serverUrl}/api/v1/guest/passportphoto/`;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private dashboardService: DashboardService,
    public utilitiesService: UtilitiesService,
    private profileService: ProfileService) { }

  displayedColumns: string[] = [
    'position',
    'dateCreated',
    'name',
    'advisor',
    'numberOfAccounts',
    'profileTotalAUM'
  ];
  advisors: any;


  ngOnInit(): void {
    this.tenantCurrency = this.utilitiesService.xnCurrencyLabel(localStorage.getItem('tenantId'))
    this.authenticationService.orionUser.subscribe(x =>{ this.orionUser = x});
    this.filtersForm = this.formBuilder.group({
      advisor: ['', Validators.required],
      fundedStatus: ['', Validators.required],
      dateFilter: ['', Validators.required],
    });

    this.dataSource = new DashboardDataSource(this.dashboardService, this.profileService);
    this.dataSource.loadDashboardData(0, 50, 'dateCreated', 'desc', 0, this.filtersForm.value);

    this.profileService.getAdvisors().subscribe((advisors) => {
      this.advisors = advisors;
    });
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadDashboardPage()))
      .subscribe();
  }

  loadDashboardPage() {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadDashboardData(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.filtersForm.value
    );
  }

  applyAdvisorFilter(event: MatSelectChange) {
    this.paginator.pageIndex = 0;
    this.dataSource.loadDashboardData(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      this.filtersForm.value
    );
  }

  applyFundedStatusFilter(event: MatSelectChange) {
    this.paginator.pageIndex = 0;
    this.dataSource.loadDashboardData(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      this.filtersForm.value
    );
  }
  applySourceStatusFilter(event: MatSelectChange) {
    this.paginator.pageIndex = 0;
    this.dataSource.loadDashboardData(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      this.filtersForm.value
    );
  }

  applyDateFilter(event: MatDatepickerInputEvent<unknown, unknown>) {
    this.paginator.pageIndex = 0;
    this.dataSource.loadDashboardData(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      this.filtersForm.value
    );
  }

  showProfileDetail(profileId: string) {
    this.showProfile = true;
    this.profileId = profileId;
  }

  hideProfile() {
    this.showProfile = false;
    this.profileId = null;
  }
}
