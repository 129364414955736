import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ProfileService } from '@orion/services/profile.service';
import { UtilitiesService } from '@orion/utilities/utilities.service';
import { User, UserNote } from '@orion/models';
import { AuthenticationService } from '@orion/services';

@Component({
  selector: 'app-view-advisor-note',
  templateUrl: './view-advisor-note.component.html',
  styleUrls: ['./view-advisor-note.component.scss'],
})
export class ViewAdvisorNoteComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    private utilities: UtilitiesService,
    private authenticationService: AuthenticationService) { }


  @Output() close = new EventEmitter();
  commentForm: UntypedFormGroup;
  editCommentForm: UntypedFormGroup;
  editNoteForm: UntypedFormGroup;
  @Input() userId: any;
  @Input() userNote: UserNote;
  orionUser: User;
  staffId: string;
  selected: string;

  editButtonVisible: boolean;
  editButtonVisibleComment: boolean;

  isEditComment: boolean;
  editCommentId: string;
  isEditNote: boolean
  noteId: string;
  showComments = false;

  ngOnInit(): void {
    this.showEditButton('');
    this.authenticationService.orionUser.subscribe(x => { this.orionUser = x; });
    this.editNoteForm = this.formBuilder.group({
      editSubject: ['', [Validators.required]],
      editNote: ['', [Validators.required]],
    });
    this.editCommentForm = this.formBuilder.group({
      editComment: ['', [Validators.required]],
    });
    this.commentForm = this.formBuilder.group({
      comment: ['', [Validators.required]],
    });

  }

  closeForm() {
    this.close.emit();
  }

  showEditNote(subject, note, noteId) {
    this.noteId = noteId;
    this.selected = subject;
    this.editNoteForm.get('editSubject').setValue(subject);
    this.editNoteForm.get('editNote').setValue(note);
    this.isEditNote = true;
  }

  hideEditNote() {
    this.noteId = '';
    this.isEditNote = false;
  }

  showEditInput(replyId, note) {
    this.editCommentId = replyId
    this.editCommentForm.get('editComment').setValue(note);
    this.isEditComment = true;
  }
  hideEditInput() {
    this.editCommentId = '';
    this.isEditComment = false;
  }

  showEditButton(noteDate) {
    let date = new Date()
    let milliSeconds = Date.parse(date.toString());
    let newDate = new Date(milliSeconds - (5 * 60 * 1000))
    if ((Date.parse(this.userNote.lastEditedOn ? this.userNote.lastEditedOn : this.userNote.postedOnDate) > Date.parse(newDate.toString()))
      || (Date.parse(noteDate) > Date.parse(newDate.toString()))
    ) {
      if (noteDate != '') {
        this.editButtonVisibleComment = true;
      } else {
        this.editButtonVisible = true;
      }
    } else {
      this.editButtonVisibleComment = false;
      this.editButtonVisible = false;
    }
  }

  submitComment() {
    let comment = this.commentForm.value;
    const stateComment = {
      id: '',
      staffId: '',
      staff: this.orionUser.lastName + ' ' + this.orionUser.firstName,
      initials: this.orionUser.firstName[0] + this.orionUser.lastName[0],
      user: this.userId,
      userId: '',
      subject: this.userNote.subject,
      parentId: this.userNote.id,
      note: comment.comment,
      postedBy: '',
      postedOn: 'now',
      lastEditedBy: null,
      lastEditedOn: null,
    }
    const noteComment = {
      user: this.userId,
      staff: this.orionUser.id,
      parentId: this.userNote.id,
      subject: this.userNote.subject,
      note: comment.comment
    }
    if (this.userNote.replies.length == 0) {
      this.userNote.replies.length != 0;
    }

    this.userNote.replies.push(stateComment)
    this.commentForm.get('comment').setValue('');
    this.profileService.saveUserNote(noteComment)
      .subscribe((response) => {
        if (response.code == 200) {
          // this.closeForm();
        }
      });

    // this.utilities.confirm4Alert(`Are you sure you want to comment on this note?`, `Please be aware that once a comment is added, it cannot be removed.`).then((result) => {
    //   if (result.isDismissed) {
    //   }
    //   else if (result.isConfirmed) {
    //     this.profileService.saveUserNote(noteComment)
    //       .subscribe((response) => {
    //         if (response.code == 200) {
    //           this.utilities.success2Alert(`Comment added successfully!`).then((result) => {
    //             if (result.isConfirmed) {
    //               this.closeForm();
    //             }
    //           });
    //         } else {
    //           this.utilities.error2Alert(`Failed to add comment!`).then((result) => {
    //             if (result.isConfirmed) {
    //             }
    //           });
    //         }
    //       },
    //         error => alert(`Something went wrong: ${error}`));
    //   }
    // })

  }

  editComment(noteId) {
    let editComment = this.editCommentForm.value;
    let editNote = this.editNoteForm.value;
    const noteComment = {
      subject: editNote.editSubject ? editNote.editSubject : '',
      note: editComment.editComment ? editComment.editComment : editNote.editNote
    }

    this.utilities.confirm4Alert(`Are you sure you want to update this ${editNote.editSubject ? 'note' : 'comment'}?`, `Please be aware that once a ${editNote.editSubject ? 'note' : 'comment'} is updated, it cannot be removed.`).then((result) => {
      if (result.isDismissed) {
      }
      else if (result.isConfirmed) {
        this.profileService.editUserComment(noteComment, noteId)
          .subscribe((response) => {
            if (response.code == 200) {
              this.utilities.success2Alert(`${editNote.editSubject ? 'Note' : 'Comment'} updated successfully!`).then((result) => {
                if (result.isConfirmed) {
                  this.closeForm();
                }
              });
            } else {
              this.utilities.error2Alert(`Failed to update ${editNote.editSubject ? 'note' : 'comment'}!`).then((result) => {
                if (result.isConfirmed) {
                }
              });
            }
          },
            error => alert(`Something went wrong: ${error}`));
      }
    })
  }

}
