<slideover [zIndex]="2" (closeWindow)="close.emit()">
  <div class="page-inner-container">

    <div class="page-section">
      <h2 class="page-subtitle huge">Reject Account Merge</h2>
      <div class="error" *ngIf="isError">
        <p>{{message}}</p>
      </div>
      <form class="edit-user-form"
            (ngSubmit)="submitRejection('REJECTED')"
            [formGroup]="rejectForm">
        <div>
          <div>
            <app-text-input
              [parentForm]="rejectForm"
              label="Reason for rejection"
              fieldName="rejectionReason"
              formControlName="rejectionReason">
            </app-text-input>
          </div>
          <div class="u-justify actions">
            <button
              type="submit"
              mat-button
              mat-flat-button
              color="primary">
              Submit
            </button>
          </div>
        </div>
      </form>

    </div>
  </div>
</slideover>
