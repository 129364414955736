import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Account } from '../../models/account';
import { AccountService } from '../../services/account.service';
import { Router } from '@angular/router';
import { InstitutionDataSource } from './institution-data-source';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-institutions',
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.scss'],
})
export class InstitutionsComponent implements OnInit, AfterViewInit {
  @Input() isSearch: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private utilitiesService: UtilitiesService
  ) {
  }

  displayedColumns: string[] = [
    'institutionName',
    'accountNumber',
    'accountType',
    'accountCategory',
    'representative',
    'funding',
    'dateCreated'
  ];
  accounts: Account[] = [];
  resultsLength = 0;
  isLoading = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
   @ViewChild(MatSort) sort: MatSort;
  // filters
  filtersForm: UntypedFormGroup;

  dataSource: InstitutionDataSource;
  noInstitutionDetails = 'Missing name';
  // windows
  singleAccountView = false;
  accountId: string;
  accountDetails: Account;


  ngOnInit() {
    // form
    // tslint:disable-next-line:no-debugger
  //  debugger;
    this.filtersForm = this.formBuilder.group({
      accountType: [''],
      accountStatus: [''],
      accountCategory: [''],
      startDate: [''],
      endDate: [''],
    });
    this.dataSource = new InstitutionDataSource(this.accountService);
    this.dataSource.loadAccounts(0, 50, 'dateCreated', 'desc', 0, '');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
       .pipe(
         tap(() => this.loadInstitutionsPage())
       ).subscribe();
  }

  loadInstitutionsPage() {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadAccounts(offset, this.paginator.pageSize, this.sort.active, this.sort.direction,
        this.paginator.pageIndex, JSON.stringify(this.filtersForm.value));
  }

  applyFilter(event: MatSelectChange) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadAccounts(0, 50, 'dateCreated', 'desc', 0, JSON.stringify(this.filtersForm.value));

  }

  applyDateRangeFilter(event: MatDatepickerInputEvent<Date>) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadAccounts(0, 50, 'dateCreated', 'desc', 0, JSON.stringify(this.filtersForm.value));
  }

  get accountType() {
    return this.filtersForm.get('accountType');
  }

  get accountCategory() {
    return this.filtersForm.get('accountCategory');
  }

  get startDate() {
    return this.filtersForm.get('startDate');
  }

  get endDate() {
    return this.filtersForm.get('endDate');
  }

  // events

  goToAccount() {
    this.router.navigateByUrl('/single-account');
  }

  showAccountDetails(id: string) {
    this.singleAccountView = true;
    this.accountId = id;
    this.accountService.getInstitutionAccountById(id).subscribe(res => {
      this.accountDetails = res;
    });
  }

  closeAccountDetails() {
    this.singleAccountView = false;
  }

}
