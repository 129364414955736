import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtilitiesService } from '@orion/utilities/utilities.service';
import { LttUpdaterService } from '@orion/services/ltt-updater.service';
import { LttDialogComponent } from '../../pages/ltt-dialog/ltt-dialog.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-ltt-updater',
  templateUrl: './ltt-updater.component.html',
  styleUrls: ['./ltt-updater.component.scss']
})
export class LttUpdaterComponent implements OnInit {
  singleTransactionForm: UntypedFormGroup;
  isError = false;
  isFileSelected = false;
  isLoading = false;
  isUpdating = false;
  responseData: any;
  message!: string;
  fileName: string;
  fileToUpload: File = null;
  @ViewChild('getFile')
  myInputVariable: ElementRef;
  error: string;
  dragAreaClass: string;

  constructor(
    private dialog: MatDialog,
    private utilities: UtilitiesService,
    private formBuilder: UntypedFormBuilder,
    private lttUpdaterService: LttUpdaterService) { }

  ngOnInit(): void {
    this.dragAreaClass = "dragarea";
    this.singleTransactionForm = this.formBuilder.group({
      providerId: ['', [Validators.required,]],
      amount: ['', [Validators.required,]],
    });
  }

  // Update single transaction
  submitSingleTransaction() {
    const transacationsDetails = {
      providerId: this.singleTransactionForm.value.providerId,
      amount: this.singleTransactionForm.value.amount,
    }
    this.utilities.confirmAlert().then((result) => {
      if (result.isDismissed) {
        window.location.reload();
      } else if (result.isConfirmed) {
        this.isLoading = true;
        this.lttUpdaterService.updateSingleTransaction(transacationsDetails)
          .subscribe((response) => {
            if (response.code === 200) {
              const items: Array<any> = response.data;
              if (items.length > 0) {
                this.isLoading = false;
                this.responseData = [];
                items.forEach((item) => {
                  this.responseData.push(item);
                });
                this.responseDialog(this.responseData);
              } else {
                this.isLoading = false;
                this.utilities.success2Alert('No transactions to update!!').then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              }
              this.ngOnInit();
            } else {
              this.isLoading = false;
              this.utilities.error2Alert('Failed to upload file!!');
              this.ngOnInit();
            }
          },
            (error) => {
              this.isLoading = false;
              this.utilities.error2Alert(error);
              this.ngOnInit();
            }
          );
      }
    });
  }

  // Choose file
  chooseFile() {
    document.getElementById("get_file").click();
  }

  // CSV file upload
  onFileChange(e) {
    if (e.target.files.length > 0) {
      this.fileToUpload = e.target.files[0];
      this.fileName = e.target.files[0].name;
      this.isFileSelected = true;
      this.uploadExcelFile();
    } else {
      this.fileToUpload = null;
    }
  }

  // Checking transactions
  uploadExcelFile() {
    if (this.fileToUpload === null) {
      this.utilities.error2Alert('Please upload the Excel File');
    }
    const formData = new FormData();
    formData.append('file', this.fileToUpload);
    this.utilities.confirm2Alert().then((result) => {
      if (result.isDismissed) {
        window.location.reload();
      }
      else if (result.isConfirmed) {
        this.isLoading = true;
        this.lttUpdaterService
          .uploadExcelFile(formData)
          .subscribe(
            (response) => {
              if (response.code === 200) {
                const items: Array<any> = response.data;
                if (items.length > 0) {
                  this.isLoading = false;
                  this.responseData = [];
                  items.forEach((item) => {
                    this.responseData.push(item);
                  });
                  this.responseDialog(this.responseData);
                } else {
                  this.isLoading = false;
                  this.utilities.success2Alert('No transactions to update!!').then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                  });
                }
                this.ngOnInit();
              } else {
                this.isLoading = false;
                this.utilities.error2Alert('Failed to upload file!!');
                this.ngOnInit();
              }
            },
            (error) => {
              this.isLoading = false;
              this.utilities.error2Alert(error);
              this.ngOnInit();
            }
          );
      }
    });
  }

  // Dialog to view updateable transacations
  responseDialog(data) {
    const dialogRef = this.dialog.open(
      LttDialogComponent,
      {
        width: '1100px',
        data: {
          transactionsData: data,
        },
        hasBackdrop: true,
      }
    );
    dialogRef.afterClosed().subscribe((updateProceed: boolean) => {
      if (updateProceed) {
        this.isUpdating = true;
        this.lttUpdaterService.updateMobilePaymentTransactions(this.responseData).subscribe((response) => {
          if (response.code === 200) {
            this.isUpdating = false;
            this.utilities.success2Alert('Transactions updated successfully!!').then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          } else {
            this.isUpdating = false;
            this.utilities.error2Alert('Failed to update transactions!!').then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
        });
      } else {
        window.location.reload();
      }
    })
  }


  onFileDrop(event: any) {
    this.onFileChange(event);
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.fileToUpload = event.dataTransfer.files[0];
      this.fileName = event.dataTransfer.files[0].name;
      this.isFileSelected = true;
      this.uploadExcelFile();
    }
  }
}
