<div class="wrap {{ class }}">
  <svg [attr.viewBox]="viewBox" class="ring" preserveAspectRatio>
    <circle
      class="base"
      [attr.stroke-width]="strokeWidth"
      [attr.r]="radius"
      [attr.cx]="width / 2"
      [attr.cy]="width / 2"
      stroke="#00CBAD"
    ></circle>
    <circle
      [attr.stroke-width]="strokeWidth"
      [attr.r]="radius"
      [attr.cx]="width / 2"
      [attr.cy]="width / 2"
      class="progress ring"
      [style.strokeDasharray]="dashArray"
      [style.strokeDashoffset]="circumference"
      [style.strokeDashoffset]="dashOffset"
    ></circle>
  </svg>
  <div class="label" *ngIf="progress">
    <span>{{ progress }}%</span>
  </div>
</div>
