import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
// @ts-ignore
import { AuthenticationService } from '@orion/services';
import { environment } from '../../environments/environment';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let userTenantId = localStorage.getItem('tenantId');
    let accessToken = localStorage.getItem('accessToken');
    // debugger;
    // add authorization header with jwt token if available
    // if (this.authenticationService.orionUserValue) {
    if (accessToken) {
      request = request.clone({
        setHeaders: {
          // Authorization: 'Bearer ' + this.authenticationService.orionUserValue.accessToken,
          Authorization: 'Bearer ' + accessToken,
          appId: 'orion',
          tenantId: userTenantId
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          appId: 'orion',
          tenantId: userTenantId
        }
      });
    }
    // rudementary way to block fat fingers trying to connect to live
    if (environment.production === false && environment.serverUrl.includes('ultima259')) {
      return null;
    }

    // debugger
    return next.handle(request);
  }
}
