import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Role } from "../models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private apiUrl = `${environment.serverUrl}/api`;

  constructor(private http: HttpClient) { }

  list(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get<any>(`${this.apiUrl}/role`, { params });
  }

  getRoles(): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('max', "20");
    return this.http.get(`${this.apiUrl}/role`, {params});
  }
  saveRole(formData): Observable<any> {
    return this.http.post(`${this.apiUrl}/role/`, formData);
  }


  updateRole(roleId: string, formData: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/role/${roleId}`, formData);
  }

  deleteRole(roleId): Observable<any> {
    return this.http.delete(`${this.apiUrl}/role/${roleId}`);
  }
}
