import {Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef} from '@angular/core';
import {
  Form,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {ProfileService} from '../../services/profile.service';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {Profile} from "@orion/models";

@Component({
  selector: 'app-edit-visual-id',
  templateUrl: './edit-visual-id.component.html',
  styleUrls: ['./edit-visual-id.component.scss']
})
export class EditVisualIdComponent implements OnInit {
  // tslint:disable-next-line:no-output-native
  @Output() close = new EventEmitter();
  isError = false;
  message!: string;
  @Input() personalInfo: Profile;

  constructor(
    private form: UntypedFormBuilder,
    private profileService: ProfileService,
    private utitlities: UtilitiesService
  ) {
  }

  IDForm: UntypedFormGroup;

  @ViewChild('idFileLabel', {static: false}) idFileLabel: ElementRef;
  @ViewChild('photoFileLabel', {static: false}) photoFileLabel: ElementRef;
  @Input() userName: string;
  @Input() names: string;
  selectedIDFile = null;
  selectedPhotoFile = null;

  onIDUpload(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size < 4000000) {
        this.idFileLabel.nativeElement.innerText = file.name;
        this.IDForm.get('idFile').setValue(file);
      } else {
        alert('Sorry, file is too big. Please upload file smaller than 4mb');
      }
    }
  }

  onPhotoUpload(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size < 4000000) {
        this.photoFileLabel.nativeElement.innerText = file.name;
        this.IDForm.get('photoFile').setValue(file);
      } else {
        alert('Sorry, file is too big. Please upload file smaller than 4mb');
      }

    }
  }

  ngOnInit(): void {
    this.IDForm = this.form.group({
      idType: [null, [Validators.required]],
      idNumber: [null, [Validators.required]],
      idFile: [null, [Validators.required]],
      photoFile: [null, [Validators.required]]
    });
  }

  submitForm() {


      const formData = new FormData();

      formData.append('userId', this.userName);
      formData.append('idType', this.IDForm.controls.idType.value);
      formData.append('idNumber', this.IDForm.controls.idNumber.value);
      formData.append('idFile', this.idFile.value);
      formData.append('photoFile', this.photoFile.value);

      this.utitlities.confirmAlert().then((result) => {
        if (result.isConfirmed) {
          this.profileService.uploadVisualIdentities(formData).subscribe(resp => {
            this.utitlities.successAlert('Successfully updated!');
            this.isError = false;
            this.ngOnInit();
            this.close.emit();
            window.location.reload();
          }, error => {
            this.isError = true;
            this.message = error;
          });
        }
      });

  }

  get idFile() {
    return this.IDForm.get('idFile');
  }

  get photoFile() {
    return this.IDForm.get('photoFile');
  }

}
