<slideover [zIndex]="2" (closeWindow)="close.emit()">
  <div class="page-inner-container">
    <div class="page-section" *ngIf="isUpload">
      <h2 class="page-subtitle">Upload Members: <span>{{accountDetails.institutionName}}</span></h2>

      <div class="import-member-form">
        <div class="xn-input-file" [class.is-default]="fileToUpload == null">
          <label class="control-label">Select XLSX</label>
          <input
            type="file"
            accept=".xlsx"
            class="custom-file-input"
            id="customFile"
            name="myfile"
            (change)="onFileSelect($event)"
          />
          <label class="file-label" for="customFile" #membersDocLabel>></label>
          <figure class="icon"></figure>
        </div>
        <div class="u-justify actions">
          <button
            type="submit"
            mat-button
            mat-flat-button
            color="primary"
            (click)="uploadMembers()">Upload
          </button>
        </div>
      </div>


    </div>
      <div class="page-section xn-fadein-up xn-delay-4" *ngIf="isResult">
        <h2 class="page-subtitle">Uploaded Members: <span>{{accountDetails.institutionName}}</span></h2>
          <table
            class="xn-table mat-elevation-z8 no-table-hover pointer">
            <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let member of uploaded">
              <td>{{member.firstName}}</td>
              <td>{{member.lastName}}</td>
            </tr>
            </tbody>
          </table>
      </div>

  </div>
</slideover>
