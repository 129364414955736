import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Profile } from '@orion/models/profile';
import {environment} from "../../../environments/environment";
import {ProfileService} from "@orion/services/profile.service";

@Component({
  selector: 'app-expandable-view',
  templateUrl: './expandable-view.component.html',
  styleUrls: ['./expandable-view.component.scss'],
})
export class ExpandableViewComponent implements OnInit{

  @Input() profileDetails: Profile;
  @Input() title: string;
  @Input() zIndex: number;
  @Input() size: string;
  @Output() closeWindow = new EventEmitter();

  constructor(private profileService: ProfileService) { }

  liveImageUrl = environment.serverUrl;
  // thumbNailBaseUrl = `${environment.serverUrl}/api/v1/guest/passportphoto/`;
  thumbNailBaseUrl = ``;



  closeSlideover() {
    this.closeWindow.emit();
    document.getElementsByTagName("body")[0].classList.remove("disable-scroll");

  }



  ngOnInit(): void {
    document.getElementsByTagName("body")[0].classList.add("disable-scroll");

    this.profileService.getPassportImage(this.profileDetails.email).subscribe({
      next: (resp) => {
        this.thumbNailBaseUrl = URL.createObjectURL(resp);
      }
    });
  }

 }

