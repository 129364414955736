import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {User} from '../models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private apiUrl = `${environment.serverUrl}/api`;
  constructor(private http: HttpClient) { }

  list(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams =  new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get(`${this.apiUrl}/v1/users/employees`, {params});
  }

  saveUser(formData): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/auth/users/staff`, formData);
    // return of(formData);
  }

  getStaffImage(staffId: string): Observable<Blob> {
    return this.http.get(`${environment.serverUrl}/api/v1/guest/staff-profile-pic/${staffId}`, { responseType: 'blob' });
  }

  updateUser(staffId: string, formData: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/v1/users/updateXenoStaff/${staffId}`, formData);
  }

  deleteUser(staffId): Observable<any> {
    return this.http.delete(`${this.apiUrl}/v1/users/xenoStaff/${staffId}`);
  }
}
