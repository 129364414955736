import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {User} from "@orion/models";
import {AuthenticationService} from "@orion/services";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Onboarding} from "@orion/models/onboarding";
import {OnboardingService} from "@orion/services/onboarding.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {OnboardingDataSource} from "@orion/pages/onboarding/onboarding-data-source";
import { saveAs } from 'file-saver';
import {merge} from "rxjs";
import {tap} from "rxjs/operators";
import {MatSelectChange} from "@angular/material/select";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit, AfterViewInit {
  orionUser: User;
  filtersForm: UntypedFormGroup;
  cardResult: any;
  percentageChange: any;
  onboardings: Onboarding[] = [];
  resultsLength = 0;
  isLoading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: OnboardingDataSource;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    // private fileSaverService: FileSaverService,
  private onBoardingService: OnboardingService) { }

  displayedColumns: string[] = ['position',
    'name',
    'source',
    'registration',
  'profiling',
   'verification',
   'funding'
  ];


  ngOnInit(): void {
    this.authenticationService.orionUser.subscribe(x =>{ this.orionUser = x});
   this.loadStripResults();
    this.filtersForm = this.formBuilder.group({
      source: ['', Validators.required],
      registration: ['', Validators.required],
      profiling: ['', Validators.required],
      verification: ['', Validators.required],
      funding: ['', Validators.required],
      dateFilter: ['', Validators.required],
    });

    this.dataSource = new OnboardingDataSource(this.onBoardingService);
    this.dataSource.loadOnboardedClients(0, 50, 'dateCreated', 'desc', 0, '');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadOnboardedPage()))
      .subscribe();
  }

  loadOnboardedPage() {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadOnboardedClients(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.filtersForm.value
    );
  }


  loadStripResults() {
    this.onBoardingService.getResults().subscribe((response) => {
      response.result.map((x) => {
        this.cardResult = x;
      });
       response.percentages.map((y) => {
         this.percentageChange = y;
       });
    }, error => {
      } );
  }


  sendNotifications(type: string) {
    type === 'SMS' ?
      this.initNotifications(type) :
      this.initNotifications(type);
  }

  initNotifications(type: string) {
    this.onBoardingService.sendNotification(type).subscribe((response) => {
    });
  }

  exportCSV() {
    this.onBoardingService.downloadCSV().subscribe({
      next: (response: Blob) => {
        let blob:any = new Blob([response], {type: 'text/json; charset=utf-8'});
        saveAs(blob, 'data_export.xlsx');
      },
      error: (error) => {
        alert('Error downloading file.' + error);
      },
    });
  }



  applyFilter(event: MatSelectChange, section: string) {
    const filterValue = event.value;
    switch (section) {
      case 'source':
        this.paginator.pageIndex = 0;
        this.loadStripResultsBySource(event);
        this.dataSource.loadOnboardedClients(
          0,
          50,
          'dateCreated',
          'desc',
          0,
          this.filtersForm.value
        );

        break;
      case 'registration':
        this.paginator.pageIndex = 0;
        this.loadStripResultsBySource(event);
        this.dataSource.loadOnboardedClients(
          0,
          50,
          'dateCreated',
          'desc',
          0,
          this.filtersForm.value
        );

        break;
      case 'profiling':
        this.paginator.pageIndex = 0;
        this.loadStripResultsBySource(event);
        this.dataSource.loadOnboardedClients(
          0,
          50,
          'dateCreated',
          'desc',
          0,
          this.filtersForm.value
        );

        break;
      case 'verification':
        this.paginator.pageIndex = 0;
        this.loadStripResultsBySource(event);
        this.dataSource.loadOnboardedClients(
          0,
          50,
          'dateCreated',
          'desc',
          0,
          this.filtersForm.value
        );

        break;
      case 'funding':
        this.paginator.pageIndex = 0;
        this.loadStripResultsBySource(event);
        this.dataSource.loadOnboardedClients(
          0,
          50,
          'dateCreated',
          'desc',
          0,
          this.filtersForm.value
        );

        break;
      default:
        break;
    }

  }

  applyDateFilter(event: MatDatepickerInputEvent<Date, Date>) {
    this.paginator.pageIndex = 0;
    this.loadStripResultsBySource(event);
    this.dataSource.loadOnboardedClients(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      this.filtersForm.value
    );
  }

  private loadStripResultsBySource(source: any) {
    this.onBoardingService.getResultsBySource(source).subscribe((response) => {
      response.result.map((x) => {
        this.cardResult = x;
      });
      response.percentages.map((y) => {
        this.percentageChange = y;
      });
    }, error => {

    } );
  }
}
