import {Component, Inject, OnInit, Optional} from '@angular/core';

import {JobsService} from '@orion/services/jobs.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent  implements OnInit{

  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: {
                cancelText: string,
                confirmText: string,
                message: string,
                title: string;
              },
              ) {
  }

  ngOnInit(): void {

    }

  confirm(): void {
    this.close(true);
  }


  public cancel() {
    this.close(false);
  }
  public close(value: any) {
    this.dialogRef.close(value);
  }



}
