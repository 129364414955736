import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {JobsService} from '@orion/services/jobs.service';
import {TasksDataSource} from '@orion/pages/tasks/tasks-data-source';
import {Job} from '@orion/models/job';
import {UntypedFormGroup} from '@angular/forms';
import {ConfirmDialogService} from '@orion/shared/confirm-modal/confirm-dialog.service';
import {MatPaginator} from "@angular/material/paginator";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, AfterViewInit {
  @Input() isSearch: boolean;
  @Input() searchResults: any;
  displayedColumns: string[] = ['name', 'description' , 'cronExpression' , 'taskClass' , 'dateCreated', 'lastUpdated', 'actions'];
  resultsLength = 0;
  isLoading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: TasksDataSource;
  view = '';
  selectedJob: Job;
  jobs: any = [];
  filtersForm: UntypedFormGroup;
  isOpen = false;
  isExecuting = false;
  tenant = '';
  activeTenant: String;


  constructor(private jobsService: JobsService,
              private dialogService: ConfirmDialogService,
              private snackBar: MatSnackBar
               ) {
  }

  closeEditView() {
    this.view = '';
    this.selectedJob = null;
    this.refreshTasks();
  }

  editJob(job: Job) {
    this.view = 'edit-job';
    this.selectedJob = job;
  }

  ngOnInit(): void {
    let setTenant = localStorage.getItem('tenantId')
    if(setTenant == "UG"){
      this.tenant = "DEFAULT";
      this.activeTenant = setTenant;
    } else {
      this.tenant = setTenant.toLowerCase();
      this.activeTenant = setTenant;
    }
    this.loadTasks();
    this.loadCronObject();
  }

  private loadTasks() {
    this.dataSource = new TasksDataSource(this.jobsService);
    this.dataSource.loadTasks(0, 50, 'dateCreated', 'desc', 0, '');
  }

  ngAfterViewInit(){
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadTasks(offset, 50, 'dateCreated', 'desc', 0, '');
  }


  scheduleJob(jobId: string) {
    if (confirm('Schedule Job')) {
      this.handleScheduleJob(jobId);
    }
  }

  unscheduleJob(jobId: string) {
    if (confirm('Un Schedule Job?')) {
      this.handleUnscheduleJob(jobId);

    }
  }


  runJob(taskName: string) {
    if (confirm('Run Job')) {
      this.handleRunJob(taskName);
    }
  }

  stopJob(taskName: string) {
    if (confirm('Stop Job')) {
      this.handleStopJob(taskName);
    }
  }


  private loadCronObject() {
    this.jobsService.getAllTasks().subscribe(resp => {
      this.jobs = resp;
    }, error => {

    });
  }

  private refreshTasks() {
    this.loadTasks();
    this.loadCronObject();
  }

  handleScheduleJob(jobId: string) {
    if (jobId) {
      this.jobsService.scheduleJob(jobId).subscribe(resp => {

        this.openSnackBar('✔️The job has been scheduled');
        this.refreshTasks();
      }, error => {
        this.openSnackBar('❌ something went wrong. unable to complete action');
      });
    }
  }

  handleRunJob(taskName: string) {
    if (taskName) {
      this.jobsService.startJobByName(taskName).subscribe(resp => {

        this.openSnackBar('✔️Job is running now');
        location.reload();
        this.refreshTasks();
      }, error => {
        this.openSnackBar('❌ something went wrong. unable to complete action');
      });
    }
  }

  handleStopJob(taskName: string) {
    if (taskName) {
      this.jobsService.stopJob(taskName).subscribe(resp => {

        this.openSnackBar('✔️The job has been stopped');
        this.refreshTasks();
      }, error => {
        this.openSnackBar('❌ something went wrong. unable to complete action');
      });
    }
  }

  handleUnscheduleJob(jobId: string): any {
    if (jobId) {
      this.jobsService.unscheduleJob(jobId).subscribe(resp => {
        this.jobs = resp;
        this.openSnackBar('✔️Job un scheduled successfully');
        location.reload();
      }, error => {
        this.openSnackBar('❌ something went wrong. unable to complete action');
      });
    } else {

    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 8000,
      verticalPosition: 'top'
    });
  }

}
