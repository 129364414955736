import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ProfileService} from "@orion/services/profile.service";
import {UtilitiesService} from "@orion/utilities/utilities.service";

@Component({
  selector: 'app-edit-profile-account-beneficiary',
  templateUrl: './edit-profile-account-beneficiary.component.html',
  styleUrls: ['./edit-profile-account-beneficiary.component.scss']
})
export class EditProfileAccountBeneficiaryComponent implements OnInit {

  @Output() close = new EventEmitter();
  editForm: UntypedFormGroup;
  @Input() userId: any;
  @Input() profileAccountBeneficiaryInformation: any;
  @Input() accountId: any;

  isError: boolean = false;
  message!: string;


  constructor(private formBuilder: UntypedFormBuilder,
              private profileService: ProfileService,
              private utilities: UtilitiesService) { }

  ngOnInit(): void {


    this.editForm = this.formBuilder.group({
      firstName: [
        null,
        [
          Validators.required,
        ],
      ],
      lastName: [
        null,
        [
          Validators.required,
        ],
      ],
      middleName: [
        null,
        [
          Validators.required,
        ],
      ],
      relationShip: [
        null,
        [
          Validators.required,
        ],
      ],
      percentage: [
        null,
        [
          Validators.required,
        ],
      ],
      countryCode: [
        null,
        [
          Validators.required,
        ],

      ],
      telephone: [
        null,
        [
          Validators.required,
        ],

      ],
      email: [
        null,
        [
          Validators.required,
        ],

      ],
    });

  }

  submitEditForm(): void{
    let beneficiary = this.editForm.value;
    const formData = new FormData();

    formData.append("userId", this.userId);
    formData.append('accountId', this.accountId);
    formData.append("id", this.profileAccountBeneficiaryInformation.id);
    formData.append("firstName", beneficiary.firstName);
    formData.append("lastName", beneficiary.lastName);
    formData.append("middleName", beneficiary.middleName);
    formData.append("relationship", beneficiary.relationShip);
    formData.append("percentage", beneficiary.percentage);
    formData.append("telephone", beneficiary.telephone);
    formData.append("countryCode", beneficiary.countryCode);
    formData.append(" email", beneficiary. email);

    this.utilities.confirmAlert().then((result) => {
      if(result.isConfirmed) {
        this.profileService.updateProfileAccountBeneficiary(formData).subscribe({
          next: (response) =>{
            if (response) {
              if(response.code == 200) {
                this.closeForm();
                this.isError = false;
                this.utilities.successAlert(response);
                this.ngOnInit();
              }

            }
          },
          error: (error) =>{

          }
        })
      }
    })


  }

  closeForm() {
    this.close.emit();
  }
}
