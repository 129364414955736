<ng-container *ngIf="showModal">
    <app-user-dash-single-profile [showProfile]="true" [zIndex]="4" (hideProfile)="hideProfileModal()"
        [profileId]="clientProfileId" [isProspect]="isProspectStatus">
    </app-user-dash-single-profile>
</ng-container>


<slideover-user-dash (closeWindow)="closeWindow()" [zIndex]="zIndex">
    <div class="xn-fadein-up">
        <div class="user-profile-content">
            <mat-grid-list cols="4" gutterSize="20" rowHeight="">
                <!-- Profile card -->
                <mat-grid-tile colspan="1" class="details-panel">
                    <div class="panel-a">
                        <div class="prof-card grid-tile-container-prof">
                            <div class="">
                                <mat-grid-list cols="2" gutterSize="2" rowHeight="162px">
                                    <mat-grid-tile colspan="1">
                                        <div>
                                            <div class="prof-img-container">
                                                <img [src]="!advisorPassportUrl ? '../../../assets/images/orion_avatar.png' : advisorPassportUrl"
                                                    alt="Selfie" class="selfie-image" mat-card-image />
                                            </div>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile colspan="1">
                                        <div class="right-section">
                                            <div class="prof-name">
                                                {{advisorDashboardProfile?.firstName ?
                                                (advisorDashboardProfile?.firstName + ' ' +
                                                advisorDashboardProfile?.lastName) : '...' }}</div>
                                            <div><span>Joined: </span> <span class="content-txt">{{
                                                    advisorDashboardProfile?.dateCreated | date
                                                    }}</span></div>
                                            <div><span>Phone: </span> <span class="content-txt">
                                                    {{advisorDashboardProfile?.phoneNumber ?
                                                    advisorDashboardProfile?.phoneNumber :
                                                    ' --- '}}
                                                </span></div>
                                        </div>
                                    </mat-grid-tile>
                                </mat-grid-list>
                            </div>
                            <div class="border-line-bottom-card-prof"></div>
                            <div class="lower-view">
                                <div class="prof-details">
                                    <span>Email</span>
                                    <span class="content-txt">
                                        {{advisorDashboardProfile?.email ? advisorDashboardProfile?.email : ' --- '}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="grid-tile-container">
                            <div class="kyc-card ">
                                <mat-grid-list cols="2" gutterSize="10" rowHeight="60px">
                                    <mat-grid-tile>
                                        <div class="prof-details-top">
                                            <span>Assigned Users</span>
                                            <span class="content-txt">
                                                {{advisorDashboardProfile?.totalUsers
                                                ? (advisorDashboardProfile?.totalUsers | number) : 0}}
                                            </span>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <div class="prof-details-top">
                                            <span>Total Deposits</span>
                                            <span class="deposits">
                                                <span class="currency-code">{{tenantCurrency}}</span>
                                                {{advisorDashboardProfile?.totalDeposit
                                                ? (advisorDashboardProfile?.totalDeposit | number: "1.0-0") : 0}}
                                            </span>
                                        </div>
                                    </mat-grid-tile>
                                </mat-grid-list>

                                <mat-grid-list cols="2" gutterSize="10" rowHeight="80px">
                                    <mat-grid-tile>
                                        <div class="prof-details-top">
                                            <span>Total Withdraws</span>
                                            <span class="withdraws">
                                                <span class="currency-code">{{tenantCurrency}}</span>
                                                {{advisorDashboardProfile?.totalWithdraws
                                                ? (advisorDashboardProfile?.totalWithdraws | number: "1.0-0") : 0}}
                                            </span>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <div class="prof-details-top">
                                            <span>Total AUM</span>
                                            <span class="content-txt">
                                                <span class="currency-code">{{tenantCurrency}}</span>
                                                {{advisorDashboardProfile?.totalAum
                                                ? (advisorDashboardProfile?.totalAum | number: "1.0-0") : 0}}
                                            </span>
                                        </div>
                                    </mat-grid-tile>
                                </mat-grid-list>

                                <div class="border-line-bottom"></div>
                                <mat-grid-list cols="2" gutterSize="10" rowHeight="80px">
                                    <mat-grid-tile>
                                        <div class="prof-details-top">
                                            <span>Closed Users</span>
                                            <span class="content-txt">
                                                {{advisorDashboardProfile?.closedUsers
                                                ? (advisorDashboardProfile?.closedUsers | number) : 0}}
                                            </span>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <!-- <div class="prof-details-top">
                                            <span>Last Login</span>
                                            <span class="content-txt">
                                               {{advisorDashboardProfile?.lastLogin}}
                                            </span>
                                        </div> -->
                                    </mat-grid-tile>
                                </mat-grid-list>
                                 <!-- Chart -->
                            <div class="chart" *ngIf="dataSource.isloadingData == false">
                                <div class="prof-details-top"><span>Band metrics</span></div>
                                <br />
                                <app-band-summary [bandSummary]="dataSource.advisorBandSummary | async"> </app-band-summary>
                            </div>
                            </div>
                        </div>

                    </div>
                </mat-grid-tile>
                <!-- Users Card -->
                <mat-grid-tile colspan="3" class="tab-panel">
                    <div class="tab-panel-table">
                        <div class="header-section-top">
                            <h1 class="header-tile">Assigned Users</h1>
                            <div class="u-justify xn-fadein-up">
                                <ng-container *ngIf="resetFiltersActive">
                                    <button mat-raised-button class="reset-button" color="primary">
                                        <span (click)="resetFilters()">Reset filters</span>
                                    </button>
                                </ng-container>
                                <form class="page-actions" [formGroup]="filtersForm">
                                    <!-- Date Filter   -->
                                    <xn-date-filter
                                        [startDate]="startDate"
                                        [endDate]="endDate"
                                        [selectedValue]="selectedValue"
                                        (onRangeStart)="setStartDate($event)"
                                        (onRangeEnd)="setEndDate($event)"
                                        (onChangeDays)="onChangeDays($event)"
                                        (onSetAllTime)="onSetAllTime()"
                                    ></xn-date-filter>
                                    <!-- Band Filter   -->
                                    <div class="page-action">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Bands</mat-label>
                                            <mat-select formControlName="band"
                                                (selectionChange)="applyBandFilter($event)">
                                                <mat-option value="M0A">M0a</mat-option>
                                                <mat-option value="M0b">M0b</mat-option>
                                                <mat-option value="M1">M1</mat-option>
                                                <mat-option value="M2">M2</mat-option>
                                                <mat-option value="M3">M3</mat-option>
                                                <mat-option value="M4">M4</mat-option>
                                                <mat-option value="M5">M5</mat-option>
                                                <mat-option value="M6">M6</mat-option>
                                                <mat-option value="M7">M7</mat-option>
                                                <mat-option value="M8">M8</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <br />
                        <div class="u-justify xn-fadein-up" *ngIf="!isDateFilter">
                            <div></div>
                            <div>
                                <span class="xn-text-label">{{toggleTableView ? 'Viewing since assigned ': 'View since assigned '}}</span>

                                <mat-slide-toggle [(ngModel)]="toggleTableView">
                                </mat-slide-toggle>
                            </div>

                        </div>
                        <div class="user-profile-view xn-fadein-up xn-delay-2">
                            <!-- <app-assigned-users [hideSummaryCards]="true" [advisorProfileId]="advisorProfileId"></app-assigned-users> -->

                            <div class="table-border">

                                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 user-table-data"
                                    matSort matSortActive="dateCreated" matSortDisableClear>
                                    <!-- Position Column -->
                                    <ng-container matColumnDef="position">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                                        <td mat-cell *matCellDef="let element">{{ element.position }}</td>
                                    </ng-container>

                                    <!-- dateCreated Column -->
                                    <ng-container matColumnDef="dateCreated">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned On</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.dateCreated | date }}
                                            <!-- <span class="join-time">- {{
                                                element.dateCreated | date:'shortTime' }}</span> -->
                                        </td>
                                    </ng-container>

                                    <!-- firstName Column -->
                                    <ng-container matColumnDef="firstName">
                                        <th mat-header-cell *matHeaderCellDef>Names</th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="u-profile">
                                                <div class="user-display-icon">
                                                    <figure class="user-profile-photo {{ element.kycStatus }}" [style.backgroundImage]="
                            'url(' + thumbNailBaseUrl + element.email + ')'
                            "></figure>
                            <span class="user_initials">{{ element.firstName ? (element.firstName[0] | titlecase) : ''}}{{ element.lastName ? (element.lastName[0] | titlecase) : ''}}</span>
                                                </div>

                                                <a><span class="user-name-label">{{ element.firstName | titlecase }}</span> </a>
                                            </div>
                                        </td>
                                    </ng-container>



                                    <!-- Total Deposit Count Column -->
                                    <ng-container matColumnDef="noOfDeposits">
                                        <th mat-header-cell *matHeaderCellDef class="center">No. of Deposits</th>
                                        <td mat-cell *matCellDef="let element" class="center">
                                            {{(toggleTableView ? element.noOfDepositsAfterAssignment : element.noOfDeposits)
                                                | number: '1.0-0'}} </td>
                                    </ng-container>

                                    <!-- Total Deposit Column -->
                                    <ng-container matColumnDef="totalDeposits">
                                        <th mat-header-cell *matHeaderCellDef class="center">Total Deposits</th>
                                        <td mat-cell *matCellDef="let element"><div class="deposits">
                                            <span class="currency-code">{{tenantCurrency}}</span> {{
                                                (toggleTableView ? element.totalDepositsAfterAssignment : element.totalDeposits) | number:
                                                '1.0-0'}}
                                        </div> </td>
                                    </ng-container>

                                    <!-- Total Withdraws Count Column -->
                                    <ng-container matColumnDef="noOfWithdraws">
                                        <th mat-header-cell *matHeaderCellDef class="center">No. of Withdraws</th>
                                        <td mat-cell *matCellDef="let element" class="center">
                                            {{(toggleTableView ? element.noOfWithdrawsAfterAssignment : element.noOfWithdraws) | number:
                                                '1.0-0'}}</td>
                                    </ng-container>

                                    <!-- Total withdraws Column -->
                                    <ng-container matColumnDef="totalWithdraws">
                                        <th mat-header-cell *matHeaderCellDef class="center">Total Withdraws</th>
                                        <td mat-cell *matCellDef="let element"><div class="withdraws">
                                            <span class="currency-code">{{tenantCurrency}}</span> {{
                                                (toggleTableView ? element.totalWithdrawsAfterAssignment : element.totalWithdraws) | number:
                                                '1.0-0'}}
                                        </div></td>
                                    </ng-container>

                                    <!-- AUM before assignment -->
                <ng-container matColumnDef="initialAum">
                    <th mat-header-cell *matHeaderCellDef>{{isDateFilter ? '' : 'Assignment AUM'}}</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="currency-code">{{isDateFilter ? '' :  tenantCurrency}}</span> {{ isDateFilter ? '' : ((element.aumBeforeAssignment) | number:'1.0-0')}}
                    </td>
                </ng-container>

                                    <!-- Initial Band Column -->
                                    <ng-container matColumnDef="initialBand">
                                        <th mat-header-cell *matHeaderCellDef>Assignment Band</th>
                                        <td mat-cell *matCellDef="let element">
                                                    <span class="xn-left-16 x-text center">
                                                <span *ngIf="element?.initialBand" class="mo-label {{
                                                                element?.initialBand | lowercase
                                                         }} xn-font-size ">{{element.initialBand}}</span></span>

                                        </td>
                                    </ng-container>



                                    <!--  Total AUM Column -->
                                    <ng-container matColumnDef="totalAum">
                                        <th mat-header-cell *matHeaderCellDef>{{toggleTableView ? 'AUM Change' : 'Current AUM' }}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <div *ngIf="toggleTableView">
                                                <div
                                                    [ngClass]="isNegativeAbusoluteAUM(element.totalAUMAfterAssigment) ?'withdraws' :'deposits'">
                                                    <mat-icon *ngIf="element.totalAUMAfterAssigment != 0" class="aum-icon">
                                                        {{isNegativeAbusoluteAUM(element.totalAUMAfterAssigment) ? 'arrow_downwards' :
                                                        'arrow_upwards'}}</mat-icon><span class="currency-code">{{tenantCurrency}}</span> {{
                                                    element.totalAUMAfterAssigment == 0 ? 0 : (element.totalAUMAfterAssigment |
                                                    number:'1.0-0')}}
                                                </div>
                                            </div>
                                            <div *ngIf="!toggleTableView">
                                                <div class="aum-table"><span class="currency-code">{{tenantCurrency}}</span> {{ (element.totalAum) | number:
                                                    '1.0-0'}}</div>
                                                <div *ngIf="element.totalAUMAfterAssigment != 0 && !isDateFilter"
                                                    [ngClass]="isNegativeAbusoluteAUM(element.totalAUMAfterAssigment) ?'aum-absolute-negative':'aum-absolute'">
                                                    <span class="aum-cash"> <mat-icon class="aum-icon">
                                                            {{isNegativeAbusoluteAUM(element.totalAUMAfterAssigment) ? 'arrow_downwards' :
                                                            'arrow_upwards'}}</mat-icon>{{tenantCurrency}} </span>
                                                    <div class="aum-amount">{{
                                                        (utilitiesService.removeFirstCharacter(element.totalAUMAfterAssigment)) | number:
                                                        '1.0-0'}}
                                                    </div>
                                                    <span class="percent-aum">
                                                        ({{utilitiesService.removeFirstCharacter(element.percentage)}}%)</span>
                                                </div>

                                            </div>

                                        </td>
                                    </ng-container>

                                    <!-- Current Band Column -->
                                    <ng-container matColumnDef="currentBand">
                                        <th mat-header-cell *matHeaderCellDef class="center">{{toggleTableView ? 'Current AUM ' : 'Current Band' }}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <ng-container *ngIf="toggleTableView">
                                                <div class="aum-table"><span class="currency-code">{{tenantCurrency}}</span> {{ (element.totalAum) | number:
                                                    '1.0-0'}}</div>
                                                </ng-container>
                                                <ng-container *ngIf="!toggleTableView">
                                                    <span class="xn-left-16 x-text center">
                                                <span *ngIf="element?.currentBand" class="mo-label {{
                                                                element?.currentBand | lowercase
                                                         }} xn-font-size">{{element.currentBand}}</span></span>
                                                    </ng-container>

                                        </td>
                                    </ng-container>
                                    <!-- Last deposit Column -->
                                    <ng-container matColumnDef="lastDepositDate">
                                        <th mat-header-cell *matHeaderCellDef class="right-flow">{{toggleTableView ? 'Current Band ' : 'Last Deposit Date' }}</th>
                                        <td mat-cell *matCellDef="let element" class="right-flow">
                                            <ng-container *ngIf="toggleTableView">
                                                <span class="xn-left-16 x-text">
                                                <span *ngIf="element?.currentBand" class="mo-label {{
                                                                element?.currentBand | lowercase
                                                         }} xn-font-size">{{element.currentBand}}</span></span>
                                                </ng-container>
                                            <ng-container *ngIf="!toggleTableView">
                                                <div>
                                            {{ element?.lastDepositDate ?
                                                (element?.lastDepositDate | date) : 'Never' }}
                                        </div>
                                        <div class="currency-code">
                                            {{ element?.noOfDaysSinceLastDepositDate ?
                                                element?.noOfDaysSinceLastDepositDate : '---' }}
                                        </div>
                                                </ng-container>

                                       </td>
                                    </ng-container>
                                    <tr class="t-header" mat-header-row
                                        *matHeaderRowDef="displayedColumns sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
                                        (click)="showProfileDetail(row.id)"></tr>
                                </table>

                            </div>
                            <mat-paginator [length]="dataSource.resultsLength | async" [pageSize]="50"
                                showFirstLastButtons [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>


                        </div>


                    </div>
                </mat-grid-tile>
            </mat-grid-list>

        </div>
    </div>
    <div *ngIf="dataSource.loading$ | async" class="spinner-container fill-container">
        <mat-spinner [diameter]="70" color="accent"></mat-spinner>
    </div>
</slideover-user-dash>
