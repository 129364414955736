import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DataExportService {
  private apiUrlRoot = `${environment.serverUrl}/api/v1`;

  constructor(private http: HttpClient) { }

  downloadData(url: string): Observable<Blob> {
    // @ts-ignore
    return this.http.get<Blob>(`${this.apiUrlRoot}/${url}`, {responseType: 'blob'});
  }
}
