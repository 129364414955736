import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Account} from '@orion/models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {AccountService} from '@orion/services/account.service';

@Component({
  selector: 'app-manage-rights',
  templateUrl: './manage-rights.component.html',
  styleUrls: ['./manage-rights.component.scss']
})
export class ManageRightsComponent implements OnInit {

  @Output() closeWindow = new EventEmitter();
  @Input() accountDetails: Account;
  isError = false;
  message!: string;
  editForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private utilities: UtilitiesService,
              private accountService: AccountService) { }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      id: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      right: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
    });
  }

  submitForm(): void {
    // Success 🎉

    const updatedFormInput = {
      accountId: this.accountDetails.id,
      memberId: this.editForm.value.id,
      right: this.editForm.value.right,
    };
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.accountService.manageInstitutionRights(updatedFormInput)
          .subscribe((response) => {
            if (response.code === 200) {
              this.utilities.successAlert(response);
              this.closeForm();
              this.isError = false;
              this.ngOnInit();
            }

            },
            error => {
              this.utilities.errorAlert(error);
              this.isError = true;
            }
          );
      }
    });
  }

  closeForm() {
    this.closeWindow.emit();
  }


}
