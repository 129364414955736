import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class KycRevertService {

  baseUrl = `${environment.serverUrl}/api/v1/users`;

  constructor(private http: HttpClient) { }

  revertKyc(formData: any, profileId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/kyc-revert/${profileId}`, formData);
  }
}
