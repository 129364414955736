import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ProfileService} from "@orion/services/profile.service";
import {UtilitiesService} from "@orion/utilities/utilities.service";

@Component({
  selector: 'app-edit-profile-bank-details',
  templateUrl: './edit-profile-bank-details.component.html',
  styleUrls: ['./edit-profile-bank-details.component.scss']
})
export class EditProfileBankDetailsComponent implements OnInit {
  @Output() close = new EventEmitter();
  editForm: UntypedFormGroup;
  @Input() userId: any;
  @Input() profileBankInformation: any;
  @Input() accountId: any;

  isError: boolean = false;
  message!: string;


  constructor(private formBuilder: UntypedFormBuilder,
              private profileService: ProfileService,
              private utilities: UtilitiesService) { }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({

      bankName: [
        null,
        [
          Validators.required,
        ],
      ],
      branchName: [
        null,
        [
          Validators.required,
        ],
      ],
      accountName: [
        null,
        [
          Validators.required,
        ],
      ],
      accountNumber: [
        null,
        [
          Validators.required,
        ],
      ],
      bankAccount: [
        null,
        [
          Validators.required,
        ],

      ],
      bankCode: [
        null,
        [
          Validators.required,
        ],

      ],
    });

  }

  submitEditForm(): void{
    let bank = this.editForm.value;
    const formData = new FormData();
    formData.append("userId", this.userId);
    formData.append('accountId', this.accountId);
    formData.append("id", this.profileBankInformation.id);
    formData.append("bankName", bank.bankName);
    formData.append("branchName", bank.branchName);
    formData.append("accountName", bank.accountName);
    formData.append("accountNumber", bank.accountNumber);
    formData.append("bankCode", bank.bankCode);

     this.utilities.confirmAlert().then((result) => {
       if (result.isConfirmed) {
         this.profileService.updateProfileBank(formData).subscribe({
           next: (response) =>{
             if(response.code == 200) {
               this.closeForm();
               this.isError = false;
               this.utilities.successAlert("Successful.");
               this.ngOnInit();
             }

           },
           error: (error) =>{

           }
         })
       }
     });

  }

  closeForm() {
    this.close.emit();
  }

}
