import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-initializer',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './initializer.component.html',
  styleUrl: './initializer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitializerComponent { }
