import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-advisory-upload-dialog',
  templateUrl: './advisory-upload-dialog.component.html',
  styleUrls: ['./advisory-upload-dialog.component.scss'],
})
export class AdvisoryUploadDialogComponent {
  advisorData: any
  displayedColumns: string[] = [
    'fullName',
    'userEmail',
    'advisor',
  ];

  constructor(
    private dialogRef: MatDialogRef<AdvisoryUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { advisorData: any }
  ) {
    dialogRef.disableClose = true;
    this.advisorData = data ? data.advisorData : '';
  }

  ngOnInit(): void {
  }

  cancelUpdate(): void {
    this.dialogRef.close(false);
  }

  proceedToUpdate(): void {
    this.dialogRef.close(true);
  }
 }
