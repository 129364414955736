<slideover [zIndex]="2" (closeWindow)="closeWindow.emit()">
  <div class="page-inner-container">

    <div class="page-section">
      <h2 class="page-subtitle huge">Edit Institutional Information</h2>
      <div class="error" *ngIf="isError">
        <p>{{message}}</p>
      </div>
      <form class="edit-user-form"
            [formGroup]="editForm">
        <div class="fields">
          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Institution Name"
              fieldName="institutionName"
              [ngModel]="accountDetails.institutionName == null ? '' : accountDetails.institutionName"
              formControlName="institutionName">
            </app-text-input>
          </div>
          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Registration Number"
              fieldName="regNumber"
              [ngModel]="accountDetails.regNumber == null ? '' : accountDetails.regNumber"
              formControlName="regNumber">
            </app-text-input>
          </div>
        </div>
        <div class="u-justify actions">
          <button
            type="button"
            mat-button
            mat-flat-button
            (click)="submitEditForm()"
            color="primary">
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</slideover>
