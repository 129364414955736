<slideover [zIndex]="2" (closeWindow)="close.emit()">
    <div class="page-inner-container">
        <div class="page-section">
            <h2 class="page-subtitle">Edit Company Doc: <span>{{accountDetails.institutionName}}</span></h2>
            <form [formGroup]="documentForm" class="edit-user-form xn-fadein-up">
                <div class="">
                    <div>
                        <mat-form-field appearance="outline">
                          <mat-label>Enter Registration Number</mat-label>
                            <input type="text" formControlName="regNumber" matInput />
                        </mat-form-field>
                    </div>

                </div>
              <div class="">
                <div>
                  <div class="xn-input-file">
                    <label class="control-label"
                    >Certificate of Registration (PDF or Image)</label
                    >
                    <input
                      type="file"
                      accept=".pdf,.jpg,.jpeg,.png.jpg,.jpeg,.png"
                      formControlName="certificateFile"
                      id="certificateFile"
                      name="certificateFile"
                      (change)="onDocumentUpload($event)"
                    />
                    <label class="file-label" #certificateFileLabel for="certificateFile"></label>
                    <figure class="icon"></figure>
                  </div>
                </div>
              </div>

                <div class="u-justify actions">
                    <button type="button" (click)="close.emit()" mat-button mat-flat-button color="warn">
                        Cancel
                    </button>
                    <button
                            type="button"
                            [disabled]="!documentForm.valid"
                            mat-button
                            mat-flat-button
                            color="primary"
                            (click)="submitDocument()"
                    >
                        Update
                    </button>
                </div>
            </form>
        </div>
    </div>
</slideover>
