<slideover [zIndex]="3" (closeWindow)="close.emit()">
  <div class="page-section">
    <h2 class="page-subtitle huge">Assign Profile to New Advisor</h2>
    <div class="error" *ngIf="isError">
      <p>{{message}}</p>
    </div>

    <form class="edit-advisor-form"
          (ngSubmit)="submitEditForm()"
          [formGroup]="editForm">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Advisors</mat-label>
          <mat-select
            formControlName="advisor"
            [ngModel]="advisorInfo.id">
            <mat-option
              *ngFor="let advisor of advisors"
              value="{{advisor.id}}">{{advisor.firstName}} {{advisor.lastName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="u-justify actions">
        <button
          type="submit"
          mat-button
          mat-flat-button
          color="primary">
          Update
        </button>
      </div>
    </form>
  </div>
</slideover>
