import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  AfterContentInit,
} from '@angular/core';
import {
  Form,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {CapabilityService} from '@orion/services/capability.service';
import {RoleService} from '@orion/services/role.service';
import {Role, User} from '@orion/models';
import {Capability} from "@orion/models/capability";

@Component({
  selector: 'create-capability',
  templateUrl: './create-capability.component.html',
  styleUrls: ['./create-capability.component.scss'],
})
export class CreateCapabilityComponent implements OnInit {
  constructor(private formBuilder: UntypedFormBuilder,
              private capabilityService: CapabilityService,
              private roleService: RoleService) {}
  @Output() close = new EventEmitter();
  @Input() capability: Capability;
  @Output() onComplete = new EventEmitter();
  form: UntypedFormGroup;

  allRoles: Role[];
  selectedRoles = [];

  ngOnInit(): void {
    this.initRoles();
    this.form = this.formBuilder.group({
      configAttribute: ['', [Validators.required]],
      url: ['', [Validators.required]]
    });

    if (this.capability != null) {
      this.form.get('configAttribute').setValue(this.capability.configAttribute);
      this.form.get('url').setValue(this.capability.url);
      this.selectedRoles = this.capability.configAttribute.split(',');
      // pretick role checkboxes
      // this.allRoles.forEach((role) => {
      //   this.selectedRoles.push(role.id);
      // });
    }
  }

  setCheckbox(name: string) {
    if (this.selectedRoles.find((roleName) => roleName === name)) {
      return true;
    }
  }

  updateRoles(name: string) {
    // check if role exists in selected roles
    const matchingRole = this.selectedRoles.findIndex((roleName) => roleName === name);

    if (matchingRole >= 0) {
      // item exists, remove it from the selected list
      this.selectedRoles.splice(matchingRole, 1);
    } else {
      // item does not exist, add it to selected list
      this.selectedRoles.push(name);
    }

    // update form control values
    this.form.get('configAttribute').setValue(this.selectedRoles.join(','));
  }

  createCapability() {
    const confirmation = confirm(
      'Are you sure you want to create this capability?'
    );
    if (confirmation && this.form.valid) {
      // do your magic here
      this.capabilityService.saveCapability(this.form.value)
        .subscribe(() => {
            alert('Created Successfully');
            this.closeForm();
          },
          error => {
            alert(`Something went wrong: ${error}`);
          }
        );
    }
  }

  updateCapability(id: string) {
    const confirmation = confirm(
      'Are you sure you want to update this capability?'
    );
    if (confirmation && id && this.form.valid) {
      // do your magic here
      this.capabilityService.updateCapability(id, this.form.value)
        .subscribe( () => {
          alert('Updated Successfully');
          this.closeForm();
      },
          error => {
          alert(`Something went wrong: ${error}`);
        });
    }
  }

  deleteCapability(id: string) {
    const confirmation = confirm(
      'Are you sure you want to delete this capability?'
    );
    if (confirmation && id) {
      // do your magic here
      this.capabilityService.deleteCapability(id)
        .subscribe(() => {
          alert('Successfully Deleted');
          this.closeForm();
        },
          error => {
           alert(`Something went wrong: ${error}`);
          });
    }
  }

  initRoles(): any {
    this.roleService.getRoles()
      .subscribe(role => {
        this.allRoles = role.data;
      },  error => {
        alert(`${error}, failed to generate Roles`);
      } );
  }
  closeForm() {
    this.close.emit();
  }
}
