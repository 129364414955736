<ng-container *appSecAccess="['ROLE_SUPER_ADMIN']">
  <app-task-create
    [job]="jobs"
    *ngIf="view == 'app-task-create' || view == 'edit-job'" (close)="closeEditView()"
    [job]="selectedJob">
  </app-task-create>


  <div class="custom-page-header">
    <div class="xn-orion-table-container-task">
      <h1 class="page-title">
        Tasks
      </h1 >
      <div class="task-header">
        <h3><span>Currently Executing</span></h3>
      </div>
      <ng-container *ngIf="jobs.runningJobs">
        <ul class="rolldown-list myList">
          <li *ngFor="let running of jobs.runningJobs">
            <span style="margin-left: 20px;">
              {{running.name}} (Will Run In the Next: [{{running.fireTime}}])</span>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="!jobs.runningJobs">
        <ul class="rolldown-list myList">
          <li>
            <span>
              No Job currently executing</span>
          </li>
        </ul>
      </ng-container>
      <br />
      <div class="task-header">
        <h3><span>Scheduled Jobs</span></h3>
        <h5 style="margin-bottom: -23px;">{{activeTenant}}:</h5>
      </div>
      <br />
      <ng-container *ngIf="jobs.scheduledJobs">
        <ul class="rolldown-list myList" *ngFor="let getScheduled of jobs.scheduledJobs">
          <li *ngFor="let viewScheduled of getScheduled[tenant]" style="display: flex;justify-content: space-between;">
            <span style="margin-top: 5px;">
              {{viewScheduled.taskName}} (Will Run In the Next: [{{viewScheduled.nextTimeFired}}]) </span>
            <div>
              <span>
                <mat-icon class="xn-material-icons-size"
                  (click)="unscheduleJob(viewScheduled.taskName)">stop_circle</mat-icon>
                <mat-icon class="xn-material-icons-size"
                  (click)="runJob(viewScheduled.taskName)">play_circle_filled</mat-icon>
              </span>
            </div>
          </li>
        </ul>
  
  
      </ng-container>
      <ng-container *ngIf="!jobs.scheduledJobs">
        <ul class="rolldown-list myList">
          <li>
            <span>No Job currently Scheduled</span>
          </li>
        </ul>
      </ng-container>
      <br />
      <h2 class="page-subtitle static">All Tasks</h2>
  
      <div class="action-button">
        <button mat-flat-button mat-button color="primary" (click)="view = 'app-task-create'">
          <span>Create</span>
        </button>
      </div>
      <br>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
        <!-- Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>NAME</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>DESCRIPTION</th>
          <td mat-cell *matCellDef="let element">
            {{ element.description }}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="cronExpression">
          <th mat-header-cell *matHeaderCellDef>CRON EXPRESSION</th>
          <td mat-cell *matCellDef="let element">
            {{ element.cronExpression }}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="taskClass">
          <th mat-header-cell *matHeaderCellDef>TASK CLASS</th>
          <td mat-cell *matCellDef="let element">
            {{ element.taskClass }}
          </td>
        </ng-container>
  
  
  
        <!-- Date Created -->
        <ng-container matColumnDef="dateCreated">
          <th mat-header-cell *matHeaderCellDef>CREATED</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.dateCreated | date}}</span>
          </td>
        </ng-container>
  
        <!-- Date Last Updated -->
        <ng-container matColumnDef="lastUpdated">
          <th mat-header-cell *matHeaderCellDef>UPDATED</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.lastUpdated | date}}</span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element;">
            <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="scheduleJob(element.id)"><mat-icon>play_arrow</mat-icon> Schedule</button>
              <button mat-menu-item (click)="unscheduleJob(element.id)"><mat-icon>stop</mat-icon> Un Schedule</button>
              <button mat-menu-item (click)="editJob(element)"><mat-icon>edit</mat-icon> Edit</button>
            </mat-menu>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
      </table>
  
      <mat-paginator [length]="dataSource.resultsLength | async" [pageSize]="50" showFirstLastButtons
        [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>


  
   
    
    <div>

      
      

    </div>

    

    </div>

  <div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
    <mat-spinner color="accent"></mat-spinner>
  </div>

  <br />
  <br />
  <br />
</ng-container>

<ng-container *appSecAccess="!['ROLE_SUPER_ADMIN']">
  <div class="z-box">
    <div>
      <mat-spinner></mat-spinner>
      <br />
      <br />
      <h1>Un authorized</h1>
      <br />
    </div>

  </div>
</ng-container>
