import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {AccountMergeService} from '@orion/services/account-merge.service';

@Component({
  selector: 'app-reject-account-merge',
  templateUrl: './reject-account-merge.component.html',
  styleUrls: ['./reject-account-merge.component.scss']
})
export class RejectAccountMergeComponent implements OnInit {

  @Output() close = new EventEmitter();
  rejectForm: UntypedFormGroup;
  @Input() accountMergeId: any;

  isError = false;
  message!: string;


  constructor(private formBuilder: UntypedFormBuilder,
              private accountMergeService: AccountMergeService,
              private utilities: UtilitiesService) {
  }

  ngOnInit(): void {


    this.rejectForm = this.formBuilder.group({
      rejectionReason: [null, [Validators.required],
      ],
    });

  }

  submitRejection(status: string): void {
    const rejectionReason = this.rejectForm.value.rejectionReason;
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.accountMergeService
          .approveMergeAccount(this.accountMergeId, status, rejectionReason != null ? rejectionReason : null)
          .subscribe({
            next: (response) => {
              if (response) {
                if (response.code === 200) {
                  this.closeForm();
                  this.isError = false;
                  this.utilities.successAlert('Successfully rejected the merge');
                  this.ngOnInit();
                } else {
                  this.closeForm();
                  this.isError = true;
                  this.utilities.errorAlert('Sorry, Please try again');
                  this.ngOnInit();
                }

              }
            },
            error: (error) => {

            }
          });
      }
    });
  }

  closeForm() {
    this.close.emit();
  }
}
