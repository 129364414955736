<edit-goal-strategy [accountGoal]="accountGoal" [goalAllocations]="accountGoal.allocations" [goal]="accountGoal.id"
                    [orionUser]="orionUser"
                    [investmentStrategy]="accountGoal.investmentStrategy"
                    *ngIf="editStrategyView" (close)="editStrategyView = false"
  (onUpdateStrategy)="updateStrategy($event)"></edit-goal-strategy>


<slideover [zIndex]="zIndex" (closeWindow)="closeWindow()">
  <div class="spinner-container fill-container" *ngIf="isLoading">
    <mat-spinner color="accent" [diameter]="70"></mat-spinner>
  </div>
  <div *ngIf="!isLoading">
    <div class="page-section xn-fadein-up xn-delay-2">
      <div class="d-flex">
        <div class="me-auto p-2 mt-4">
          <h2 class="page-subtitle">
            {{ accountGoal.goalTitle | titlecase }} Goal
          </h2>
        </div>
        <div class="p-2 mt-0">
          <form class="" [formGroup]="filtersForm" *ngIf="downloadStatements">
            <div class="date-filter">
              <mat-form-field appearance="fill">
                <mat-label>Date-Filter</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input
                    matStartDate
                    matInput
                    placeholder="Start date"
                    formControlName="startDate"
                  />
                  <input
                    matEndDate
                    matInput
                    placeholder="End date"
                    formControlName="endDate"
                    (dateChange)="applyDateRangeFilter($event)"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
          </form>
        </div>
        <div class="p-2 mt-1">
          <button *ngIf="downloadStatements" aria-expanded="false" class="btn btn-primary btn-lg fs-6 ps-4 px-4" (click)="downloadPerformanceSummary(accountGoal.id,
                 profileDetails.email,
                 accountId)" type="button" [disabled]="isProgressLoading">
            <mat-progress-spinner *ngIf="isProgressLoading" mode="indeterminate" diameter="20" strokeWidth="4"
                                  color="accent"></mat-progress-spinner>
            <ng-container *ngIf="!isProgressLoading">
              Performance Summary
            </ng-container>
          </button>
        </div>
        <div class="p-2 mt-1">
          <button *ngIf="downloadStatements" aria-expanded="false" class="btn btn-primary btn-lg fs-6 ps-4 px-4" (click)="downloadStatement(accountGoal.id,
                 profileDetails.email,
                 accountId)" type="button" [disabled]="isProgressLoading">
            <mat-progress-spinner *ngIf="isProgressLoading" mode="indeterminate" diameter="20" strokeWidth="4"
                                  color="accent"></mat-progress-spinner>
            <ng-container *ngIf="!isProgressLoading">
              Download return statement
            </ng-container>
          </button>
        </div>
      </div>


      <table class="xn-table mat-elevation-z8 is-summary">
        <tbody>
          <tr>
            <td>Goal Number</td>
            <td>{{ accountGoal.goalNumber }}</td>
          </tr>
          <tr>
            <td>Date Created</td>
            <td>{{ accountGoal.dateCreated | date }}</td>
          </tr>
          <tr>
            <td>Net Invested</td>
            <td>{{tenantCurrency}} {{ accountGoal.netInvested | number: "1.0-0" }}</td>
          </tr>
          <tr>
            <td>Total Deposits</td>
            <td>
              <span *ngIf="accountGoal.depositSummary">{{tenantCurrency}} {{accountGoal.depositSummary.totalDeposits | number:
                "1.0-0"}}
              </span>
              <span *ngIf="accountGoal.depositSummary == null"></span>
            </td>
          </tr>
          <tr>
            <td>Total Withdrawals</td>
            <td>
              <span class="u-txt-red">{{tenantCurrency}} {{accountGoal.totalWithdraws | number: "1.0-0"}}</span>
            </td>
          </tr>
          <tr>
            <td>Average Monthly Deposits</td>
            <td>
              <span *ngIf="accountGoal.goalTarget">{{tenantCurrency}} {{accountGoal.goalTarget.averageMonthlyDeposit | number:
                "1.0-0"}}</span>
              <span *ngIf="accountGoal.goalTarget == null"></span>
            </td>
          </tr>
          <tr>
            <td>Current Values</td>
            <td>{{tenantCurrency}} {{ accountGoal.currentValue | number: "1.0-0" }}</td>
          </tr>
          <tr>
            <td>Absolute Gain</td>
            <td>
              <strong><span class="u-txt-bold u-txt-green">+</span> {{tenantCurrency}}
                {{ accountGoal.absoluteGain | number: "1.0-0" }}</strong>
            </td>
          </tr>
          <tr>
            <td>Risk Tolerance</td>
            <td>{{ accountGoal.riskTolerance }} out of 10</td>
          </tr>
          <tr>
            <td>Investment strategy</td>
            <td>{{ accountGoal.investmentStrategy }}</td>
          </tr>

          <tr *ngIf="accountGoal.depositSummary">
            <td>Time Invested</td>
            <td>
              <span *ngIf="accountGoal.goalTarget"> {{ accountGoal.depositSummary?.timeElapsed?.years | number:
                "1.0-1"}}
                years
              </span>
              <span *ngIf="accountGoal.goalTarget == null"></span>
            </td>
          </tr>
          <tr>
            <td>Last Investment Date</td>
            <td>{{ accountGoal.lastDepositDate | date}}</td>
          </tr>

          <tr>
            <td>Last Valuation Date</td>
            <td>{{ accountGoal.lastValuationDate | date}}</td>
          </tr>
          <tr>
            <td>Days Since Last Deposit</td>
            <td>
              <span *ngIf="accountGoal.daysLastDeposited">{{ accountGoal.daysLastDeposited.days}} days ago
              </span>
              <span *ngIf="accountGoal.daysLastDeposited == null"></span>
            </td>
          </tr>

        </tbody>
      </table>

    </div>

    <div class="page-section xn-fadein-up xn-delay-2">
      <div class="u-justify is-table-header">
        <h2 class="page-subtitle">
          Target
        </h2>
      </div>

      <table class="xn-table mat-elevation-z8 is-summary">
        <tbody>
          <tr>
            <td>Time Horizon</td>
            <td>{{ accountGoal.timeHorizon}}</td>
          </tr>

          <tr>
            <td>Target Value</td>
            <td>{{ accountGoal.targetValue | number: "1.0-0" }}</td>
          </tr>

          <tr>
            <td>Recommended Monthly Deposit</td>
            <td>
              <span *ngIf="monthlySavings">{{tenantCurrency}} {{ monthlySavings | number: "1.0-0"}}
              </span>
              <span *ngIf="monthlySavings == null"></span>
            </td>
          </tr>

          <tr>
            <td>Initial Investment</td>
            <td>{{tenantCurrency}} {{ accountGoal.initialInvestment | number: "1.0-0" }}</td>
          </tr>
          <tr>
            <td>Total Deposited</td>
            <td>
              <span *ngIf="accountGoal.depositSummary">{{tenantCurrency}} {{accountGoal.depositSummary.totalDeposits | number:
                "1.0-0"}}</span>
              <span *ngIf="accountGoal.depositSummary == null"></span>
            </td>
          </tr>


        </tbody>
      </table>
    </div>

    <div class="page-section xn-fadein-up xn-delay-3">
      <div class="u-justify is-table-header">
        <h2 class="page-subtitle">Strategy</h2>
        <ng-container *appSecAccess="['ROLE_SUPER_ADMIN']">
          <button mat-button mat-flat-button color="primary" (click)="editStrategyView = true">
            Edit
          </button>
        </ng-container>
        <ng-container *appSecAccess="['ROLE_OPERATIONS']">
          <button mat-button mat-flat-button color="primary" (click)="editStrategyView = true">
            Edit
          </button>
        </ng-container>
      </div>
      <table class="xn-table mat-elevation-z8 no-table-hover">
        <thead>
          <tr>
            <th>Asset</th>
            <th>Recommended (%)</th>
            <th>Current (%)</th>
            <th>Net Invested ({{tenantCurrency}})</th>
            <th>Gain ({{tenantCurrency}})</th>
            <th>Current Value ({{tenantCurrency}})</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let allocation of sortedAllocations">
            <td>{{ allocation.asset }}</td>
            <td>{{ allocation.allocation }}</td>
            <td>{{
              (allocation.currentValue / sumCurrentValue()) * 100 | number: "1.0-1"
              }}
            </td>
            <td>{{allocation.totalInvested | number}}</td>
            <td>
              <span>{{allocation.gain | number: "1.0-0"}}</span>
            </td>
            <td>{{allocation.currentValue | number: "1.0-0"}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td></td>
            <td></td>
            <td>
              <span *ngIf="sortedAllocations"> {{ sumTotalInvested() | number: "1.0-0" }}
              </span>
              <span *ngIf="sortedAllocations == null"></span>
            </td>
            <td>
              <span class="c-d" *ngIf="sortedAllocations">{{ sumGain() | number: "1.0-0" }}</span>
              <span *ngIf="sortedAllocations == null"></span>
            </td>
            <td>
              <span *ngIf="sortedAllocations">{{ sumCurrentValue() | number: "1.0-0" }}
              </span>
              <span *ngIf="sortedAllocations == null"></span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="page-section xn-fadein-up xn-delay-2">
      <div class="u-justify is-table-header">
        <h2 class="page-subtitle">
          {{accountGoalAutoSave?.data.length > 1 ? 'AutoSaves' :
          accountGoalAutoSave?.data.length == 1 ? 'AutoSave':
          'AutoSave'}}
        </h2>
      </div>

      <table class="xn-table mat-elevation-z8 no-table-hover" *ngIf="accountGoalAutoSave?.data.length > 0">
        <thead>
          <tr>
            <th>Created on</th>
            <th>Goal Title</th>
            <th>Status</th>
            <th>AutoSave Amount</th>
            <th>Frequency</th>
            <th>Source</th>
            <th>Total Deposited</th>
            <th>Executions</th>
            <th>Successful</th>
            <th>Failed</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let autoSaveDetails of accountGoalAutoSave.data">
            <td>{{ autoSaveDetails.dateCreated | date }}</td>
            <td>{{ autoSaveDetails.goalTitle }}</td>
            <td>
              <span class="status-label {{
              autoSaveDetails.status == 'ACTIVE' ? 'active' :
              autoSaveDetails.status == 'FAILED' ? 'failed' :
               autoSaveDetails.status == 'CANCELED' ? 'cancelled' :
               autoSaveDetails.status == 'PENDING' ? 'pending' :
                 'not-set'}}">{{autoSaveDetails.status}}</span>
            </td>
            <td>{{tenantCurrency}} {{ autoSaveDetails.amount | number }}</td>
            <td>{{ autoSaveDetails.frequency }}</td>
            <td>{{ autoSaveDetails.source }}</td>
            <td>{{tenantCurrency}} {{ autoSaveDetails.amountDeposited }}</td>
            <td>{{ autoSaveDetails.executionTimes }}</td>
            <td>{{ autoSaveDetails.successful }}</td>
            <td>{{ autoSaveDetails.failed }}</td>
          </tr>
        </tbody>
      </table>
      <p *ngIf="accountGoalAutoSave?.data.length == 0">
        No AutoSave set by user
      </p>
    </div>

    <div class="page-section xn-fadein-up xn-delay-4">
      <h2 class="page-subtitle">Transactions</h2>
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="All">
          <table class="xn-table mat-elevation-z8 no-table-hover" *ngIf="accountGoal?.transactions?.length > 0">
            <thead>
              <tr>
                <th>Type</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let transaction of accountGoal.transactions">
                <td>{{ transaction.transactionType }}</td>
                <td>{{ transaction.transactionDate | date }}</td>
                <td class="u-bold" [class.c-d]="transaction.amount > 0" [class.c-f]="transaction.amount < 0">
                  {{tenantCurrency}}
                  {{ transaction.amount | number: "1.0-0"}}
                </td>
                <td>
                  <span class="status-label approved">{{ transaction.status }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="accountGoal?.transactions?.length == 0">
            No transactions made by user
          </p>
        </mat-tab>
        <mat-tab label="Deposits">
          <table class="xn-table mat-elevation-z8 no-table-hover" *ngIf="accountGoal?.transactions?.length > 0">
            <thead>
              <tr>
                <th>Type</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let transaction of accountGoal.transactions | myFilter: filterDeposits">
                <td>{{ transaction.transactionType }}</td>
                <td>{{ transaction.transactionDate | date }}</td>
                <td>
                  <span class="u-txt-green">{{tenantCurrency}}
                    {{ transaction.amount | number: "1.0-0"}}</span>
                </td>
                <td>
                  <span class="status-label approved">{{ transaction.status }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="accountGoal?.transactions?.length == 0">
            No transactions made by user
          </p>
        </mat-tab>
        <mat-tab label="Withdraws">
          <table class="xn-table mat-elevation-z8 no-table-hover" *ngIf="accountGoal?.transactions?.length > 0">
            <thead>
              <tr>
                <th>Type</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let transaction of accountGoal.transactions | myFilter: filterWithdrawals">
                <td>{{ transaction.transactionType }}</td>
                <td>{{ transaction.transactionDate | date }}</td>
                <td>
                  <span class="u-txt-red">{{tenantCurrency}}
                    {{ transaction.amount | number: "1.0-0"}}</span>
                </td>
                <td>
                  <span class="status-label approved">{{ transaction.status }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="accountGoal?.transactions?.length == 0">
            No transactions made by user
          </p>
        </mat-tab>
      </mat-tab-group>
<!--      <p *ngIf="accountGoal?.transactions?.length == 0">-->
<!--        No transactions made by user-->
<!--      </p>-->
    </div>

    <div class="page-section xn-fadein-up xn-delay-4">
      <h2 class="page-subtitle">Recent Mobile Activity</h2>
      <table class="xn-table mat-elevation-z8 no-table-hover" *ngIf="accountGoal?.recentTransactions?.length > 0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Phone Number</th>
            <th>Goal Number</th>
            <th>Transaction Status</th>
            <th>Investment Status</th>
            <th>Reason</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let transaction of accountGoal.recentTransactions">
            <td>{{ transaction.transactionDate | date }}</td>
            <td class="u-bold" [class.c-d]="transaction.transactionStatus.toLowerCase() === 'successful'"
              [class.c-f]="transaction.transactionStatus.toLowerCase() === 'failed'">
              {{tenantCurrency}} {{ transaction.amount | number: "1.0-0"}}
            </td>
            <td> {{transaction.phoneNumber}}</td>
            <td> {{transaction.goalNumber}}</td>
            <td>
              <span class="status-label {{transaction.transactionStatus | lowercase }}">{{
                setStatus(transaction.transactionStatus) | lowercase }} </span>
            </td>
            <td>
              <span class="status-label {{transaction.investmentStatus | lowercase}}">{{
                setStatus(transaction.investmentStatus) | lowercase }} </span>
            </td>
            <td>{{transaction.reason}}</td>
          </tr>
        </tbody>
      </table>
      <p *ngIf="accountGoal?.recentTransactions?.length == 0">
        No recent transactions made by user
      </p>
    </div>

    <div class="page-section xn-fadein-up xn-delay-5">
      <h2 class="page-subtitle">SetUp Questions</h2>
      <table class="xn-table mat-elevation-z8 no-table-hover is-fixed">
        <thead>
          <tr>
            <th>Question</th>
            <th>Response</th>
            <!--            <th>Date Created</th>-->
            <!--            <th>Last Updated</th>-->
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let setupQuestion of accountGoal.goalSetupAnswers">
            <td>
              <p>{{ setupQuestion.question }}</p>
            </td>
            <td>
              <p>{{ setupQuestion.answer }}</p>
            </td>
            <!--            <td>-->
            <!--              <p>{{ setupQuestion.dateCreated | date}}</p>-->
            <!--            </td>-->
            <!--            <td>-->
            <!--              <p>{{ setupQuestion.lastUpdated | date}}</p>-->
            <!--            </td>-->

          </tr>
          <tr>
            <td *ngIf="accountGoal.goalSetupAnswers == null">

            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="page-section xn-fadein-up xn-delay-6">
      <h2 class="page-subtitle">Risk Assessment Questions</h2>
      <table class="xn-table mat-elevation-z8 no-table-hover is-fixed">
        <thead>
          <tr>
            <th>Question</th>
            <th>Response</th>
            <th>Date Created</th>
            <th>Last Updated</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let riskAssessmentQuestion of accountGoal?.riskAssessmentQuestions">
            <td>
              <p>
                {{riskAssessmentQuestion.question}}
              </p>
            </td>
            <td>
              <p>{{riskAssessmentQuestion.answer}}</p>
            </td>
            <td>
              <p>{{riskAssessmentQuestion.dateCreated | date}}</p>
            </td>
            <td>
              <p>{{riskAssessmentQuestion.lastUpdated | date}}</p>
            </td>

          </tr>
          <tr>
            <td *ngIf="accountGoal.riskAssessmentQuestions == null"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="page-section xn-fadein-up xn-delay-6">
      <h2 class="page-subtitle">Our Recommendation</h2>
      <table class="xn-table mat-elevation-z8 is-summary">
        <tbody *ngIf="accountGoal.goalRecommendations">
          <tr>
            <td>Time Horizon</td>
            <td>{{ accountGoal.timeHorizon}}</td>
          </tr>
          <tr>
            <td>Total Deposits</td>
            <td>{{tenantCurrency}} {{accountGoal.goalRecommendations.totalDeposit | number: "1.0-0"}}</td>
          </tr>
          <tr>
            <td>Worst case Return</td>
            <td>{{tenantCurrency}} {{accountGoal.goalRecommendations.worstCase | number: "1.0-0"}}</td>
          </tr>
          <tr>
            <td>Most-like Return</td>
            <td>{{tenantCurrency}} {{accountGoal.goalRecommendations.mostLikely | number: "1.0-0"}}</td>
          </tr>
          <tr>
            <td>Best-case Return</td>
            <td>{{tenantCurrency}} {{accountGoal.goalRecommendations.bestCase | number: "1.0-0"}}</td>
          </tr>
          <tr>
            <td>Date Created</td>
            <td>{{accountGoal.goalRecommendations.dateCreated | date}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="accountGoal.goalRecommendations == null">
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <!--      <button class="actions-button"-->
      <!--              mat-button mat-flat-button-->
      <!--              color="accent"-->
      <!--              *ngIf="showDeactivateButton"-->
      <!--      (click)="deactivate(accountGoal.goalNumber)">-->
      <!--        <mat-icon matPrefix>delete</mat-icon>-->
      <!--        <span>Deactivate Goal</span>-->
      <!--      </button>-->
      <div *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
        <ng-container *ngIf="isLocked">
          <button class="actions-button" mat-button mat-flat-button color="accent"
            (click)="initGoalWithdrawLock(accountGoal.id)">
            <mat-icon matPrefix>lock</mat-icon>
            <span>Remove Withdraw Lock</span>
          </button>
        </ng-container>
      </div>

      <div *appSecAccess="['ROLE_OPERATIONS']">
        <ng-container *ngIf="isLocked">
          <button class="actions-button" mat-button mat-flat-button color="accent"
            (click)="initGoalWithdrawLock(accountGoal.id)">
            <mat-icon matPrefix>lock</mat-icon>
            <span>Remove Withdraw Lock</span>
          </button>
        </ng-container>
      </div>


    </div>
  </div>
</slideover>
