import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {map, take} from 'rxjs/operators';
import {ConfirmModalComponent} from '@orion/shared/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private dialog: MatDialog) { }

  // @ts-ignore
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  public open(options: any) {
    this.dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText
      }
    });
  }
  // @ts-ignore
  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(5), map(res => {
        return res;
      }
    ));
  }
}
