import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {Export} from '@orion/models/export';
import {UtilitiesService} from '@orion/utilities/utilities.service';

export class ExportDataSource implements DataSource<Export> {

  private exportSubject = new BehaviorSubject<Export[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private utilitiesService: UtilitiesService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Export[] | ReadonlyArray<Export>> {
    return this.exportSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.exportSubject.complete();
    this.loadingSubject.complete();
  }

  loadData() {
    const dateTime = this.utilitiesService.formatDateWithTime(new Date());
    const resp = [
      {
        name: 'Client Account Data',
        url: 'export/client-account-data',
        fileName: 'Ultima Client Account Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Client Account Goal Data',
        url: 'export/client-account-goal-data',
        fileName: 'Ultima Client Account Goal Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Client Bank Data',
        url: 'export/client-bank-data',
        fileName: 'Ultima Client Bank Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Client Data',
        url: 'export/client-data',
        fileName: 'Ultima Client Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Client Goal Allocations',
        url: 'export/client-allocations',
        fileName: 'Ultima Goal Allocation Data Export - ' + dateTime + '.xlsx'
      },
      {
        name: 'Goal Stats Data',
        url: 'export/goal-stat-export',
        fileName: 'Ultima Goal Stats Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Client Extra Data',
        url: 'export/client-data-extra',
        fileName: 'Ultima Client Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Mobile Payments Data',
        url: 'export/mobile-payment-data',
        fileName: 'Ultima Payments Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Withdraw Request Data',
        url: 'export/withdraw-request-data',
        fileName: 'Ultima Payments Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Goal Transactions Data',
        url: 'export/goal-data',
        fileName: 'Ultima Goal Transactions Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Fund Prices Data',
        url: 'export/prices-data',
        fileName: 'Ultima Fund Prices Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Fund Transactions Data',
        url: 'export/funds-data',
        fileName: 'Ultima Fund Transactions Data Export -' + dateTime + '.xlsx'
      },
      {
        name: 'Referral Data',
        url: 'export/referral-data',
        fileName: 'Ultima Referral Data Export -' + dateTime + '.xlsx'
      },
    ];
    this.loadingSubject.next(true);
    this.exportSubject.next(resp);
    this.resultsLengthSubject.next(resp.length);
    this.loadingSubject.next(false);
  }
}
