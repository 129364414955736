<div class="center-selector-modal">
    <div class="center-selector-content-box">
        <div class="center-selector-header">
            <div class="center-header-txt">Country Switcher</div>
            <mat-icon class="close-btn" (click)="closeCenterModal()">cancel</mat-icon>
        </div>
        <div class="divider-line-2 "></div>
      <div class="content-center-box">
        <h5 class="center-title">Are you sure you want to switch to the {{ countryName }} Orion View?</h5>
        <div class="text-confirm">Please note, if you click <strong>"Continue"</strong> we shall automatically switch you to the {{ countryName }} view and you will be creating, viewing and editing  {{ countryName }} information.</div>
        <div class="center-btn-submit" type="submit" (click)="switchCountry()">
            <span class="">{{isSwitching ? 'Switching...' : 'Yes, Continue'}}</span>
          </div>
        <!-- <ng-content></ng-content> -->
      </div>
    </div>
  </div>