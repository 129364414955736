<ng-container *ngIf="switchCountry">
  <app-country-switcher [countryName]="countryName" [countryCode]="countryCode" (closeWindow)="closeCenterModal()">

  </app-country-switcher>
</ng-container>

<app-single-profile *ngIf="showProfile" [profileId]="profileId" [myKycStatus]="filteredOptions"
  (closeProfile)="showProfile = false"></app-single-profile>
<ng-container *ngIf="showModal">
  <app-user-dash-single-profile [showProfile]="true" (hideProfile)="hideProfileModal()" [profileId]="profileId"
    [isProspect]="isProspectStatus">
  </app-user-dash-single-profile>
</ng-container>

<app-single-goal (closeGoal)="closeGoalDetails()"
                 *ngIf="singleGoal"
                 [accountId]="accountId"
                 [tenantCurrency]="tenantCurrency"
                 [profileDetails]="profileDetails"
                 [accountType]="accountType"
                 [accountGoalId]="goalId" [zIndex]="2">
</app-single-goal>

<app-single-account *ngIf="showAccount" [id]="accountId" (closeWindow)="showAccount = false">
</app-single-account>

  <div class="wrap" *ngIf="orionUser">
    <div class="search-form">
      <form>
        <ng-container *ngIf="isHideToolbar">
        <div class="search-form" *ngIf="goalsSearch">
          <div class="search-form-input">
            <input type="text" placeholder="Search {{ context }}" [formControl]="goalsControl" (keyup)="onKey($event)"
                   autocomplete="false" />
            <div class="search-results" [class.is-visible]="
            goalsControl.value != null && goalsControl.value.length > 0
          ">
              <button class="search-close" *ngIf="goalsControl.value != null && goalsControl.value.length > 0"
                      (click)="goalsControl.setValue('')">
                <mat-icon color="primary">close</mat-icon>
              </button>
              <div class="mat-spinner-wrap" *ngIf="isLoading">
                <mat-spinner color="accent" [diameter]="40"></mat-spinner>
              </div>
              <h2>
                Results for
                <span>"{{ goalsControl.value }}"</span>
                <span *ngIf="resultsCount != null && resultsCount != 0">- {{ resultsCount }}</span>
              </h2>
              <div class="search-results__wrap">
            <span class="no-results" *ngIf="resultsCount == 0">Sorry, no entries found!
            </span>
                <ng-container *ngIf="resultsCount != 0 && resultsCount != null">
                  <table cellspacing="0" cellpadding="0">
                    <thead>
                    <tr>
                      <th>Created</th>
                      <th>Goal</th>
                      <th>User</th>
                      <th>Target</th>
                      <th>AutoSave</th>
                      <th>Funding</th>
                      <th>Band</th>
                      <th>Last Deposit</th>
                      <th>Progress</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let profile of filteredOptions"
                        (click)="showGoalProfileDetail(profile.id, profile.isProspect, profile.goalId)"
                        class="{{profile.kycStatus | lowercase}}">

                      <td>
                        <div class="u-profile">

                          <figure class="{{
                            profile.kycStatus | lowercase
                          }} u-profile-photo" [style.backgroundImage]="
                            'url('  + loadGoalCoverImg(profile.goalRefNumber) + ')'
                          "></figure>
                          <span>{{ profile.goalTitle | titlecase }}</span>
                        </div>
                      </td>
                      <td> {{profile.firstName | titlecase}} {{profile.lastName | titlecase}}</td>
                      <td>{{ profile.source | uppercase }}</td>
                      <td>
                      <span
                        class="{{profile.hasTargets === false ?
               'status-label has-no-target' :
               'status-label has-target'}}">
              {{profile.hasTargets === false ?
                        'not set' :
                        'set'}}
            </span>
                      </td>
                      <td>
                      <span>
                          <span
                            class="{{profile.hasAutoSave === true ?
               'status-label has-autosave' :
               'status-label has-no-autosave'}}">
              {{profile.hasAutoSave === true ?
                            'on' :
                            'off'}}
            </span></span>
                      </td>
                      <td>
                    <span style="color: white">
                      {{profile.id}}  {{profile.isProspect}}
                    </span>
                        <span
                          class="{{profile.isFunded === false ?
               'status-label not-funded' :
               'status-label funded'}}">
              {{profile.isFunded === false ?
                          'not funded' :
                          'funded'}}
            </span>
                      </td>

                      <td>
                        {{profile.aumBand}}
                      </td>

                      <td>
                        {{profile.lastDepositDate | date : 'MMM d, y'}}
                      </td>
                      <td>
                        {{profile.achieved == null ?  0 : profile.achieved}} %
                      </td>

                    </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        </ng-container>
        <div class="search-form" *ngIf="usersDashprofileSearch">
          <div class="search-form-input">
            <input type="text" placeholder="Search {{ context }}" [formControl]="userDashbordControl"
                   (keyup)="onKey($event)" autocomplete="false" />
          </div>
          <div class="search-results" [class.is-visible]="
            userDashbordControl.value != null && userDashbordControl.value.length > 0
          ">
            <button class="search-close" *ngIf="userDashbordControl.value != null && userDashbordControl.value.length > 0"
                    (click)="userDashbordControl.setValue('')">
              <mat-icon color="primary">close</mat-icon>
            </button>
            <div class="mat-spinner-wrap" *ngIf="isLoading">
              <mat-spinner color="accent" [diameter]="40"></mat-spinner>
            </div>
            <h2>
              Results for
              <span>"{{ userDashbordControl.value }}"</span>
              <span *ngIf="resultsCount != null && resultsCount != 0">- {{ resultsCount }}</span>
            </h2>
            <div class="search-results__wrap">
            <span class="no-results" *ngIf="resultsCount == 0">Sorry, no entries found!
            </span>
              <ng-container *ngIf="resultsCount != 0 && resultsCount != null">
                <table cellspacing="0" cellpadding="0">
                  <thead>
                  <tr>
                    <th>Profile</th>
                    <th>Created</th>
                    <th>Source</th>
                    <th>Accounts</th>
                    <th>Phone Number</th>
                    <th>KYC status</th>
                    <th>Funding</th>
                    <!-- <th>Advisor</th> -->
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let profile of filteredOptions"
                      (click)="showProfileDetail(profile.id, profile.isProspect)"
                      class="{{profile.kycStatus | lowercase}}">
                    <td>
                      <div class="u-profile">

                        <figure class="{{
                            profile.kycStatus | lowercase
                          }} u-profile-photo" [style.backgroundImage]="
                            'url('  + profile.avatarUrl + ')'
                          "></figure>
                        <span>{{ profile.firstName | titlecase }}
                          {{ profile.lastName | titlecase }}</span>
                      </div>
                    </td>
                    <td>{{ profile.dateCreated | date }}</td>
                    <td>{{ profile.source | uppercase }}</td>
                    <td>
                      {{
                        profile.numberOfAccounts ? profile.numberOfAccounts : 0
                      }}
                    </td>
                    <td>
                      <span> {{ profile.phoneNumber }} </span>
                    </td>
                    <td>
                      <span class="{{ profile.kycStatus | lowercase }} status-label center">{{ profile.kycStatus
                        }}</span>
                    </td>

                    <td>
                      <span class="status-label {{ profile.fundedStatus | lowercase }}">
                        {{ setFundedStatus(profile.fundedStatus) | lowercase }}
                      </span>
                    </td>
                    <!-- <td >
                      <span class="center"> {{profile.advisor}} </span>
                    </td> -->
                  </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="search-form" *ngIf="assignedUsersSearch">
          <div class="search-form-input">
            <input type="text" placeholder="Search {{ context }}" [formControl]="assignedUsersControl"
                   (keyup)="onKey($event)" autocomplete="false" />
          </div>
          <div class="search-results" [class.is-visible]="
            assignedUsersControl.value != null && assignedUsersControl.value.length > 0
          ">
            <button class="search-close"
                    *ngIf="assignedUsersControl.value != null && assignedUsersControl.value.length > 0"
                    (click)="assignedUsersControl.setValue('')">
              <mat-icon color="primary">close</mat-icon>
            </button>
            <div class="mat-spinner-wrap" *ngIf="isLoading">
              <mat-spinner color="accent" [diameter]="40"></mat-spinner>
            </div>
            <h2>
              Results for
              <span>"{{ assignedUsersControl.value }}"</span>
              <span *ngIf="resultsCount != null && resultsCount != 0">- {{ resultsCount }}</span>
            </h2>
            <div class="search-results__wrap">
            <span class="no-results" *ngIf="resultsCount == 0">Sorry, no entries found!
            </span>
              <ng-container *ngIf="resultsCount != 0 && resultsCount != null">
                <table cellspacing="0" cellpadding="0">
                  <thead>
                  <tr>
                    <th>Profile</th>
                    <th>Created</th>
                    <th>Source</th>
                    <th>Accounts</th>
                    <th>Phone Number</th>
                    <th>KYC status</th>
                    <th>Funding</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let profile of filteredOptions"
                      (click)="showProfileDetail(profile.id, profile.isProspect)"
                      class="{{profile.kycStatus | lowercase}}">
                    <td>
                      <div class="u-profile">
                        <figure class="{{
                            profile.kycStatus | lowercase
                          }} u-profile-photo" [style.backgroundImage]="
                            'url(' + profile.avatarUrl + ')'
                          "></figure>
                        <span>{{ profile.firstName | titlecase }}
                          {{ profile.lastName | titlecase }}</span>
                      </div>
                    </td>
                    <td>{{ profile.dateCreated | date }}</td>
                    <td>{{ profile.source | uppercase }}</td>
                    <td>
                      {{
                        profile.numberOfAccounts ? profile.numberOfAccounts : 0
                      }}
                    </td>
                    <td>
                      <span> {{ profile.phoneNumber }} </span>
                    </td>
                    <td>
                      <span class="{{ profile.kycStatus | lowercase }} status-label center">{{ profile.kycStatus
                        }}</span>
                    </td>

                    <td>
                      <span class="status-label {{ profile.fundedStatus | lowercase }}">
                        {{ setFundedStatus(profile.fundedStatus) | lowercase }}
                      </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="search-form" *ngIf="institutionSearch">
          <div class="search-form-input">
            <input type="text" placeholder="Search {{ context }}" [formControl]="institutionControl"
                   (keyup)="onKey($event)" autocomplete="false" />
          </div>
          <div class="search-results" [class.is-visible]="
            institutionControl.value != null &&
            institutionControl.value.length > 0
          ">
            <button class="search-close" *ngIf="
              institutionControl.value != null &&
              institutionControl.value.length > 0
            " (click)="institutionControl.setValue('')">
              <mat-icon color="primary">close</mat-icon>
            </button>
            <div class="mat-spinner-wrap" *ngIf="isLoading">
              <mat-spinner color="accent" [diameter]="40"></mat-spinner>
            </div>
            <h2>
              Results for
              <span>"{{ institutionControl.value }}"</span>
              <span *ngIf="resultsCount != null && resultsCount != 0">- {{ resultsCount }}</span>
            </h2>
            <div class="search-results__wrap">
            <span class="no-results" *ngIf="resultsCount == 0">Sorry, no entries found!
            </span>
              <ng-container *ngIf="resultsCount != 0 && resultsCount != null">
                <table cellspacing="0" cellpadding="0">
                  <thead>
                  <tr>
                    <th>Institution</th>
                    <th>Account Number</th>
                    <th class="center">Type</th>
                    <th class="center">Representative</th>
                    <!-- <th class="center">Goals</th> -->
                    <th>Created</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let institution of filteredOptions" (click)="showAccountDetails(institution.id)">
                    <td>
                      {{ institution.institutionName | titlecase }}
                    </td>
                    <td>{{ institution.accountNumber | titlecase }}</td>
                    <td class="center">
                      {{ institution.accountType | uppercase }}
                    </td>
                    <td class="center">
                      {{ institution.representative | titlecase }}
                    </td>
                    <td>{{ institution.dateCreated | date }}</td>
                  </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="country-icon-section">
      <div class="username-details">
        <!-- <div class="user-lastname">
          {{orionUser.username}}
        </div> -->
        <div class="user-lastname">
          {{orionUser.firstName}} {{orionUser.lastName}}
        </div>
      </div>
      <!-- <div class="divider-line"></div> -->
      <div class="user-icon">
        <span>{{orionUser.firstName[0]}}{{orionUser.lastName[0]}}</span>
      </div>
      <div class="divider-line"></div>
      <!-- <img src='../../../assets/icons/user_icon.png' class="user-icon" /> -->
      <div class="switch-country" (click)="showCountrySwitcher()">
        <img src='../../../assets/icons/{{currentInstanceCode}}.png' class="country-icon" />
        <span class="country-icon-text">{{currentInstanceCode}}</span>
        <ng-container *appSecAccess="['ROLE_XENO_GROUP']">
          <mat-icon class="fa-chevron-up" *ngIf="countrySelector">expand_less</mat-icon>
          <mat-icon class="fa-chevron-down" *ngIf="!countrySelector">expand_more</mat-icon>
          <div *ngIf="countrySelector" class="country-dropdown">
            <div class="country-item" (click)="confirmSwitchCountry(country.cName, country.code)"
                 *ngFor="let country of countries | myFilter:filterCountries">
              <img src='../../../assets/icons/{{country.code}}.png' class="country-icon" />
              <span class="user-lastname">{{country.name}}</span>
            </div>
          </div>
        </ng-container>
      </div>


    </div>
  </div>


