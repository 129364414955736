import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {DepositConfirmationService} from "@orion/pages/deposit-confirmation/deposit-confirmation.service";
import {UtilitiesService} from "@orion/utilities/utilities.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-deposit-reminder',
  templateUrl: './deposit-confirmation.component.html',
  styleUrl: './deposit-confirmation.component.scss'
})
export class DepositConfirmationComponent implements OnInit {
  isError = false;
  isFileSelected = false;
  isLoading = false;
  isUpdating = false;
  responseData: any;
  message!: string;
  fileName: string;
  fileToUpload: File = null;
  @ViewChild('getFile')
  myInputVariable: ElementRef;
  error: string;
  dragAreaClass: string;

  constructor(private service: DepositConfirmationService,
              private utilities: UtilitiesService,
  ) {
  }
  ngOnInit() {
  }

  uploadCSvFile() {
    if (this.fileToUpload === null) {
      this.utilities.error2Alert('Please upload the CSV File');
    }
    const formData = new FormData();
    formData.append('file', this.fileToUpload);
    this.utilities.confirm2Alert().then((result) => {
      if (result.isDismissed) {
        window.location.reload();
      }
      else if (result.isConfirmed) {
        this.isLoading = true;
        this.service
          .uploadCsFile(formData)
          .subscribe(
            (response) => {
              if (response.code === 200) {
                  this.isLoading = false;
                 this.isFileSelected = false;
                  this.responseDialog();
              } else {
                this.isLoading = false;
                this.utilities.error2Alert('Failed to upload file!!');
                this.ngOnInit();
              }
            },
            (error) => {
              this.isLoading = false;
              this.utilities.error2Alert(error);
              this.ngOnInit();
            }
          );
      }
    });
  }

  responseDialog() {
    this.utilities.successAlert('Done!!!');
  }

  onFileChange(e) {
    if (e.target.files.length > 0) {
      this.fileToUpload = e.target.files[0];
      this.fileName = e.target.files[0].name;
      this.isFileSelected = true;
      this.uploadCSvFile();
    } else {
      this.fileToUpload = null;
    }
  }


  onFileDrop(event: any) {
    this.onFileChange(event);
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.fileToUpload = event.dataTransfer.files[0];
      this.fileName = event.dataTransfer.files[0].name;
      this.isFileSelected = true;
      this.uploadCSvFile();
    }
  }

}
