<div class="dialog-header">
  <h1 mat-dialog-title class="dialog-title mx-xxl-3 mx-xl-3 mx-lg-3 mx-md-3 mx-sm-3 text-white">Transactions found</h1>
</div>
<div mat-dialog-content>
  <table mat-table [dataSource]="transactionsData" matSort matSortActive="dateCreated" matSortDisableClear
    matSortDirection="desc" class="xn-table">

    <!-- providerID Column -->
    <ng-container matColumnDef="providerID">
      <th mat-header-cell *matHeaderCellDef>Provider_ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.providerId}}
      </td>
    </ng-container>

    <!-- amount Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let element">
       <span class="currency-txt">{{tenantCurrency}}</span> {{ element.amount | number }}
      </td>
    </ng-container>

    <!--  transactionDate Column-->
    <ng-container matColumnDef="transactionDate">
      <th mat-header-cell *matHeaderCellDef>Transaction Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.transactionDate | date }} <span class="join-time">- {{ element.transactionDate | date:'shortTime' }}</span>
      </td>
    </ng-container>

    <!-- currentStatus Column -->
    <ng-container matColumnDef="currentStatus">
      <th mat-header-cell *matHeaderCellDef>Transaction Status</th>
      <td mat-cell *matCellDef="let element">
        <span class="xn-left-16 x-text">
          <span *ngIf="element?.transactionStatus" class="status-label {{
                  element?.transactionStatus | lowercase
           }} xn-font-size">{{element?.transactionStatus | lowercase}}</span>
        </span>
      </td>
    </ng-container>

    <!-- investmentStatus Column -->
    <ng-container matColumnDef="investmentStatus">
      <th mat-header-cell *matHeaderCellDef>Investment Status</th>
      <td mat-cell *matCellDef="let element">
        <span class="xn-left-16 x-text">
          <span *ngIf="element?.investmentStatus" class="status-label {{
                  element?.investmentStatus | lowercase
           }} xn-font-size">{{element?.investmentStatus | lowercase}}</span>
        </span>
      </td>
    </ng-container>

    <!-- matchingStatus Column -->
    <ng-container matColumnDef="matchingStatus">
      <th mat-header-cell *matHeaderCellDef>Matching Status</th>
      <td mat-cell *matCellDef="let element">
        <span class="xn-left-16 x-text">
          <span *ngIf="element?.matchingStatus" class="status-label {{
                  element?.matchingStatus | lowercase
           }} xn-font-size">{{element?.matchingStatus | lowercase}}</span>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
  </table>
</div>
<div mat-dialog-actions class="d-flex">
  <div class="flex-grow-1">
    <button mat-stroked-button (click)="cancelUpdate()">Cancel</button>
  </div>
 <div>
   <button mat-raised-button (click)="proceedToUpdate()" color="primary">Update matching transactions</button>
 </div>

</div>
