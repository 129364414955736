import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import {Referrals} from "@orion/models/referrals";
import {ReferralsService} from "@orion/services/referrals.service";

export class ReferralsDataSource implements DataSource<Referrals> {
  private referralsSubject = new BehaviorSubject<Referrals[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private referralsService: ReferralsService) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<Referrals[] | ReadonlyArray<Referrals>> {
    return this.referralsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.referralsSubject.complete();
    this.loadingSubject.complete();
  }

  loadReferrals(
    offset: any,
    max: any,
    sort: string,
    order: string,
    page: number,
    filter = ''
  ) {
    this.loadingSubject.next(true);
    this.referralsService
      .getReferrals(offset, max, sort, order, page, filter)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((resp) => {
        this.referralsSubject.next(resp.data);
        this.resultsLengthSubject.next(resp.totalCount);
      });
  }
}
