import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProfileService} from '@orion/services/profile.service';
import {Account} from '@orion/models';
import {UtilitiesService} from '@orion/utilities/utilities.service';

@Component({
  selector: 'app-edit-company-doc',
  templateUrl: './edit-company-doc.component.html',
  styleUrls: ['./edit-company-doc.component.scss']
})
export class EditCompanyDocComponent implements OnInit {
  @Output() close = new EventEmitter();

  documentForm: UntypedFormGroup;
  @ViewChild('certificateFileLabel', { static: false }) certificateFileLabel: ElementRef;
  @Input() accountDetails: Account;

  constructor(
      private form: UntypedFormBuilder,
      private profileService: ProfileService,
      private utilities: UtilitiesService
  ) {
  }


  ngOnInit(): void {
    this.documentForm = this.form.group({
      regNumber: [null, [Validators.required]],
      certificateFile: [null, [Validators.required]]
    });
  }

  get certificateFile() {
    return this.documentForm.get('certificateFile');
  }

  submitDocument() {
    if (this.documentForm.valid) {
      const formData = new FormData();

      formData.append('accountId', this.accountDetails.id);
      formData.append('regNumber', this.documentForm.controls.regNumber.value);
      formData.append('certificateFile', this.certificateFile.value);
      this.utilities.confirmAlert().then((result) => {
          if (result.isConfirmed) {
            this.profileService.uploadCompanyDocs(formData).subscribe(resp => {
              if (resp.code === 200) {
                this.utilities.successAlert('Successful');
                this.close.emit();
              }
            }, error => {
              this.utilities.errorAlert(error);
            });
          }
        });
      }

  }
  onDocumentUpload(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size < 4000000) {
        this.certificateFileLabel.nativeElement.innerText = file.name;
        this.documentForm.get('certificateFile').setValue(file);
      } else {
        alert('Sorry, file is too big. Please upload file smaller than 4mb');
      }

    }
  }

}
