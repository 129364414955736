import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Account} from '@orion/models';
import {AccountService} from '@orion/services/account.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload-member-phone-numbers',
  templateUrl: './upload-member-phone-numbers.component.html',
  styleUrls: ['./upload-member-phone-numbers.component.scss']
})
export class UploadMemberPhoneNumbersComponent implements OnInit {
  @Output() close = new EventEmitter();
  @ViewChild('memberFileLabel', { static: false }) memberFileLabel: ElementRef;
  @Input() accountDetails: Account;
  arrayBuffer: any;
  file?: File;
  uploaded: any;
  isUpload: boolean;
  isResult: boolean;
  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.isUpload = true;
    this.isResult = false;
  }

  onMemberUpload(event: any) {
    this.file = event.target.files[0];
    this.memberFileLabel.nativeElement.innerText = this.file.name;
  }

  uploadMemberPhoneNumbers() {
    let fileReader: FileReader;
    fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = [];

      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const binaryString = arr.join('');
      const workbook = XLSX.read(binaryString, {type: 'binary'});

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      this.uploaded = XLSX.utils.sheet_to_json(worksheet, {raw: true});

      if (this.uploaded) {
        this.accountService.addMemberPhoneNumbers(JSON.stringify(XLSX.utils.sheet_to_json(worksheet, {raw: true})),
        this.accountDetails.id).subscribe((response) => {
          this.uploaded = response;
        });
      }
      this.isUpload = false;
      this.isResult = true;
    };

    fileReader.readAsArrayBuffer(this.file);
  }
}
