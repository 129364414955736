import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {MatSort} from '@angular/material/sort';
import {ProfileService} from '@orion/services/profile.service';
import {UserDashboardSource} from './users-dashboard-data-source';
import {UserDashboardStats} from '@orion/models/user-dashboard';
import {merge, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {User} from '@orion/models';
import {AuthenticationService} from '@orion/services';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";
import {environment} from "../../../environments/environment";
import {HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-users-dasboard',
  templateUrl: './users-dashboard.component.html',
  styleUrls: ['./users-dashboard.component.scss'],
})
export class UsersDashboardComponent implements OnInit, AfterViewInit {
  @Input() isSearch: boolean;
  @Input() searchResults: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private utilitiesService: UtilitiesService,
  ) {
    // authenticationService.orionUser.subscribe((x) => {
    //   this.orionUser = x
    //   if (x.roles?.includes('ROLE_ADVISOR_SUPERVISOR')) {
    //     this.displayedColumns.push('advisor');
    //   }
    // });
   }

  displayedColumns: string[] = [
    'position',
    'dateCreated',
    'firstName',
    'source',
    'kycStatus',
    'fundedStatus',
  ];

  roles: any = [];
  resultsLength = 0;
  filtersForm: UntypedFormGroup;
  advisorControl = new UntypedFormControl('');
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  advisors: any;
  userDashboardStats?: UserDashboardStats;

  showProfile = false;
  resetFiltersActive = false;
  activeCard: string;
  profileId: string;
  orionUser: User;
  dataSource: UserDashboardSource;
  liveImageUrl = environment.serverUrl;
  // thumbNailBaseUrl = `${environment.serverUrl}/api/v1/guest/passportphoto/`;
 // thumbNailBaseUrl = `${this.liveImageUrl}/api/v1/guest/passportphoto/`;
  isLoading = true;
  showModal = false;
  clientProfileId = '';
  isProspectStatus: boolean;
  filteredOptions: Observable<string[]>;
  tenantId: string = '';
  isNotHidden: boolean;


  ngOnInit() {
    this.loadDashboard();
    this.tenantId = localStorage.getItem("tenantId");
    // this.tenantId = this.authenticationService.tenantId$.value;
    if (this.tenantId === 'UG') {
      this.isNotHidden = true;
    } else {
      this.isNotHidden = false;
    }

  }

  loadDashboard(){
    this.filtersForm = this.formBuilder.group({
      source: [''],
      kycStatus: [''],
      fundedStatus: [''],
      advisor: [''],
      startDate: [''],
      endDate: [''],
    });
    this.dataSource = new UserDashboardSource(this.profileService);
    this.dataSource.loadUserProfiles(0, 50, 'dateCreated', 'desc', 0, '', '');
    this.profileService.getAdvisors().subscribe((advisors) => {
      this.advisors = advisors;
    });
    this.getDashStats('');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadUsersPage()
      ))
      .subscribe();
  }

  loadUsersPage() {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadUserProfiles(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      JSON.stringify(this.filtersForm.value),
      this.activeCard
    );
    this.getDashStats(JSON.stringify(this.filtersForm.value))
  }


  setStatus(status: string) {
    let s = '';

    switch (status) {
      case 'active':
        s = 'verified';
        break;

      default:
        s = status;
        break;
    }
    return s;
  }

  setFundedStatus(status: string) {
    let s = '';

    switch (status) {
      case 'not_funded':
        s = 'Not Funded';
        break;

      default:
        s = 'Funded';
        break;
    }
    return s;
  }

  showProfileDetail(profileId: string, isProspect: boolean) {
    this.clientProfileId = profileId;
    this.isProspectStatus = isProspect;
    this.showModal = true;
  }


  applyDateRangeFilter(event: MatSelectChange) {
    this.resetFiltersActive = true
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadUserProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value),
      this.activeCard
    );

    this.getDashStats(JSON.stringify(this.filtersForm.value));
  }

  applyAdvisorFilter(event: MatSelectChange) {
    this.resetFiltersActive = true
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;

    this.dataSource.loadUserProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value),
      this.activeCard
    );

    this.getDashStats(JSON.stringify(this.filtersForm.value));
  }

  applyFundedStatusFilter(event: MatSelectChange) {
    this.resetFiltersActive = true
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadUserProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value),
      this.activeCard
    );

    this.getDashStats(JSON.stringify(this.filtersForm.value));
  }

  applySourceStatusFilter(event: MatSelectChange) {
    this.resetFiltersActive = true
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadUserProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value),
      this.activeCard
    );


    this.getDashStats(JSON.stringify(this.filtersForm.value));
  }

  applyKycStatusFilter(event: MatSelectChange) {
    this.resetFiltersActive = true
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadUserProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value),
      this.activeCard
    );


    this.getDashStats(JSON.stringify(this.filtersForm.value));
  }

  showProfileModal(profileId: string) {
    this.clientProfileId = profileId;
    this.showModal = true;

  }

  hideProfileModal() {
    this.showModal = false;
    this.clientProfileId = '';
  }

  getDashStats(filters: string){
    this.profileService.getUserDashboardStats(filters).subscribe((dashboardStats) => {
      this.userDashboardStats = dashboardStats.data;
    });
  }

  updateTableData(filter: string){
    this.resetFiltersActive = true
    this.activeCard = filter;
    this.paginator.pageIndex = 0;
    this.dataSource.loadUserProfiles(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value),
      filter
    );

  }

  resetFilters(){
    this.activeCard = '';
    this.filtersForm.reset();
    this.dataSource = new UserDashboardSource(this.profileService);
    this.paginator.pageIndex = 0;
    this.dataSource.loadUserProfiles(0, 50, 'dateCreated', 'desc', 0, '', '');

    this.profileService.getAdvisors().subscribe((advisors) => {
      this.advisors = advisors;
    });
    this.getDashStats('');
    this.resetFiltersActive = false
  }
 }
