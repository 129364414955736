import { Component, OnInit, Output, EventEmitter, Input, output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Staff } from '@orion/models/staff';
import { UserService } from '@orion/services';
import { RoleService } from "@orion/services/role.service";
import { DepartmentService } from "@orion/services/department.service";
import { Department, Role } from "@orion/models";
import { UtilitiesService } from '@orion/utilities/utilities.service';
import { codes } from "@orion/modules/user/create-staff/codes";
import { Router } from '@angular/router';

interface CountryCode {
  name: string;
  dial_code: string;
  code: string;
}


@Component({
  selector: 'create-staff',
  templateUrl: './create-staff.component.html',
  styleUrls: ['./create-staff.component.scss'],
})
export class CreateStaffComponent implements OnInit {
  constructor(private formBuilder: UntypedFormBuilder,
    private staffService: UserService,
    private roleService: RoleService,
    private router: Router,
    private utilities: UtilitiesService,
    private departmentService: DepartmentService) { }
  countryCodes: CountryCode[] = codes;
  countryCode = "256";
  country = "";
  @Input() staff: Staff;
  @Output() close = new EventEmitter();
  @Output() closeReload = new EventEmitter();
  @Output() onComplete = new EventEmitter();
  form: UntypedFormGroup;
  selectedRoles = [];
  selectedDepartments = [];
  file: any;
  isLocked: boolean = false;


  allRoles: Role[];
  allDepartments: Department[];

  ngOnInit(): void {
    this.countryCode = this.utilities.countries();
    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      locked: [''],
      isAdvisor: [false],
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$'),
        ],
      ],
      middleName: [
        '',
        [Validators.minLength(3), Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]*$')],
      ],
      referralCode: [
        '',
        [Validators.minLength(3), Validators.pattern('^[A-Za-z0-9]*$')],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      telephone: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      meetingLink: [''],
      countryCode: [''],
      gender: ['', [Validators.required]],
      departments: ['', [Validators.required]],
    });


    if (this.staff != null) {
      this.form.get('username').setValue(this.staff.username);
      this.form.get('email').setValue(this.staff.email);
      this.form.get('firstName').setValue(this.staff.firstName);
      this.form.get('lastName').setValue(this.staff.lastName);
      this.form.get('middleName').setValue(this.staff.middleName);
      this.form.get('referralCode').setValue(this.staff.referralCode);
      this.form.get('telephone').setValue(this.staff.telephone);
      this.form.get('gender').setValue(this.staff.gender);
      this.form.get('locked').setValue(this.staff.accountLocked);
      this.form.get('meetingLink').setValue(this.staff.meetingLink);
      this.form.get('isAdvisor').setValue(this.staff.isAdvisor);


      if (this.staff.accountLocked) {
        this.isLocked = true
      }


      this.staff.departments.forEach((department) => {
        this.selectedDepartments.push(department.id);
      });
      this.form.get('departments').setValue(this.selectedDepartments.join(','));
    }
    // this.initRoles();
    this.initDepartments();
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
    console.log(this.file);
  }

  submitStaff() {
    let tenantId = localStorage.getItem('tenantId');
    const payload = {
      "email": this.form.value['email'],
      "username": this.form.value['username'],
      "phoneNumber": {
        "countryCode": this.form.value['countryCode'],
        "region": tenantId,
        "subscriberNumber": this.form.value['telephone']
      },
      "firstName": this.form.value['firstName'],
      "lastName": this.form.value['lastName'],
      "middleName": this.form.value['middleName'],
      "tenants": [tenantId],
      "userType": "STAFF",
      "approvalPolicyId": "na",
      "gender": this.form.value['gender'],
      "meetingLink": this.form.value['meetingLink'],
      "departments": this.form.value['departments'],
      "referralCode": this.form.value['referralCode']
    };
    const staffUpdateFormData = new FormData();
    staffUpdateFormData.append('email', this.form.value['email']);
    staffUpdateFormData.append('username', this.form.value['username']);
    staffUpdateFormData.append('telephone', this.form.value['telephone']);
    staffUpdateFormData.append('firstName', this.form.value['firstName']);
    staffUpdateFormData.append('middleName', this.form.value['middleName']);
    staffUpdateFormData.append('lastName', this.form.value['lastName']);
    staffUpdateFormData.append('profilePic', this.file);
    staffUpdateFormData.append('gender', this.form.value['gender']);
    staffUpdateFormData.append('meetingLink', this.form.value['meetingLink']);
    staffUpdateFormData.append('isAdvisor', this.form.value['isAdvisor']);
    staffUpdateFormData.append('accountLocked', this.form.value['locked']);
    staffUpdateFormData.append('referralCode', this.form.value['referralCode']);

    let confirmation;
    if (this.form.valid) {
      if (this.staff != null) {
        // update staff
        this.utilities.confirm4Alert(`Are you sure you want to update ${this.staff.firstName + ' ' + this.staff.lastName} details?`, '').then((result) => {
          if (result.isDismissed) {
            this.close.emit();
          }
          else if (result.isConfirmed) {
            this.staffService.updateUser(this.staff.id, staffUpdateFormData)
              .subscribe(() => {
                this.utilities.success2Alert(`${this.staff.firstName + ' ' + this.staff.lastName} details updated successfully!`).then((result) => {
                  if (result.isConfirmed) {
                    this.closeReload.emit();
                  }
                });
                // this.onUpdateComplete();
              },
                error => alert(`Something went wrong: ${error}`));
          }
        });
      } else {
        // create staff
        this.utilities.confirm4Alert('Are you sure you want to create this staff?', '').then((result) => {
          if (result.isDismissed) {
            this.close.emit();
          }
          else if (result.isConfirmed) {
            this.staffService.saveUser(payload)
              .subscribe(() => {
                this.utilities.success2Alert('Successfully Created Staff Details!').then((result) => {
                  if (result.isConfirmed) {
                    this.closeReload.emit();
                  }
                });
                // this.onUpdateComplete();
              },
                error => alert(`Something went wrong: ${error}`));
          }
        });
      }
    }
  }

  deleteStaff(staff: Staff) {
    const confirmation = confirm('Are you sure you want to delete this staff?');
    if (confirmation) {
      // do your submit magic here
      this.staffService.deleteUser(staff.id)
        .subscribe(() => {
          alert('Successfully Deleted');
          this.onUpdateComplete();
          this.close.emit();
        },
          error => {
            alert(`Something went wrong: ${error}`);
            this.onUpdateComplete();
            this.close.emit();
          });
    }
  }

  setCheckbox(id: string) {
    if (this.selectedRoles.find((roleId) => roleId === id)) {
      return true;
    }
  }

  setDepartmentCheckbox(id: string) {
    if (this.selectedDepartments.find((departmentId) => departmentId === id)) {
      return true;
    }
  }

  initRoles(): any {
    this.roleService.getRoles()
      .subscribe(role => {
        this.allRoles = role.data;
      }, error => {
        alert(`${error}, failed to generate Roles`);
      });
  }

  initDepartments(): any {
    this.departmentService.getDepartments()
      .subscribe(departments => {
        this.allDepartments = departments.data;
      }, error => {
        alert(`${error}, failed to return Departments`);
      });
  }

  updateRoles(id: string) {
    // check if role exists in selected roles
    const matchingRole = this.selectedRoles.findIndex((roleId) => roleId === id);

    if (matchingRole >= 0) {
      // item exists, remove it from the selected list
      this.selectedRoles.splice(matchingRole, 1);
    } else {
      // item does not exist, add it to selected list
      this.selectedRoles.push(id);
    }

    // update form control values
    this.form.get('roles').setValue(this.selectedRoles.join(','));
  }


  updateDepartments(id: string) {
    // check if role exists in selected roles
    const matchingDepartment = this.selectedDepartments.findIndex((departmentId) => departmentId === id);

    if (matchingDepartment >= 0) {
      // item exists, remove it from the selected list
      this.selectedDepartments.splice(matchingDepartment, 1);
    } else {
      // item does not exist, add it to selected list
      this.selectedDepartments.push(id);
    }

    // update form control values
    this.form.get('departments').setValue(this.selectedDepartments.join(','));

  }

  onSetAdvisor() {
    if (this.staff.isAdvisor) {
      this.utilities.confirm4Alert(`By doing this, the users who were assigned to, ${this.staff.firstName + ' ' + this.staff.lastName} will no longer be assigned to ${this.staff.gender == 'Female' ? 'her' : 'him'}!`, '').then((result) => {
        if (result.isDismissed) {
          this.form.get('isAdvisor').setValue(this.staff.isAdvisor);
        }
        else if (result.isConfirmed) {
          this.form.get('isAdvisor').setValue(false);
        }
      });
    } else {
      return null
    }
  }

  onSetLock() {
    if (!this.staff.accountLocked) {
      this.utilities.confirm4Alert(`By doing this, ${this.staff.firstName + ' ' + this.staff.lastName}'\s account will be locked and ${this.staff.lastName} will nolonger have access to Orion.`, '').then((result) => {
        if (result.isDismissed) {
          this.form.get('locked').setValue(this.staff.accountLocked);
        }
        else if (result.isConfirmed) {
          this.form.get('locked').setValue(true);
        }
      });
    } else if (this.staff.accountLocked) {
      this.utilities.confirm4Alert(`By doing this, ${this.staff.firstName + ' ' + this.staff.lastName}'\s account will be unlocked and ${this.staff.lastName} will have access to Orion.`, '').then((result) => {
        if (result.isDismissed) {
          this.form.get('locked').setValue(this.staff.accountLocked);
        }
        else if (result.isConfirmed) {
          this.form.get('locked').setValue(false);
        }
      });

    } else {
      return null
    }
  }

  onUpdateComplete() {
    this.onComplete.emit();
  }

  public changed(evt) {
    this.countryCode = evt['dial_code'];
  }


}
