import {AfterViewInit, Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';

import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Account } from '@orion/models';
import { AccountService } from '../../services/account.service';
import { Router } from '@angular/router';
import {IndividualDataSource} from '@orion/pages/individuals/individual-data';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-clients',
  templateUrl: './individuals.component.html',
  styleUrls: ['./individuals.component.scss'],
})
export class IndividualsComponent implements OnInit, AfterViewInit {
  @Input() isSearch: boolean;
  @Input() dateChange: EventEmitter<any> = new EventEmitter();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private utilitiesService: UtilitiesService
  ) {
  }

  isLoading = true;
  accounts: Account[] = [];
  // filters
  filtersForm: UntypedFormGroup;
  accountId: string;

  // table
  displayedColumns: string[] = [
    'name',
    'dateCreated',
    'type',
    'number',
    'status'
  ];
  // @ts-ignore
  dataSource: IndividualDataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  singleAccountView = false;

  // events
  goToAccount() {
    this.router.navigateByUrl('/single-account');
  }

  applyFilter(event: MatSelectChange) {
    const filterValue = event.value;
    this.paginator.pageIndex = 0;
    this.dataSource.loadIndividualAccounts(0, 50, 'dateCreated', 'desc', 0, JSON.stringify(this.filtersForm.value));

  }


  ngOnInit() {
    this.filtersForm = this.formBuilder.group({
      accountType: [''],
      accountStatus: [''],
      startDate: [''],
      endDate: ['']
    });

    this.dataSource = new IndividualDataSource(this.accountService);
    this.dataSource.loadIndividualAccounts(0, 50, 'dateCreated', 'desc', 0, '');

    // this.getAllIndividuals();
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadIndividualPage())
        ).subscribe();
  }

  loadIndividualPage() {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadIndividualAccounts(offset, this.paginator.pageSize, this.sort.active, this.sort.direction,
        this.paginator.pageIndex, JSON.stringify(this.filtersForm.value));
  }

  applyDateRangeFilter(event: MatDatepickerInputEvent<Date>) {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadIndividualAccounts(0, 50, 'dateCreated', 'desc', 0, JSON.stringify(this.filtersForm.value));
  }

  get accountType() {
    return this.filtersForm.get('accountType');
  }

  get accountFilter() {
    return this.filtersForm.get('accountFilter');
  }

  get startDate() {
    return this.filtersForm.get('startDate');
  }

  get endDate() {
    return this.filtersForm.get('startDate');
  }

  showAccountDetails(accountId: string) {
    this.singleAccountView = true;
    this.accountId = accountId;
    this.accountService.getIndividualAccountById(accountId).subscribe(res => {
      this.accounts = res;
    });
  }

  closeAccountDetails() {
    this.singleAccountView = false;
  }

}
