<ng-container *ngIf="showModal">
    <app-user-dash-single-profile [showProfile]="true" (hideProfile)="hideProfileModal()" [profileId]="clientProfileId"
        [isProspect]="isProspectStatus">
    </app-user-dash-single-profile>
</ng-container>
<ng-container *ngIf="showModal">
    <div class="overlay">
    </div>
</ng-container>
<div class="header-section-top">
    <h1 class="header-tile">Assigned Users</h1>
    <div class="u-justify xn-fadein-up">

        <ng-container *ngIf="resetFiltersActive">
            <button mat-raised-button class="reset-button" color="primary">
                <span (click)="resetFilters()">Reset filters</span>
            </button>
        </ng-container>
        <form class="page-actions" [formGroup]="filtersForm">
            <!-- Date Filter   -->
            <xn-date-filter
            [startDate]="startDate"
            [endDate]="endDate"
            [selectedValue]="selectedValue"
            (onRangeStart)="setStartDate($event)"
            (onRangeEnd)="setEndDate($event)"
            (onChangeDays)="onChangeDays($event)"
            (onSetAllTime)="onSetAllTime()"
        ></xn-date-filter>
            <!-- Band Filter   -->
            <div class="page-action">
                <mat-form-field appearance="fill">
                    <mat-label>Bands</mat-label>
                    <mat-select formControlName="band" (selectionChange)="applyBandFilter($event)">
                        <mat-option value="M0A">M0a</mat-option>
                        <mat-option value="M0b">M0b</mat-option>
                        <mat-option value="M1">M1</mat-option>
                        <mat-option value="M2">M2</mat-option>
                        <mat-option value="M3">M3</mat-option>
                        <mat-option value="M4">M4</mat-option>
                        <mat-option value="M5">M5</mat-option>
                        <mat-option value="M6">M6</mat-option>
                        <mat-option value="M7">M7</mat-option>
                        <mat-option value="M8">M8</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
</div>
<br />
<div class="xn-fadein-up">
    <ng-container *ngIf="!hideSummaryCards">
        <mat-grid-list cols="4" gutterSize="20" rowHeight="100px">
            <mat-grid-tile>
                <mat-card class="card xn-card-height ">
                    <mat-card-content class="card-content">
                    <div class="card-title">
                        <div>Total Assigned Users</div>
                        <div class="cont" align="center">
                            <mat-icon>group</mat-icon>
                        </div>
                    </div>
                    <!-- <ng-container *ngIf="orionUser">
                            </ng-container> -->
                    <div class="total-card">{{advisorUsersDashboardStats?.assigned ?
                        (advisorUsersDashboardStats?.assigned |
                        number ): 0}}</div>

                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-card class=" card xn-card-height">
                    <mat-card-content class="card-content">
                    <div class="card-title">
                        <div>Total Assigned Users AUM</div>
                        <div class="cont" align="center">
                            <mat-icon>money</mat-icon>
                        </div>
                    </div>
                    <div class="total-card">{{tenantCurrency}} {{advisorUsersDashboardStats?.assignedAum ?
                        (this.utilitiesService.numberFormatter(advisorUsersDashboardStats?.assignedAum)): 0}}</div>
                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-card class="card xn-card-height">
                    <mat-card-content class="card-content">
                    <div class="card-title">
                        <div>Total Deposits</div>
                        <div class="cont" align="center">
                            <mat-icon>money</mat-icon>
                        </div>
                    </div>
                    <div class="total-card">{{tenantCurrency}} {{advisorUsersDashboardStats?.totalDeposits ?
                        (this.utilitiesService.numberFormatter(advisorUsersDashboardStats?.totalDeposits)) : 0}}</div>
                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-card class="card xn-card-height">
                    <mat-card-content class="card-content">
                        <div class="card-title">
                            <div>Total Withdraws</div>
                            <div class="cont" align="center">
                                <mat-icon>money</mat-icon>
                            </div>
                        </div>
                        <div class="total-card">{{tenantCurrency}} {{advisorUsersDashboardStats?.totalWithdraws?
                            (this.utilitiesService.numberFormatter(advisorUsersDashboardStats?.totalWithdraws)): 0}}</div>
                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </ng-container>
</div>
<br />
<div class="xn-padding ">
    <div class="u-justify xn-fadein-up" *ngIf="!isDateFilter">
        <div></div>
        <div>
            <span class="xn-text-label">{{toggleTableView ? 'Viewing since assigned ': 'View since assigned '}}</span>

            <mat-slide-toggle [(ngModel)]="toggleTableView">
            </mat-slide-toggle>
        </div>

    </div>
    <br />
    <div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
        <!-- Advisor table -->
        <table mat-table [dataSource]="advisorDataSource" class="mat-elevation-z8 user-table-data" matSort
            matSortActive="dateCreated" matSortDisableClear>
            <!-- Position Column -->
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                <td mat-cell *matCellDef="let element">{{ element.position }}</td>
            </ng-container>

            <!-- dateCreated Column -->
            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned on</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.dateCreated | date }}
                    <!-- <span class="join-time">- {{
                            element.dateCreated | date:'shortTime' }}</span> -->
                </td>
            </ng-container>

            <!-- firstName Column -->
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef>Names</th>
                <td mat-cell *matCellDef="let element">
                    <div class="u-profile">
                        <div class="user-display-icon">
                            <figure class="user-profile-photo {{ element.kycStatus }}" [style.backgroundImage]="
    'url(' + thumbNailBaseUrl + element.email + ')'
    "></figure>
    <span class="user_initials">{{ element.firstName ? (element.firstName[0] | titlecase) : ''}}{{ element.lastName ? (element.lastName[0] | titlecase) : ''}}</span>
                        </div>
                        
                        <a><span class="user-name-label">{{ element.firstName | titlecase }}</span> </a>
                    </div>
                </td>
            </ng-container>



            <!-- Total Deposit Count Column -->
            <ng-container matColumnDef="noOfDeposits">
                <th mat-header-cell *matHeaderCellDef class="center">No. of Deposits</th>
                <td mat-cell *matCellDef="let element" class="center">
                    {{(toggleTableView ? element.noOfDepositsAfterAssignment : element.noOfDeposits)
                    | number: '1.0-0'}}
                </td>
            </ng-container>

            <!-- Total Deposit Column -->
            <ng-container matColumnDef="totalDeposits">
                <th mat-header-cell *matHeaderCellDef>Total Deposits</th>
                <td mat-cell *matCellDef="let element">
                    <div class="deposits">
                        <span class="currency-code">{{tenantCurrency}}</span> {{
                        (toggleTableView ? element.totalDepositsAfterAssignment : element.totalDeposits) | number:
                        '1.0-0'}}
                    </div>
                </td>
            </ng-container>

            <!-- Total Withdraws Count Column -->
            <ng-container matColumnDef="noOfWithdraws">
                <th mat-header-cell *matHeaderCellDef class="center">No. of Withdraws</th>
                <td mat-cell *matCellDef="let element" class="center">
                    {{(toggleTableView ? element.noOfWithdrawsAfterAssignment : element.noOfWithdraws) | number:
                    '1.0-0'}}
                </td>
            </ng-container>

            <!-- Total withdraws Column -->
            <ng-container matColumnDef="totalWithdraws">
                <th mat-header-cell *matHeaderCellDef>Total Withdraws</th>
                <td mat-cell *matCellDef="let element">
                    <div class="withdraws">
                        <span class="currency-code">{{tenantCurrency}}</span> {{
                        (toggleTableView ? element.totalWithdrawsAfterAssignment : element.totalWithdraws) | number:
                        '1.0-0'}}
                    </div>
                </td>
            </ng-container>

            <!-- AUM before assignment -->
            <ng-container matColumnDef="initialAum">
                <th mat-header-cell *matHeaderCellDef>{{isDateFilter ? '' : 'Assignment AUM'}}</th>
                <td mat-cell *matCellDef="let element">
                    <span class="currency-code">{{isDateFilter ? '' :  tenantCurrency}}</span> {{ isDateFilter ? '' : ((element.aumBeforeAssignment) | number:'1.0-0')}}
                </td>
            </ng-container>

             <!-- Initial Band Column -->
             <ng-container matColumnDef="initialBand">
                <th mat-header-cell *matHeaderCellDef>Assignment Band</th>
                <td mat-cell *matCellDef="let element">
                            <span class="xn-left-16 x-text center">
                        <span *ngIf="element?.initialBand" class="mo-label {{
                                        element?.initialBand | lowercase
                                 }} xn-font-size ">{{element.initialBand}}</span></span>
                    
                </td>
            </ng-container>

            <!--  Total AUM Column -->
            <ng-container matColumnDef="totalAum">
                <th mat-header-cell *matHeaderCellDef>{{toggleTableView ? 'AUM Change' : 'Current AUM' }}</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="toggleTableView">
                        <div
                            [ngClass]="isNegativeAbusoluteAUM(element.totalAUMAfterAssigment) ?'withdraws' :'deposits'">
                            <mat-icon *ngIf="element.totalAUMAfterAssigment != 0" class="aum-icon">
                                {{isNegativeAbusoluteAUM(element.totalAUMAfterAssigment) ? 'arrow_downwards' :
                                'arrow_upwards'}}</mat-icon><span class="currency-code">{{tenantCurrency}}</span> {{
                            element.totalAUMAfterAssigment == 0 ? 0 : (element.totalAUMAfterAssigment |
                            number:'1.0-0')}}
                        </div>
                    </div>
                    <div *ngIf="!toggleTableView">
                        <div class="aum-table"><span class="currency-code">{{tenantCurrency}}</span> {{ (element.totalAum) | number:
                            '1.0-0'}}</div>
                        <div *ngIf="element.totalAUMAfterAssigment != 0 && !isDateFilter"
                            [ngClass]="isNegativeAbusoluteAUM(element.totalAUMAfterAssigment) ?'aum-absolute-negative':'aum-absolute'">
                            <span class="aum-cash"> <mat-icon class="aum-icon">
                                    {{isNegativeAbusoluteAUM(element.totalAUMAfterAssigment) ? 'arrow_downwards' :
                                    'arrow_upwards'}}</mat-icon>{{tenantCurrency}} </span>
                            <div class="aum-amount">{{
                                (utilitiesService.removeFirstCharacter(element.totalAUMAfterAssigment)) | number:
                                '1.0-0'}}
                            </div>
                            <span class="percent-aum">
                                ({{utilitiesService.removeFirstCharacter(element.percentage)}}%)</span>
                        </div>

                    </div>

                </td>
            </ng-container>

              <!-- Current Band Column -->
                                    <ng-container matColumnDef="currentBand">
                                        <th mat-header-cell *matHeaderCellDef class="center">{{toggleTableView ? 'Current AUM ' : 'Current Band' }}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <ng-container *ngIf="toggleTableView">
                                                <div class="aum-table"><span class="currency-code">{{tenantCurrency}}</span> {{ (element.totalAum) | number:
                                                    '1.0-0'}}</div>
                                                </ng-container>
                                                <ng-container *ngIf="!toggleTableView">
                                                    <span class="xn-left-16 x-text center">
                                                <span *ngIf="element?.currentBand" class="mo-label {{
                                                                element?.currentBand | lowercase
                                                         }} xn-font-size">{{element.currentBand}}</span></span>
                                                    </ng-container>
                                            
                                        </td>
                                    </ng-container>

            <!-- Last deposit Column -->
            <ng-container matColumnDef="lastDepositDate">
                <th mat-header-cell *matHeaderCellDef class="right-flow">{{toggleTableView ? 'Current Band ' : 'Last Deposit Date' }}</th>
                <td mat-cell *matCellDef="let element" class="right-flow">
                    <ng-container *ngIf="toggleTableView">
                                                <span class="xn-left-16 x-text">
                                                <span *ngIf="element?.currentBand" class="mo-label {{
                                                                element?.currentBand | lowercase
                                                         }} xn-font-size">{{element.currentBand}}</span></span>
                                                </ng-container>
                                            <ng-container *ngIf="!toggleTableView">
                                                <div>
                                            {{ element?.lastDepositDate ?
                                                (element?.lastDepositDate | date) : 'Never' }}
                                        </div>
                                        <div class="currency-code">
                                            {{ element?.noOfDaysSinceLastDepositDate ?
                                                element?.noOfDaysSinceLastDepositDate : '---' }}
                                        </div>
                                                </ng-container>
                </td>
            </ng-container>
            <tr class="t-header" mat-header-row *matHeaderRowDef="advisorisplayedColumns sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: advisorisplayedColumns; let i = index"
                (click)="showProfileDetail(row.id, false)"></tr>
        </table>
        <mat-paginator [length]="advisorDataSource.resultsLength | async" [pageSize]="50" showFirstLastButtons
            [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
</div>


<div class="spinner-container" *ngIf="!isSearch && advisorDataSource.loading$ | async">
    <mat-spinner color="accent"></mat-spinner>
</div>