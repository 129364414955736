<div class="file-preview" *ngIf="fileUrl != null">
  <button class="close-button" (click)="closeFilePreview()"></button>
  <!-- <button class="download-button" (click)="downloadFile(fileType)"> Download </button> -->

  <div class="spinner-container fill-container" *ngIf="isLoading">
    <mat-spinner color="accent" [diameter]="70"></mat-spinner>
  </div>
  <div class="image-view">
    <img [src]="fileUrl" *ngIf="!isLoading">
<!--    <pdf-viewer-->
<!--    *ngIf="!isLoading"-->
<!--    [src]="idURLFile"-->
<!--    [original-size]="false"-->
<!--    [show-all]="true"-->
<!--    [render-text]="true"-->
<!--    [external-link-target]="'blank'"-->
<!--    [autoresize]="true"-->
<!--    [show-borders]="false"-->
<!--    style="width: 700px; height: 700px"></pdf-viewer>-->
  </div>
  <div *ngIf="fileType == 'Image'">
    <img [src]="idURLFile" *ngIf="!isLoading">
  </div>
  <div *ngIf="fileType == 'PDF'">
    <pdf-viewer
      *ngIf="!isLoading"
      [src]="idURLFile"
      [original-size]="false"
      [show-all]="true"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      style="width: 700px; height: 700px"></pdf-viewer>
  </div>
</div>
