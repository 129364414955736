import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '@orion/services/profile.service';
import { UtilitiesService } from '@orion/utilities/utilities.service';
import { AuthenticationService } from '@orion/services';
import { Profile, User } from '@orion/models';

@Component({
  selector: 'app-reset-pin',
  templateUrl: './reset-pin.component.html',
  styleUrls: ['./reset-pin.component.scss']
})
export class ResetPinComponent implements OnInit {
  @Output() closeWindow = new EventEmitter();
  resetPINForm: FormGroup;
  @Input() profileInformation: any;
  isError = false;
  message!: string;
  currentUser: User;
  constructor(
    private formBuilder: FormBuilder,
    private utilities: UtilitiesService,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService
  ) {

    authenticationService.orionUser.subscribe((x) => (this.currentUser = x));
  }

  ngOnInit(): void {
    this.resetPINForm = this.formBuilder.group({
      ticketNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
        ],
      ],
      comments: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
        ],
      ],

    });
  }

  closeResetPINWindow() {
    this.closeWindow.emit();
  }

  generateUSSDPIN(): void {
    const resetPINFormInput = {
      phoneNumber: this.profileInformation.profilePhoneNumber,
      ticketNumber: this.resetPINForm.value.ticketNumber,
      comments: this.resetPINForm.value.comments,
    };

    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.profileService.resetUSSDPIN(resetPINFormInput)
          .subscribe((response) => {
            var responseCode = JSON.stringify(response['code'])
            if (responseCode == '200') {
              this.closeWindow.emit();
              this.isError = false;
              this.utilities.success2Alert('PIN successfully sent');
            } else {
              this.utilities.error2Alert(JSON.stringify(response['msg']));
            }

          },
            error => {
              this.message = `Un Authorized Access! ${error}`;
              this.isError = true;
            }
          );
      }
    })
  }
}


