import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-country-switcher',
  templateUrl: './country-switcher.component.html',
  styleUrl: './country-switcher.component.scss',
})
export class CountrySwitcherComponent implements OnInit  { 
  @Input() countryName: string;
  @Input() countryCode: string;
  @Input() zIndex: number;
  @Input() size: string;
  @Output() closeWindow = new EventEmitter();

  isSwitching = false;

  ngOnInit(){

  }

  closeCenterModal() {
    this.closeWindow.emit();
  }

  switchCountry() {
    this.isSwitching = true;
    localStorage.removeItem('tenantId');
    localStorage.setItem('tenantId', this.countryCode);
    location.reload();
    this.isSwitching = false;
  }
}
