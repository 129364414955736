<slideover [zIndex]="2" (closeWindow)="close.emit()">
  <div class="page-inner-container">
    <div class="page-section" *ngIf="isUpload">
      <h2 class="page-subtitle">Upload Member Phone Numbers: <span>{{accountDetails.institutionName}}</span></h2>
      <div class="import-member-form">
        <div class="fields">
          <div>
            <div class="xn-input-file xn-top">
              <label class="control-label"
              >Select Excel with list of Members with phone Numbers</label
              >
              <input
                type="file"
                id="memberFile"
                accept=".xlsx"
                (change)="onMemberUpload($event)"
              />
              <label class="file-label" #memberFileLabel for="memberFile"></label>
              <figure class="icon"></figure>
            </div>
          </div>
          <div class="xn-top">
            <button
              class="xn-import-btn"
              type="button"
              mat-button
              mat-flat-button
              color="primary"
              (click)="uploadMemberPhoneNumbers()"
            >
              Import
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="page-section xn-fadein-up xn-delay-4" *ngIf="isResult">
      <h2 class="page-subtitle">Uploaded Members: <span>{{accountDetails.institutionName}}</span></h2>
      <table
        class="xn-table mat-elevation-z8 no-table-hover pointer">
        <thead>
        <tr>
          <th>First Name</th>
          <th>Middle Name</th>
          <th>Last Name</th>
          <th>Phone Number</th>
          <th>Email Address</th>
          <th>Message</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let member of uploaded">
          <td>{{member.firstName}}</td>
          <td>{{member.middleName}}</td>
          <td>{{member.lastName}}</td>
          <td>{{member.countryCode}}{{member.phone}}</td>
          <td>{{member.email}}</td>
          <td>{{member.sendEmail}}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</slideover>
