import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { Staff } from '@orion/models/staff';
import {StaffDataSource} from '@orion/modules/user/view-staff/staff-data-source';
import {MatSort} from '@angular/material/sort';
import {UntypedFormGroup} from "@angular/forms";
import {UserService} from "@orion/services";
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";
import { ProfileService } from '@orion/services/profile.service';


@Component({
  selector: 'view-staff',
  templateUrl: './view-staff.component.html',
  styleUrls: ['./view-staff.component.scss'],
})
export class ViewStaffComponent implements OnInit {
  @Input() isSearch: boolean;
  @Input() searchResults: any;
  displayedColumns: string[] = ['name', 'roles', 'departments', 'accountLocked'];
  dataSource: StaffDataSource;
  resultsLength = 0;
  isLoading = true;
  view = '';
  selectedStaff: Staff;
  staffRoles: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtersForm: UntypedFormGroup;

  constructor(private staffService: UserService, private profileService: ProfileService) {}

  closeEditView() {
    this.view = '';
    this.selectedStaff = null;
  }

  closeEditViewReload(){
    this.view = '';
    this.selectedStaff = null;
    this.initStaffMembers();
  }

  editStaff(staff: Staff) {
    this.view = 'edit-staff';
    this.selectedStaff = staff;
  }

  applyFilter(event: MatSelectChange) {
    const filterValue = event.value;
    this.paginator.pageIndex = 0;
    this.dataSource.loadStaff(0, 50, 'dateCreated', 'desc', 0, JSON.stringify(this.filtersForm.value));
  }

  ngOnInit(): void {
    this.staffRoles = JSON.parse(localStorage.getItem("roles"));
    this.initStaffMembers();
  }

  private initStaffMembers() {
      this.dataSource = new StaffDataSource(this.staffService, this.profileService);
      this.dataSource.loadStaff(0, 50, 'dateCreated', 'desc', 0, '');
  }

  refreshStaff() {
    this.initStaffMembers();
  }
}
