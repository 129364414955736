<slideover [zIndex]="3" (closeWindow)="close.emit()">

  <div class="page-section xn-fadein-up">
    <h2 class="page-subtitle">Edit Goal Stategy</h2>

    <!-- edit -->
    <div class="u-pt-48" *ngIf="view == 'edit'">

      <!-- spinner -->
      <div *ngIf="isLoading" class="spinner">
        <div>
          <mat-spinner color="accent" [diameter]="36"></mat-spinner>
        </div>
        <p>Saving...</p>
      </div>

      <!-- form -->
      <form [formGroup]="editForm" (ngSubmit)="onSubmit()" *ngIf="!isLoading" style="position: relative;">
        <div class="overlay-spinner" *ngIf="isFetching">
          <mat-spinner color="accent" [diameter]="36"></mat-spinner>
        </div>
        <!-- errors -->
        <div *ngIf="errorMsg">
          <p>{{errorMsg}}</p>
        </div>

        <!-- form -->
        <table class="xn-table mat-elevation-z8 no-table-hover is-summary">
          <tbody>
          <tr>
            <td>Select Risk Tolerance</td>
            <td>
              <div class="field-wrap">
                <mat-form-field appearance="outline">
                  <mat-select formControlName="strategy" (selectionChange)="strategyChange($event.value)">
                    <mat-option value="1">1</mat-option>
                    <mat-option value="2">2</mat-option>
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="5">5</mat-option>
                    <mat-option value="6">6</mat-option>
                    <mat-option value="7">7</mat-option>
                    <mat-option value="8">8</mat-option>
                    <mat-option value="9">9</mat-option>
                    <mat-option value="10">10</mat-option>

                    <!-- check if user has rights to select custom option -->
                    <mat-option *ngIf="canSetCustomStrategy" value="custom">Custom</mat-option>

                  </mat-select>
                </mat-form-field>
              </div>
            </td>
          </tr>
          <tr>
            <td>Money Markets</td>
            <td>
              <div class="field-wrap">
                                    <span class="u-txt-bold"
                                          *ngIf="strategy.value != 'custom'">{{moneyMarkets.value}}</span>
                <mat-form-field appearance="outline" *ngIf="strategy.value == 'custom'">
                  <input formControlName="moneyMarkets" type="number" matInput>
                </mat-form-field>
              </div>
            </td>
          </tr>
          <tr>
            <td>Bond Markets</td>
            <td>
              <div class="field-wrap">
                <span class="u-txt-bold" *ngIf="strategy.value != 'custom'">{{bonds.value}}</span>
                <mat-form-field appearance="outline" *ngIf="strategy.value == 'custom'">
                  <input formControlName="bonds" type="number" matInput>
                </mat-form-field>
              </div>
            </td>
          </tr>
          <tr>
            <td>Regional Equities</td>
            <td>
              <div class="field-wrap">
                                    <span class="u-txt-bold"
                                          *ngIf="strategy.value != 'custom'">{{regionalEquities.value}}</span>
                <mat-form-field appearance="outline" *ngIf="strategy.value == 'custom'">
                  <input formControlName="regionalEquities" type="number" matInput>
                </mat-form-field>
              </div>
            </td>
          </tr>

          <tr>
            <td>Domestic Equities</td>
            <td>
              <div class="field-wrap">
                                    <span class="u-txt-bold"
                                          *ngIf="strategy.value != 'custom'">{{domesticEquities.value}}</span>
                <mat-form-field appearance="outline" *ngIf="strategy.value == 'custom'">
                  <input formControlName="domesticEquities" type="number" matInput>
                </mat-form-field>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td>Total</td>
            <td>
                                <span class="u-txt-bold aggregate"
                                      [class.u-txt-red]="aggregate < 100 || aggregate > 100">{{aggregate}}%</span>
              <span class="error u-txt-red" *ngIf="aggregate < 100 || aggregate > 100">Total of all
                                    funds must
                                    equal 100% </span>
            </td>
          </tr>
          </tfoot>
        </table>

        <!-- actions -->
        <div class="u-pt-48">
          <div class="u-justify">
            <button mat-button mat-stroked-button color="primary" type="button"
                    (click)="close.emit()">Cancel</button>
            <button [disabled]="editForm.invalid || (aggregate < 100 || aggregate > 100)" mat-button
                    mat-flat-button color="primary" type="submit">Save</button>
          </div>
        </div>
      </form>
    </div>

    <!-- success -->
    <div *ngIf="view == 'preview'" class="xn-fadein-up u-pt-48">
      <table class="xn-table mat-elevation-z8 no-table-hover is-summary">
        <tbody>
        <ng-container>
          <tr>

            <td>Investment strategy</td>
            <td>
              <span>{{investmentStrategy}}</span>
            </td>

          </tr>
          <tr *ngFor="let p of profile">
            <td>{{p['fund']['fundCode'] }}</td>
            <td>
              <span>{{p['percentageValue']}}%</span>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <!-- actions -->
      <div class="u-pt-48">
        <div class="u-justify">
          <button mat-button mat-stroked-button color="primary" type="button"
                  (click)="view = 'edit'">Back</button>
          <button mat-button mat-flat-button color="primary" type="button"
                  (click)="closeWindow()">Close</button>
        </div>
      </div>
    </div>
  </div>


</slideover>
