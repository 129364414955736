import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DepositConfirmationService {
  apiUrl = `${environment.serverUrl}/api/v1`;

  constructor(private http: HttpClient) {

  }

  uploadCsFile(formData: FormData): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/guest/mails/deposit-confirmations`,
      formData,
      {}
    );
  }
}
