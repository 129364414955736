<ng-container *ngIf="showModal">
    <app-user-dash-single-profile [showProfile]="true" (hideProfile)="hideProfileModal()" [profileId]="clientProfileId"
        [isProspect]="isProspectStatus">
    </app-user-dash-single-profile>
</ng-container>
<ng-container *ngIf="showModal">
    <div class="overlay">
    </div>
</ng-container>


<div class="header-section-top">
    <h1 class="header-tile">Users</h1>
    <div class="u-justify xn-fadein-up">
        <ng-container *ngIf="resetFiltersActive">
            <button mat-raised-button color="primary">
                <span (click)="resetFilters()">Reset filters</span>
            </button>
        </ng-container>
        <form class="page-actions" [formGroup]="filtersForm">
            <!-- Date Filter   -->
            <div class="page-action">
                <mat-form-field appearance="fill">
                    <mat-label>Created</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate matInput placeholder="Start date" formControlName="startDate" />
                        <input matEndDate matInput placeholder="End date" formControlName="endDate"
                            (dateChange)="applyDateRangeFilter($event)" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
            <!-- Source Filter -->
            <div class="page-action">
                <mat-form-field appearance="fill">
                    <mat-label>Source</mat-label>
                    <mat-select formControlName="source" (selectionChange)="applySourceStatusFilter($event)">
                        <mat-option value="ALL">All</mat-option>
                        <mat-option value="WEB">WEB</mat-option>
                      <ng-container *ngIf="isNotHidden">
                        <mat-option value="MTN">MTN</mat-option>
                      </ng-container>
                        <mat-option value="APP_IOS">APP IOS</mat-option>
                      <ng-container *ngIf="isNotHidden">
                        <mat-option value="WEB_MTN">WEB_MTN</mat-option>
                        <mat-option value="WEB_AIRTEL">WEB_AIRTEL</mat-option>
                      </ng-container>
                        <mat-option value="APP_ANDROID">APP ANDROID</mat-option>
                      <ng-container *ngIf="isNotHidden">
                        <mat-option value="APP_ANDROID_MTN">APP ANDROID MTN</mat-option>
                        <mat-option value="APP_ANDROID_Airtel">APP ANDROID Airtel</mat-option>
                        <mat-option value="APP_IOS_MTN">APP IOS MTN</mat-option>
                        <mat-option value="APP_IOS_Airtel">APP IOS Airtel</mat-option>
                      </ng-container>
                        <mat-option value="APP">APP</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- KYC Status Filter   -->
            <div class="page-action">
                <mat-form-field appearance="fill">
                    <mat-label>KYC Status</mat-label>
                    <mat-select formControlName="kycStatus" (selectionChange)="applyKycStatusFilter($event)">
                        <mat-option value="ALL">All</mat-option>
                        <mat-option value="TODO">To do</mat-option>
                        <mat-option value="SUBMITTED">Submitted</mat-option>
                        <mat-option value="VERIFIED">Verified</mat-option>
                        <mat-option value="EXTERNAL">External</mat-option>
                        <mat-option value="REVERTED">Reverted</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- Funded Status Filter  -->
            <div class="page-action">
                <mat-form-field appearance="fill">
                    <mat-label>Funded Status</mat-label>
                    <mat-select formControlName="fundedStatus" (selectionChange)="applyFundedStatusFilter($event)">
                        <mat-option value="ALL">All</mat-option>
                        <mat-option value="funded">Funded</mat-option>
                        <mat-option value="not_funded">Not Funded</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Advisor Filter   -->
            <!-- <div class="page-action" *appSecAccess="['ROLE_ADVISOR_SUPERVISOR']">
                <mat-form-field appearance="fill">
                    <mat-label>Advisor</mat-label>
                    <mat-select formControlName="advisor" (selectionChange)="applyAdvisorFilter($event)">
                        <mat-option value="ALL">All</mat-option>
                        <mat-option *ngFor="let advisor of advisors" value="{{ advisor.id }}">
                            {{advisor.firstName}} {{advisor.lastName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
        </form>
    </div>
</div>
<div class="xn-fadein-up">
    <mat-grid-list cols="5" gutterSize="20" rowHeight="100px">
        <mat-grid-tile (click)="updateTableData('registered')">
            <mat-card [ngClass]="activeCard ==  'registered' ? 'active' : 'card'" class="xn-card-height ">
              <mat-card-content>

                <div class="card-title">
                  <div>Registered</div>
                  <div class="cont" align="center">
                    <mat-icon>group</mat-icon>
                  </div>
                </div>
                <!-- <ng-container *ngIf="orionUser">
                    </ng-container> -->
                <div class="total-card">{{userDashboardStats?.registered.total ? (userDashboardStats?.registered.total |
                  number ): 0}}</div>
              </mat-card-content>

            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile (click)="updateTableData('submitted')">
            <mat-card [ngClass]="activeCard ==  'submitted' ? 'active' : 'card'" class="xn-card-height">
              <mat-card-content>
                <div class="card-title">
                  <div>KYC Submitted</div>
                  <div class="cont" align="center">
                    <mat-icon>group</mat-icon>
                  </div>
                </div>
                <div class="total-card">{{userDashboardStats?.submitted.total ? (userDashboardStats?.submitted.total |
                  number) : 0}}</div>
              </mat-card-content>

            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile (click)="updateTableData('verified')">
            <mat-card [ngClass]="activeCard ==  'verified' ? 'active' : 'card'" class=" xn-card-height">
              <mat-card-content>
                <div class="card-title">
                  <div>KYC Verified</div>
                  <div class="cont" align="center">
                    <mat-icon>group</mat-icon>
                  </div>
                </div>
                <div class="total-card">{{userDashboardStats?.verified.total ? (userDashboardStats?.verified.total |
                  number) : 0}}</div>
              </mat-card-content>

            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile (click)="updateTableData('not_funded')">
            <mat-card [ngClass]="activeCard ==  'not_funded' ? 'active' : 'card'" class="xn-card-height">
              <mat-card-content>
                <div class="card-title">
                  <div>Not Funded</div>
                  <div class="cont" align="center">
                    <mat-icon>group</mat-icon>
                  </div>
                </div>
                <div class="total-card">{{userDashboardStats?.not_funded.total? (userDashboardStats?.not_funded.total |
                  number) : 0}}</div>
              </mat-card-content>

            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile (click)="updateTableData('funded')">
            <mat-card [ngClass]="activeCard ==  'funded' ? 'active' : 'card'" class=" xn-card-height">
              <mat-card-content>
                <div class="card-title">
                  <div>Funded</div>
                  <div class="cont" align="center">
                    <mat-icon>group</mat-icon>
                  </div>
                </div>
                <div class="total-card">{{userDashboardStats?.funded.total ? (userDashboardStats?.funded.total | number)
                  : 0}}</div>
              </mat-card-content>

            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>

</div>
<br />
<div class="xn-padding ">
    <div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
        <!-- All users table -->
        <table mat-table [dataSource]="dataSource" matSort matSortActive="dateCreated" matSortDisableClear
            class="xn-table">
            <!-- Position Column -->
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                <td mat-cell *matCellDef="let element">{{ element.position }}</td>
            </ng-container>

            <!-- dateCreated Column -->
            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.dateCreated | date }} <span class="join-time">- {{ element.dateCreated | date:'shortTime'
                        }}</span>
                </td>
            </ng-container>

            <!-- firstName Column -->
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Names</th>
                <td mat-cell *matCellDef="let element">
                    <div class="u-profile">
                          <div class="user-display-icon">
                            <ng-container *ngIf="element.loadingAvatar; else avatarLoaded">
                              <div class="loader-0">
                              </div> <!-- Add your loader styling here -->
                            </ng-container>
                            <ng-template #avatarLoaded>
                            <figure class="user-profile-photo {{ element.kycStatus }}" [ngStyle]="{'background-image': 'url(' + (element.avatarUrl) + ')'}"></figure>
                            </ng-template>
                            <!--                          <img ngSrc="{{thumbNailBaseUrl}}{{element.email}}" height="50" width="50"/>-->
                            <span class="user_initials">{{ element.firstName ? (element.firstName[0] | titlecase) :
                                ''}}{{ element.lastName ? (element.lastName[0] | titlecase) : ''}}</span>
                        </div>
                        <a> <span class="user-name-label">{{ element.firstName | titlecase }}
                                {{ element.lastName | titlecase }}</span></a>
                    </div>
                </td>
            </ng-container>

            <!--  Column -->
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef>SOURCE</th>
                <td mat-cell *matCellDef="let element">
                    {{ (element.source) | uppercase | replace : '_' : ' ' }}
                </td>
            </ng-container>

            <!-- kycStatus Column -->
            <ng-container matColumnDef="kycStatus">
                <th mat-header-cell *matHeaderCellDef>KYC</th>
                <td mat-cell *matCellDef="let element">
                    <span class="status-label {{ element.kycStatus | lowercase }}">
                        {{ setStatus(element.kycStatus) | lowercase }}
                    </span>
                </td>
            </ng-container>
            <!-- fundedStatus Column -->
            <ng-container matColumnDef="fundedStatus">
                <th mat-header-cell *matHeaderCellDef>FUNDING</th>
                <td mat-cell *matCellDef="let element">
                    <span class="status-label {{ element.fundedStatus | lowercase }}">
                        {{ setFundedStatus(element.fundedStatus) | lowercase }}
                    </span>
                </td>
            </ng-container>

            <!-- XENO Advisor Column -->
            <ng-container matColumnDef="advisor" *appSecAccess="['ROLE_ADVISOR_SUPERVISOR']">
                <th mat-header-cell *matHeaderCellDef>ADVISOR</th>
                <td mat-cell *matCellDef="let element">{{ element.advisor }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
                (click)="showProfileDetail(row.id, row.isProspect)"></tr>
        </table>


        <mat-paginator [length]="dataSource.resultsLength | async" [pageSize]="50" showFirstLastButtons
            [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
</div>


<div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
    <mat-spinner color="accent"></mat-spinner>
</div>
