import {CommonModule, TitleCasePipe} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Profile} from '@orion/models';
import {
  DisableProfileService
} from "@orion/pages/user-dash-single-profile/micro/disable-profile/disableProfile.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-disable-profile',
  standalone: true,
  imports: [TitleCasePipe, CommonModule, MatProgressSpinner],
  templateUrl: './disable-profile.component.html',
  styleUrl: './disable-profile.component.scss'
})
export class DisableProfileComponent implements OnInit {
  @Input() profileDetails: Profile;
  @Output() isOpened = new EventEmitter();
  @Input() view: string = "preview";
  isLoading: boolean = false;
  isError: boolean = false;
  isSuccess: boolean = true;
  @Input() isOpenWindow: boolean;
  isSuccessWindow: boolean;
  isErrorWindow: boolean;
  message: string = "";
  isServerError: boolean = false;
  messages= []

  constructor(private service: DisableProfileService) {
  }


  ngOnInit() {
  }


  closeModal() {
    this.isOpened.emit(false);
  }

  confirm() {
    // Success message
    //  this.view = "success";
    //  this.isSuccess = true;
    //  this.isError = false;
    //  this.isOpenWindow = true;
    //  this.isSuccessWindow = true;

    // Error message
    // this.view = "error";
    // this.isSuccess = false;
    // this.isError = true;
    // this.isOpenWindow = true;
    // this.isSuccessWindow = false;
    // this.isErrorWindow = true;
    // Uncomment once live
    this.isLoading = true;
    this.service.disableProfile(this.profileDetails.id).subscribe(
      (resp: any) => {
        console.log(`sss: ${JSON.stringify(resp)}`)
      // if (resp.code === 200) {
        console.log(JSON.stringify(resp));
        this.isLoading = false;
        this.view = "success";
        this.isSuccess = true;
        this.isError = false;
        this.isOpenWindow = true;
        this.isSuccessWindow = true;
        this.ngOnInit();
      // }

    }, (error: any)=> {
        error['error']['activeGoals'].forEach((c) => {
          let status = error['error']['status'];
          let currentValue = c['currentValue'];
          let numberOfGroupAccounts =error['error']['groupAccounts'].length;
          if (status === 406 &&
            currentValue > 0 &&
            numberOfGroupAccounts > 0) {
            this.messages = ["The client has a funded goal",
              "The client is still part of a group"];
          }else
          if (status === 406 &&
            currentValue > 0) {
            this.messages = ["The client has a funded goal"];
          }else if (status === 406 &&
            numberOfGroupAccounts > 0) {
            this.messages = ["The client is still part of a group"];
          }
          this.isLoading = false;
          this.view = "error";
          this.isSuccess = false;
          this.isError = true;
          this.isOpenWindow = true;
          this.isSuccessWindow = false;
          this.isErrorWindow = true;
          return;
        });


         if(error['status'] == 500) {
           this.isLoading = false;
           this.isServerError = true;
           this.messages = ["The client has a funded goal",
             "The client is still part of a group"];
           return;
         }

    });


  }
}
