import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Capability } from '@orion/models/capability';
import {CapabilitiesDataSource} from '@orion/modules/user/view-capabilities/capabilities-data-source';
import {CapabilityService} from '@orion/services/capability.service';
import {MatSort} from '@angular/material/sort';
import {UntypedFormGroup} from '@angular/forms';
import {merge} from "rxjs";
import {tap} from "rxjs/operators";
import {Role} from "@orion/models";
import {RoleService} from '@orion/services/role.service';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";


@Component({
  selector: 'view-capabilities',
  templateUrl: './view-capabilities.component.html',
  styleUrls: ['./view-capabilities.component.scss'],
})
export class ViewCapabilitiesComponent implements OnInit, AfterViewInit {
  @Input() isSearch: boolean;
  @Input() searchResults: any;
  displayedColumns: string[] = ['configAttributes', 'url'];
  dataSource: CapabilitiesDataSource;
  resultsLength = 0;
  isLoading = true;
  view = '';
  selectedCapability: Capability;
   allRoles: Role[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtersForm: UntypedFormGroup;


  constructor(private capabilityService: CapabilityService,
              private roleService: RoleService) {}

  closeEditView() {
    this.view = '';
    this.selectedCapability = null;
    this.refreshCapabilities();
  }

  editCapability(capability: Capability) {
    this.view = 'edit-capability';
    this.selectedCapability = capability;
  }

  applyFilter(event: MatSelectChange) {
    const filterValue = event.value;
    this.paginator.pageIndex = 0;

    this.dataSource.loadCapabilities(0, 50, 'url', 'desc', 0, JSON.stringify(this.filtersForm.value));
  }

  ngOnInit(): void {
    this.initCapabilities();
  }

  private initCapabilities(){
    this.dataSource = new CapabilitiesDataSource(this.capabilityService);
    this.dataSource.loadCapabilities(0, 50, 'url', 'desc', 0, '');
  }

  loadCapabilitiesPage() {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadCapabilities(offset, this.paginator.pageSize, this.sort.active, this.sort.direction,
      this.paginator.pageIndex, JSON.stringify(this.filtersForm.value));
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     tap(() => this.loadCapabilitiesPage())
    //   ).subscribe();
  }

  initRoles(): any {
    this.roleService.getRoles()
      .subscribe(role => {
        this.allRoles = role.data;
      },  error => {
        alert(`${error}, failed to generate Roles`);
      } );
  }
  refreshCapabilities() {
    this.initCapabilities();
  }
}
