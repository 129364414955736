<app-single-account
  *ngIf="singleAccountView"
  [id]="accountId"
  (closeWindow)="closeAccountDetails()"
></app-single-account>


  <div class="page-header xn-fadein-up">
    <h1 class="page-title">Individual Accounts</h1>
    <form class="page-actions" [formGroup]="filtersForm">
      <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label>Created</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              matInput
              placeholder="Start date"
              formControlName="startDate"
            />
            <input
              matEndDate
              matInput
              placeholder="End date"
              formControlName="endDate"
              (dateChange)="applyDateRangeFilter($event)"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label>Type</mat-label>
          <mat-select formControlName="accountType"
                      (selectionChange)="applyFilter($event)">
            <mat-option value="">All</mat-option>
            <mat-option value="PERSONAL">Personal</mat-option>
            <mat-option value="JOINT">Joint</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="page-action">
        <mat-form-field appearance="fill">
          <mat-label>Status</mat-label>
          <mat-select formControlName="accountStatus"
                      (selectionChange)="applyFilter($event)">
            <mat-option value="all">All</mat-option>
            <mat-option value="funded">Funded</mat-option>
            <mat-option value="not-funded">Not Funded</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="mat-elevation-z8 xn-fadein-up xn-delay-2">
    <table
  [dataSource]="dataSource"
      mat-table
      matSort
      matSortActive="dateCreated"
      matSortDisableClear
      matSortDirection="desc"
      style="table-layout: auto"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">
          <span>{{ row.accountName | titlecase }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let row">{{ row.dateCreated | date }}</td>
      </ng-container>

      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Account Number
        </th>
        <td mat-cell *matCellDef="let row">
          <a>{{ row.accountNumber }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let row">
          {{ row.accountType | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Funding</th>
        <td mat-cell *matCellDef="let row">
          <span
            class="status-label"
            [class.active]="row.currentValue > 0"
            [class.incomplete]="row.currentValue == 0"
          >
            <i *ngIf="row.currentValue > 0">Funded</i>
            <i *ngIf="row.currentValue == 0">Not Funded</i>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="showAccountDetails(row.id)"
      ></tr>
    </table>

    <mat-paginator
            [length]="dataSource.resultsLength | async"
            [pageSize]="50"
            showFirstLastButtons
            [pageSizeOptions]="[10, 50, 100, 200]"
    ></mat-paginator>
  </div>


<div class="spinner-container" *ngIf="!isSearch && dataSource.loading$ | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
