import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Prospect } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {

  private apiUrl = `${environment.serverUrl}/api/v1/users`;

  constructor(private http: HttpClient) {
  }

  getAll(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get<any>(`${this.apiUrl}/prospects`, { params });
  }

  searchProspects(filter): Observable<Prospect[]> {
    let filterOption = filter ? filter : "''";
    return this.http.get<any>(`${this.apiUrl}/prospects/search-prospects/${filterOption}`);
  }
}
