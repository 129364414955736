import {Component, Input, OnInit} from '@angular/core';
import {XnTabComponent} from "@orion/shared/xn-tab/xn-tab.component";

@Component({
  selector: 'xn-tabs',
  templateUrl: './xn-tabs.component.html',
  styleUrls: ['./xn-tabs.component.scss']
})
export class XnTabsComponent implements OnInit {
  tabs: XnTabComponent[] = [];
  @Input() activeTab: number = 0;
  @Input() format!: string;
  constructor() { }

  addTab(tab: XnTabComponent) {
    if (this.tabs.length === 0) {
      tab.active = true;
    }
    this.tabs.push(tab);
  }

  selectTab(tab: XnTabComponent) {
    this.tabs.forEach((e) => {
      e.active = false;
    });
    if (tab) {
      tab.active = true;
    }
  }

  onSelectTab(tab: XnTabComponent) {
    this.selectTab(tab);
  }

  ngOnChanges() {
    this.selectTab(this.tabs[this.activeTab]);
  }

  ngOnInit(): void {
  }

}
