import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivateUssdClientService} from "@orion/services/activate-ussd-client.service";
import {UtilitiesService} from "@orion/utilities/utilities.service";

@Component({
  selector: 'app-activate-ussd-account',
  templateUrl: './activate-ussd-account.component.html',
  styleUrls: ['./activate-ussd-account.component.scss']
})
export class ActivateUssdAccountComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private activateUssdClientService: ActivateUssdClientService,
              private utilities: UtilitiesService) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      providerId: [
        null,
        [
          Validators.required,
        ],
      ],
    });
  }

  submitForm(): void {
    // Success 🎉
    let providerId = this.form.value.providerId;
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.activateUssdClientService.activateUssdAccount(providerId).subscribe({
          next: (response) => {
            this.utilities.successAlert("Successfully Activated");
            this.resetForm();
          },
          error: (error) => {
            this.utilities.errorAlert("Failed to Activate this account");
            this.resetForm();
          }
        })
      }
    });
  }

  resetForm() {
    this.form.reset({
      providerId: ''
    });
  }

}
