import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProfileService } from '@orion/services/profile.service';
import { UtilitiesService } from '@orion/utilities/utilities.service';
import { MatSort } from '@angular/material/sort';
import { AdvisorDashboardSource } from './advisor-dashboard-data-source';
import { Observable, merge } from 'rxjs';
import { tap } from 'rxjs/operators'
import { AdvisorDashboardProfile, AdvisoryDashboardStats } from '@orion/models/advisor-dashboard';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-advisor-dashboard',
  templateUrl: './advisor-dashboard.component.html',
  styleUrls: ['./advisor-dashboard.component.scss'],
})
export class AdvisorDashboardComponent implements OnInit, AfterViewInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    public utilitiesService: UtilitiesService,
  ) { }

  displayedColumns: string[] = [
    'position',
    'first_name',
    'assignedUsers',
    'advisorDeposits',
    'advisorWithdraws',
    'advisorAUM',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: AdvisorDashboardSource;
  advisorDashboardProfile?: AdvisorDashboardProfile;
  advisoryDashboardStats?: AdvisoryDashboardStats;
  filtersForm: UntypedFormGroup;
  filteredOptions: Observable<string[]>;
  isLoading = true;
  showModal = false;
  showAdvisorModal = false;
  resetFiltersActive = false;
  advisorProfileId = '';
  liveImageUrl = environment.serverUrl;
  // thumbNailBaseUrl = `${environment.serverUrl}/api/v1/guest/passportphoto/`;
  // thumbNailBaseUrl = `${this.liveImageUrl}/api/v1/guest/passportphoto/`;
  tenantCurrency: string;

  ngOnInit() {
    this.tenantCurrency = this.utilitiesService.xnCurrencyLabel(localStorage.getItem('tenantId'))
    this.filtersForm = this.formBuilder.group({
      source: [''],
      kycStatus: [''],
      fundedStatus: [''],
      advisor: [''],
      startDate: [''],
      endDate: [''],
    });
    this.dataSource = new AdvisorDashboardSource(this.profileService);
    this.dataSource.loadAdvisoryProfiles(0, 10, 'first_name', 'asc', 0, '');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadAdvisorPage()))
      .subscribe();
  }

  loadAdvisorPage() {
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadAdvisoryProfiles(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      JSON.stringify(this.filtersForm.value),
    );
    // this.getDashStats(JSON.stringify(this.filtersForm.value))
  }

  showAdvisorProfileDetail(profileId: string, row) {
    this.advisorProfileId = profileId;
    this.advisorDashboardProfile = row;
    this.showModal = true;
  }

  applyDateRangeFilter(event: MatSelectChange) {
    this.resetFiltersActive = true
    if (this.filtersForm.value.startDate) {
      this.filtersForm.value.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      this.filtersForm.value.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadAdvisoryProfiles(
      0,
      10,
      'first_name',
      'asc',
      0,
      JSON.stringify(this.filtersForm.value),
    );
  }

  hideProfileModal() {
    this.showModal = false;
    this.advisorProfileId = '';
  }

  showAdvisorUploadModa() {
    this.showAdvisorModal = true
  }

  hideAdvisorUploadModal() {
    this.showAdvisorModal = false;
  }

  resetFilters(){
    this.filtersForm.reset();
    this.dataSource = new AdvisorDashboardSource(this.profileService);
    this.paginator.pageIndex = 0;
    this.dataSource.loadAdvisoryProfiles(0, 50, 'dateCreated', 'desc', 0, '');
    this.resetFiltersActive = false
  }
}
