<slideover (closeWindow)="close.emit()" xmlns="http://www.w3.org/1999/html">
  <div class="page-inner-container">
    <div class="page-section">
      <h2 class="page-subtitle huge">
        {{ job ? "Update" : "Create" }} Task
      </h2>
      <form class="edit-user-form" [formGroup]="form" (submit)="submitTask()">
        <div class="fields">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input type="text" formControlName="name" matInput />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Cron Expression</mat-label>
              <input type="text" formControlName="cronExpression" matInput />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Task Class</mat-label>
              <input type="text" formControlName="taskClass" matInput />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Extra Params</mat-label>
              <input type="text" formControlName="extraParams" matInput />
            </mat-form-field>
          </div>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea autofocus matInput #message rows="4" formControlName="description"></textarea>
          </mat-form-field>
        </div>



        <div>
          <h2
            style="
              text-transform: uppercase;
              font-weight: bold;
              font-size: 14px;
              padding-bottom: 1rem;
              color: #4a4a95;
              letter-spacing: -0.5px;
            "
          >
            Options
          </h2>
          <mat-checkbox formControlName="startOnStartup">Start On Startup</mat-checkbox>
        </div>

        <div class="u-justify actions">
          <button
            type="button"
            *ngIf="job"
            mat-button
            mat-flat-button
            color="warn"
            (click)="deleteJob(job)"
          >
            Delete
          </button>
          <button
            type="button"
            [disabled]="!form.valid"
            [class.u-fill-w]="!job"
            mat-button
            mat-flat-button
            color="primary"
            (click)="submitTask()"
          >
            {{ job ? "Update" : "Create" }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- {{ form.value | json }} -->
</slideover>
