<div class="wrap">
  <svg [attr.viewBox]="viewBox" class="ring" preserveAspectRatio>
    <circle
      [attr.stroke-width]="strokeWidth"
      [attr.r]="radius"
      [attr.cx]="width / 2"
      [attr.cy]="width / 2"
      stroke="#e7e7e7"
      fill="transparent"
    ></circle>
    <circle
      [attr.stroke-width]="strokeWidth"
      [attr.r]="radius"
      [attr.cx]="width / 2"
      [attr.cy]="width / 2"
      stroke="#83cc56"
      fill="transparent"
      class="progress"
      [style.strokeDasharray]="dashArray"
      [style.strokeDashoffset]="circumference"
      [style.strokeDashoffset]="dashOffset"
    ></circle>
  </svg>
  <div class="label" *ngIf="progress">
    <span>{{ progress | number: "1.0-0" }}</span>
  </div>
</div>
