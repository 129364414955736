<h2 class="page-title">
  Bulk SMS
</h2>
<br>
<mat-card>
  <mat-card-content>
    <div class="page-section">
      <form class="sender-form" [formGroup]="form">
        <div class="error" *ngIf="isError">
          <p>{{message}}</p>
        </div>
        <div>
          <!-- <div class="sender-form"> -->
            <mat-label class="ml-label">Enter SMS</mat-label>
        <mat-form-field class="form-border" appearance="">
          <textarea
          formControlName="messageBody"
            matTextareaAutosize
            rows="13"
            matInput></textarea>
        </mat-form-field>
          


          <!-- <mat-form-field appearance="outline">
            <mat-label>Enter SMS</mat-label>
            <textarea
              formControlName="messageBody"
              rows="20"
              matInput></textarea>
          </mat-form-field> -->
        </div>
        <div>
          <input
            #myInput
            size="60"
            type="file"
            accept=".xlsx"
            name="file"
            (change)="onFileChange($event)"
          />
        </div>
        <div class="u-justify actions">
          <button
            type="button"
            mat-stroked-button
            (click)="clearForm()"
            mat-flat-button
            color="default"
          >
            Clear
          </button>
          <button
            type="button"
            [disabled]="!form.valid"
            mat-button
            mat-flat-button
            color="primary"
            (click)="uploadExcelFileAxeSMS()"
          >
            Send
          </button>
        </div>
      </form>
    </div>

  </mat-card-content>
</mat-card>

<br/>
<br/>
<br/>
