<slideover [zIndex]="2" (closeWindow)="close.emit()">
  <div class="page-inner-container">

    <div class="page-section">
      <h2 class="page-subtitle huge">Edit Bank</h2>
      <div class="error" *ngIf="isError">
        <p>{{message}}</p>
      </div>
      <form class="edit-user-form"
            (ngSubmit)="submitEditForm()"
            [formGroup]="editForm">
        <div class="fields">

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Bank Name"
              fieldName="bankName"
              [ngModel]="profileBankInformation.bankName"
              formControlName="bankName">
            </app-text-input>
          </div>
          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Branch Name"
              fieldName="branchName"
              [ngModel]="profileBankInformation.branchName"
              formControlName="branchName">
            </app-text-input>
          </div>

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Account Name"
              fieldName="accountName"
              [ngModel]="profileBankInformation.accountName"
              formControlName="accountName">
            </app-text-input>
          </div>

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Account Number"
              fieldName="accountNumber"
              [ngModel]="profileBankInformation.accountNumber"
              formControlName="accountNumber">
            </app-text-input>
          </div>

          <div>
            <app-text-input
              [parentForm]="editForm"
              label="Bank Code"
              fieldName="bankCode"
              [ngModel]="profileBankInformation.bankCode"
              formControlName="bankCode">
            </app-text-input>
          </div>
          <div></div>




          <div class="u-justify actions">
            <button
              type="submit"
              mat-button
              mat-flat-button
              color="primary">
              Update
            </button>
          </div>
        </div>
      </form>

    </div>
  </div>
</slideover>
