<slideover (closeWindow)="close.emit()">
  <div class="xn-fadein-up xd-container">
    <div class="user-profile-content">
   <div class="d-flex">
     <div class="p-2 mr-12">
       <h2  class="pb-3">Osborn's Education</h2>
       <div class="card g-card mb-3">
         <div class="card-body">
         <div class="xnbottom10">
           <div class="d-flex">
               <div class="ps-3">
                 <img (click)="showFilePreview('passport', 'Image')"
                      [src]="!passportUrl ? '../../../assets/images/orion_avatar.png' : passportUrl" alt="Selfie"
                      class="selfie-image" mat-card-image />
               </div>

             <div class="flex-grow-1">
               <div class="x-marginleft100 pl-2 ntop4 pb-2">
                 <h6 class="float-start">Marieh Musimenta</h6>
                 <table class="">
                   <tr>
                     <td>
                       <div>
                         <span class="text-white subtext text-white-sub">JOINED</span>
                       </div>
                     <div class="">
                       <span class="text-white ft-13">Feb 13 2023</span>
                     </div>

                     </td>
                     <td>
                       <div>
                         <span class="text-white subtext text-white-sub">SOURCE</span>
                       </div>
                       <div class="">
                         <span class="text-white ft-13">MTN</span>
                       </div>
                     </td>
                   </tr>

                   <tr>
                     <td>
<!--                       <div>-->
<!--                         <span class="text-white subtext text-white-sub">LAST LOGIN</span>-->
<!--                       </div>-->
<!--                    <div class="">-->
<!--                      <span class="text-white ft-13">2 days ago</span>-->
<!--                    </div>-->
                     </td>
                     <td>
                     <div>
                       <span class="text-white subtext text-white-sub">KYC</span>
                     </div>
                      <div  class="">
                        <span class="text-white ft-13">Verified</span>
                      </div>
                     </td>
                   </tr>

                 </table>
               </div>


             </div>
           </div>


         </div>
         </div>
       </div>
       <div class="rounded-corners">
         <table class="table bg-color-transparent m-lg-3 xwidth94">
           <tbody>
           <tr class="tr">
             <th scope="row">
               <span>Goal Target</span>
             </th>
             <td>
               <span>UGX 1,289,000,000</span>
             </td>

           </tr>
           <tr class="tr">
             <th scope="row">
               <span>Total Deposits</span>
             </th>
             <td>
               <span class="text-success">UGX 89,000,000</span>
             </td>

           </tr>
           <tr class="tr">
             <th scope="row"><span>Total Withdraws</span></th>
             <td colspan="2">
               <span class="text-danger">-UGX 7,410,367</span>
             </td>

           </tr>
           <tr class="tr">
             <th scope="row">
               <span>Current Value</span>
             </th>
             <td colspan="2">
               <span class="primary-color">UGX 7,000,000</span>
             </td>

           </tr>
           <tr class="tr">
             <th scope="row">
               <span>Absolute Gain</span>
             </th>
             <td colspan="2">
               <span class="text-success">+UGX 410,000</span>
             </td>

           </tr>
           <tr class="tr">
             <th scope="row">
               <span>Band</span>
             </th>
             <td colspan="2">
                        <span class="">
                             <span
                               class="status-label has-no-autosave">
                          M01
                         </span>
                        </span>
             </td>

           </tr>
           <tr class="tr">
             <th scope="row">
               <span>Goal Number</span>
             </th>
             <td colspan="2">
                        <span class="">
                           701-30487582849a
                        </span>
             </td>

           </tr>
           <tr class="tr">
             <th scope="row">
               <span>Last Valuation</span>
             </th>
             <td colspan="2">
                        <span class="">
Feb 21,2024
                        </span>
             </td>

           </tr>
           <tr class="tr">
             <th scope="row">
               <span>AutoSave</span>
             </th>
             <td colspan="2">
                        <span class="">
                             <span
                               class="status-label active">
                          on
                         </span>
                        </span>
             </td>

           </tr>
           <tr>
             <th scope="row">
               <span>Withdraw Lock</span>
             </th>
             <td colspan="2">
                        <span class="">
                             <span
                               class="status-label active">
                          on
                         </span>
                        </span>
             </td>

           </tr>
           </tbody>
         </table>
       </div>




     </div>
     <div class="p-2 flex-grow-1 xtabbackground">
       <mat-tab-group animationDuration="0ms" class="xn-mat-tab-label-container mat-tab-header">
         <mat-tab class="mat-label-details" aria-label="profile" label="Summary">
           <div class="user-profile-view">
<!--<div class="d-flex text-color-light">-->
<!--  <div class="p-4"><img src="assets/icons/copy.svg" class="nbottom3"/> 701-30487582849a</div>-->
<!--  <div class="p-4"><img src="assets/icons/clock.svg" class="nbottom3"/> Last Valuation: Feb 21,2024</div>-->
<!--  <div class="p-4"><img src="assets/icons/joiners.svg" class="nbottom3"/> AutoSave-->
<!--    <span-->
<!--      class="status-label has-autosave text-black">-->
<!--      on-->
<!--            </span>-->
<!--  </div>-->
<!--  <div class="p-4"><img src="assets/icons/lock.svg" class="nbottom3"/> Withdraw Lock-->
<!--    <span-->
<!--      class="status-label disabled text-black">-->
<!--      off-->
<!--            </span>-->
<!--  </div>-->
<!--  <div class="p-4"><img src="assets/icons/check.svg" class="nbottom3"/> Status-->
<!--    <span-->
<!--      class="status-label active text-black">-->
<!--      on-->
<!--            </span>-->
<!--  </div>-->
<!--</div>-->

             <div class="ms-2 mt-3">
               <div class="container">
                 <div class="row">
                   <div class="col-lg-3">
                     <div class="x-card">
                       <div>
                         <img src="assets/icons/hand.svg" class="nbottom3 px-2 ps-0"/> <span class="text-uppercase">net invested</span>
                       </div>
                       <div class="pt-3">
                         <h1 class="page-subtitle huge text-bold">UGX 5,045,500</h1>
                       </div>
                       <div class="xntop12">
                         <span class="caption-text">Last Deposit: UGX 50,000</span>
                       </div>


                     </div>
                   </div>
                   <div class="col-lg-3">
                     <div class="x-card">
                       <div>
                         <img src="assets/icons/thumb.svg" class="nbottom3 px-2 ps-0"/> <span class="text-uppercase">current value</span>
                       </div>
                       <div class="pt-3">
                         <h1 class="page-subtitle huge text-bold">UGX 5,045,500</h1>
                       </div>
                       <div class="xntop12">
                         <span class="caption-text">Absolute gain: UGX 50,000</span>
                       </div>


                     </div>
                   </div>
                   <div class="col-lg-3">
                     <div class="x-card">
                       <div>
                         <img src="assets/icons/target.svg" class="nbottom3 px-2 ps-0"/> <span class="text-uppercase">target</span>
                       </div>
                       <div class="pt-3">
                         <h1 class="page-subtitle huge text-bold">UGX 5,045,500</h1>
                       </div>
                       <div class="xntop12">
                         <span class="caption-text">Recommended Monthly: UGX 50,000</span>
                       </div>


                     </div>
                   </div>
                   <div class="col-lg-3">
                     <div class="x-card">
                       <div>
                         <img src="assets/icons/palm.svg" class="nbottom3 px-2 ps-0"/> <span class="text-uppercase">total withdraws</span>
                       </div>
                       <div class="pt-3">
                         <h1 class="page-subtitle huge text-bold">UGX 5,045,500</h1>
                       </div>
                       <div class="xntop12">
                         <span class="caption-text">Last Withdraw: UGX 50,000</span>
                       </div>


                     </div>
                   </div>

                 </div>
               </div>
             </div>

             <div class="ms-2 mt-3">
               <div class="container">
                 <div class="row">
                   <div class="col-lg-4">
                     <div class="gf-card">
                       <div>
                         <img src="assets/icons/hand.svg" class="nbottom3 px-2 ps-0"/> <span class="text-uppercase">current strategy</span>
                       </div>
                       <div>

                         <div class="o-card-body slim">
                           <div class="o-card-body__wrap">
                             <div class="">
                               <div id="planPieChart"></div>
                             </div>

                             <div class="u-justify u-txt-center u-txt-14 summary">
                               <div>
                                 <span class="u-block u-pb-8">Risk Tolerance</span>
                                 <span class="u-block c-b"
                                 >
                                  4 out of 10</span
                                 >
                               </div>
                               <div class="vl"></div>
                               <div>
                                 <span class="u-block u-pb-8">Strategy</span>
                                 <span class="u-block c-b">Conservative</span>
                               </div>
                             </div>

                           </div>
                         </div>

                       </div>
                     </div>
                   </div>
                   <div class="col-lg-8">
                     <div class="gf-card">
                       <div>
                          <span class="text-uppercase">performance</span>
                       </div>
                       <div class="pt-3">
                         <app-goal-performance-chart></app-goal-performance-chart>
                       </div>


                     </div>
                   </div>
                 </div>
               </div>
             </div>



           </div>
         </mat-tab>
         <mat-tab aria-label="accounts" label="Targets">
<div class="user-profile-view">
  <div class="ms-2 mt-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-2">
          <div class="x-card">
            <div>
              <span class="text-capitalize ft-13">Time Horizon</span>
            </div>
            <div class="pt-3">
              <h1 class="page-subtitle ft20px">Long - Term</h1>
            </div>



          </div>
        </div>
        <div class="col-lg-2">
          <div class="x-card">
            <div>
             <span class="text-capitalize ft-13">Target Value</span>
            </div>
            <div class="pt-3">
              <h1 class="page-subtitle ft20px ">UGX 25,000,000</h1>
            </div>



          </div>
        </div>
        <div class="col-lg-2">
          <div class="x-card">
            <div>
               <span class="text-capitalize ft-13">Initial Investment</span>
            </div>
            <div class="pt-3">
              <h1 class="page-subtitle ft20px">UGX 25,000</h1>
            </div>


          </div>
        </div>
        <div class="col-lg-2">
          <div class="x-card">
            <div>
        <span class="text-capitalize ft-13">Total Deposit</span>
            </div>
            <div class="pt-3">
              <h1 class="page-subtitle ft20px">UGX 5,450,000</h1>
            </div>



          </div>
        </div>
        <div class="col-lg-2">
          <div class="x-card">
            <div>
              <span class="text-capitalize ft-13">Recommended Monthly</span>
            </div>
            <div class="pt-3">
              <h1 class="page-subtitle ft20px">UGX 739,250</h1>
            </div>


          </div>
        </div>
        <div class="col-lg-2">
          <div class="x-card">
            <div>
              <span class="text-capitalize ft-13">Band</span>
            </div>
            <div class="pt-3">
               <span class="">
                             <span
                               class="status-label active">
                          M0
                         </span>
                        </span>
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ms-2 mt-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="">
            <table class="table  xwidth94Inner">
              <tbody>
              <tr class="tr">
                <th scope="row" class="thbgcolor w50">
                  <span class="text-uppercase">Question</span>
                </th>
                <th class="thbgcolor w50">
                  <span class="text-uppercase">Responses</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>Date Created</span>
                </td>
                <th class="w50">
                  <span>13 Aug,2023  10:30pm</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>Last Updated</span>
                </td>
                <th> class="w50"
                  <span>21 Jan, 2024 11:20am</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>What is your child’s name?</span>
                </td>
                <th class="w50">
                  <span>Osborn Danny</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>How old is (childName)?</span>
                </td>
                <th class="w50">
                  <span>5</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>What level of (childName?) are you planning for?</span>
                </td>
                <th class="w50">
                  <span>University</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>What (educationLevel) do you want (childName) to attend?</span>
                </td>
                <th class="w50">
                  <span>Yale University</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>What format does (schoolName) use?</span>
                </td>
                <th class="w50">
                  <span>Semester</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>How many years will (childName)spend at (schoolName)?</span>
                </td>
                <th class="w50">
                  <span>3</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>What is the current cost of a (schoolFormat) at (schoolName)?</span>
                </td>
                <th class="w50">
                  <span>$ 21,000</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>When will (childName) start (educationLevel)</span>
                </td>
                <th class="w50">
                  <span>14 Years</span>
                </th>

              </tr>
              <tr class="tr">
                <td scope="row" class="w50">
                  <span>How much are you able to start investing towards (childName)’s (educationLevel) education?</span>
                </td>
                <th class="w50">
                  <span>UGX 550,000</span>
                </th>

              </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

         </mat-tab>
         <mat-tab aria-label="bankDetails" label="Strategy">
           <div class="user-profile-view">
             <div class="ms-2 mt-4">
               <div class="container">
                 <div class="row">
                   <div class="col-lg-12">
                     <div class="">
                       <div class="d-flex justify-content-end pb-3">
                         <button
                           type="button"
                           mat-button
                           mat-flat-button
                           color="primary"
                         >
                           Edit
                         </button>
                       </div>
                       <table class="table  xwidth94Inner">
                         <tbody>
                         <tr class="tr">
                           <th scope="row" class="thbgcolor">
                             <span class="text-uppercase">assets</span>
                           </th>
                           <th class="thbgcolor">
                             <span class="text-uppercase">recommended(%)</span>
                           </th>
                           <th class="thbgcolor">
                             <span class="text-uppercase">current(%)</span>
                           </th>
                           <th class="thbgcolor">
                             <span class="text-uppercase">net invested(UGX)</span>
                           </th>
                           <th class="thbgcolor">
                             <span class="text-uppercase">gain(ugx)</span>
                           </th>
                           <th class="thbgcolor">
                             <span class="text-uppercase">current value(ugx)</span>
                           </th>

                         </tr>
                         <tr class="tr">
                           <td>
                             <span>XUMMF</span>
                           </td>
                           <td>
                             <span>25</span>
                           </td>
                           <td>
                             <span>24.7</span>
                           </td>

                           <td>
                             <span>-824,783.578</span>
                           </td>

                           <td>
                             <span>1,365,112</span>
                           </td>

                           <td>
                             <span>537,345</span>
                           </td>



                         </tr>
                         <tr class="tr">
                           <td>
                             <span>XUBF</span>
                           </td>
                           <td>
                             <span>25</span>
                           </td>
                           <td>
                             <span>24.7</span>
                           </td>

                           <td>
                             <span>-824,783.578</span>
                           </td>

                           <td>
                             <span>1,365,112</span>
                           </td>

                           <td>
                             <span>537,345</span>
                           </td>



                         </tr>
                         <tr class="tr">
                           <td>
                             <span>XUDEF</span>
                           </td>
                           <td>
                             <span>0</span>
                           </td>
                           <td>
                             <span>0</span>
                           </td>

                           <td>
                             <span>0</span>
                           </td>

                           <td>
                             <span>0</span>
                           </td>

                           <td>
                             <span>0</span>
                           </td>



                         </tr>
                         <tr class="tr">
                           <td>
                             <span>XUREF</span>
                           </td>
                           <td>
                             <span>0</span>
                           </td>
                           <td>
                             <span>0</span>
                           </td>

                           <td>
                             <span>0</span>
                           </td>

                           <td>
                             <span>0</span>
                           </td>

                           <td>
                             <span>0</span>
                           </td>



                         </tr>
                         <tr class="tr">
                           <td colspan="3">
                             <span>TOTAL</span>
                           </td>

                           <td>
                             <span>-4,909,320</span>
                           </td>

                           <td>
                             <span class="text-success">7,084,505</span>
                           </td>

                           <td>
                             <span>21,757,185</span>
                           </td>



                         </tr>


                         </tbody>
                       </table>
                     </div>
                   </div>
                 </div>
               </div>
             </div>

           </div>
         </mat-tab>
         <mat-tab aria-label="beneficiaries" label="Transactions">
           <!-- beneficiary -->
           <div class="user-profile-view">


           </div>
         </mat-tab>

         <mat-tab aria-label="advisor" label="AutoSave">
           <div class="user-profile-view">
             <div class="ms-2 mt-3">
               <div class="container">
                 <div class="row">
                   <div class="col-lg-12">
                     <div class="">
                       <div class="d-flex justify-content-end">
                         <mat-form-field appearance="fill" class="px-3">
                           <mat-label>Date Created</mat-label>
                           <mat-select>
                             <mat-option value="today">Today</mat-option>
                             <mat-option value="yesterday">Yesterday</mat-option>
                             <mat-option value="last_7_days">Last 7 Days</mat-option>
                             <mat-option value="last_30_days">Last 30 Days</mat-option>
                             <mat-option value="this_month">This Month</mat-option>
                             <mat-option value="last_month">Last Month</mat-option>
                             <mat-option value="this_year">This Year</mat-option>
                             <mat-option value="last_year">Last Year</mat-option>
                           </mat-select>
                         </mat-form-field>

                         <mat-form-field appearance="fill">
                           <mat-label>Frequency</mat-label>
                           <mat-select>
<!--                             <mat-option value="daily">Daily</mat-option>-->
<!--                             <mat-option value="weekly">Weekly</mat-option>-->
<!--                             <mat-option value="monthly">Monthly</mat-option>-->
<!--                             <mat-option value="quarterly">Quarterly</mat-option>-->
<!--                             <mat-option value="annually">Annually</mat-option>-->
<!--                             <mat-option value="hourly">Hourly</mat-option>-->
<!--                             <mat-option value="bi-monthly">Bi-Weekly</mat-option>-->
                           </mat-select>
                         </mat-form-field>
                       </div>
                       <table class="table xwidth94Inner">
                         <tbody>
                         <tr class="tr">
                           <th scope="row" class="thbgcolor">
                             <span class="text-capitalize">AutoSave</span>
                           </th>
                           <th class="thbgcolor">
                             <span class="text-capitalize">Date Created</span>
                           </th>
                           <th class="thbgcolor">
                             <span class="text-capitalized">Frequency</span>
                           </th>
                           <th class="thbgcolor">
                             <span class="text-capitalize">Set Amount</span>
                           </th>
                           <th class="thbgcolor">
                             <span class="text-capitalize">Last executed</span>
                           </th>
                           <th class="thbgcolor">
                             <span class="text-capitalize">Successfuly</span>
                           </th>

                           <th class="thbgcolor">
                             <span class="text-capitalize">Failed</span>
                           </th>

                         </tr>
                         <tr class="tr">
                           <td>
                             <span>1</span>
                           </td>
                           <td>
                             <span>Sept 12, 2023</span>
                           </td>
                           <td>
                             <span>Monthly</span>
                           </td>

                           <td>
                             <span>UGX 200,000</span>
                           </td>

                           <td>
                             <span>24 Feb, 2024</span>
                           </td>

                           <td>
                             <span>4</span>
                           </td>
                           <td>
                             <span>3</span>
                           </td>



                         </tr>



                         </tbody>
                       </table>
                     </div>
                   </div>
                 </div>
               </div>
             </div>

           </div>

         </mat-tab>

       </mat-tab-group>
     </div>
   </div>

    </div>
  </div>

</slideover>
