import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as Highcharts from "highcharts/highstock";
import HC_stock from "highcharts/modules/stock";
import {GoalDashboardService} from "@orion/modules/goal/goal-dashboard.service";


declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
HC_stock(Highcharts);

const namesX = [
  {
    name: "Current Value",
    color: "#5d8feb",
    type: "area",
    lineWidth: 1,
    fillColor: {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [0, "#81b2ff"],
        [1, Highcharts.color("#81b2ff").setOpacity(0).get("rgba")],
      ],
    },
  },
  {
    name: "Net Invested",
    dashStyle: "shortDot",
    color: "#4db827",
    lineWidth: 1.8,
    visibility: true,
  },
];

@Component({
  selector: 'app-goal-performance-chart',
  templateUrl: './goal-performance-chart.component.html',
  styleUrls: ['./goal-performance-chart.component.scss']
})
export class GoalPerformanceChartComponent implements OnInit, AfterViewInit {
  performanceData: any = {};
  maxCurrentVal: number = 0;
  seriesOptions = [];
  seriesCounter = 0;
  public options: any = {
    chart: {
      plotBackgroundColor: null,
      backgroundColor: null,
    },
    rangeSelector: {
      inputEnabled: false,
      buttonSpacing: 2,
      labelStyle: {
        display: "none",
      },
      buttonPosition: {
        align: "right",
        x: -12,
      },
      buttonTheme: {
        // styles for the buttons
        fill: "none",
        stroke: "none",
        r: 10,
        "stroke-width": 0,

        style: {
          fontFamily: "DM Sans",
          padding: "2px",
          color: "#718196",
        },
        states: {
          hover: {},
          select: {
            fill: "#abf1e7",
            style: {
              color: "#000000",
            },
          },
          // disabled: { ... }
        },
      },
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      tickColor: "#b5e4e7",
      gridLineColor: "#b5e4e7",
      lineColor: "#b5e4e7",
      labels: {
        style: {
          fontFamily: "DM Sans",
          color: "#718196",
        },
      },
    },

    yAxis: {
      min: 0,
      gridLineColor: "#b5e4e7",
      lineColor: "#b5e4e7",
      labels: {
        formatter: function () {
          return (this.value > 0 ? " + " : "") + this.value + "";
        },
      },
      tickInterval: this.goalDashoardService.graphTickValue(
        this.maxCurrentVal,
        0,
        4
      ),
    },

    navigator: {
      enabled: false,
    },

    scrollbar: {
      enabled: false,
    },

    plotOptions: {
      series: {
        showInNavigator: false,
      },
      line: {
        marker: {
          radius: 2.5,
        },
      },
    },

    tooltip: {
      headerFormat:
        '<span style="display:block; padding-bottom: 1.25rem">{point.key}</span><br/><br/>',
      pointFormat:
        '<span><span style="color:{point.color}">●</span> {series.name}</span>: <b>{point.y}</b><br/>',
      valueDecimals: 0,
      split: false,
      shared: true,
      style: {
        fontFamily: "DM Sans",
        fontSize: "0.75rem",
      },
    },

    series: this.seriesOptions,
  };
  constructor(private goalDashoardService: GoalDashboardService) { }

  ngOnInit(): void {
    this.performanceData = [
      [
        [
          1530057600000,
          99704.05654610001
        ],
        [
          1530144000000,
          99713.7442538
        ],
        [
          1530230400000,
          99713.0607902
        ],
        [
          1530316800000,
          99748.1732614
        ],
        [
          1530403200000,
          99774.3800631
        ],
        [
          1530489600000,
          99847.0638734
        ],
        [
          1530576000000,
          99688.70225
        ],
        [
          1530662400000,
          99756.4450298
        ],
        [
          1530748800000,
          99791.98904490001
        ],
        [
          1530835200000,
          99856.1010106
        ],
        [
          1530921600000,
          99882.7630207
        ],
        [
          1531008000000,
          99916.010513
        ],
        [
          1531094400000,
          99911.7295275
        ],
        [
          1531180800000,
          100088.0756994
        ],
        [
          1531267200000,
          99925.7964435
        ],
        [
          1531353600000,
          100022.67098370001
        ],
        [
          1531440000000,
          99935.06734770001
        ],
        [
          1531526400000,
          99961.7293578
        ],
        [
          1531612800000,
          99994.1105786
        ],
        [
          1531699200000,
          99966.4033584
        ],
        [
          1531785600000,
          100030.0495851
        ],
        [
          1531872000000,
          100057.8102378
        ],
        [
          1531958400000,
          100160.1261237
        ],
        [
          1532044800000,
          100145.7867306
        ],
        [
          1532131200000,
          100171.9935323
        ],
        [
          1532217600000,
          100204.3747531
        ],
        [
          1532304000000,
          100190.7674366
        ],
        [
          1532390400000,
          100198.8673561
        ],
        [
          1532476800000,
          100228.1274478
        ],
        [
          1532563200000,
          100253.7570772
        ],
        [
          1532649600000,
          100285.64979740001
        ],
        [
          1532736000000,
          100311.8565991
        ],
        [
          1532822400000,
          100344.6930283
        ],
        [
          1532908800000,
          100398.3896774
        ],
        [
          1532995200000,
          100300.0275008
        ],
        [
          1533081600000,
          100215.2300373
        ],
        [
          1533168000000,
          100287.2124892
        ],
        [
          1533254400000,
          100337.8683079
        ],
        [
          1533340800000,
          100358.4125038
        ],
        [
          1533427200000,
          100380.06722150001
        ],
        [
          1533513600000,
          100443.1247485
        ],
        [
          1533600000000,
          100482.2326415
        ],
        [
          1533686400000,
          100606.38732570001
        ],
        [
          1533772800000,
          100608.1351602
        ],
        [
          1533859200000,
          100660.3787671
        ],
        [
          1533945600000,
          100682.0334848
        ],
        [
          1534032000000,
          100710.31783
        ],
        [
          1534118400000,
          100829.1640148
        ],
        [
          1534204800000,
          100843.45918600001
        ],
        [
          1534291200000,
          100841.0006406
        ],
        [
          1534377600000,
          100900.71452370001
        ],
        [
          1534464000000,
          100893.3264752
        ],
        [
          1534550400000,
          100914.9811929
        ],
        [
          1534636800000,
          100942.8103297
        ],
        [
          1534723200000,
          100950.6647442
        ],
        [
          1534809600000,
          100978.493881
        ],
        [
          1534896000000,
          100936.2865764
        ],
        [
          1534982400000,
          100947.0844541
        ],
        [
          1535068800000,
          101000.4814441
        ],
        [
          1535155200000,
          101028.3105809
        ],
        [
          1535241600000,
          101049.2658399
        ],
        [
          1535328000000,
          200628.55366069998
        ],
        [
          1535414400000,
          200778.0627894
        ],
        [
          1535500800000,
          200870.0319063
        ],
        [
          1535587200000,
          200807.16602499998
        ],
        [
          1535673600000,
          200462.1561946
        ],
        [
          1535760000000,
          200517.31805879998
        ],
        [
          1535846400000,
          200571.0640896
        ],
        [
          1535932800000,
          200539.5778731
        ],
        [
          1536019200000,
          200623.45911939998
        ],
        [
          1536105600000,
          200664.33334739998
        ],
        [
          1536192000000,
          100331.7706015
        ],
        [
          1536278400000,
          100282.2201071
        ],
        [
          1536364800000,
          100313.9957935
        ],
        [
          1536451200000,
          100341.5948163
        ],
        [
          1536537600000,
          100330.25101390001
        ],
        [
          1536624000000,
          100291.946404
        ],
        [
          1536710400000,
          100307.42140790001
        ],
        [
          1536796800000,
          100254.2179558
        ],
        [
          1536883200000,
          100325.89037010001
        ],
        [
          1536969600000,
          100357.6660565
        ],
        [
          1537056000000,
          100389.4417429
        ],
        [
          1537142400000,
          100167.2265126
        ],
        [
          1537228800000,
          100457.5436619
        ],
        [
          1537315200000,
          100373.9105162
        ],
        [
          1537401600000,
          99990.192286
        ],
        [
          1537488000000,
          99938.5242544
        ],
        [
          1537574400000,
          99966.387777
        ],
        [
          1537660800000,
          99988.0696787
        ],
        [
          1537747200000,
          200071.4887212
        ],
        [
          1537833600000,
          200228.1164458
        ],
        [
          1537920000000,
          200476.9785624
        ],
        [
          1538006400000,
          200530.78014400002
        ],
        [
          1538092800000,
          200499.93725420002
        ],
        [
          1538179200000,
          200555.78768480002
        ],
        [
          1538265600000,
          200610.7490746
        ],
        [
          1538352000000,
          200663.66161540002
        ],
        [
          1538438400000,
          200686.5307953
        ],
        [
          1538524800000,
          200733.8766932
        ],
        [
          1538611200000,
          200650.32305110001
        ],
        [
          1538697600000,
          100308.7509477
        ],
        [
          1538784000000,
          100336.6628395
        ],
        [
          1538870400000,
          100363.0033008
        ],
        [
          1538956800000,
          100369.5744787
        ],
        [
          1539043200000,
          100398.8251106
        ],
        [
          1539129600000,
          100433.8505737
        ],
        [
          1539216000000,
          100398.5263847
        ],
        [
          1539302400000,
          100260.8860717
        ],
        [
          1539388800000,
          100281.4517018
        ],
        [
          1539475200000,
          100308.7169053
        ],
        [
          1539561600000,
          100182.7043569
        ],
        [
          1539648000000,
          100223.8787915
        ],
        [
          1539734400000,
          100236.9260486
        ],
        [
          1539820800000,
          100322.0990639
        ],
        [
          1539907200000,
          100441.7995972
        ],
        [
          1539993600000,
          100470.7756038
        ],
        [
          1540080000000,
          100492.4952379
        ],
        [
          1540166400000,
          100585.722648
        ],
        [
          1540252800000,
          100614.6813338
        ],
        [
          1540339200000,
          100675.2724987
        ],
        [
          1540425600000,
          100734.902349
        ],
        [
          1540512000000,
          100719.0146901
        ],
        [
          1540598400000,
          100748.9154389
        ],
        [
          1540684800000,
          100770.635073
        ],
        [
          1540771200000,
          100775.6836125
        ],
        [
          1540857600000,
          100787.7115667
        ],
        [
          1540944000000,
          100783.183073
        ],
        [
          1541030400000,
          100785.9194846
        ],
        [
          1541116800000,
          100829.4006877
        ],
        [
          1541203200000,
          100859.0233826
        ],
        [
          1541289600000,
          100888.228651
        ],
        [
          1541376000000,
          100949.915402
        ],
        [
          1541462400000,
          100884.4749168
        ],
        [
          1541548800000,
          100853.8107529
        ],
        [
          1541635200000,
          100931.9232554
        ],
        [
          1541721600000,
          101089.6138666
        ],
        [
          1541808000000,
          101118.819135
        ],
        [
          1541894400000,
          101147.7951416
        ],
        [
          1541980800000,
          101169.2401504
        ],
        [
          1542067200000,
          101169.2401504
        ],
        [
          1542153600000,
          101133.7354607
        ],
        [
          1542240000000,
          101205.8071513
        ],
        [
          1542326400000,
          101197.1324353
        ],
        [
          1542412800000,
          101226.3377037
        ],
        [
          1542499200000,
          101255.3137103
        ],
        [
          1542585600000,
          101407.7089067
        ],
        [
          1542672000000,
          101338.0768357
        ],
        [
          1542758400000,
          101282.786554
        ],
        [
          1542844800000,
          101329.869833
        ],
        [
          1542931200000,
          101376.3552158
        ],
        [
          1543017600000,
          101405.3312224
        ],
        [
          1543104000000,
          101434.5364908
        ],
        [
          1543190400000,
          101457.2934365
        ],
        [
          1543276800000,
          101465.94310229999
        ],
        [
          1543363200000,
          101506.1702579
        ],
        [
          1543449600000,
          101563.6211557
        ],
        [
          1543536000000,
          101604.1315812
        ],
        [
          1543622400000,
          101632.4608995
        ],
        [
          1543708800000,
          101655.1052758
        ],
        [
          1543795200000,
          101707.48535249999
        ],
        [
          1543881600000,
          101714.26201589999
        ],
        [
          1543968000000,
          101788.2531654
        ],
        [
          1544054400000,
          101727.1612493
        ],
        [
          1544140800000,
          101725.1450096
        ],
        [
          1544227200000,
          101748.8535007
        ],
        [
          1544313600000,
          101770.5731348
        ],
        [
          1544400000000,
          101760.59115
        ],
        [
          1544486400000,
          101797.8800954
        ],
        [
          1544572800000,
          101829.2623855
        ],
        [
          1544659200000,
          101723.2894831
        ],
        [
          1544745600000,
          101695.583992
        ],
        [
          1544832000000,
          101725.4847408
        ],
        [
          1544918400000,
          101749.1932319
        ],
        [
          1545004800000,
          101797.3590426
        ],
        [
          1545091200000,
          101783.9316753
        ],
        [
          1545177600000,
          101777.5733536
        ],
        [
          1545264000000,
          101768.7787749
        ],
        [
          1545350400000,
          101848.0678182
        ],
        [
          1545436800000,
          101870.6223053
        ],
        [
          1545523200000,
          101900.5230541
        ],
        [
          1545609600000,
          101771.9897542
        ],
        [
          1545696000000,
          101794.1268148
        ],
        [
          1545782400000,
          101817.8353059
        ],
        [
          1545868800000,
          101860.6698207
        ],
        [
          1545955200000,
          101881.8327991
        ],
        [
          1546041600000,
          101909.7446909
        ],
        [
          1546128000000,
          101932.8064937
        ],
        [
          1546214400000,
          101911.2836345
        ],
        [
          1546300800000,
          101939.6129528
        ],
        [
          1546387200000,
          101926.8322738
        ],
        [
          1546473600000,
          101907.7599655
        ],
        [
          1546560000000,
          101906.9440429
        ],
        [
          1546646400000,
          101934.6266729
        ],
        [
          1546732800000,
          101964.5274217
        ],
        [
          1546819200000,
          101976.6605402
        ],
        [
          1546905600000,
          102059.7303417
        ],
        [
          1546992000000,
          102060.5151603
        ],
        [
          1547078400000,
          102194.9501519
        ],
        [
          1547164800000,
          102320.8536648
        ],
        [
          1547251200000,
          102353.3000697
        ],
        [
          1547337600000,
          102382.1367037
        ],
        [
          1547424000000,
          102427.832597
        ],
        [
          1547510400000,
          102459.8507101
        ],
        [
          1547596800000,
          102577.7230219
        ],
        [
          1547683200000,
          102720.15479659999
        ],
        [
          1547769600000,
          102788.2383412
        ],
        [
          1547856000000,
          102818.13909
        ],
        [
          1547942400000,
          102845.4042935
        ],
        [
          1548028800000,
          102949.4536112
        ],
        [
          1548115200000,
          102974.8577737
        ],
        [
          1548201600000,
          102987.2979384
        ],
        [
          1548288000000,
          102871.8270552
        ],
        [
          1548374400000,
          102910.686335
        ],
        [
          1548460800000,
          102940.5870838
        ],
        [
          1548547200000,
          102967.8522873
        ],
        [
          1548633600000,
          102994.5606917
        ],
        [
          1548720000000,
          103055.7490615
        ],
        [
          1548806400000,
          103122.663327
        ],
        [
          1548892800000,
          103193.2341098
        ],
        [
          1548979200000,
          103371.302218
        ],
        [
          1549065600000,
          103399.2141098
        ],
        [
          1549152000000,
          103428.4681703
        ],
        [
          1549238400000,
          103478.6455182
        ],
        [
          1549324800000,
          103701.2593804
        ],
        [
          1549411200000,
          103829.8253852
        ],
        [
          1549497600000,
          103855.1993159
        ],
        [
          1549584000000,
          103844.5468665
        ],
        [
          1549670400000,
          103873.3835005
        ],
        [
          1549756800000,
          103901.7128188
        ],
        [
          1549843200000,
          103941.5431539
        ],
        [
          1549929600000,
          103955.9233835
        ],
        [
          1550016000000,
          103997.46452169999
        ],
        [
          1550102400000,
          103977.3410096
        ],
        [
          1550188800000,
          103979.4341615
        ],
        [
          1550275200000,
          104009.8917094
        ],
        [
          1550361600000,
          104039.7924582
        ],
        [
          1550448000000,
          104031.5385973
        ],
        [
          1550534400000,
          104053.8672512
        ],
        [
          1550620800000,
          103975.5679325
        ],
        [
          1550707200000,
          103997.3363587
        ],
        [
          1550793600000,
          104001.687487
        ],
        [
          1550880000000,
          104035.1449482
        ],
        [
          1550966400000,
          104066.1098118
        ],
        [
          1551052800000,
          104130.8634268
        ],
        [
          1551139200000,
          104195.4452167
        ],
        [
          1551225600000,
          104221.5069328
        ],
        [
          1551312000000,
          104214.963875
        ],
        [
          1551398400000,
          104284.7495465
        ],
        [
          1551484800000,
          104306.8866071
        ],
        [
          1551571200000,
          104336.3699294
        ],
        [
          1551657600000,
          104405.8355003
        ],
        [
          1551744000000,
          104441.6039964
        ],
        [
          1551830400000,
          104508.3659783
        ],
        [
          1551916800000,
          104604.1511493
        ],
        [
          1552003200000,
          104658.5438593
        ],
        [
          1552089600000,
          104686.2264893
        ],
        [
          1552176000000,
          104716.7739264
        ],
        [
          1552262400000,
          104721.4949286
        ],
        [
          1552348800000,
          104729.5553428
        ],
        [
          1552435200000,
          104753.0172513
        ],
        [
          1552521600000,
          104810.3569626
        ],
        [
          1552608000000,
          104939.1361479
        ],
        [
          1552694400000,
          104965.8976108
        ],
        [
          1552780800000,
          104995.7983596
        ],
        [
          1552867200000,
          105097.4936145
        ],
        [
          1552953600000,
          105069.0863024
        ],
        [
          1553040000000,
          105143.5751418
        ],
        [
          1553126400000,
          105225.8576429
        ],
        [
          1553212800000,
          105197.6506003
        ],
        [
          1553299200000,
          105227.1339226
        ],
        [
          1553385600000,
          105257.5419871
        ],
        [
          1553472000000,
          105237.8532223
        ],
        [
          1553558400000,
          105236.7595357
        ],
        [
          1553644800000,
          105223.0098471
        ],
        [
          1553731200000,
          105243.0047859
        ],
        [
          1553817600000,
          105350.1394963
        ],
        [
          1553904000000,
          105373.4305609
        ],
        [
          1553990400000,
          105402.9138832
        ],
        [
          1554076800000,
          105504.2175336
        ],
        [
          1554163200000,
          105542.4539014
        ],
        [
          1554249600000,
          105566.9605582
        ],
        [
          1554336000000,
          105580.5684726
        ],
        [
          1554422400000,
          105636.9834417
        ],
        [
          1554508800000,
          105660.2745063
        ],
        [
          1554595200000,
          105689.7578286
        ],
        [
          1554681600000,
          105743.0171406
        ],
        [
          1554768000000,
          105725.6608514
        ],
        [
          1554854400000,
          105765.497642
        ],
        [
          1554940800000,
          105801.8652738
        ],
        [
          1555027200000,
          105777.1003285
        ],
        [
          1555113600000,
          105804.365532
        ],
        [
          1555200000000,
          105833.202166
        ],
        [
          1555286400000,
          105837.6904778
        ],
        [
          1555372800000,
          105838.1312445
        ],
        [
          1555459200000,
          105858.8355559
        ],
        [
          1555545600000,
          105835.494827
        ],
        [
          1555632000000,
          105864.331461
        ],
        [
          1555718400000,
          105891.5966645
        ],
        [
          1555804800000,
          105912.8988721
        ],
        [
          1555891200000,
          105941.3180796
        ],
        [
          1555977600000,
          105925.9949148
        ],
        [
          1556064000000,
          105960.339048
        ],
        [
          1556150400000,
          106015.2712363
        ],
        [
          1556236800000,
          106026.3692323
        ],
        [
          1556323200000,
          106053.6344358
        ],
        [
          1556409600000,
          106080.4822128
        ],
        [
          1556496000000,
          106107.83085
        ],
        [
          1556582400000,
          106216.5787793
        ],
        [
          1556668800000,
          106244.9979868
        ],
        [
          1556755200000,
          106453.9761471
        ],
        [
          1556841600000,
          106473.0636645
        ],
        [
          1556928000000,
          106501.482872
        ],
        [
          1557014400000,
          106528.7480755
        ],
        [
          1557100800000,
          106510.5307929
        ],
        [
          1557187200000,
          106570.8258918
        ],
        [
          1557273600000,
          106555.6800264
        ],
        [
          1557360000000,
          106503.77889839999
        ],
        [
          1557446400000,
          106381.200581
        ],
        [
          1557532800000,
          106408.048358
        ],
        [
          1557619200000,
          106434.896135
        ],
        [
          1557705600000,
          106269.1044916
        ],
        [
          1557792000000,
          106105.4182226
        ],
        [
          1557878400000,
          106145.1586685
        ],
        [
          1557964800000,
          106135.0867945
        ],
        [
          1558051200000,
          106142.64758799999
        ],
        [
          1558137600000,
          106170.5594798
        ],
        [
          1558224000000,
          106197.8246833
        ],
        [
          1558310400000,
          106231.4334785
        ],
        [
          1558396800000,
          106300.5379951
        ],
        [
          1558483200000,
          106317.7342397
        ],
        [
          1558569600000,
          106375.4888985
        ],
        [
          1558656000000,
          106390.6962861
        ],
        [
          1558742400000,
          106420.318981
        ],
        [
          1558828800000,
          106447.8622384
        ],
        [
          1558915200000,
          106510.0486101
        ],
        [
          1559001600000,
          106730.7224074
        ],
        [
          1559088000000,
          106843.5386312
        ],
        [
          1559174400000,
          106817.3168705
        ],
        [
          1559260800000,
          106852.5792904
        ],
        [
          1559347200000,
          106880.6793469
        ],
        [
          1559433600000,
          106909.1968299
        ],
        [
          1559520000000,
          106943.8124181
        ],
        [
          1559606400000,
          107044.3826613
        ],
        [
          1559692800000,
          107080.2840745
        ],
        [
          1559779200000,
          107078.748089
        ],
        [
          1559865600000,
          107127.9898294
        ],
        [
          1559952000000,
          107155.2550329
        ],
        [
          1560038400000,
          107183.6742404
        ],
        [
          1560124800000,
          107233.1452426
        ],
        [
          1560211200000,
          107245.4890626
        ],
        [
          1560297600000,
          107266.5892133
        ],
        [
          1560384000000,
          107273.4134293
        ],
        [
          1560470400000,
          107288.4737493
        ],
        [
          1560556800000,
          107315.7389528
        ],
        [
          1560643200000,
          107344.1581603
        ],
        [
          1560729600000,
          107276.7582437
        ],
        [
          1560816000000,
          107310.347529
        ],
        [
          1560902400000,
          107251.8834316
        ],
        [
          1560988800000,
          107221.2213134
        ],
        [
          1561075200000,
          107241.804666
        ],
        [
          1561161600000,
          107269.7165578
        ],
        [
          1561248000000,
          107292.3609341
        ],
        [
          1561334400000,
          107286.0017746
        ],
        [
          1561420800000,
          107333.3143154
        ],
        [
          1561507200000,
          107510.3373569
        ],
        [
          1561593600000,
          107502.6813922
        ],
        [
          1561680000000,
          107587.7799083
        ],
        [
          1561766400000,
          107617.2632306
        ],
        [
          1561852800000,
          107644.1110076
        ],
        [
          1561939200000,
          107670.1771335
        ],
        [
          1562025600000,
          107718.1363626
        ],
        [
          1562112000000,
          107759.0308125
        ],
        [
          1562198400000,
          107844.6895563
        ],
        [
          1562284800000,
          107860.780589
        ],
        [
          1562371200000,
          107889.1099073
        ],
        [
          1562457600000,
          107911.3368571
        ],
        [
          1562544000000,
          107938.2386422
        ],
        [
          1562630400000,
          107871.2020006
        ],
        [
          1562716800000,
          107924.6872892
        ],
        [
          1562803200000,
          108000.8151695
        ],
        [
          1562889600000,
          108057.4831767
        ],
        [
          1562976000000,
          108080.4508239
        ],
        [
          1563062400000,
          108109.2874579
        ],
        [
          1563148800000,
          108067.3851994
        ],
        [
          1563235200000,
          108095.7145177
        ],
        [
          1563321600000,
          108133.1873614
        ],
        [
          1563408000000,
          108161.1641266
        ],
        [
          1563494400000,
          108236.5623182
        ],
        [
          1563580800000,
          108265.3989522
        ],
        [
          1563667200000,
          108292.2467292
        ],
        [
          1563753600000,
          108328.1969345
        ],
        [
          1563840000000,
          108204.4205207
        ],
        [
          1563926400000,
          108273.3107322
        ],
        [
          1564012800000,
          108338.1096791
        ],
        [
          1564099200000,
          108363.4425127
        ],
        [
          1564185600000,
          108391.1251427
        ],
        [
          1564272000000,
          108419.0370345
        ],
        [
          1564358400000,
          108431.0597727
        ],
        [
          1564444800000,
          108385.8462883
        ],
        [
          1564531200000,
          108393.0307524
        ],
        [
          1564617600000,
          108418.667347
        ],
        [
          1564704000000,
          108563.2611867
        ],
        [
          1564790400000,
          108590.5263902
        ],
        [
          1564876800000,
          108613.8174548
        ],
        [
          1564963200000,
          108650.4228496
        ],
        [
          1565049600000,
          108640.1801317
        ],
        [
          1565136000000,
          108653.4551494
        ],
        [
          1565222400000,
          108710.4680147
        ],
        [
          1565308800000,
          108764.5905092
        ],
        [
          1565395200000,
          108790.1449096
        ],
        [
          1565481600000,
          108812.7892859
        ],
        [
          1565568000000,
          108826.7034403
        ],
        [
          1565654400000,
          108869.185402
        ],
        [
          1565740800000,
          108888.9683998
        ],
        [
          1565827200000,
          108937.8446761
        ],
        [
          1565913600000,
          108992.5488421
        ],
        [
          1566000000000,
          109013.6217879
        ],
        [
          1566086400000,
          109040.4695649
        ],
        [
          1566172800000,
          109052.8210799
        ],
        [
          1566259200000,
          109024.786812
        ],
        [
          1566345600000,
          109078.0158922
        ],
        [
          1566432000000,
          109119.9645728
        ],
        [
          1566518400000,
          109195.8776286
        ],
        [
          1566604800000,
          109223.1428321
        ],
        [
          1566691200000,
          109251.5620396
        ],
        [
          1566777600000,
          109189.4902486
        ],
        [
          1566864000000,
          109207.5656136
        ],
        [
          1566950400000,
          109220.0057783
        ],
        [
          1567036800000,
          109282.8215175
        ],
        [
          1567123200000,
          109266.3899705
        ],
        [
          1567209600000,
          109293.655174
        ],
        [
          1567296000000,
          109322.0743815
        ],
        [
          1567382400000,
          109149.1442659
        ],
        [
          1567468800000,
          109319.974272
        ],
        [
          1567555200000,
          109354.4018389
        ],
        [
          1567641600000,
          109334.0002729
        ],
        [
          1567728000000,
          109237.9849679
        ],
        [
          1567814400000,
          109267.2390284
        ],
        [
          1567900800000,
          109294.7334937
        ],
        [
          1567987200000,
          109374.6585034
        ],
        [
          1568073600000,
          109418.6511421
        ],
        [
          1568160000000,
          109491.7047533
        ],
        [
          1568246400000,
          109522.1082931
        ],
        [
          1568332800000,
          109579.1050771
        ],
        [
          1568419200000,
          109606.3702806
        ],
        [
          1568505600000,
          109635.8536029
        ],
        [
          1568592000000,
          109656.9299773
        ],
        [
          1568678400000,
          109673.4545178
        ],
        [
          1568764800000,
          109729.22279859999
        ],
        [
          1568851200000,
          109776.789617
        ],
        [
          1568937600000,
          109815.5654631
        ],
        [
          1569024000000,
          109845.0487854
        ],
        [
          1569110400000,
          109872.3139889
        ],
        [
          1569196800000,
          109933.2557761
        ],
        [
          1569283200000,
          109976.4589253
        ],
        [
          1569369600000,
          110032.5992691
        ],
        [
          1569456000000,
          110026.2854731
        ],
        [
          1569542400000,
          110038.496376
        ],
        [
          1569628800000,
          110054.4411789
        ],
        [
          1569715200000,
          110082.8603864
        ],
        [
          1569801600000,
          110170.4007772
        ],
        [
          1569888000000,
          110175.1660468
        ],
        [
          1569974400000,
          110246.6970196
        ],
        [
          1570060800000,
          110312.1829457
        ],
        [
          1570147200000,
          110353.6299283
        ],
        [
          1570233600000,
          110382.4665623
        ],
        [
          1570320000000,
          110411.9498846
        ],
        [
          1570406400000,
          110224.8449072
        ],
        [
          1570492800000,
          110195.6017894
        ],
        [
          1570579200000,
          110248.783317
        ],
        [
          1570665600000,
          110450.7395225
        ],
        [
          1570752000000,
          110486.7426398
        ],
        [
          1570838400000,
          110518.7716182
        ],
        [
          1570924800000,
          110547.6082522
        ],
        [
          1571011200000,
          110575.6434353
        ],
        [
          1571097600000,
          110592.7163886
        ],
        [
          1571184000000,
          110627.242231
        ],
        [
          1571270400000,
          110817.9348383
        ],
        [
          1571356800000,
          110875.8789013
        ],
        [
          1571443200000,
          110903.1441048
        ],
        [
          1571529600000,
          110932.6274271
        ],
        [
          1571616000000,
          110961.4122421
        ],
        [
          1571702400000,
          111110.5252049
        ],
        [
          1571788800000,
          111138.0684623
        ],
        [
          1571875200000,
          111259.663697
        ],
        [
          1571961600000,
          111381.6234462
        ],
        [
          1572048000000,
          111416.7548798
        ],
        [
          1572134400000,
          111445.5915138
        ],
        [
          1572220800000,
          111516.3276346
        ],
        [
          1572307200000,
          111388.2312711
        ],
        [
          1572393600000,
          111871.0934962
        ],
        [
          1572480000000,
          112175.9257406
        ],
        [
          1572566400000,
          112459.455083
        ],
        [
          1572652800000,
          112494.4839747
        ],
        [
          1572739200000,
          112521.7491782
        ],
        [
          1572825600000,
          112409.4606952
        ],
        [
          1572912000000,
          112453.3231253
        ],
        [
          1572998400000,
          112751.3875776
        ],
        [
          1573084800000,
          112631.8913677
        ],
        [
          1573171200000,
          112680.2516236
        ],
        [
          1573257600000,
          112711.2164872
        ],
        [
          1573344000000,
          112738.7109525
        ],
        [
          1573430400000,
          112601.2430413
        ],
        [
          1573516800000,
          12275.9729966
        ],
        [
          1573603200000,
          12252.6384996
        ],
        [
          1573689600000,
          12296.6411196
        ],
        [
          1573776000000,
          12303.601494
        ],
        [
          1573862400000,
          12307.711653
        ],
        [
          1573948800000,
          12312.7921363
        ],
        [
          1574035200000,
          12309.7105397
        ],
        [
          1574121600000,
          12294.8054279
        ],
        [
          1574208000000,
          12298.8771249
        ],
        [
          1574294400000,
          12307.5251733
        ],
        [
          1574380800000,
          12305.3607687
        ],
        [
          1574467200000,
          12309.3363011
        ],
        [
          1574553600000,
          12314.5262168
        ],
        [
          1574640000000,
          12317.9388212
        ],
        [
          1574726400000,
          12335.5329948
        ],
        [
          1574812800000,
          12336.495604400001
        ],
        [
          1574899200000,
          12338.6760108
        ],
        [
          1574985600000,
          12339.842737500001
        ],
        [
          1575072000000,
          12343.8434641
        ],
        [
          1575158400000,
          12348.1253703
        ],
        [
          1575244800000,
          12352.2726499
        ],
        [
          1575331200000,
          12360.385528
        ],
        [
          1575417600000,
          12397.353715
        ],
        [
          1575504000000,
          12386.851866
        ],
        [
          1575590400000,
          12381.868778
        ],
        [
          1575676800000,
          12386.0412518
        ],
        [
          1575763200000,
          12391.1217351
        ],
        [
          1575849600000,
          12418.7096521
        ],
        [
          1575936000000,
          12409.0501851
        ],
        [
          1576022400000,
          12429.5461703
        ],
        [
          1576108800000,
          12434.7012179
        ],
        [
          1576195200000,
          12436.0854212
        ],
        [
          1576281600000,
          12440.0861478
        ],
        [
          1576368000000,
          12444.342859800001
        ],
        [
          1576454400000,
          12448.628786
        ],
        [
          1576540800000,
          12454.10082
        ],
        [
          1576627200000,
          12501.8256868
        ],
        [
          1576713600000,
          12496.0862693
        ],
        [
          1576800000000,
          12504.066773300001
        ],
        [
          1576886400000,
          12508.0423057
        ],
        [
          1576972800000,
          12512.3242119
        ],
        [
          1577059200000,
          12517.3291126
        ],
        [
          1577145600000,
          12520.8126874
        ],
        [
          1577232000000,
          12525.0693994
        ],
        [
          1577318400000,
          12529.9781355
        ],
        [
          1577404800000,
          12526.0377753
        ],
        [
          1577491200000,
          12530.185054900001
        ],
        [
          1577577600000,
          12523.5237211
        ],
        [
          1577664000000,
          12527.4740593
        ],
        [
          1577750400000,
          12529.3548792
        ],
        [
          1577836800000,
          12533.8808444
        ],
        [
          1577923200000,
          12536.5416662
        ],
        [
          1578009600000,
          12535.469703
        ],
        [
          1578096000000,
          12539.482356
        ],
        [
          1578182400000,
          12543.6548298
        ],
        [
          1578268800000,
          12560.5277998
        ],
        [
          1578355200000,
          12554.6190212
        ],
        [
          1578441600000,
          12560.9811613
        ],
        [
          1578528000000,
          12557.2885661
        ],
        [
          1578614400000,
          12569.4086449
        ],
        [
          1578700800000,
          12573.4093715
        ],
        [
          1578787200000,
          12577.5818453
        ],
        [
          1578873600000,
          12583.2242383
        ],
        [
          1578960000000,
          12576.4411105
        ],
        [
          1579046400000,
          12601.1078092
        ],
        [
          1579132800000,
          12607.8868746
        ],
        [
          1579219200000,
          12619.0481856
        ],
        [
          1579305600000,
          12622.8890914
        ],
        [
          1579392000000,
          12627.1709976
        ],
        [
          1579478400000,
          12628.0501182
        ],
        [
          1579564800000,
          12635.574539700001
        ],
        [
          1579651200000,
          12631.9434633
        ],
        [
          1579737600000,
          12645.3782761
        ],
        [
          1579824000000,
          12643.0901949
        ],
        [
          1579910400000,
          12648.204528
        ],
        [
          1579996800000,
          12652.3770018
        ],
        [
          1580083200000,
          12664.4097286
        ],
        [
          1580169600000,
          12681.876386
        ],
        [
          1580256000000,
          12674.606348
        ],
        [
          1580342400000,
          12679.260602
        ],
        [
          1580428800000,
          12682.4940023
        ],
        [
          1580515200000,
          12686.7759085
        ],
        [
          1580601600000,
          12690.776635100001
        ],
        [
          1580688000000,
          12704.0689383
        ],
        [
          1580774400000,
          12708.7211202
        ],
        [
          1580860800000,
          12699.4480648
        ],
        [
          1580947200000,
          12699.0438498
        ],
        [
          1581033600000,
          12712.745755
        ],
        [
          1581120000000,
          12716.7464816
        ],
        [
          1581206400000,
          12720.8937612
        ],
        [
          1581292800000,
          12726.435377400001
        ],
        [
          1581379200000,
          12726.465186
        ],
        [
          1581465600000,
          12726.7833265
        ],
        [
          1581552000000,
          12741.5795557
        ],
        [
          1581638400000,
          12752.2219893
        ],
        [
          1581724800000,
          12756.3692689
        ],
        [
          1581811200000,
          12760.3699955
        ],
        [
          1581897600000,
          12765.6848639
        ],
        [
          1581984000000,
          12771.5949649
        ],
        [
          1582070400000,
          12775.9931682
        ],
        [
          1582156800000,
          12779.3143587
        ],
        [
          1582243200000,
          12786.753733900001
        ],
        [
          1582329600000,
          12791.0104459
        ],
        [
          1582416000000,
          12795.1829197
        ],
        [
          1582502400000,
          12803.4465278
        ],
        [
          1582588800000,
          12804.2392728
        ],
        [
          1582675200000,
          12812.0603353
        ],
        [
          1582761600000,
          12817.4413581
        ],
        [
          1582848000000,
          12831.7253617
        ],
        [
          1582934400000,
          12836.0072679
        ],
        [
          1583020800000,
          12840.8908098
        ],
        [
          1583107200000,
          12839.5961422
        ],
        [
          1583193600000,
          12834.2549506
        ],
        [
          1583280000000,
          12835.781853
        ],
        [
          1583366400000,
          12841.9928279
        ],
        [
          1583452800000,
          12845.738018600001
        ],
        [
          1583539200000,
          12849.9104924
        ],
        [
          1583625600000,
          12854.794034300001
        ],
        [
          1583712000000,
          12879.3665427
        ],
        [
          1583798400000,
          12879.8173853
        ],
        [
          1583884800000,
          12877.2286076
        ],
        [
          1583971200000,
          12893.2675496
        ],
        [
          1584057600000,
          12912.3725467
        ],
        [
          1584144000000,
          12916.5198263
        ],
        [
          1584230400000,
          12921.6003096
        ],
        [
          1584316800000,
          12927.2689834
        ],
        [
          1584403200000,
          12928.8143957
        ],
        [
          1584489600000,
          12933.5828747
        ],
        [
          1584576000000,
          12939.234169
        ],
        [
          1584662400000,
          12944.3388282
        ],
        [
          1584748800000,
          12949.3941173
        ],
        [
          1584835200000,
          12953.5665911
        ],
        [
          1584921600000,
          12968.4408218
        ],
        [
          1585008000000,
          12981.443941200001
        ],
        [
          1585094400000,
          12980.5895433
        ],
        [
          1585180800000,
          12981.3773387
        ],
        [
          1585267200000,
          12981.9984486
        ],
        [
          1585353600000,
          12986.2803548
        ],
        [
          1585440000000,
          12990.4528286
        ],
        [
          1585526400000,
          13004.5075413
        ],
        [
          1585612800000,
          13009.6714902
        ],
        [
          1585699200000,
          13012.5677154
        ],
        [
          1585785600000,
          13014.4989237
        ],
        [
          1585872000000,
          13017.5926591
        ],
        [
          1585958400000,
          13022.6731424
        ],
        [
          1586044800000,
          13026.8456162
        ],
        [
          1586131200000,
          13042.3399597
        ],
        [
          1586217600000,
          13052.2984439
        ],
        [
          1586304000000,
          13059.0394266
        ],
        [
          1586390400000,
          13059.082369400001
        ],
        [
          1586476800000,
          13063.9911055
        ],
        [
          1586563200000,
          13068.273011700001
        ],
        [
          1586649600000,
          13072.4454855
        ],
        [
          1586736000000,
          13077.5259688
        ],
        [
          1586822400000,
          13079.355382
        ],
        [
          1586908800000,
          13079.5647291
        ],
        [
          1586995200000,
          13090.2963505
        ],
        [
          1587081600000,
          13093.512463000001
        ],
        [
          1587168000000,
          13097.5131896
        ],
        [
          1587254400000,
          13101.6856634
        ],
        [
          1587340800000,
          13101.09847
        ],
        [
          1587427200000,
          13101.642979
        ],
        [
          1587513600000,
          13107.277734700001
        ],
        [
          1587600000000,
          13110.3330081
        ],
        [
          1587686400000,
          13119.646117
        ],
        [
          1587772800000,
          13123.8185908
        ],
        [
          1587859200000,
          13128.8990741
        ],
        [
          1587945600000,
          13132.7455685
        ],
        [
          1588032000000,
          13131.4842238
        ],
        [
          1588118400000,
          13128.380186
        ],
        [
          1588204800000,
          13131.1089531
        ],
        [
          1588291200000,
          13136.1894364
        ],
        [
          1588377600000,
          13140.3619102
        ],
        [
          1588464000000,
          13145.5518259
        ],
        [
          1588550400000,
          13143.854051100001
        ],
        [
          1588636800000,
          13141.3676188
        ],
        [
          1588723200000,
          13133.3209056
        ],
        [
          1588809600000,
          13149.9393142
        ],
        [
          1588896000000,
          13157.4888291
        ],
        [
          1588982400000,
          13162.4094916
        ],
        [
          1589068800000,
          13166.5819654
        ],
        [
          1589155200000,
          13171.6362362
        ],
        [
          1589241600000,
          13181.5838123
        ],
        [
          1589328000000,
          13192.0800303
        ],
        [
          1589414400000,
          13207.2793576
        ],
        [
          1589500800000,
          13210.4944518
        ],
        [
          1589587200000,
          13215.5749351
        ],
        [
          1589673600000,
          13219.7474089
        ],
        [
          1589760000000,
          13216.9917234
        ],
        [
          1589846400000,
          13214.1767482
        ],
        [
          1589932800000,
          13219.1167706
        ],
        [
          1590019200000,
          13230.0675025
        ],
        [
          1590105600000,
          13233.501707200001
        ],
        [
          1590192000000,
          13237.674181
        ],
        [
          1590278400000,
          13241.8214606
        ],
        [
          1590364800000,
          13245.9939344
        ],
        [
          1590451200000,
          13240.8436165
        ],
        [
          1590537600000,
          13259.9149289
        ],
        [
          1590624000000,
          13265.852015
        ],
        [
          1590710400000,
          13270.0914158
        ],
        [
          1590796800000,
          13275.1718991
        ],
        [
          1590883200000,
          13279.3191787
        ],
        [
          1590969600000,
          13283.2364397
        ],
        [
          1591056000000,
          13288.568865
        ],
        [
          1591142400000,
          13292.4051586
        ],
        [
          1591228800000,
          13293.8062704
        ],
        [
          1591315200000,
          13301.9265963
        ],
        [
          1591401600000,
          13305.964443500001
        ],
        [
          1591488000000,
          13311.1543592
        ],
        [
          1591574400000,
          13314.614285
        ],
        [
          1591660800000,
          13319.9960804
        ],
        [
          1591747200000,
          13326.0339433
        ],
        [
          1591833600000,
          13328.4387797
        ],
        [
          1591920000000,
          13333.318482
        ],
        [
          1592006400000,
          13337.3563292
        ],
        [
          1592092800000,
          13342.4368125
        ],
        [
          1592179200000,
          13347.3399181
        ],
        [
          1592265600000,
          13349.2629977
        ],
        [
          1592352000000,
          13351.2492066
        ],
        [
          1592438400000,
          13354.1367762
        ],
        [
          1592524800000,
          13359.9982797
        ],
        [
          1592611200000,
          13364.0361269
        ],
        [
          1592697600000,
          13368.0739741
        ],
        [
          1592784000000,
          13375.1447992
        ],
        [
          1592870400000,
          13383.1719856
        ],
        [
          1592956800000,
          13389.3940909
        ],
        [
          1593043200000,
          13394.369754
        ],
        [
          1593129600000,
          13398.441451
        ],
        [
          1593216000000,
          13402.4792982
        ],
        [
          1593302400000,
          13406.651772
        ],
        [
          1593388800000,
          13412.4685176
        ],
        [
          1593475200000,
          13420.654997900001
        ],
        [
          1593561600000,
          13425.9525551
        ],
        [
          1593648000000,
          13434.8780771
        ],
        [
          1593734400000,
          13436.5476695
        ],
        [
          1593820800000,
          13441.6281528
        ],
        [
          1593907200000,
          13445.8006266
        ],
        [
          1593993600000,
          13451.7366944
        ],
        [
          1594080000000,
          13458.6805302
        ],
        [
          1594166400000,
          13465.9345794
        ],
        [
          1594252800000,
          13480.0155729
        ],
        [
          1594339200000,
          13485.0194553
        ],
        [
          1594425600000,
          13489.8306854
        ],
        [
          1594512000000,
          13494.0031592
        ],
        [
          1594598400000,
          13497.2444659
        ],
        [
          1594684800000,
          13500.1185873
        ],
        [
          1594771200000,
          13503.9558992
        ],
        [
          1594857600000,
          13509.1778738
        ],
        [
          1594944000000,
          13513.947371100001
        ],
        [
          1595030400000,
          13517.8505917
        ],
        [
          1595116800000,
          13522.7964484
        ],
        [
          1595203200000,
          13527.741286800001
        ],
        [
          1595289600000,
          13537.5330855
        ],
        [
          1595376000000,
          13545.2406303
        ],
        [
          1595462400000,
          13550.875386
        ],
        [
          1595548800000,
          13551.5577924
        ],
        [
          1595635200000,
          13556.2092017
        ],
        [
          1595721600000,
          13560.2470489
        ],
        [
          1595808000000,
          13561.9252969
        ],
        [
          1595894400000,
          13569.2897968
        ],
        [
          1595980800000,
          13574.4000865
        ],
        [
          1596067200000,
          13580.8511921
        ],
        [
          1596153600000,
          13585.797048800001
        ],
        [
          1596240000000,
          13589.9695226
        ],
        [
          1596326400000,
          13595.024811700001
        ],
        [
          1596412800000,
          13562.7137909
        ],
        [
          1596499200000,
          13575.0098592
        ],
        [
          1596585600000,
          13583.3409212
        ],
        [
          1596672000000,
          13587.9931031
        ],
        [
          1596758400000,
          13586.876742800001
        ],
        [
          1596844800000,
          13590.8642016
        ],
        [
          1596931200000,
          13595.9446849
        ],
        [
          1597017600000,
          13607.8508485
        ],
        [
          1597104000000,
          13617.0041366
        ],
        [
          1597190400000,
          13621.6168382
        ],
        [
          1597276800000,
          13628.4468283
        ],
        [
          1597363200000,
          13630.3266299
        ],
        [
          1597449600000,
          13635.2724866
        ],
        [
          1597536000000,
          13639.4449604
        ],
        [
          1597622400000,
          13639.8352791
        ],
        [
          1597708800000,
          13645.573902
        ],
        [
          1597795200000,
          13645.3760332
        ],
        [
          1597881600000,
          13643.985201
        ],
        [
          1597968000000,
          13646.3360551
        ],
        [
          1598054400000,
          13651.6625268
        ],
        [
          1598140800000,
          13655.8350006
        ],
        [
          1598227200000,
          13656.9007048
        ],
        [
          1598313600000,
          28627.359364599997
        ],
        [
          1598400000000,
          28662.726104899997
        ],
        [
          1598486400000,
          28719.837466499997
        ],
        [
          1598572800000,
          28740.3826556
        ],
        [
          1598659200000,
          28749.362354499997
        ],
        [
          1598745600000,
          28756.6620286
        ],
        [
          1598832000000,
          28781.261402699998
        ],
        [
          1598918400000,
          28803.1617286
        ],
        [
          1599004800000,
          28821.9260702
        ],
        [
          1599091200000,
          28829.5242807
        ],
        [
          1599177600000,
          28791.233515
        ],
        [
          1599264000000,
          28801.1422174
        ],
        [
          1599350400000,
          28808.4418915
        ],
        [
          1599436800000,
          28789.7420704
        ],
        [
          1599523200000,
          28796.5162292
        ],
        [
          1599609600000,
          28827.7632905
        ],
        [
          1599696000000,
          28864.910361899998
        ],
        [
          1599782400000,
          28895.3385576
        ],
        [
          1599868800000,
          28902.6382317
        ],
        [
          1599955200000,
          28911.528466
        ],
        [
          1600041600000,
          28920.8905103
        ],
        [
          1600128000000,
          28933.5034617
        ],
        [
          1600214400000,
          28941.0423113
        ],
        [
          1600300800000,
          28943.8621675
        ],
        [
          1600387200000,
          28956.1812669
        ],
        [
          1600473600000,
          28965.318235799998
        ],
        [
          1600560000000,
          28972.7751799
        ],
        [
          1600646400000,
          28970.689453699997
        ],
        [
          1600732800000,
          28982.219414199997
        ],
        [
          1600819200000,
          28988.478444599998
        ],
        [
          1600905600000,
          28997.3686789
        ],
        [
          1600992000000,
          29015.6126993
        ],
        [
          1601078400000,
          29024.6602036
        ],
        [
          1601164800000,
          29033.5165352
        ],
        [
          1601251200000,
          29061.069204299998
        ],
        [
          1601337600000,
          29059.6945025
        ],
        [
          1601424000000,
          29074.6994743
        ],
        [
          1601510400000,
          29076.583747099998
        ],
        [
          1601596800000,
          29070.150985999997
        ],
        [
          1601683200000,
          29075.7385503
        ],
        [
          1601769600000,
          29083.1954944
        ],
        [
          1601856000000,
          29076.857853499998
        ],
        [
          1601942400000,
          29077.340249099998
        ],
        [
          1602028800000,
          29084.801916499997
        ],
        [
          1602115200000,
          29073.046462399998
        ],
        [
          1602201600000,
          29097.3656151
        ],
        [
          1602288000000,
          29104.6652892
        ],
        [
          1602374400000,
          29113.7127935
        ],
        [
          1602460800000,
          29128.750704799997
        ],
        [
          1602547200000,
          29142.4503992
        ],
        [
          1602633600000,
          29146.332480499997
        ],
        [
          1602720000000,
          29155.8339346
        ],
        [
          1602806400000,
          29165.7412499
        ],
        [
          1602892800000,
          29175.1197526
        ],
        [
          1602979200000,
          29184.009986899997
        ],
        [
          1603065600000,
          29187.508829399998
        ],
        [
          1603152000000,
          29203.7907537
        ],
        [
          1603238400000,
          29206.701900199998
        ],
        [
          1603324800000,
          29219.4721216
        ],
        [
          1603411200000,
          29220.3274469
        ],
        [
          1603497600000,
          29229.3749512
        ],
        [
          1603584000000,
          29237.0395264
        ],
        [
          1603670400000,
          29247.1973673
        ],
        [
          1603756800000,
          29245.9884189
        ],
        [
          1603843200000,
          29253.6920976
        ],
        [
          1603929600000,
          29261.4475392
        ],
        [
          1604016000000,
          29274.9118102
        ],
        [
          1604102400000,
          29284.0826818
        ],
        [
          1604188800000,
          29291.382355899997
        ],
        [
          1604275200000,
          29297.225107299997
        ],
        [
          1604361600000,
          29295.528345299997
        ],
        [
          1604448000000,
          29302.824220399998
        ],
        [
          1604534400000,
          29339.0332885
        ],
        [
          1604620800000,
          29350.8701681
        ],
        [
          1604707200000,
          29358.4504795
        ],
        [
          1604793600000,
          29367.7056149
        ],
        [
          1604880000000,
          29352.380060199997
        ],
        [
          1604966400000,
          29371.598351599998
        ],
        [
          1605052800000,
          29419.177005899997
        ],
        [
          1605139200000,
          29417.5546063
        ],
        [
          1605225600000,
          29438.188327699998
        ],
        [
          1605312000000,
          29448.669122199997
        ],
        [
          1605398400000,
          29455.603895199998
        ],
        [
          1605484800000,
          29465.4198893
        ],
        [
          1605571200000,
          29467.8441934
        ],
        [
          1605657600000,
          29468.8827473
        ],
        [
          1605744000000,
          29463.6008214
        ],
        [
          1605830400000,
          29471.3925923
        ],
        [
          1605916800000,
          29480.4325081
        ],
        [
          1606003200000,
          29487.732182199998
        ],
        [
          1606089600000,
          29498.5510794
        ],
        [
          1606176000000,
          29504.2931248
        ],
        [
          1606262400000,
          29519.305215599998
        ],
        [
          1606348800000,
          29523.3642543
        ],
        [
          1606435200000,
          29502.916602999998
        ],
        [
          1606521600000,
          29510.3735471
        ],
        [
          1606608000000,
          29517.639318499998
        ],
        [
          1606694400000,
          29538.5875799
        ],
        [
          1606780800000,
          29550.954212599998
        ],
        [
          1606867200000,
          29572.0503283
        ],
        [
          1606953600000,
          29568.477676399998
        ],
        [
          1607040000000,
          29571.891707799998
        ],
        [
          1607126400000,
          29580.905309399997
        ],
        [
          1607212800000,
          29589.952813699998
        ],
        [
          1607299200000,
          29583.7587975
        ],
        [
          1607385600000,
          29584.7007596
        ],
        [
          1607472000000,
          29591.4965776
        ],
        [
          1607558400000,
          29596.6123319
        ],
        [
          1607644800000,
          29614.8870033
        ],
        [
          1607731200000,
          29625.367797799998
        ],
        [
          1607817600000,
          29632.425938099997
        ],
        [
          1607904000000,
          29643.0461424
        ],
        [
          1607990400000,
          29644.038974
        ],
        [
          1608076800000,
          29642.672755599997
        ],
        [
          1608163200000,
          29629.891776
        ],
        [
          1608249600000,
          29669.3818549
        ],
        [
          1608336000000,
          29678.794260299997
        ],
        [
          1608422400000,
          29687.841764599998
        ],
        [
          1608508800000,
          29688.137710799998
        ],
        [
          1608595200000,
          29714.989225999998
        ],
        [
          1608681600000,
          29762.1855349
        ],
        [
          1608768000000,
          29773.1570097
        ],
        [
          1608854400000,
          29782.0269719
        ],
        [
          1608940800000,
          29790.8833035
        ],
        [
          1609027200000,
          29798.340247599997
        ],
        [
          1609113600000,
          29821.120603
        ],
        [
          1609200000000,
          29832.807794599998
        ],
        [
          1609286400000,
          29858.479772699997
        ],
        [
          1609372800000,
          29882.3229005
        ],
        [
          1609459200000,
          29890.1447457
        ],
        [
          1609545600000,
          29899.03498
        ],
        [
          1609632000000,
          29907.925214299998
        ],
        [
          1609718400000,
          29922.9895081
        ],
        [
          1609804800000,
          29941.8117619
        ],
        [
          1609891200000,
          29962.2156457
        ],
        [
          1609977600000,
          29974.139223899998
        ],
        [
          1610064000000,
          29990.3354826
        ],
        [
          1610150400000,
          29999.3490842
        ],
        [
          1610236800000,
          30008.761489599998
        ],
        [
          1610323200000,
          30010.837351
        ],
        [
          1610409600000,
          30022.9737302
        ],
        [
          1610496000000,
          30060.8939369
        ],
        [
          1610582400000,
          30068.9464742
        ],
        [
          1610668800000,
          30074.0622285
        ],
        [
          1610755200000,
          30081.361902599998
        ],
        [
          1610841600000,
          30090.2521369
        ],
        [
          1610928000000,
          30075.9897785
        ],
        [
          1611014400000,
          30075.1796339
        ],
        [
          1611100800000,
          30087.3047328
        ],
        [
          1611187200000,
          30094.9589211
        ],
        [
          1611273600000,
          30095.412137299998
        ],
        [
          1611360000000,
          30103.233982499998
        ],
        [
          1611446400000,
          30112.1242168
        ],
        [
          1611532800000,
          30123.5669932
        ],
        [
          1611619200000,
          30133.6814848
        ],
        [
          1611705600000,
          30139.084919099998
        ],
        [
          1611792000000,
          30137.9145967
        ],
        [
          1611878400000,
          30147.138202399998
        ],
        [
          1611964800000,
          30156.5506078
        ],
        [
          1612051200000,
          30164.0075519
        ],
        [
          1612137600000,
          30167.792139999998
        ],
        [
          1612224000000,
          30181.731017899998
        ],
        [
          1612310400000,
          30196.637070499997
        ],
        [
          1612396800000,
          30209.0121557
        ],
        [
          1612483200000,
          30218.05966
        ],
        [
          1612569600000,
          30227.314795399998
        ],
        [
          1612656000000,
          30234.7717395
        ],
        [
          1612742400000,
          30249.9188711
        ],
        [
          1612828800000,
          30276.397448599997
        ],
        [
          1612915200000,
          30292.488200199998
        ],
        [
          1613001600000,
          30333.0546821
        ],
        [
          1613088000000,
          30353.340376699998
        ],
        [
          1613174400000,
          30362.387881
        ],
        [
          1613260800000,
          30371.643016399998
        ],
        [
          1613347200000,
          30391.2779718
        ],
        [
          1613433600000,
          30405.023820399998
        ],
        [
          1613520000000,
          30410.5044758
        ],
        [
          1613606400000,
          30399.240025199997
        ],
        [
          1613692800000,
          30441.543157699998
        ],
        [
          1613779200000,
          30450.9216604
        ],
        [
          1613865600000,
          30459.8118947
        ],
        [
          1613952000000,
          30465.764360099998
        ],
        [
          1614038400000,
          30475.687679
        ],
        [
          1614124800000,
          30480.5976441
        ],
        [
          1614211200000,
          30481.7816484
        ],
        [
          1614297600000,
          30495.5420519
        ],
        [
          1614384000000,
          30504.589556199997
        ],
        [
          1614470400000,
          30513.8446916
        ],
        [
          1614556800000,
          30520.8476859
        ],
        [
          1614643200000,
          30529.8033526
        ],
        [
          1614729600000,
          30521.977791899997
        ],
        [
          1614816000000,
          30525.015133499997
        ],
        [
          1614902400000,
          30529.167928
        ],
        [
          1614988800000,
          30538.0581623
        ],
        [
          1615075200000,
          30547.3132977
        ],
        [
          1615161600000,
          30530.096712
        ],
        [
          1615248000000,
          30546.9973683
        ],
        [
          1615334400000,
          30592.5027571
        ],
        [
          1615420800000,
          30617.792389799997
        ],
        [
          1615507200000,
          30639.1751754
        ],
        [
          1615593600000,
          30648.346047
        ],
        [
          1615680000000,
          30656.0106222
        ],
        [
          1615766400000,
          30657.8764097
        ],
        [
          1615852800000,
          30672.6661377
        ],
        [
          1615939200000,
          30686.431741999997
        ],
        [
          1616025600000,
          30745.3841974
        ],
        [
          1616112000000,
          30777.4891742
        ],
        [
          1616198400000,
          30786.379408499997
        ],
        [
          1616284800000,
          30795.8257166
        ],
        [
          1616371200000,
          30798.625739299998
        ],
        [
          1616457600000,
          30818.7880666
        ],
        [
          1616544000000,
          30838.6617816
        ],
        [
          1616630400000,
          30846.973296999997
        ],
        [
          1616716800000,
          30826.157361499998
        ],
        [
          1616803200000,
          30835.4124969
        ],
        [
          1616889600000,
          30844.460001199997
        ],
        [
          1616976000000,
          30754.7744464
        ],
        [
          1617062400000,
          30784.408278199997
        ],
        [
          1617148800000,
          30783.0801774
        ],
        [
          1617235200000,
          30799.2001393
        ],
        [
          1617321600000,
          30808.0903736
        ],
        [
          1617408000000,
          30815.9122188
        ],
        [
          1617494400000,
          30824.802453099997
        ],
        [
          1617580800000,
          30834.0575885
        ],
        [
          1617667200000,
          30833.5361098
        ],
        [
          1617753600000,
          30831.433902499997
        ],
        [
          1617840000000,
          30833.4701976
        ],
        [
          1617926400000,
          30852.592397599998
        ],
        [
          1618012800000,
          30862.004803
        ],
        [
          1618099200000,
          30870.928939999998
        ],
        [
          1618185600000,
          30892.986959799997
        ],
        [
          1618272000000,
          30911.408514299997
        ],
        [
          1618358400000,
          30930.2086232
        ],
        [
          1618444800000,
          30963.8406086
        ],
        [
          1618531200000,
          30978.7551446
        ],
        [
          1618617600000,
          30987.8026489
        ],
        [
          1618704000000,
          30997.091687
        ],
        [
          1618790400000,
          30976.249377
        ],
        [
          1618876800000,
          30996.5525159
        ],
        [
          1618963200000,
          31015.243851299998
        ],
        [
          1619049600000,
          30996.881223499997
        ],
        [
          1619136000000,
          31017.8812478
        ],
        [
          1619222400000,
          31025.1809219
        ],
        [
          1619308800000,
          31034.1945235
        ],
        [
          1619395200000,
          31045.9495205
        ],
        [
          1619481600000,
          31050.4865559
        ],
        [
          1619568000000,
          31084.7528221
        ],
        [
          1619654400000,
          31093.2946137
        ],
        [
          1619740800000,
          31095.526265499997
        ],
        [
          1619827200000,
          31104.731039799997
        ],
        [
          1619913600000,
          31113.621274099998
        ],
        [
          1620000000000,
          31127.1999206
        ],
        [
          1620086400000,
          31150.621175599998
        ],
        [
          1620172800000,
          31175.0472372
        ],
        [
          1620259200000,
          31266.2557475
        ],
        [
          1620345600000,
          31283.7675538
        ],
        [
          1620432000000,
          31292.6916908
        ],
        [
          1620518400000,
          31301.705292399998
        ],
        [
          1620604800000,
          31315.347529399998
        ],
        [
          1620691200000,
          31301.157191299997
        ],
        [
          1620777600000,
          31310.2385983
        ],
        [
          1620864000000,
          31316.5946524
        ],
        [
          1620950400000,
          31306.4552167
        ],
        [
          1621036800000,
          31316.9360112
        ],
        [
          1621123200000,
          31324.791759099997
        ],
        [
          1621209600000,
          31313.5175307
        ],
        [
          1621296000000,
          31305.0261206
        ],
        [
          1621382400000,
          31293.1388547
        ],
        [
          1621468800000,
          31324.7240107
        ],
        [
          1621555200000,
          31356.3844923
        ],
        [
          1621641600000,
          31365.5553639
        ],
        [
          1621728000000,
          31374.810499299998
        ],
        [
          1621814400000,
          31441.418823599997
        ],
        [
          1621900800000,
          31458.3712508
        ],
        [
          1621987200000,
          31485.5509513
        ],
        [
          1622073600000,
          31473.678740699997
        ],
        [
          1622160000000,
          31502.7550969
        ],
        [
          1622246400000,
          31511.645331199998
        ],
        [
          1622332800000,
          31520.7267382
        ],
        [
          1622419200000,
          31499.4606908
        ],
        [
          1622505600000,
          31506.1491451
        ],
        [
          1622592000000,
          31521.9121111
        ],
        [
          1622678400000,
          31524.197005399998
        ],
        [
          1622764800000,
          31534.141983499998
        ],
        [
          1622851200000,
          31543.0322178
        ],
        [
          1622937600000,
          31552.3212559
        ],
        [
          1623024000000,
          31560.4157245
        ],
        [
          1623110400000,
          31588.3356288
        ],
        [
          1623196800000,
          31602.151594199997
        ],
        [
          1623283200000,
          31612.4572585
        ],
        [
          1623369600000,
          31613.153410299998
        ],
        [
          1623456000000,
          31622.2009146
        ],
        [
          1623542400000,
          31631.489952699998
        ],
        [
          1623628800000,
          31641.805965
        ],
        [
          1623715200000,
          31660.6042093
        ],
        [
          1623801600000,
          31678.7201311
        ],
        [
          1623888000000,
          31686.91348
        ],
        [
          1623974400000,
          31694.1641412
        ],
        [
          1624060800000,
          31704.6788384
        ],
        [
          1624147200000,
          31713.5690727
        ],
        [
          1624233600000,
          31729.2544179
        ],
        [
          1624320000000,
          31760.106890299998
        ],
        [
          1624406400000,
          31777.3380902
        ],
        [
          1624492800000,
          31794.133247399997
        ],
        [
          1624579200000,
          31822.9793274
        ],
        [
          1624665600000,
          31831.869561699998
        ],
        [
          1624752000000,
          31840.7936987
        ],
        [
          1624838400000,
          31852.7436285
        ],
        [
          1624924800000,
          31860.336599299997
        ],
        [
          1625011200000,
          31856.3109299
        ],
        [
          1625097600000,
          31874.934043999998
        ],
        [
          1625184000000,
          31890.9984131
        ],
        [
          1625270400000,
          31900.0459174
        ],
        [
          1625356800000,
          31908.9361517
        ],
        [
          1625443200000,
          31921.2486632
        ],
        [
          1625529600000,
          31934.6140619
        ],
        [
          1625616000000,
          31966.483576399998
        ],
        [
          1625702400000,
          32006.690343299997
        ],
        [
          1625788800000,
          32015.9454787
        ],
        [
          1625875200000,
          32016.564487199998
        ],
        [
          1625961600000,
          32023.8641613
        ],
        [
          1626048000000,
          32020.764212799997
        ],
        [
          1626134400000,
          32016.973042499998
        ],
        [
          1626220800000,
          32029.1485025
        ],
        [
          1626307200000,
          32060.440777699998
        ],
        [
          1626393600000,
          32071.3058211
        ],
        [
          1626480000000,
          32080.1960554
        ],
        [
          1626566400000,
          32089.120192399998
        ],
        [
          1626652800000,
          32098.9041015
        ],
        [
          1626739200000,
          32106.568676699997
        ],
        [
          1626825600000,
          32113.238299599998
        ],
        [
          1626912000000,
          32120.8355309
        ],
        [
          1626998400000,
          32117.735551499998
        ],
        [
          1627084800000,
          32126.7830558
        ],
        [
          1627171200000,
          32136.038191199998
        ],
        [
          1627257600000,
          32149.8037955
        ],
        [
          1627344000000,
          32152.1809822
        ],
        [
          1627430400000,
          32166.2422562
        ],
        [
          1627516800000,
          32190.6683178
        ],
        [
          1627603200000,
          32200.253534
        ],
        [
          1627689600000,
          32209.3010383
        ],
        [
          1627776000000,
          32218.191272599997
        ],
        [
          1627862400000,
          32215.2721179
        ],
        [
          1627948800000,
          32224.0389849
        ],
        [
          1628035200000,
          32233.0864892
        ],
        [
          1628121600000,
          32253.114646399998
        ],
        [
          1628208000000,
          32289.466907299997
        ],
        [
          1628294400000,
          32298.845409999998
        ],
        [
          1628380800000,
          32307.7356443
        ],
        [
          1628467200000,
          32327.320238599998
        ],
        [
          1628553600000,
          32338.143859099997
        ],
        [
          1628640000000,
          32331.088066599998
        ],
        [
          1628726400000,
          32342.213051299997
        ],
        [
          1628812800000,
          32369.315530699998
        ],
        [
          1628899200000,
          32378.205765
        ],
        [
          1628985600000,
          32387.0959993
        ],
        [
          1629072000000,
          32412.236806499997
        ],
        [
          1629158400000,
          32473.935858099998
        ],
        [
          1629244800000,
          32481.219489699997
        ],
        [
          1629331200000,
          32449.1168836
        ],
        [
          1629417600000,
          32470.638624199997
        ],
        [
          1629504000000,
          529595.8822258
        ],
        [
          1629590400000,
          529703.6897301
        ],
        [
          1629676800000,
          530367.0039646
        ],
        [
          1629763200000,
          530656.6659489
        ],
        [
          1629849600000,
          530721.6295316
        ],
        [
          1629936000000,
          530967.3242947999
        ],
        [
          1630022400000,
          530972.6977415
        ],
        [
          1630108800000,
          531103.8919804
        ],
        [
          1630195200000,
          531204.1222147
        ],
        [
          1630281600000,
          530971.980593
        ],
        [
          1630368000000,
          530042.5444762
        ],
        [
          1630454400000,
          529319.2183007
        ],
        [
          1630540800000,
          528137.7666055
        ],
        [
          1630627200000,
          526240.2172581999
        ],
        [
          1630713600000,
          526341.2898614
        ],
        [
          1630800000000,
          526441.5200957
        ],
        [
          1630886400000,
          525816.6711778
        ],
        [
          1630972800000,
          525484.1555478
        ],
        [
          1631059200000,
          526175.0357414
        ],
        [
          1631145600000,
          124225.7455738
        ],
        [
          1631232000000,
          124458.1294638
        ],
        [
          1631318400000,
          124481.2236008
        ],
        [
          1631404800000,
          124506.2523013
        ],
        [
          1631491200000,
          124697.0434872
        ],
        [
          1631577600000,
          124669.877739
        ],
        [
          1631664000000,
          124453.5521185
        ],
        [
          1631750400000,
          124643.7964531
        ],
        [
          1631836800000,
          125173.5082191
        ],
        [
          1631923200000,
          125197.4984534
        ],
        [
          1632009600000,
          125224.0543468
        ],
        [
          1632096000000,
          125143.36478
        ],
        [
          1632182400000,
          124727.2232085
        ],
        [
          1632268800000,
          124712.1224653
        ],
        [
          1632355200000,
          124736.4653007
        ],
        [
          1632441600000,
          124524.1201823
        ],
        [
          1632528000000,
          124549.8676866
        ],
        [
          1632614400000,
          124574.7540182
        ],
        [
          1632700800000,
          124571.0028313
        ],
        [
          1632787200000,
          124453.0034416
        ],
        [
          1632873600000,
          124303.3382673
        ],
        [
          1632960000000,
          124586.0687396
        ],
        [
          1633046400000,
          124880.6785539
        ],
        [
          1633132800000,
          124904.6687882
        ],
        [
          1633219200000,
          124930.4162925
        ],
        [
          1633305600000,
          124929.24595319999
        ],
        [
          1633392000000,
          14012.7810185
        ],
        [
          1633478400000,
          14004.2796868
        ],
        [
          1633564800000,
          13986.2190298
        ],
        [
          1633651200000,
          13991.445297299999
        ],
        [
          1633737600000,
          14007.8242084
        ],
        [
          1633824000000,
          14010.571881599999
        ],
        [
          1633910400000,
          14015.0355047
        ],
        [
          1633996800000,
          14033.3210268
        ],
        [
          1634083200000,
          14017.9048872
        ],
        [
          1634169600000,
          14050.4899918
        ],
        [
          1634256000000,
          14075.2580182
        ],
        [
          1634342400000,
          14078.0056914
        ],
        [
          1634428800000,
          14080.0910191
        ],
        [
          1634515200000,
          14080.263736699999
        ],
        [
          1634601600000,
          14064.8802373
        ],
        [
          1634688000000,
          14069.2598681
        ],
        [
          1634774400000,
          14056.8365841
        ],
        [
          1634860800000,
          14056.64241
        ],
        [
          1634947200000,
          14059.3900832
        ],
        [
          1635033600000,
          14062.1377564
        ],
        [
          1635120000000,
          14021.6996597
        ],
        [
          1635206400000,
          14014.295409299999
        ],
        [
          1635292800000,
          14008.440788099999
        ],
        [
          1635379200000,
          14012.6173888
        ],
        [
          1635465600000,
          14012.1238233
        ],
        [
          1635552000000,
          14014.969760799999
        ],
        [
          1635638400000,
          14017.717434
        ],
        [
          1635724800000,
          14005.819248
        ],
        [
          1635811200000,
          14004.464642199999
        ],
        [
          1635897600000,
          14013.194432799999
        ],
        [
          1635984000000,
          13999.260132
        ],
        [
          1636070400000,
          13982.6402423
        ],
        [
          1636156800000,
          13985.578576599999
        ],
        [
          1636243200000,
          13988.3262498
        ],
        [
          1636329600000,
          13987.0664461
        ],
        [
          1636416000000,
          14012.9413052
        ],
        [
          1636502400000,
          14042.3729157
        ],
        [
          1636588800000,
          14028.1108917
        ],
        [
          1636675200000,
          14012.139260799999
        ],
        [
          1636761600000,
          14015.6475438
        ],
        [
          1636848000000,
          14018.684169299999
        ],
        [
          1636934400000,
          14022.2612206
        ],
        [
          1637020800000,
          13993.2295694
        ],
        [
          1637107200000,
          13992.5802298
        ],
        [
          1637193600000,
          14015.505181
        ],
        [
          1637280000000,
          14037.6140741
        ],
        [
          1637366400000,
          14040.5563729
        ],
        [
          1637452800000,
          14043.2097463
        ],
        [
          1637539200000,
          14013.739685
        ],
        [
          1637625600000,
          13997.0438905
        ],
        [
          1637712000000,
          13988.2354506
        ],
        [
          1637798400000,
          13992.6710842
        ],
        [
          1637884800000,
          13987.5181892
        ],
        [
          1637971200000,
          13990.4605149
        ],
        [
          1638057600000,
          13992.9273502
        ],
        [
          1638144000000,
          13995.869649
        ],
        [
          1638230400000,
          13970.920852
        ],
        [
          1638316800000,
          13955.2290006
        ],
        [
          1638403200000,
          13927.421263299999
        ],
        [
          1638489600000,
          13914.534223999999
        ],
        [
          1638576000000,
          13917.1915619
        ],
        [
          1638662400000,
          13920.414884
        ],
        [
          1638748800000,
          13844.2112082
        ],
        [
          1638835200000,
          13879.692304799999
        ],
        [
          1638921600000,
          13928.0119454
        ],
        [
          1639008000000,
          13935.7626894
        ],
        [
          1639094400000,
          13962.4165533
        ],
        [
          1639180800000,
          13965.0699267
        ],
        [
          1639267200000,
          13967.7233001
        ],
        [
          1639353600000,
          13991.9528011
        ],
        [
          1639440000000,
          14022.1455237
        ],
        [
          1639526400000,
          14043.6723114
        ],
        [
          1639612800000,
          14048.1981502
        ],
        [
          1639699200000,
          14045.316035599999
        ],
        [
          1639785600000,
          14047.969409
        ],
        [
          1639872000000,
          14050.4360858
        ],
        [
          1639958400000,
          14048.2305618
        ],
        [
          1640044800000,
          14069.9964282
        ],
        [
          1640131200000,
          14088.8707963
        ],
        [
          1640217600000,
          14113.5994688
        ],
        [
          1640304000000,
          14113.20023
        ],
        [
          1640390400000,
          14115.6669068
        ],
        [
          1640476800000,
          14118.609232499999
        ],
        [
          1640563200000,
          14122.8821945
        ],
        [
          1640649600000,
          14133.3870577
        ],
        [
          1640736000000,
          14114.3693429
        ],
        [
          1640822400000,
          14131.5514676
        ],
        [
          1640908800000,
          14151.6230306
        ],
        [
          1640995200000,
          14154.5653563
        ],
        [
          1641081600000,
          14157.0320331
        ],
        [
          1641168000000,
          14157.5498658
        ],
        [
          1641254400000,
          14193.135411199999
        ],
        [
          1641340800000,
          14205.8957259
        ],
        [
          1641427200000,
          14228.757141299999
        ],
        [
          1641513600000,
          14232.2674589
        ],
        [
          1641600000000,
          14234.7341357
        ],
        [
          1641686400000,
          14237.387509099999
        ],
        [
          1641772800000,
          14217.3985677
        ],
        [
          1641859200000,
          14170.1925588
        ],
        [
          1641945600000,
          14131.665064499999
        ],
        [
          1642032000000,
          14102.8330861
        ],
        [
          1642118400000,
          14120.6837139
        ],
        [
          1642204800000,
          14123.150390699999
        ],
        [
          1642291200000,
          14126.279413
        ],
        [
          1642377600000,
          14093.7401937
        ],
        [
          1642464000000,
          14125.129265
        ],
        [
          1642550400000,
          14134.552006599999
        ],
        [
          1642636800000,
          14174.6594218
        ],
        [
          1642723200000,
          14191.038359799999
        ],
        [
          1642809600000,
          14194.1673552
        ],
        [
          1642896000000,
          14197.296377499999
        ],
        [
          1642982400000,
          14196.9773518
        ],
        [
          1643068800000,
          14169.4547608
        ],
        [
          1643155200000,
          14154.2823521
        ],
        [
          1643241600000,
          14149.3994852
        ],
        [
          1643328000000,
          14118.7511279
        ],
        [
          1643414400000,
          14121.7877534
        ],
        [
          1643500800000,
          14124.4411268
        ],
        [
          1643587200000,
          14178.0519669
        ],
        [
          1643673600000,
          14224.155483
        ],
        [
          1643760000000,
          14187.592508599999
        ],
        [
          1643846400000,
          14215.128054699999
        ],
        [
          1643932800000,
          14256.762291
        ],
        [
          1644019200000,
          14259.9856131
        ],
        [
          1644105600000,
          14262.6389865
        ],
        [
          1644192000000,
          14263.3342383
        ],
        [
          1644278400000,
          14269.825942399999
        ],
        [
          1644364800000,
          14251.3015301
        ],
        [
          1644451200000,
          14280.9324184
        ],
        [
          1644537600000,
          14281.1503574
        ],
        [
          1644624000000,
          14283.8076953
        ],
        [
          1644710400000,
          14286.9367176
        ],
        [
          1644796800000,
          14295.8709121
        ],
        [
          1644883200000,
          14248.9890595
        ],
        [
          1644969600000,
          14252.027719599999
        ],
        [
          1645056000000,
          14258.2112571
        ],
        [
          1645142400000,
          14249.041607699999
        ],
        [
          1645228800000,
          14251.6949811
        ],
        [
          1645315200000,
          14254.7316066
        ],
        [
          1645401600000,
          14254.5250635
        ],
        [
          1645488000000,
          14250.7702004
        ],
        [
          1645574400000,
          14221.2558176
        ],
        [
          1645660800000,
          14167.708488
        ],
        [
          1645747200000,
          14180.612737
        ],
        [
          1645833600000,
          14183.6493356
        ],
        [
          1645920000000,
          14187.0633188
        ],
        [
          1646006400000,
          14169.363237399999
        ],
        [
          1646092800000,
          14179.8970932
        ],
        [
          1646179200000,
          14156.6319498
        ],
        [
          1646265600000,
          14234.5983607
        ],
        [
          1646352000000,
          14257.3124065
        ],
        [
          1646438400000,
          14259.7790833
        ],
        [
          1646524800000,
          14262.9081056
        ],
        [
          1646611200000,
          14247.5429743
        ],
        [
          1646697600000,
          14225.0289509
        ],
        [
          1646784000000,
          14227.6802628
        ],
        [
          1646870400000,
          14248.097944
        ],
        [
          1646956800000,
          14261.7112653
        ],
        [
          1647043200000,
          14264.462903
        ],
        [
          1647129600000,
          14267.5919253
        ],
        [
          1647216000000,
          14257.860414699999
        ],
        [
          1647302400000,
          14261.0837368
        ],
        [
          1647388800000,
          14265.5801854
        ],
        [
          1647475200000,
          14240.001470899999
        ],
        [
          1647561600000,
          14247.1343763
        ],
        [
          1647648000000,
          14249.6010531
        ],
        [
          1647734400000,
          14253.0150363
        ],
        [
          1647820800000,
          14261.5789038
        ],
        [
          1647907200000,
          14291.0818475
        ],
        [
          1647993600000,
          14284.520639999999
        ],
        [
          1648080000000,
          14305.3012753
        ],
        [
          1648166400000,
          14309.4653485
        ],
        [
          1648252800000,
          14312.8793317
        ],
        [
          1648339200000,
          14315.817665999999
        ],
        [
          1648425600000,
          14315.121941899999
        ],
        [
          1648512000000,
          14296.8299499
        ],
        [
          1648598400000,
          14291.7996865
        ],
        [
          1648684800000,
          14253.467505499999
        ],
        [
          1648771200000,
          14235.1460964
        ],
        [
          1648857600000,
          14238.5600796
        ],
        [
          1648944000000,
          14241.026756399999
        ],
        [
          1649030400000,
          14287.960335
        ],
        [
          1649116800000,
          14301.5296771
        ],
        [
          1649203200000,
          14274.6218601
        ],
        [
          1649289600000,
          14276.6022636
        ],
        [
          1649376000000,
          14284.883073199999
        ],
        [
          1649462400000,
          14288.0120955
        ],
        [
          1649548800000,
          14290.7637332
        ],
        [
          1649635200000,
          14266.0979941
        ],
        [
          1649721600000,
          14287.5417657
        ],
        [
          1649808000000,
          14308.0650842
        ],
        [
          1649894400000,
          14314.310096
        ],
        [
          1649980800000,
          14317.6297794
        ],
        [
          1650067200000,
          14320.190756
        ],
        [
          1650153600000,
          14323.3197783
        ],
        [
          1650240000000,
          14325.7864551
        ],
        [
          1650326400000,
          14321.5116201
        ],
        [
          1650412800000,
          14304.2754525
        ],
        [
          1650499200000,
          14332.850357899999
        ],
        [
          1650585600000,
          14303.217095799999
        ],
        [
          1650672000000,
          14306.631078999999
        ],
        [
          1650758400000,
          14309.0977558
        ],
        [
          1650844800000,
          14317.0056746
        ],
        [
          1650931200000,
          14275.710502099999
        ],
        [
          1651017600000,
          14247.103701099999
        ],
        [
          1651104000000,
          14259.361779699999
        ],
        [
          1651190400000,
          14261.8284565
        ],
        [
          1651276800000,
          14258.5121084
        ],
        [
          1651363200000,
          14260.978785199999
        ],
        [
          1651449600000,
          14263.6321586
        ],
        [
          1651536000000,
          14260.770817499999
        ],
        [
          1651622400000,
          14270.5583893
        ],
        [
          1651708800000,
          14205.781110599999
        ],
        [
          1651795200000,
          14186.4224306
        ],
        [
          1651881600000,
          14189.174068299999
        ],
        [
          1651968000000,
          14192.3030906
        ],
        [
          1652054400000,
          14199.7455095
        ],
        [
          1652140800000,
          14205.7323868
        ],
        [
          1652227200000,
          14224.236901799999
        ],
        [
          1652313600000,
          14196.227722
        ],
        [
          1652400000000,
          14228.6855697
        ],
        [
          1652486400000,
          14231.3429076
        ],
        [
          1652572800000,
          14234.5662297
        ],
        [
          1652659200000,
          14249.1053424
        ],
        [
          1652745600000,
          14261.987471
        ],
        [
          1652832000000,
          14263.387988999999
        ],
        [
          1652918400000,
          14214.1222889
        ],
        [
          1653004800000,
          14167.7849324
        ],
        [
          1653091200000,
          14171.768837399999
        ],
        [
          1653177600000,
          14174.2355142
        ],
        [
          1653264000000,
          14135.0578312
        ],
        [
          1653350400000,
          14143.6705139
        ],
        [
          1653436800000,
          14146.8738818
        ],
        [
          1653523200000,
          14155.0305544
        ],
        [
          1653609600000,
          14206.4911348
        ],
        [
          1653696000000,
          14208.9578116
        ],
        [
          1653782400000,
          14212.1850982
        ],
        [
          1653868800000,
          14243.7529908
        ],
        [
          1653955200000,
          14247.6465605
        ],
        [
          1654041600000,
          14262.0264907
        ],
        [
          1654128000000,
          14253.9104457
        ],
        [
          1654214400000,
          14257.3164999
        ],
        [
          1654300800000,
          14259.9738378
        ],
        [
          1654387200000,
          14273.9745073
        ],
        [
          1654473600000,
          14266.0451589
        ],
        [
          1654560000000,
          14295.9649995
        ],
        [
          1654646400000,
          14299.7523759
        ],
        [
          1654732800000,
          14302.7889745
        ],
        [
          1654819200000,
          14252.8218142
        ],
        [
          1654905600000,
          14243.0167489
        ],
        [
          1654992000000,
          14245.9550832
        ],
        [
          1655078400000,
          14200.4597112
        ],
        [
          1655164800000,
          14195.7855028
        ],
        [
          1655251200000,
          14209.5276402
        ],
        [
          1655337600000,
          14179.6582055
        ],
        [
          1655424000000,
          14157.7657999
        ],
        [
          1655510400000,
          14161.0873594
        ],
        [
          1655596800000,
          14164.3106815
        ],
        [
          1655683200000,
          14129.1434368
        ],
        [
          1655769600000,
          14142.084208299999
        ],
        [
          1655856000000,
          14098.4871235
        ],
        [
          1655942400000,
          14119.8357507
        ],
        [
          1656028800000,
          14116.9618552
        ],
        [
          1656115200000,
          14127.049003799999
        ],
        [
          1656201600000,
          14129.8006415
        ],
        [
          1656288000000,
          14126.922808399999
        ],
        [
          1656374400000,
          14128.8259333
        ],
        [
          1656460800000,
          14185.028647
        ],
        [
          1656547200000,
          14244.5438789
        ],
        [
          1656633600000,
          14253.9238582
        ],
        [
          1656720000000,
          14256.7678927
        ],
        [
          1656806400000,
          14259.9912148
        ],
        [
          1656892800000,
          14309.1436177
        ],
        [
          1656979200000,
          14334.7670291
        ],
        [
          1657065600000,
          14362.0845854
        ],
        [
          1657152000000,
          14422.061538
        ],
        [
          1657238400000,
          14441.866032799999
        ],
        [
          1657324800000,
          14444.6176705
        ],
        [
          1657411200000,
          14447.654295999999
        ],
        [
          1657497600000,
          14453.419589699999
        ],
        [
          1657584000000,
          14495.041009999999
        ],
        [
          1657670400000,
          14483.188706199999
        ],
        [
          1657756800000,
          14533.982845
        ],
        [
          1657843200000,
          14517.1374341
        ],
        [
          1657929600000,
          14520.266456399999
        ],
        [
          1658016000000,
          14523.2087821
        ],
        [
          1658102400000,
          14481.9946958
        ],
        [
          1658188800000,
          14437.3744508
        ],
        [
          1658275200000,
          14431.9809701
        ],
        [
          1658361600000,
          14447.5029639
        ],
        [
          1658448000000,
          14500.7081698
        ],
        [
          1658534400000,
          14504.1261175
        ],
        [
          1658620800000,
          14507.349439599999
        ],
        [
          1658707200000,
          14517.1555649
        ],
        [
          1658793600000,
          14517.2201083
        ],
        [
          1658880000000,
          14555.7196984
        ],
        [
          1658966400000,
          14603.2750777
        ],
        [
          1659052800000,
          14600.5733167
        ],
        [
          1659139200000,
          14610.2956082
        ],
        [
          1659225600000,
          14612.9529461
        ],
        [
          1659312000000,
          14616.176268199999
        ],
        [
          1659398400000,
          14582.5247194
        ],
        [
          1659484800000,
          14612.4234158
        ],
        [
          1659571200000,
          14631.7565701
        ],
        [
          1659657600000,
          14667.5374288
        ],
        [
          1659744000000,
          14670.0984054
        ],
        [
          1659830400000,
          14673.321727499999
        ],
        [
          1659916800000,
          14727.0314433
        ],
        [
          1660003200000,
          14731.2300597
        ],
        [
          1660089600000,
          14777.0692299
        ],
        [
          1660176000000,
          14827.2954815
        ],
        [
          1660262400000,
          14813.5539302
        ],
        [
          1660348800000,
          14816.6829525
        ],
        [
          1660435200000,
          14819.9102391
        ],
        [
          1660521600000,
          14800.309862799999
        ],
        [
          1660608000000,
          14801.1528549
        ],
        [
          1660694400000,
          14813.2489749
        ],
        [
          1660780800000,
          14805.024384999999
        ],
        [
          1660867200000,
          14798.333329899999
        ],
        [
          1660953600000,
          14801.2716642
        ],
        [
          1661040000000,
          14804.4989508
        ],
        [
          1661126400000,
          14837.1010482
        ],
        [
          1661212800000,
          14816.206745
        ],
        [
          1661299200000,
          14756.7165218
        ],
        [
          1661385600000,
          14738.774400299999
        ],
        [
          1661472000000,
          14736.7574759
        ],
        [
          1661558400000,
          14739.695810199999
        ],
        [
          1661644800000,
          14742.9191323
        ],
        [
          1661731200000,
          14773.9166008
        ],
        [
          1661817600000,
          14790.7711339
        ],
        [
          1661904000000,
          14767.870133
        ],
        [
          1661990400000,
          14771.856099499999
        ],
        [
          1662076800000,
          14789.9860329
        ],
        [
          1662163200000,
          14793.018667
        ],
        [
          1662249600000,
          14796.1476893
        ],
        [
          1662336000000,
          14848.1106912
        ],
        [
          1662422400000,
          14969.5438684
        ],
        [
          1662508800000,
          14929.2640049
        ],
        [
          1662595200000,
          14936.4683751
        ],
        [
          1662681600000,
          14915.7626984
        ],
        [
          1662768000000,
          14919.1766816
        ],
        [
          1662854400000,
          14922.400003699999
        ],
        [
          1662940800000,
          14904.3424113
        ],
        [
          1663027200000,
          14896.4728733
        ],
        [
          1663113600000,
          14875.4841387
        ],
        [
          1663200000000,
          14851.6167219
        ],
        [
          1663286400000,
          14791.5116709
        ],
        [
          1663372800000,
          14794.734993
        ],
        [
          1663459200000,
          14798.1489762
        ],
        [
          1663545600000,
          14809.7413009
        ],
        [
          1663632000000,
          14827.2235047
        ],
        [
          1663718400000,
          14827.2235047
        ],
        [
          1663804800000,
          14821.299826999999
        ],
        [
          1663891200000,
          14871.7514414
        ],
        [
          1663977600000,
          14877.7383187
        ],
        [
          1664064000000,
          14881.1523019
        ],
        [
          1664150400000,
          14875.3202256
        ],
        [
          1664236800000,
          24697.6549509
        ],
        [
          1664323200000,
          24758.5222814
        ],
        [
          1664409600000,
          24712.275915500002
        ],
        [
          1664496000000,
          24680.7379331
        ],
        [
          1664582400000,
          24686.6802016
        ],
        [
          1664668800000,
          24691.3791955
        ],
        [
          1664755200000,
          24689.9468887
        ],
        [
          1664841600000,
          24672.2703679
        ],
        [
          1664928000000,
          24574.5899494
        ],
        [
          1665014400000,
          24560.5106606
        ],
        [
          1665100800000,
          24655.2454594
        ],
        [
          1665187200000,
          24661.0061662
        ],
        [
          1665273600000,
          24665.705160100002
        ],
        [
          1665360000000,
          24666.4801464
        ],
        [
          1665446400000,
          34620.6077412
        ],
        [
          1665532800000,
          34752.8863004
        ],
        [
          1665619200000,
          34589.4444315
        ],
        [
          1665705600000,
          34579.4919193
        ],
        [
          1665792000000,
          34587.4036499
        ],
        [
          1665878400000,
          34594.89287
        ],
        [
          1665964800000,
          34572.560141300004
        ],
        [
          1666051200000,
          34616.573709100005
        ],
        [
          1666137600000,
          34606.3120271
        ],
        [
          1666224000000,
          34615.661088400004
        ],
        [
          1666310400000,
          34583.7427333
        ],
        [
          1666396800000,
          34591.2319534
        ],
        [
          1666483200000,
          34597.6842254
        ],
        [
          1666569600000,
          34636.5929987
        ],
        [
          1666656000000,
          34650.7303526
        ],
        [
          1666742400000,
          134286.7251024
        ],
        [
          1666828800000,
          134559.6231511
        ],
        [
          1666915200000,
          134567.768672
        ],
        [
          1667001600000,
          134597.0954478
        ],
        [
          1667088000000,
          134625.9690145
        ],
        [
          1667174400000,
          134485.6076385
        ],
        [
          1667260800000,
          134324.9185082
        ],
        [
          1667347200000,
          133948.7964077
        ],
        [
          1667433600000,
          134050.1034799
        ],
        [
          1667520000000,
          134122.4171482
        ],
        [
          1667606400000,
          134151.2907149
        ],
        [
          1667692800000,
          134175.1977122
        ],
        [
          1667779200000,
          134129.6643919
        ],
        [
          1667865600000,
          134062.9674559
        ],
        [
          1667952000000,
          134151.4965437
        ],
        [
          1668038400000,
          134140.6158016
        ],
        [
          1668124800000,
          134111.8232673
        ],
        [
          1668211200000,
          134139.5540955
        ],
        [
          1668297600000,
          134167.2849237
        ],
        [
          1668384000000,
          13468.6184446
        ],
        [
          1668470400000,
          13463.5745448
        ],
        [
          1668556800000,
          13518.109287199999
        ],
        [
          1668643200000,
          13484.4774315
        ],
        [
          1668729600000,
          13508.2901879
        ],
        [
          1668816000000,
          13511.458429799999
        ],
        [
          1668902400000,
          13514.0768854
        ],
        [
          1668988800000,
          13490.1057402
        ],
        [
          1669075200000,
          13485.2231824
        ],
        [
          1669161600000,
          13491.2973662
        ],
        [
          1669248000000,
          13511.2283611
        ],
        [
          1669334400000,
          13531.2099398
        ],
        [
          1669420800000,
          63249.6638047
        ],
        [
          1669507200000,
          63263.2756377
        ],
        [
          1669593600000,
          63257.2120549
        ],
        [
          1669680000000,
          63290.2875481
        ],
        [
          1669766400000,
          63227.9927523
        ],
        [
          1669852800000,
          63226.5809631
        ],
        [
          1669939200000,
          63212.1605097
        ],
        [
          1670025600000,
          63225.7723427
        ],
        [
          1670112000000,
          63239.1858093
        ],
        [
          1670198400000,
          63088.3858425
        ],
        [
          1670284800000,
          63098.750614
        ],
        [
          1670371200000,
          63231.2002493
        ],
        [
          1670457600000,
          63229.0133315
        ],
        [
          1670544000000,
          63355.2111731
        ],
        [
          1670630400000,
          63366.3049172
        ],
        [
          1670716800000,
          63379.9167502
        ],
        [
          1670803200000,
          13097.178875
        ],
        [
          1670889600000,
          13125.2692553
        ],
        [
          1670976000000,
          13116.2174829
        ],
        [
          1671062400000,
          13141.8303849
        ],
        [
          1671148800000,
          13164.271391799999
        ],
        [
          1671235200000,
          13166.8980679
        ],
        [
          1671321600000,
          13169.677386899999
        ],
        [
          1671408000000,
          13159.5442352
        ],
        [
          1671494400000,
          13136.8915268
        ],
        [
          1671580800000,
          13122.9221886
        ],
        [
          1671667200000,
          62912.9349055
        ],
        [
          1671753600000,
          63040.6658962
        ],
        [
          1671840000000,
          63054.5210045
        ],
        [
          1671926400000,
          63067.0931297
        ],
        [
          1672012800000,
          63079.6652549
        ],
        [
          1672099200000,
          63095.2498001
        ],
        [
          1672185600000,
          63448.0842841
        ],
        [
          1672272000000,
          23275.1893302
        ],
        [
          1672358400000,
          23214.403799300002
        ],
        [
          1672444800000,
          23218.9960966
        ],
        [
          1672531200000,
          23223.5533441
        ],
        [
          1672617600000,
          23238.230069100002
        ],
        [
          1672704000000,
          23246.5038094
        ],
        [
          1672790400000,
          23241.0440678
        ],
        [
          1672876800000,
          23252.218812900002
        ],
        [
          1672963200000,
          23273.5679264
        ],
        [
          1673049600000,
          23279.2170307
        ],
        [
          1673136000000,
          23283.774278200002
        ],
        [
          1673222400000,
          23298.3629923
        ],
        [
          1673308800000,
          23260.7012206
        ],
        [
          1673395200000,
          23244.590748000002
        ],
        [
          1673481600000,
          23238.865764500002
        ],
        [
          1673568000000,
          23213.6505265
        ],
        [
          1673654400000,
          23212.776286700002
        ],
        [
          1673740800000,
          23217.5987761
        ],
        [
          1673827200000,
          23175.9279546
        ],
        [
          1673913600000,
          23150.0287937
        ],
        [
          1674000000000,
          23165.647769400002
        ],
        [
          1674086400000,
          23213.658623400002
        ],
        [
          1674172800000,
          23223.2596452
        ],
        [
          1674259200000,
          23228.0821346
        ],
        [
          1674345600000,
          23232.639382100002
        ],
        [
          1674432000000,
          23248.3736838
        ],
        [
          1674518400000,
          23171.257010600002
        ],
        [
          1674604800000,
          23226.3106966
        ],
        [
          1674691200000,
          122593.4880856
        ],
        [
          1674777600000,
          122645.8454517
        ],
        [
          1674864000000,
          122671.1098139
        ],
        [
          1674950400000,
          122692.0127676
        ],
        [
          1675036800000,
          122572.0062223
        ],
        [
          1675123200000,
          122374.2141224
        ],
        [
          1675209600000,
          11740.9939215
        ],
        [
          1675296000000,
          11737.5745934
        ],
        [
          1675382400000,
          11765.45507
        ],
        [
          1675468800000,
          11767.8510998
        ],
        [
          1675555200000,
          11769.9175048
        ],
        [
          1675641600000,
          11807.1489072
        ],
        [
          1675728000000,
          11799.728872
        ],
        [
          1675814400000,
          11794.9332256
        ],
        [
          1675900800000,
          11791.1322005
        ],
        [
          1675987200000,
          11793.1960574
        ],
        [
          1676073600000,
          11795.7315142
        ],
        [
          1676160000000,
          11798.266971
        ],
        [
          1676246400000,
          11790.4581274
        ],
        [
          1676332800000,
          11790.2318023
        ],
        [
          1676419200000,
          11792.016469099999
        ],
        [
          1676505600000,
          11792.463635099999
        ],
        [
          1676592000000,
          11797.823338
        ],
        [
          1676678400000,
          11800.3587948
        ],
        [
          1676764800000,
          11802.7548246
        ],
        [
          1676851200000,
          11798.520681
        ],
        [
          1676937600000,
          11791.244004099999
        ],
        [
          1677024000000,
          11800.7730379
        ],
        [
          1677110400000,
          11803.5482166
        ],
        [
          1677196800000,
          11806.6177375
        ],
        [
          1677283200000,
          111246.0087074
        ],
        [
          1677369600000,
          111267.1669549
        ],
        [
          1677456000000,
          111061.5494972
        ],
        [
          1677542400000,
          111248.43266030001
        ],
        [
          1677628800000,
          111386.97337960001
        ],
        [
          1677715200000,
          111415.1068757
        ],
        [
          1677801600000,
          111312.5746491
        ],
        [
          1677888000000,
          111339.6868159
        ],
        [
          1677974400000,
          111362.86023610001
        ],
        [
          1678060800000,
          111163.2792171
        ],
        [
          1678147200000,
          110637.590142
        ],
        [
          1678233600000,
          110055.12631
        ],
        [
          1678320000000,
          109897.8639177
        ],
        [
          1678406400000,
          9320.9511552
        ],
        [
          1678492800000,
          9323.1759344
        ],
        [
          1678579200000,
          9325.278047
        ],
        [
          1678665600000,
          9307.794300399999
        ],
        [
          1678752000000,
          9293.3200348
        ],
        [
          1678838400000,
          9280.234250399999
        ],
        [
          1678924800000,
          9250.609223
        ],
        [
          1679011200000,
          9215.867918599999
        ],
        [
          1679097600000,
          9218.092697799999
        ],
        [
          1679184000000,
          9220.317477
        ],
        [
          1679270400000,
          9232.097913
        ],
        [
          1679356800000,
          9245.015217
        ],
        [
          1679443200000,
          9261.169059
        ],
        [
          1679529600000,
          9266.9221934
        ],
        [
          1679616000000,
          9272.0005494
        ],
        [
          1679702400000,
          108710.3954216
        ],
        [
          1679788800000,
          108733.0821288
        ],
        [
          1679875200000,
          109247.71314369999
        ],
        [
          1679961600000,
          109708.7394353
        ],
        [
          1680048000000,
          109810.8338774
        ],
        [
          1680134400000,
          109873.3237748
        ],
        [
          1680220800000,
          110150.5375356
        ],
        [
          1680307200000,
          110173.2242428
        ],
        [
          1680393600000,
          110194.65598
        ],
        [
          1680480000000,
          109831.41385659999
        ],
        [
          1680566400000,
          19287.811371
        ],
        [
          1680652800000,
          19315.282161
        ],
        [
          1680739200000,
          19355.3402664
        ],
        [
          1680825600000,
          19361.575999
        ],
        [
          1680912000000,
          19365.9959656
        ],
        [
          1680998400000,
          19370.1478732
        ],
        [
          1681084800000,
          19374.1657854
        ],
        [
          1681171200000,
          19389.4346736
        ],
        [
          1681257600000,
          19382.1017635
        ],
        [
          1681344000000,
          19368.143318399998
        ],
        [
          1681430400000,
          19319.8501887
        ],
        [
          1681516800000,
          19323.8681009
        ],
        [
          1681603200000,
          19327.8860131
        ],
        [
          1681689600000,
          19320.7788289
        ],
        [
          1681776000000,
          19324.5303871
        ],
        [
          1681862400000,
          19318.768914199998
        ],
        [
          1681948800000,
          19314.1951939
        ],
        [
          1682035200000,
          19318.7491559
        ],
        [
          1682121600000,
          19322.767068099998
        ],
        [
          1682208000000,
          19327.3210301
        ],
        [
          1682294400000,
          19401.2906791
        ],
        [
          1682380800000,
          19433.1841357
        ],
        [
          1682467200000,
          19452.332307
        ],
        [
          1682553600000,
          118973.6839554
        ],
        [
          1682640000000,
          118379.58638260001
        ],
        [
          1682726400000,
          118407.21524040001
        ],
        [
          1682812800000,
          118434.6401861
        ],
        [
          1682899200000,
          118458.91586430001
        ],
        [
          1682985600000,
          118074.9142924
        ],
        [
          1683072000000,
          117348.89218620001
        ],
        [
          1683158400000,
          13773.365434
        ],
        [
          1683244800000,
          13759.3085028
        ],
        [
          1683331200000,
          13762.5171945
        ],
        [
          1683417600000,
          13765.8953733
        ],
        [
          1683504000000,
          13780.6957917
        ],
        [
          1683590400000,
          13776.4775997
        ],
        [
          1683676800000,
          13750.137160100001
        ],
        [
          1683763200000,
          13701.028341700001
        ],
        [
          1683849600000,
          13632.0969717
        ],
        [
          1683936000000,
          13629.8690993
        ],
        [
          1684022400000,
          13633.1271801
        ],
        [
          1684108800000,
          13640.7960793
        ],
        [
          1684195200000,
          13669.7657681
        ],
        [
          1684281600000,
          13709.461006900001
        ],
        [
          1684368000000,
          13712.8857109
        ],
        [
          1684454400000,
          13692.0654417
        ],
        [
          1684540800000,
          13695.4678042
        ],
        [
          1684627200000,
          13698.845983000001
        ],
        [
          1684713600000,
          13717.2883798
        ],
        [
          1684800000000,
          13722.0791814
        ],
        [
          1684886400000,
          13739.6418538
        ],
        [
          1684972800000,
          13705.2812742
        ],
        [
          1685059200000,
          13718.8504326
        ],
        [
          1685145600000,
          113154.610029
        ],
        [
          1685232000000,
          113181.6002642
        ],
        [
          1685318400000,
          113548.1330583
        ],
        [
          1685404800000,
          114098.9509291
        ],
        [
          1685491200000,
          114671.7093472
        ],
        [
          1685577600000,
          114671.7093472
        ],
        [
          1685664000000,
          114671.7093472
        ],
        [
          1685750400000,
          114671.7093472
        ],
        [
          1685836800000,
          114671.7093472
        ],
        [
          1685923200000,
          114671.7093472
        ],
        [
          1686009600000,
          114671.7093472
        ],
        [
          1686096000000,
          114671.7093472
        ],
        [
          1686182400000,
          114671.7093472
        ],
        [
          1686268800000,
          114671.7093472
        ],
        [
          1686355200000,
          114671.7093472
        ],
        [
          1686441600000,
          114671.7093472
        ],
        [
          1686528000000,
          114671.7093472
        ],
        [
          1686614400000,
          114671.7093472
        ],
        [
          1686700800000,
          114671.7093472
        ],
        [
          1686787200000,
          114671.7093472
        ],
        [
          1686873600000,
          114671.7093472
        ],
        [
          1686960000000,
          114671.7093472
        ],
        [
          1687046400000,
          114671.7093472
        ],
        [
          1687132800000,
          114671.7093472
        ],
        [
          1687219200000,
          114671.7093472
        ],
        [
          1687305600000,
          114671.7093472
        ],
        [
          1687392000000,
          114671.7093472
        ],
        [
          1687478400000,
          114671.7093472
        ],
        [
          1687564800000,
          114671.7093472
        ],
        [
          1687651200000,
          114671.7093472
        ],
        [
          1687737600000,
          114671.7093472
        ],
        [
          1687824000000,
          114671.7093472
        ],
        [
          1687910400000,
          114671.7093472
        ],
        [
          1687996800000,
          114671.7093472
        ],
        [
          1688083200000,
          114671.7093472
        ],
        [
          1688169600000,
          114671.7093472
        ],
        [
          1688256000000,
          114671.7093472
        ],
        [
          1688342400000,
          114671.7093472
        ],
        [
          1688428800000,
          114671.7093472
        ],
        [
          1688515200000,
          114671.7093472
        ],
        [
          1688601600000,
          114671.7093472
        ],
        [
          1688688000000,
          114671.7093472
        ],
        [
          1688774400000,
          114671.7093472
        ],
        [
          1688860800000,
          114671.7093472
        ],
        [
          1688947200000,
          114671.7093472
        ],
        [
          1689033600000,
          114671.7093472
        ],
        [
          1689120000000,
          114671.7093472
        ],
        [
          1689206400000,
          114671.7093472
        ],
        [
          1689292800000,
          114671.7093472
        ],
        [
          1689379200000,
          114671.7093472
        ],
        [
          1689465600000,
          114671.7093472
        ],
        [
          1689552000000,
          114671.7093472
        ],
        [
          1689638400000,
          114671.7093472
        ],
        [
          1689724800000,
          114671.7093472
        ],
        [
          1689811200000,
          114671.7093472
        ],
        [
          1689897600000,
          114671.7093472
        ],
        [
          1689984000000,
          114671.7093472
        ],
        [
          1690070400000,
          114671.7093472
        ],
        [
          1690156800000,
          114671.7093472
        ],
        [
          1690243200000,
          114671.7093472
        ],
        [
          1690329600000,
          114671.7093472
        ],
        [
          1690416000000,
          114671.7093472
        ],
        [
          1690502400000,
          114671.7093472
        ],
        [
          1690588800000,
          114671.7093472
        ],
        [
          1690675200000,
          114671.7093472
        ],
        [
          1690761600000,
          114671.7093472
        ],
        [
          1690848000000,
          114671.7093472
        ],
        [
          1690934400000,
          114671.7093472
        ],
        [
          1691020800000,
          114671.7093472
        ],
        [
          1691107200000,
          114671.7093472
        ],
        [
          1691193600000,
          114671.7093472
        ],
        [
          1691280000000,
          114671.7093472
        ],
        [
          1691366400000,
          114671.7093472
        ],
        [
          1691452800000,
          114671.7093472
        ],
        [
          1691539200000,
          114671.7093472
        ],
        [
          1691625600000,
          114671.7093472
        ],
        [
          1691712000000,
          114671.7093472
        ],
        [
          1691798400000,
          114671.7093472
        ],
        [
          1691884800000,
          114671.7093472
        ],
        [
          1691971200000,
          114671.7093472
        ],
        [
          1692057600000,
          114671.7093472
        ],
        [
          1692144000000,
          114671.7093472
        ],
        [
          1692230400000,
          114671.7093472
        ],
        [
          1692316800000,
          114671.7093472
        ],
        [
          1692403200000,
          114671.7093472
        ],
        [
          1692489600000,
          114671.7093472
        ],
        [
          1692576000000,
          114671.7093472
        ],
        [
          1692662400000,
          114671.7093472
        ],
        [
          1692748800000,
          114671.7093472
        ],
        [
          1692835200000,
          114671.7093472
        ],
        [
          1692921600000,
          114671.7093472
        ],
        [
          1693008000000,
          114671.7093472
        ],
        [
          1693094400000,
          114671.7093472
        ],
        [
          1693180800000,
          114671.7093472
        ],
        [
          1693267200000,
          114671.7093472
        ],
        [
          1693353600000,
          114671.7093472
        ],
        [
          1693440000000,
          114671.7093472
        ],
        [
          1693526400000,
          114671.7093472
        ],
        [
          1693612800000,
          114671.7093472
        ],
        [
          1693699200000,
          114671.7093472
        ],
        [
          1693785600000,
          114671.7093472
        ],
        [
          1693872000000,
          114671.7093472
        ],
        [
          1693958400000,
          114671.7093472
        ],
        [
          1694044800000,
          114671.7093472
        ],
        [
          1694131200000,
          114671.7093472
        ],
        [
          1694217600000,
          114671.7093472
        ],
        [
          1694304000000,
          114671.7093472
        ],
        [
          1694390400000,
          114671.7093472
        ],
        [
          1694476800000,
          114671.7093472
        ],
        [
          1694563200000,
          114671.7093472
        ],
        [
          1694649600000,
          114671.7093472
        ],
        [
          1694736000000,
          114671.7093472
        ],
        [
          1694822400000,
          114671.7093472
        ],
        [
          1694908800000,
          114671.7093472
        ],
        [
          1694995200000,
          114671.7093472
        ],
        [
          1695081600000,
          114671.7093472
        ],
        [
          1695168000000,
          114671.7093472
        ],
        [
          1695254400000,
          114671.7093472
        ],
        [
          1695340800000,
          114671.7093472
        ],
        [
          1695427200000,
          114671.7093472
        ],
        [
          1695513600000,
          114671.7093472
        ],
        [
          1695600000000,
          114671.7093472
        ],
        [
          1695686400000,
          114671.7093472
        ],
        [
          1695772800000,
          114671.7093472
        ],
        [
          1695859200000,
          114671.7093472
        ],
        [
          1695945600000,
          114671.7093472
        ],
        [
          1696032000000,
          114671.7093472
        ],
        [
          1696118400000,
          114671.7093472
        ],
        [
          1696204800000,
          114671.7093472
        ],
        [
          1696291200000,
          114671.7093472
        ],
        [
          1696377600000,
          114671.7093472
        ],
        [
          1696464000000,
          114671.7093472
        ],
        [
          1696550400000,
          114671.7093472
        ],
        [
          1696636800000,
          114671.7093472
        ],
        [
          1696723200000,
          114671.7093472
        ],
        [
          1696809600000,
          114671.7093472
        ],
        [
          1696896000000,
          114671.7093472
        ],
        [
          1696982400000,
          114671.7093472
        ],
        [
          1697068800000,
          114671.7093472
        ],
        [
          1697155200000,
          114671.7093472
        ],
        [
          1697241600000,
          114671.7093472
        ],
        [
          1697328000000,
          114671.7093472
        ],
        [
          1697414400000,
          114671.7093472
        ],
        [
          1697500800000,
          114671.7093472
        ],
        [
          1697587200000,
          114671.7093472
        ],
        [
          1697673600000,
          114671.7093472
        ],
        [
          1697760000000,
          114671.7093472
        ],
        [
          1697846400000,
          114671.7093472
        ],
        [
          1697932800000,
          114671.7093472
        ],
        [
          1698019200000,
          114671.7093472
        ],
        [
          1698105600000,
          114671.7093472
        ],
        [
          1698192000000,
          114671.7093472
        ],
        [
          1698278400000,
          114671.7093472
        ],
        [
          1698364800000,
          114671.7093472
        ],
        [
          1698451200000,
          114671.7093472
        ],
        [
          1698537600000,
          114671.7093472
        ],
        [
          1698624000000,
          114671.7093472
        ],
        [
          1698710400000,
          114671.7093472
        ],
        [
          1698796800000,
          114671.7093472
        ],
        [
          1698883200000,
          114671.7093472
        ],
        [
          1698969600000,
          114671.7093472
        ],
        [
          1699056000000,
          114671.7093472
        ],
        [
          1699142400000,
          114671.7093472
        ],
        [
          1699228800000,
          114671.7093472
        ],
        [
          1699315200000,
          114671.7093472
        ],
        [
          1699401600000,
          114671.7093472
        ],
        [
          1699488000000,
          114671.7093472
        ],
        [
          1699574400000,
          114671.7093472
        ],
        [
          1699660800000,
          114671.7093472
        ],
        [
          1699747200000,
          114671.7093472
        ],
        [
          1699833600000,
          114671.7093472
        ],
        [
          1699920000000,
          114671.7093472
        ],
        [
          1700006400000,
          114671.7093472
        ],
        [
          1700092800000,
          114671.7093472
        ],
        [
          1700179200000,
          114671.7093472
        ],
        [
          1700265600000,
          114671.7093472
        ],
        [
          1700352000000,
          114671.7093472
        ],
        [
          1700438400000,
          114671.7093472
        ],
        [
          1700524800000,
          114671.7093472
        ],
        [
          1700611200000,
          114671.7093472
        ],
        [
          1700697600000,
          114671.7093472
        ],
        [
          1700784000000,
          114671.7093472
        ],
        [
          1700870400000,
          114671.7093472
        ],
        [
          1700956800000,
          114671.7093472
        ],
        [
          1701043200000,
          114671.7093472
        ],
        [
          1701129600000,
          114671.7093472
        ],
        [
          1701216000000,
          114671.7093472
        ],
        [
          1701302400000,
          114671.7093472
        ],
        [
          1701388800000,
          114671.7093472
        ],
        [
          1701475200000,
          114671.7093472
        ],
        [
          1701561600000,
          114671.7093472
        ],
        [
          1701648000000,
          114671.7093472
        ],
        [
          1701734400000,
          114671.7093472
        ],
        [
          1701820800000,
          114671.7093472
        ],
        [
          1701907200000,
          114671.7093472
        ],
        [
          1701993600000,
          114671.7093472
        ],
        [
          1702080000000,
          114671.7093472
        ],
        [
          1702166400000,
          114671.7093472
        ],
        [
          1702252800000,
          114671.7093472
        ],
        [
          1702339200000,
          114671.7093472
        ],
        [
          1702425600000,
          114671.7093472
        ],
        [
          1702512000000,
          114671.7093472
        ],
        [
          1702598400000,
          114671.7093472
        ],
        [
          1702684800000,
          114671.7093472
        ],
        [
          1702771200000,
          114671.7093472
        ],
        [
          1702857600000,
          114671.7093472
        ],
        [
          1702944000000,
          114671.7093472
        ],
        [
          1703030400000,
          114671.7093472
        ],
        [
          1703116800000,
          114671.7093472
        ],
        [
          1703203200000,
          114671.7093472
        ],
        [
          1703289600000,
          114671.7093472
        ],
        [
          1703376000000,
          114671.7093472
        ],
        [
          1703462400000,
          114671.7093472
        ],
        [
          1703548800000,
          114671.7093472
        ],
        [
          1703635200000,
          114671.7093472
        ],
        [
          1703721600000,
          114671.7093472
        ],
        [
          1703808000000,
          114671.7093472
        ],
        [
          1703894400000,
          114671.7093472
        ],
        [
          1703980800000,
          114671.7093472
        ],
        [
          1704067200000,
          114671.7093472
        ],
        [
          1704153600000,
          114671.7093472
        ],
        [
          1704240000000,
          114671.7093472
        ],
        [
          1704326400000,
          114671.7093472
        ],
        [
          1704412800000,
          114671.7093472
        ],
        [
          1704499200000,
          114671.7093472
        ],
        [
          1704585600000,
          114671.7093472
        ],
        [
          1704672000000,
          114671.7093472
        ],
        [
          1704758400000,
          114671.7093472
        ],
        [
          1704844800000,
          114671.7093472
        ],
        [
          1704931200000,
          114671.7093472
        ],
        [
          1705017600000,
          114671.7093472
        ],
        [
          1705104000000,
          114671.7093472
        ],
        [
          1705190400000,
          114671.7093472
        ],
        [
          1705276800000,
          114671.7093472
        ],
        [
          1705363200000,
          114671.7093472
        ],
        [
          1705449600000,
          114671.7093472
        ],
        [
          1705536000000,
          114671.7093472
        ],
        [
          1705622400000,
          114671.7093472
        ],
        [
          1705708800000,
          114671.7093472
        ],
        [
          1705795200000,
          114671.7093472
        ],
        [
          1705881600000,
          114671.7093472
        ],
        [
          1705968000000,
          114671.7093472
        ],
        [
          1706054400000,
          114671.7093472
        ],
        [
          1706140800000,
          114671.7093472
        ],
        [
          1706227200000,
          114671.7093472
        ],
        [
          1706313600000,
          114671.7093472
        ],
        [
          1706400000000,
          114671.7093472
        ],
        [
          1706486400000,
          114671.7093472
        ],
        [
          1706572800000,
          114671.7093472
        ],
        [
          1706659200000,
          114671.7093472
        ],
        [
          1706745600000,
          114671.7093472
        ],
        [
          1706832000000,
          114671.7093472
        ],
        [
          1706918400000,
          114671.7093472
        ],
        [
          1707004800000,
          114671.7093472
        ],
        [
          1707091200000,
          114671.7093472
        ],
        [
          1707177600000,
          114671.7093472
        ],
        [
          1707264000000,
          114671.7093472
        ],
        [
          1707350400000,
          114671.7093472
        ],
        [
          1707436800000,
          114671.7093472
        ],
        [
          1707523200000,
          114671.7093472
        ],
        [
          1707609600000,
          114671.7093472
        ],
        [
          1707696000000,
          114671.7093472
        ],
        [
          1707782400000,
          114671.7093472
        ],
        [
          1707868800000,
          114671.7093472
        ],
        [
          1707955200000,
          114671.7093472
        ],
        [
          1708041600000,
          114671.7093472
        ],
        [
          1708128000000,
          114671.7093472
        ],
        [
          1708214400000,
          114671.7093472
        ],
        [
          1708300800000,
          114671.7093472
        ],
        [
          1708387200000,
          114671.7093472
        ],
        [
          1708473600000,
          114671.7093472
        ],
        [
          1708560000000,
          114671.7093472
        ],
        [
          1708646400000,
          114671.7093472
        ],
        [
          1708732800000,
          114671.7093472
        ],
        [
          1708819200000,
          114671.7093472
        ],
        [
          1708905600000,
          114671.7093472
        ],
        [
          1708992000000,
          114671.7093472
        ],
        [
          1709078400000,
          114671.7093472
        ],
        [
          1709164800000,
          114671.7093472
        ],
        [
          1709251200000,
          114671.7093472
        ],
        [
          1709337600000,
          114671.7093472
        ],
        [
          1709424000000,
          114671.7093472
        ],
        [
          1709510400000,
          114671.7093472
        ],
        [
          1709596800000,
          114671.7093472
        ],
        [
          1709683200000,
          114671.7093472
        ],
        [
          1709769600000,
          114671.7093472
        ],
        [
          1709856000000,
          114671.7093472
        ],
        [
          1709942400000,
          114671.7093472
        ],
        [
          1710028800000,
          114671.7093472
        ],
        [
          1710115200000,
          114671.7093472
        ],
        [
          1710201600000,
          114671.7093472
        ],
        [
          1710288000000,
          114671.7093472
        ],
        [
          1710374400000,
          114671.7093472
        ],
        [
          1710460800000,
          114671.7093472
        ]
      ],
      [
        [
          1530057600000,
          100000
        ],
        [
          1530144000000,
          100000
        ],
        [
          1530230400000,
          100000
        ],
        [
          1530316800000,
          100000
        ],
        [
          1530403200000,
          100000
        ],
        [
          1530489600000,
          100000
        ],
        [
          1530576000000,
          100000
        ],
        [
          1530662400000,
          100000
        ],
        [
          1530748800000,
          100000
        ],
        [
          1530835200000,
          100000
        ],
        [
          1530921600000,
          100000
        ],
        [
          1531008000000,
          100000
        ],
        [
          1531094400000,
          100000
        ],
        [
          1531180800000,
          100000
        ],
        [
          1531267200000,
          100000
        ],
        [
          1531353600000,
          100000
        ],
        [
          1531440000000,
          100000
        ],
        [
          1531526400000,
          100000
        ],
        [
          1531612800000,
          100000
        ],
        [
          1531699200000,
          100000
        ],
        [
          1531785600000,
          100000
        ],
        [
          1531872000000,
          100000
        ],
        [
          1531958400000,
          100000
        ],
        [
          1532044800000,
          100000
        ],
        [
          1532131200000,
          100000
        ],
        [
          1532217600000,
          100000
        ],
        [
          1532304000000,
          100000
        ],
        [
          1532390400000,
          100000
        ],
        [
          1532476800000,
          100000
        ],
        [
          1532563200000,
          100000
        ],
        [
          1532649600000,
          100000
        ],
        [
          1532736000000,
          100000
        ],
        [
          1532822400000,
          100000
        ],
        [
          1532908800000,
          100000
        ],
        [
          1532995200000,
          100000
        ],
        [
          1533081600000,
          100000
        ],
        [
          1533168000000,
          100000
        ],
        [
          1533254400000,
          100000
        ],
        [
          1533340800000,
          100000
        ],
        [
          1533427200000,
          100000
        ],
        [
          1533513600000,
          100000
        ],
        [
          1533600000000,
          100000
        ],
        [
          1533686400000,
          100000
        ],
        [
          1533772800000,
          100000
        ],
        [
          1533859200000,
          100000
        ],
        [
          1533945600000,
          100000
        ],
        [
          1534032000000,
          100000
        ],
        [
          1534118400000,
          100000
        ],
        [
          1534204800000,
          100000
        ],
        [
          1534291200000,
          100000
        ],
        [
          1534377600000,
          100000
        ],
        [
          1534464000000,
          100000
        ],
        [
          1534550400000,
          100000
        ],
        [
          1534636800000,
          100000
        ],
        [
          1534723200000,
          100000
        ],
        [
          1534809600000,
          100000
        ],
        [
          1534896000000,
          100000
        ],
        [
          1534982400000,
          100000
        ],
        [
          1535068800000,
          100000
        ],
        [
          1535155200000,
          100000
        ],
        [
          1535241600000,
          100000
        ],
        [
          1535328000000,
          200000
        ],
        [
          1535414400000,
          200000
        ],
        [
          1535500800000,
          200000
        ],
        [
          1535587200000,
          200000
        ],
        [
          1535673600000,
          200000
        ],
        [
          1535760000000,
          200000
        ],
        [
          1535846400000,
          200000
        ],
        [
          1535932800000,
          200000
        ],
        [
          1536019200000,
          200000
        ],
        [
          1536105600000,
          200000
        ],
        [
          1536192000000,
          100000
        ],
        [
          1536278400000,
          100000
        ],
        [
          1536364800000,
          100000
        ],
        [
          1536451200000,
          100000
        ],
        [
          1536537600000,
          100000
        ],
        [
          1536624000000,
          100000
        ],
        [
          1536710400000,
          100000
        ],
        [
          1536796800000,
          100000
        ],
        [
          1536883200000,
          100000
        ],
        [
          1536969600000,
          100000
        ],
        [
          1537056000000,
          100000
        ],
        [
          1537142400000,
          100000
        ],
        [
          1537228800000,
          100000
        ],
        [
          1537315200000,
          100000
        ],
        [
          1537401600000,
          100000
        ],
        [
          1537488000000,
          100000
        ],
        [
          1537574400000,
          100000
        ],
        [
          1537660800000,
          100000
        ],
        [
          1537747200000,
          200000
        ],
        [
          1537833600000,
          200000
        ],
        [
          1537920000000,
          200000
        ],
        [
          1538006400000,
          200000
        ],
        [
          1538092800000,
          200000
        ],
        [
          1538179200000,
          200000
        ],
        [
          1538265600000,
          200000
        ],
        [
          1538352000000,
          200000
        ],
        [
          1538438400000,
          200000
        ],
        [
          1538524800000,
          200000
        ],
        [
          1538611200000,
          200000
        ],
        [
          1538697600000,
          100000
        ],
        [
          1538784000000,
          100000
        ],
        [
          1538870400000,
          100000
        ],
        [
          1538956800000,
          100000
        ],
        [
          1539043200000,
          100000
        ],
        [
          1539129600000,
          100000
        ],
        [
          1539216000000,
          100000
        ],
        [
          1539302400000,
          100000
        ],
        [
          1539388800000,
          100000
        ],
        [
          1539475200000,
          100000
        ],
        [
          1539561600000,
          100000
        ],
        [
          1539648000000,
          100000
        ],
        [
          1539734400000,
          100000
        ],
        [
          1539820800000,
          100000
        ],
        [
          1539907200000,
          100000
        ],
        [
          1539993600000,
          100000
        ],
        [
          1540080000000,
          100000
        ],
        [
          1540166400000,
          100000
        ],
        [
          1540252800000,
          100000
        ],
        [
          1540339200000,
          100000
        ],
        [
          1540425600000,
          100000
        ],
        [
          1540512000000,
          100000
        ],
        [
          1540598400000,
          100000
        ],
        [
          1540684800000,
          100000
        ],
        [
          1540771200000,
          100000
        ],
        [
          1540857600000,
          100000
        ],
        [
          1540944000000,
          100000
        ],
        [
          1541030400000,
          100000
        ],
        [
          1541116800000,
          100000
        ],
        [
          1541203200000,
          100000
        ],
        [
          1541289600000,
          100000
        ],
        [
          1541376000000,
          100000
        ],
        [
          1541462400000,
          100000
        ],
        [
          1541548800000,
          100000
        ],
        [
          1541635200000,
          100000
        ],
        [
          1541721600000,
          100000
        ],
        [
          1541808000000,
          100000
        ],
        [
          1541894400000,
          100000
        ],
        [
          1541980800000,
          100000
        ],
        [
          1542067200000,
          100000
        ],
        [
          1542153600000,
          100000
        ],
        [
          1542240000000,
          100000
        ],
        [
          1542326400000,
          100000
        ],
        [
          1542412800000,
          100000
        ],
        [
          1542499200000,
          100000
        ],
        [
          1542585600000,
          100000
        ],
        [
          1542672000000,
          100000
        ],
        [
          1542758400000,
          100000
        ],
        [
          1542844800000,
          100000
        ],
        [
          1542931200000,
          100000
        ],
        [
          1543017600000,
          100000
        ],
        [
          1543104000000,
          100000
        ],
        [
          1543190400000,
          100000
        ],
        [
          1543276800000,
          100000
        ],
        [
          1543363200000,
          100000
        ],
        [
          1543449600000,
          100000
        ],
        [
          1543536000000,
          100000
        ],
        [
          1543622400000,
          100000
        ],
        [
          1543708800000,
          100000
        ],
        [
          1543795200000,
          100000
        ],
        [
          1543881600000,
          100000
        ],
        [
          1543968000000,
          100000
        ],
        [
          1544054400000,
          100000
        ],
        [
          1544140800000,
          100000
        ],
        [
          1544227200000,
          100000
        ],
        [
          1544313600000,
          100000
        ],
        [
          1544400000000,
          100000
        ],
        [
          1544486400000,
          100000
        ],
        [
          1544572800000,
          100000
        ],
        [
          1544659200000,
          100000
        ],
        [
          1544745600000,
          100000
        ],
        [
          1544832000000,
          100000
        ],
        [
          1544918400000,
          100000
        ],
        [
          1545004800000,
          100000
        ],
        [
          1545091200000,
          100000
        ],
        [
          1545177600000,
          100000
        ],
        [
          1545264000000,
          100000
        ],
        [
          1545350400000,
          100000
        ],
        [
          1545436800000,
          100000
        ],
        [
          1545523200000,
          100000
        ],
        [
          1545609600000,
          100000
        ],
        [
          1545696000000,
          100000
        ],
        [
          1545782400000,
          100000
        ],
        [
          1545868800000,
          100000
        ],
        [
          1545955200000,
          100000
        ],
        [
          1546041600000,
          100000
        ],
        [
          1546128000000,
          100000
        ],
        [
          1546214400000,
          100000
        ],
        [
          1546300800000,
          100000
        ],
        [
          1546387200000,
          100000
        ],
        [
          1546473600000,
          100000
        ],
        [
          1546560000000,
          100000
        ],
        [
          1546646400000,
          100000
        ],
        [
          1546732800000,
          100000
        ],
        [
          1546819200000,
          100000
        ],
        [
          1546905600000,
          100000
        ],
        [
          1546992000000,
          100000
        ],
        [
          1547078400000,
          100000
        ],
        [
          1547164800000,
          100000
        ],
        [
          1547251200000,
          100000
        ],
        [
          1547337600000,
          100000
        ],
        [
          1547424000000,
          100000
        ],
        [
          1547510400000,
          100000
        ],
        [
          1547596800000,
          100000
        ],
        [
          1547683200000,
          100000
        ],
        [
          1547769600000,
          100000
        ],
        [
          1547856000000,
          100000
        ],
        [
          1547942400000,
          100000
        ],
        [
          1548028800000,
          100000
        ],
        [
          1548115200000,
          100000
        ],
        [
          1548201600000,
          100000
        ],
        [
          1548288000000,
          100000
        ],
        [
          1548374400000,
          100000
        ],
        [
          1548460800000,
          100000
        ],
        [
          1548547200000,
          100000
        ],
        [
          1548633600000,
          100000
        ],
        [
          1548720000000,
          100000
        ],
        [
          1548806400000,
          100000
        ],
        [
          1548892800000,
          100000
        ],
        [
          1548979200000,
          100000
        ],
        [
          1549065600000,
          100000
        ],
        [
          1549152000000,
          100000
        ],
        [
          1549238400000,
          100000
        ],
        [
          1549324800000,
          100000
        ],
        [
          1549411200000,
          100000
        ],
        [
          1549497600000,
          100000
        ],
        [
          1549584000000,
          100000
        ],
        [
          1549670400000,
          100000
        ],
        [
          1549756800000,
          100000
        ],
        [
          1549843200000,
          100000
        ],
        [
          1549929600000,
          100000
        ],
        [
          1550016000000,
          100000
        ],
        [
          1550102400000,
          100000
        ],
        [
          1550188800000,
          100000
        ],
        [
          1550275200000,
          100000
        ],
        [
          1550361600000,
          100000
        ],
        [
          1550448000000,
          100000
        ],
        [
          1550534400000,
          100000
        ],
        [
          1550620800000,
          100000
        ],
        [
          1550707200000,
          100000
        ],
        [
          1550793600000,
          100000
        ],
        [
          1550880000000,
          100000
        ],
        [
          1550966400000,
          100000
        ],
        [
          1551052800000,
          100000
        ],
        [
          1551139200000,
          100000
        ],
        [
          1551225600000,
          100000
        ],
        [
          1551312000000,
          100000
        ],
        [
          1551398400000,
          100000
        ],
        [
          1551484800000,
          100000
        ],
        [
          1551571200000,
          100000
        ],
        [
          1551657600000,
          100000
        ],
        [
          1551744000000,
          100000
        ],
        [
          1551830400000,
          100000
        ],
        [
          1551916800000,
          100000
        ],
        [
          1552003200000,
          100000
        ],
        [
          1552089600000,
          100000
        ],
        [
          1552176000000,
          100000
        ],
        [
          1552262400000,
          100000
        ],
        [
          1552348800000,
          100000
        ],
        [
          1552435200000,
          100000
        ],
        [
          1552521600000,
          100000
        ],
        [
          1552608000000,
          100000
        ],
        [
          1552694400000,
          100000
        ],
        [
          1552780800000,
          100000
        ],
        [
          1552867200000,
          100000
        ],
        [
          1552953600000,
          100000
        ],
        [
          1553040000000,
          100000
        ],
        [
          1553126400000,
          100000
        ],
        [
          1553212800000,
          100000
        ],
        [
          1553299200000,
          100000
        ],
        [
          1553385600000,
          100000
        ],
        [
          1553472000000,
          100000
        ],
        [
          1553558400000,
          100000
        ],
        [
          1553644800000,
          100000
        ],
        [
          1553731200000,
          100000
        ],
        [
          1553817600000,
          100000
        ],
        [
          1553904000000,
          100000
        ],
        [
          1553990400000,
          100000
        ],
        [
          1554076800000,
          100000
        ],
        [
          1554163200000,
          100000
        ],
        [
          1554249600000,
          100000
        ],
        [
          1554336000000,
          100000
        ],
        [
          1554422400000,
          100000
        ],
        [
          1554508800000,
          100000
        ],
        [
          1554595200000,
          100000
        ],
        [
          1554681600000,
          100000
        ],
        [
          1554768000000,
          100000
        ],
        [
          1554854400000,
          100000
        ],
        [
          1554940800000,
          100000
        ],
        [
          1555027200000,
          100000
        ],
        [
          1555113600000,
          100000
        ],
        [
          1555200000000,
          100000
        ],
        [
          1555286400000,
          100000
        ],
        [
          1555372800000,
          100000
        ],
        [
          1555459200000,
          100000
        ],
        [
          1555545600000,
          100000
        ],
        [
          1555632000000,
          100000
        ],
        [
          1555718400000,
          100000
        ],
        [
          1555804800000,
          100000
        ],
        [
          1555891200000,
          100000
        ],
        [
          1555977600000,
          100000
        ],
        [
          1556064000000,
          100000
        ],
        [
          1556150400000,
          100000
        ],
        [
          1556236800000,
          100000
        ],
        [
          1556323200000,
          100000
        ],
        [
          1556409600000,
          100000
        ],
        [
          1556496000000,
          100000
        ],
        [
          1556582400000,
          100000
        ],
        [
          1556668800000,
          100000
        ],
        [
          1556755200000,
          100000
        ],
        [
          1556841600000,
          100000
        ],
        [
          1556928000000,
          100000
        ],
        [
          1557014400000,
          100000
        ],
        [
          1557100800000,
          100000
        ],
        [
          1557187200000,
          100000
        ],
        [
          1557273600000,
          100000
        ],
        [
          1557360000000,
          100000
        ],
        [
          1557446400000,
          100000
        ],
        [
          1557532800000,
          100000
        ],
        [
          1557619200000,
          100000
        ],
        [
          1557705600000,
          100000
        ],
        [
          1557792000000,
          100000
        ],
        [
          1557878400000,
          100000
        ],
        [
          1557964800000,
          100000
        ],
        [
          1558051200000,
          100000
        ],
        [
          1558137600000,
          100000
        ],
        [
          1558224000000,
          100000
        ],
        [
          1558310400000,
          100000
        ],
        [
          1558396800000,
          100000
        ],
        [
          1558483200000,
          100000
        ],
        [
          1558569600000,
          100000
        ],
        [
          1558656000000,
          100000
        ],
        [
          1558742400000,
          100000
        ],
        [
          1558828800000,
          100000
        ],
        [
          1558915200000,
          100000
        ],
        [
          1559001600000,
          100000
        ],
        [
          1559088000000,
          100000
        ],
        [
          1559174400000,
          100000
        ],
        [
          1559260800000,
          100000
        ],
        [
          1559347200000,
          100000
        ],
        [
          1559433600000,
          100000
        ],
        [
          1559520000000,
          100000
        ],
        [
          1559606400000,
          100000
        ],
        [
          1559692800000,
          100000
        ],
        [
          1559779200000,
          100000
        ],
        [
          1559865600000,
          100000
        ],
        [
          1559952000000,
          100000
        ],
        [
          1560038400000,
          100000
        ],
        [
          1560124800000,
          100000
        ],
        [
          1560211200000,
          100000
        ],
        [
          1560297600000,
          100000
        ],
        [
          1560384000000,
          100000
        ],
        [
          1560470400000,
          100000
        ],
        [
          1560556800000,
          100000
        ],
        [
          1560643200000,
          100000
        ],
        [
          1560729600000,
          100000
        ],
        [
          1560816000000,
          100000
        ],
        [
          1560902400000,
          100000
        ],
        [
          1560988800000,
          100000
        ],
        [
          1561075200000,
          100000
        ],
        [
          1561161600000,
          100000
        ],
        [
          1561248000000,
          100000
        ],
        [
          1561334400000,
          100000
        ],
        [
          1561420800000,
          100000
        ],
        [
          1561507200000,
          100000
        ],
        [
          1561593600000,
          100000
        ],
        [
          1561680000000,
          100000
        ],
        [
          1561766400000,
          100000
        ],
        [
          1561852800000,
          100000
        ],
        [
          1561939200000,
          100000
        ],
        [
          1562025600000,
          100000
        ],
        [
          1562112000000,
          100000
        ],
        [
          1562198400000,
          100000
        ],
        [
          1562284800000,
          100000
        ],
        [
          1562371200000,
          100000
        ],
        [
          1562457600000,
          100000
        ],
        [
          1562544000000,
          100000
        ],
        [
          1562630400000,
          100000
        ],
        [
          1562716800000,
          100000
        ],
        [
          1562803200000,
          100000
        ],
        [
          1562889600000,
          100000
        ],
        [
          1562976000000,
          100000
        ],
        [
          1563062400000,
          100000
        ],
        [
          1563148800000,
          100000
        ],
        [
          1563235200000,
          100000
        ],
        [
          1563321600000,
          100000
        ],
        [
          1563408000000,
          100000
        ],
        [
          1563494400000,
          100000
        ],
        [
          1563580800000,
          100000
        ],
        [
          1563667200000,
          100000
        ],
        [
          1563753600000,
          100000
        ],
        [
          1563840000000,
          100000
        ],
        [
          1563926400000,
          100000
        ],
        [
          1564012800000,
          100000
        ],
        [
          1564099200000,
          100000
        ],
        [
          1564185600000,
          100000
        ],
        [
          1564272000000,
          100000
        ],
        [
          1564358400000,
          100000
        ],
        [
          1564444800000,
          100000
        ],
        [
          1564531200000,
          100000
        ],
        [
          1564617600000,
          100000
        ],
        [
          1564704000000,
          100000
        ],
        [
          1564790400000,
          100000
        ],
        [
          1564876800000,
          100000
        ],
        [
          1564963200000,
          100000
        ],
        [
          1565049600000,
          100000
        ],
        [
          1565136000000,
          100000
        ],
        [
          1565222400000,
          100000
        ],
        [
          1565308800000,
          100000
        ],
        [
          1565395200000,
          100000
        ],
        [
          1565481600000,
          100000
        ],
        [
          1565568000000,
          100000
        ],
        [
          1565654400000,
          100000
        ],
        [
          1565740800000,
          100000
        ],
        [
          1565827200000,
          100000
        ],
        [
          1565913600000,
          100000
        ],
        [
          1566000000000,
          100000
        ],
        [
          1566086400000,
          100000
        ],
        [
          1566172800000,
          100000
        ],
        [
          1566259200000,
          100000
        ],
        [
          1566345600000,
          100000
        ],
        [
          1566432000000,
          100000
        ],
        [
          1566518400000,
          100000
        ],
        [
          1566604800000,
          100000
        ],
        [
          1566691200000,
          100000
        ],
        [
          1566777600000,
          100000
        ],
        [
          1566864000000,
          100000
        ],
        [
          1566950400000,
          100000
        ],
        [
          1567036800000,
          100000
        ],
        [
          1567123200000,
          100000
        ],
        [
          1567209600000,
          100000
        ],
        [
          1567296000000,
          100000
        ],
        [
          1567382400000,
          100000
        ],
        [
          1567468800000,
          100000
        ],
        [
          1567555200000,
          100000
        ],
        [
          1567641600000,
          100000
        ],
        [
          1567728000000,
          100000
        ],
        [
          1567814400000,
          100000
        ],
        [
          1567900800000,
          100000
        ],
        [
          1567987200000,
          100000
        ],
        [
          1568073600000,
          100000
        ],
        [
          1568160000000,
          100000
        ],
        [
          1568246400000,
          100000
        ],
        [
          1568332800000,
          100000
        ],
        [
          1568419200000,
          100000
        ],
        [
          1568505600000,
          100000
        ],
        [
          1568592000000,
          100000
        ],
        [
          1568678400000,
          100000
        ],
        [
          1568764800000,
          100000
        ],
        [
          1568851200000,
          100000
        ],
        [
          1568937600000,
          100000
        ],
        [
          1569024000000,
          100000
        ],
        [
          1569110400000,
          100000
        ],
        [
          1569196800000,
          100000
        ],
        [
          1569283200000,
          100000
        ],
        [
          1569369600000,
          100000
        ],
        [
          1569456000000,
          100000
        ],
        [
          1569542400000,
          100000
        ],
        [
          1569628800000,
          100000
        ],
        [
          1569715200000,
          100000
        ],
        [
          1569801600000,
          100000
        ],
        [
          1569888000000,
          100000
        ],
        [
          1569974400000,
          100000
        ],
        [
          1570060800000,
          100000
        ],
        [
          1570147200000,
          100000
        ],
        [
          1570233600000,
          100000
        ],
        [
          1570320000000,
          100000
        ],
        [
          1570406400000,
          100000
        ],
        [
          1570492800000,
          100000
        ],
        [
          1570579200000,
          100000
        ],
        [
          1570665600000,
          100000
        ],
        [
          1570752000000,
          100000
        ],
        [
          1570838400000,
          100000
        ],
        [
          1570924800000,
          100000
        ],
        [
          1571011200000,
          100000
        ],
        [
          1571097600000,
          100000
        ],
        [
          1571184000000,
          100000
        ],
        [
          1571270400000,
          100000
        ],
        [
          1571356800000,
          100000
        ],
        [
          1571443200000,
          100000
        ],
        [
          1571529600000,
          100000
        ],
        [
          1571616000000,
          100000
        ],
        [
          1571702400000,
          100000
        ],
        [
          1571788800000,
          100000
        ],
        [
          1571875200000,
          100000
        ],
        [
          1571961600000,
          100000
        ],
        [
          1572048000000,
          100000
        ],
        [
          1572134400000,
          100000
        ],
        [
          1572220800000,
          100000
        ],
        [
          1572307200000,
          100000
        ],
        [
          1572393600000,
          100000
        ],
        [
          1572480000000,
          100000
        ],
        [
          1572566400000,
          100000
        ],
        [
          1572652800000,
          100000
        ],
        [
          1572739200000,
          100000
        ],
        [
          1572825600000,
          100000
        ],
        [
          1572912000000,
          100000
        ],
        [
          1572998400000,
          100000
        ],
        [
          1573084800000,
          100000
        ],
        [
          1573171200000,
          100000
        ],
        [
          1573257600000,
          100000
        ],
        [
          1573344000000,
          100000
        ],
        [
          1573430400000,
          100000
        ],
        [
          1573516800000,
          0
        ],
        [
          1573603200000,
          0
        ],
        [
          1573689600000,
          0
        ],
        [
          1573776000000,
          0
        ],
        [
          1573862400000,
          0
        ],
        [
          1573948800000,
          0
        ],
        [
          1574035200000,
          0
        ],
        [
          1574121600000,
          0
        ],
        [
          1574208000000,
          0
        ],
        [
          1574294400000,
          0
        ],
        [
          1574380800000,
          0
        ],
        [
          1574467200000,
          0
        ],
        [
          1574553600000,
          0
        ],
        [
          1574640000000,
          0
        ],
        [
          1574726400000,
          0
        ],
        [
          1574812800000,
          0
        ],
        [
          1574899200000,
          0
        ],
        [
          1574985600000,
          0
        ],
        [
          1575072000000,
          0
        ],
        [
          1575158400000,
          0
        ],
        [
          1575244800000,
          0
        ],
        [
          1575331200000,
          0
        ],
        [
          1575417600000,
          0
        ],
        [
          1575504000000,
          0
        ],
        [
          1575590400000,
          0
        ],
        [
          1575676800000,
          0
        ],
        [
          1575763200000,
          0
        ],
        [
          1575849600000,
          0
        ],
        [
          1575936000000,
          0
        ],
        [
          1576022400000,
          0
        ],
        [
          1576108800000,
          0
        ],
        [
          1576195200000,
          0
        ],
        [
          1576281600000,
          0
        ],
        [
          1576368000000,
          0
        ],
        [
          1576454400000,
          0
        ],
        [
          1576540800000,
          0
        ],
        [
          1576627200000,
          0
        ],
        [
          1576713600000,
          0
        ],
        [
          1576800000000,
          0
        ],
        [
          1576886400000,
          0
        ],
        [
          1576972800000,
          0
        ],
        [
          1577059200000,
          0
        ],
        [
          1577145600000,
          0
        ],
        [
          1577232000000,
          0
        ],
        [
          1577318400000,
          0
        ],
        [
          1577404800000,
          0
        ],
        [
          1577491200000,
          0
        ],
        [
          1577577600000,
          0
        ],
        [
          1577664000000,
          0
        ],
        [
          1577750400000,
          0
        ],
        [
          1577836800000,
          0
        ],
        [
          1577923200000,
          0
        ],
        [
          1578009600000,
          0
        ],
        [
          1578096000000,
          0
        ],
        [
          1578182400000,
          0
        ],
        [
          1578268800000,
          0
        ],
        [
          1578355200000,
          0
        ],
        [
          1578441600000,
          0
        ],
        [
          1578528000000,
          0
        ],
        [
          1578614400000,
          0
        ],
        [
          1578700800000,
          0
        ],
        [
          1578787200000,
          0
        ],
        [
          1578873600000,
          0
        ],
        [
          1578960000000,
          0
        ],
        [
          1579046400000,
          0
        ],
        [
          1579132800000,
          0
        ],
        [
          1579219200000,
          0
        ],
        [
          1579305600000,
          0
        ],
        [
          1579392000000,
          0
        ],
        [
          1579478400000,
          0
        ],
        [
          1579564800000,
          0
        ],
        [
          1579651200000,
          0
        ],
        [
          1579737600000,
          0
        ],
        [
          1579824000000,
          0
        ],
        [
          1579910400000,
          0
        ],
        [
          1579996800000,
          0
        ],
        [
          1580083200000,
          0
        ],
        [
          1580169600000,
          0
        ],
        [
          1580256000000,
          0
        ],
        [
          1580342400000,
          0
        ],
        [
          1580428800000,
          0
        ],
        [
          1580515200000,
          0
        ],
        [
          1580601600000,
          0
        ],
        [
          1580688000000,
          0
        ],
        [
          1580774400000,
          0
        ],
        [
          1580860800000,
          0
        ],
        [
          1580947200000,
          0
        ],
        [
          1581033600000,
          0
        ],
        [
          1581120000000,
          0
        ],
        [
          1581206400000,
          0
        ],
        [
          1581292800000,
          0
        ],
        [
          1581379200000,
          0
        ],
        [
          1581465600000,
          0
        ],
        [
          1581552000000,
          0
        ],
        [
          1581638400000,
          0
        ],
        [
          1581724800000,
          0
        ],
        [
          1581811200000,
          0
        ],
        [
          1581897600000,
          0
        ],
        [
          1581984000000,
          0
        ],
        [
          1582070400000,
          0
        ],
        [
          1582156800000,
          0
        ],
        [
          1582243200000,
          0
        ],
        [
          1582329600000,
          0
        ],
        [
          1582416000000,
          0
        ],
        [
          1582502400000,
          0
        ],
        [
          1582588800000,
          0
        ],
        [
          1582675200000,
          0
        ],
        [
          1582761600000,
          0
        ],
        [
          1582848000000,
          0
        ],
        [
          1582934400000,
          0
        ],
        [
          1583020800000,
          0
        ],
        [
          1583107200000,
          0
        ],
        [
          1583193600000,
          0
        ],
        [
          1583280000000,
          0
        ],
        [
          1583366400000,
          0
        ],
        [
          1583452800000,
          0
        ],
        [
          1583539200000,
          0
        ],
        [
          1583625600000,
          0
        ],
        [
          1583712000000,
          0
        ],
        [
          1583798400000,
          0
        ],
        [
          1583884800000,
          0
        ],
        [
          1583971200000,
          0
        ],
        [
          1584057600000,
          0
        ],
        [
          1584144000000,
          0
        ],
        [
          1584230400000,
          0
        ],
        [
          1584316800000,
          0
        ],
        [
          1584403200000,
          0
        ],
        [
          1584489600000,
          0
        ],
        [
          1584576000000,
          0
        ],
        [
          1584662400000,
          0
        ],
        [
          1584748800000,
          0
        ],
        [
          1584835200000,
          0
        ],
        [
          1584921600000,
          0
        ],
        [
          1585008000000,
          0
        ],
        [
          1585094400000,
          0
        ],
        [
          1585180800000,
          0
        ],
        [
          1585267200000,
          0
        ],
        [
          1585353600000,
          0
        ],
        [
          1585440000000,
          0
        ],
        [
          1585526400000,
          0
        ],
        [
          1585612800000,
          0
        ],
        [
          1585699200000,
          0
        ],
        [
          1585785600000,
          0
        ],
        [
          1585872000000,
          0
        ],
        [
          1585958400000,
          0
        ],
        [
          1586044800000,
          0
        ],
        [
          1586131200000,
          0
        ],
        [
          1586217600000,
          0
        ],
        [
          1586304000000,
          0
        ],
        [
          1586390400000,
          0
        ],
        [
          1586476800000,
          0
        ],
        [
          1586563200000,
          0
        ],
        [
          1586649600000,
          0
        ],
        [
          1586736000000,
          0
        ],
        [
          1586822400000,
          0
        ],
        [
          1586908800000,
          0
        ],
        [
          1586995200000,
          0
        ],
        [
          1587081600000,
          0
        ],
        [
          1587168000000,
          0
        ],
        [
          1587254400000,
          0
        ],
        [
          1587340800000,
          0
        ],
        [
          1587427200000,
          0
        ],
        [
          1587513600000,
          0
        ],
        [
          1587600000000,
          0
        ],
        [
          1587686400000,
          0
        ],
        [
          1587772800000,
          0
        ],
        [
          1587859200000,
          0
        ],
        [
          1587945600000,
          0
        ],
        [
          1588032000000,
          0
        ],
        [
          1588118400000,
          0
        ],
        [
          1588204800000,
          0
        ],
        [
          1588291200000,
          0
        ],
        [
          1588377600000,
          0
        ],
        [
          1588464000000,
          0
        ],
        [
          1588550400000,
          0
        ],
        [
          1588636800000,
          0
        ],
        [
          1588723200000,
          0
        ],
        [
          1588809600000,
          0
        ],
        [
          1588896000000,
          0
        ],
        [
          1588982400000,
          0
        ],
        [
          1589068800000,
          0
        ],
        [
          1589155200000,
          0
        ],
        [
          1589241600000,
          0
        ],
        [
          1589328000000,
          0
        ],
        [
          1589414400000,
          0
        ],
        [
          1589500800000,
          0
        ],
        [
          1589587200000,
          0
        ],
        [
          1589673600000,
          0
        ],
        [
          1589760000000,
          0
        ],
        [
          1589846400000,
          0
        ],
        [
          1589932800000,
          0
        ],
        [
          1590019200000,
          0
        ],
        [
          1590105600000,
          0
        ],
        [
          1590192000000,
          0
        ],
        [
          1590278400000,
          0
        ],
        [
          1590364800000,
          0
        ],
        [
          1590451200000,
          0
        ],
        [
          1590537600000,
          0
        ],
        [
          1590624000000,
          0
        ],
        [
          1590710400000,
          0
        ],
        [
          1590796800000,
          0
        ],
        [
          1590883200000,
          0
        ],
        [
          1590969600000,
          0
        ],
        [
          1591056000000,
          0
        ],
        [
          1591142400000,
          0
        ],
        [
          1591228800000,
          0
        ],
        [
          1591315200000,
          0
        ],
        [
          1591401600000,
          0
        ],
        [
          1591488000000,
          0
        ],
        [
          1591574400000,
          0
        ],
        [
          1591660800000,
          0
        ],
        [
          1591747200000,
          0
        ],
        [
          1591833600000,
          0
        ],
        [
          1591920000000,
          0
        ],
        [
          1592006400000,
          0
        ],
        [
          1592092800000,
          0
        ],
        [
          1592179200000,
          0
        ],
        [
          1592265600000,
          0
        ],
        [
          1592352000000,
          0
        ],
        [
          1592438400000,
          0
        ],
        [
          1592524800000,
          0
        ],
        [
          1592611200000,
          0
        ],
        [
          1592697600000,
          0
        ],
        [
          1592784000000,
          0
        ],
        [
          1592870400000,
          0
        ],
        [
          1592956800000,
          0
        ],
        [
          1593043200000,
          0
        ],
        [
          1593129600000,
          0
        ],
        [
          1593216000000,
          0
        ],
        [
          1593302400000,
          0
        ],
        [
          1593388800000,
          0
        ],
        [
          1593475200000,
          0
        ],
        [
          1593561600000,
          0
        ],
        [
          1593648000000,
          0
        ],
        [
          1593734400000,
          0
        ],
        [
          1593820800000,
          0
        ],
        [
          1593907200000,
          0
        ],
        [
          1593993600000,
          0
        ],
        [
          1594080000000,
          0
        ],
        [
          1594166400000,
          0
        ],
        [
          1594252800000,
          0
        ],
        [
          1594339200000,
          0
        ],
        [
          1594425600000,
          0
        ],
        [
          1594512000000,
          0
        ],
        [
          1594598400000,
          0
        ],
        [
          1594684800000,
          0
        ],
        [
          1594771200000,
          0
        ],
        [
          1594857600000,
          0
        ],
        [
          1594944000000,
          0
        ],
        [
          1595030400000,
          0
        ],
        [
          1595116800000,
          0
        ],
        [
          1595203200000,
          0
        ],
        [
          1595289600000,
          0
        ],
        [
          1595376000000,
          0
        ],
        [
          1595462400000,
          0
        ],
        [
          1595548800000,
          0
        ],
        [
          1595635200000,
          0
        ],
        [
          1595721600000,
          0
        ],
        [
          1595808000000,
          0
        ],
        [
          1595894400000,
          0
        ],
        [
          1595980800000,
          0
        ],
        [
          1596067200000,
          0
        ],
        [
          1596153600000,
          0
        ],
        [
          1596240000000,
          0
        ],
        [
          1596326400000,
          0
        ],
        [
          1596412800000,
          0
        ],
        [
          1596499200000,
          0
        ],
        [
          1596585600000,
          0
        ],
        [
          1596672000000,
          0
        ],
        [
          1596758400000,
          0
        ],
        [
          1596844800000,
          0
        ],
        [
          1596931200000,
          0
        ],
        [
          1597017600000,
          0
        ],
        [
          1597104000000,
          0
        ],
        [
          1597190400000,
          0
        ],
        [
          1597276800000,
          0
        ],
        [
          1597363200000,
          0
        ],
        [
          1597449600000,
          0
        ],
        [
          1597536000000,
          0
        ],
        [
          1597622400000,
          0
        ],
        [
          1597708800000,
          0
        ],
        [
          1597795200000,
          0
        ],
        [
          1597881600000,
          0
        ],
        [
          1597968000000,
          0
        ],
        [
          1598054400000,
          0
        ],
        [
          1598140800000,
          0
        ],
        [
          1598227200000,
          0
        ],
        [
          1598313600000,
          15000
        ],
        [
          1598400000000,
          15000
        ],
        [
          1598486400000,
          15000
        ],
        [
          1598572800000,
          15000
        ],
        [
          1598659200000,
          15000
        ],
        [
          1598745600000,
          15000
        ],
        [
          1598832000000,
          15000
        ],
        [
          1598918400000,
          15000
        ],
        [
          1599004800000,
          15000
        ],
        [
          1599091200000,
          15000
        ],
        [
          1599177600000,
          15000
        ],
        [
          1599264000000,
          15000
        ],
        [
          1599350400000,
          15000
        ],
        [
          1599436800000,
          15000
        ],
        [
          1599523200000,
          15000
        ],
        [
          1599609600000,
          15000
        ],
        [
          1599696000000,
          15000
        ],
        [
          1599782400000,
          15000
        ],
        [
          1599868800000,
          15000
        ],
        [
          1599955200000,
          15000
        ],
        [
          1600041600000,
          15000
        ],
        [
          1600128000000,
          15000
        ],
        [
          1600214400000,
          15000
        ],
        [
          1600300800000,
          15000
        ],
        [
          1600387200000,
          15000
        ],
        [
          1600473600000,
          15000
        ],
        [
          1600560000000,
          15000
        ],
        [
          1600646400000,
          15000
        ],
        [
          1600732800000,
          15000
        ],
        [
          1600819200000,
          15000
        ],
        [
          1600905600000,
          15000
        ],
        [
          1600992000000,
          15000
        ],
        [
          1601078400000,
          15000
        ],
        [
          1601164800000,
          15000
        ],
        [
          1601251200000,
          15000
        ],
        [
          1601337600000,
          15000
        ],
        [
          1601424000000,
          15000
        ],
        [
          1601510400000,
          15000
        ],
        [
          1601596800000,
          15000
        ],
        [
          1601683200000,
          15000
        ],
        [
          1601769600000,
          15000
        ],
        [
          1601856000000,
          15000
        ],
        [
          1601942400000,
          15000
        ],
        [
          1602028800000,
          15000
        ],
        [
          1602115200000,
          15000
        ],
        [
          1602201600000,
          15000
        ],
        [
          1602288000000,
          15000
        ],
        [
          1602374400000,
          15000
        ],
        [
          1602460800000,
          15000
        ],
        [
          1602547200000,
          15000
        ],
        [
          1602633600000,
          15000
        ],
        [
          1602720000000,
          15000
        ],
        [
          1602806400000,
          15000
        ],
        [
          1602892800000,
          15000
        ],
        [
          1602979200000,
          15000
        ],
        [
          1603065600000,
          15000
        ],
        [
          1603152000000,
          15000
        ],
        [
          1603238400000,
          15000
        ],
        [
          1603324800000,
          15000
        ],
        [
          1603411200000,
          15000
        ],
        [
          1603497600000,
          15000
        ],
        [
          1603584000000,
          15000
        ],
        [
          1603670400000,
          15000
        ],
        [
          1603756800000,
          15000
        ],
        [
          1603843200000,
          15000
        ],
        [
          1603929600000,
          15000
        ],
        [
          1604016000000,
          15000
        ],
        [
          1604102400000,
          15000
        ],
        [
          1604188800000,
          15000
        ],
        [
          1604275200000,
          15000
        ],
        [
          1604361600000,
          15000
        ],
        [
          1604448000000,
          15000
        ],
        [
          1604534400000,
          15000
        ],
        [
          1604620800000,
          15000
        ],
        [
          1604707200000,
          15000
        ],
        [
          1604793600000,
          15000
        ],
        [
          1604880000000,
          15000
        ],
        [
          1604966400000,
          15000
        ],
        [
          1605052800000,
          15000
        ],
        [
          1605139200000,
          15000
        ],
        [
          1605225600000,
          15000
        ],
        [
          1605312000000,
          15000
        ],
        [
          1605398400000,
          15000
        ],
        [
          1605484800000,
          15000
        ],
        [
          1605571200000,
          15000
        ],
        [
          1605657600000,
          15000
        ],
        [
          1605744000000,
          15000
        ],
        [
          1605830400000,
          15000
        ],
        [
          1605916800000,
          15000
        ],
        [
          1606003200000,
          15000
        ],
        [
          1606089600000,
          15000
        ],
        [
          1606176000000,
          15000
        ],
        [
          1606262400000,
          15000
        ],
        [
          1606348800000,
          15000
        ],
        [
          1606435200000,
          15000
        ],
        [
          1606521600000,
          15000
        ],
        [
          1606608000000,
          15000
        ],
        [
          1606694400000,
          15000
        ],
        [
          1606780800000,
          15000
        ],
        [
          1606867200000,
          15000
        ],
        [
          1606953600000,
          15000
        ],
        [
          1607040000000,
          15000
        ],
        [
          1607126400000,
          15000
        ],
        [
          1607212800000,
          15000
        ],
        [
          1607299200000,
          15000
        ],
        [
          1607385600000,
          15000
        ],
        [
          1607472000000,
          15000
        ],
        [
          1607558400000,
          15000
        ],
        [
          1607644800000,
          15000
        ],
        [
          1607731200000,
          15000
        ],
        [
          1607817600000,
          15000
        ],
        [
          1607904000000,
          15000
        ],
        [
          1607990400000,
          15000
        ],
        [
          1608076800000,
          15000
        ],
        [
          1608163200000,
          15000
        ],
        [
          1608249600000,
          15000
        ],
        [
          1608336000000,
          15000
        ],
        [
          1608422400000,
          15000
        ],
        [
          1608508800000,
          15000
        ],
        [
          1608595200000,
          15000
        ],
        [
          1608681600000,
          15000
        ],
        [
          1608768000000,
          15000
        ],
        [
          1608854400000,
          15000
        ],
        [
          1608940800000,
          15000
        ],
        [
          1609027200000,
          15000
        ],
        [
          1609113600000,
          15000
        ],
        [
          1609200000000,
          15000
        ],
        [
          1609372800000,
          15000
        ],
        [
          1609459200000,
          15000
        ],
        [
          1609545600000,
          15000
        ],
        [
          1609632000000,
          15000
        ],
        [
          1609718400000,
          15000
        ],
        [
          1609804800000,
          15000
        ],
        [
          1609891200000,
          15000
        ],
        [
          1609977600000,
          15000
        ],
        [
          1610064000000,
          15000
        ],
        [
          1610150400000,
          15000
        ],
        [
          1610236800000,
          15000
        ],
        [
          1610323200000,
          15000
        ],
        [
          1610409600000,
          15000
        ],
        [
          1610496000000,
          15000
        ],
        [
          1610582400000,
          15000
        ],
        [
          1610668800000,
          15000
        ],
        [
          1610755200000,
          15000
        ],
        [
          1610841600000,
          15000
        ],
        [
          1610928000000,
          15000
        ],
        [
          1611014400000,
          15000
        ],
        [
          1611100800000,
          15000
        ],
        [
          1611187200000,
          15000
        ],
        [
          1611273600000,
          15000
        ],
        [
          1611360000000,
          15000
        ],
        [
          1611446400000,
          15000
        ],
        [
          1611532800000,
          15000
        ],
        [
          1611619200000,
          15000
        ],
        [
          1611705600000,
          15000
        ],
        [
          1611792000000,
          15000
        ],
        [
          1611878400000,
          15000
        ],
        [
          1611964800000,
          15000
        ],
        [
          1612051200000,
          15000
        ],
        [
          1612137600000,
          15000
        ],
        [
          1612224000000,
          15000
        ],
        [
          1612310400000,
          15000
        ],
        [
          1612396800000,
          15000
        ],
        [
          1612483200000,
          15000
        ],
        [
          1612569600000,
          15000
        ],
        [
          1612656000000,
          15000
        ],
        [
          1612742400000,
          15000
        ],
        [
          1612828800000,
          15000
        ],
        [
          1612915200000,
          15000
        ],
        [
          1613001600000,
          15000
        ],
        [
          1613088000000,
          15000
        ],
        [
          1613174400000,
          15000
        ],
        [
          1613260800000,
          15000
        ],
        [
          1613347200000,
          15000
        ],
        [
          1613433600000,
          15000
        ],
        [
          1613520000000,
          15000
        ],
        [
          1613606400000,
          15000
        ],
        [
          1613692800000,
          15000
        ],
        [
          1613779200000,
          15000
        ],
        [
          1613865600000,
          15000
        ],
        [
          1613952000000,
          15000
        ],
        [
          1614038400000,
          15000
        ],
        [
          1614124800000,
          15000
        ],
        [
          1614211200000,
          15000
        ],
        [
          1614297600000,
          15000
        ],
        [
          1614384000000,
          15000
        ],
        [
          1614470400000,
          15000
        ],
        [
          1614556800000,
          15000
        ],
        [
          1614643200000,
          15000
        ],
        [
          1614729600000,
          15000
        ],
        [
          1614816000000,
          15000
        ],
        [
          1614902400000,
          15000
        ],
        [
          1614988800000,
          15000
        ],
        [
          1615075200000,
          15000
        ],
        [
          1615161600000,
          15000
        ],
        [
          1615248000000,
          15000
        ],
        [
          1615334400000,
          15000
        ],
        [
          1615420800000,
          15000
        ],
        [
          1615507200000,
          15000
        ],
        [
          1615593600000,
          15000
        ],
        [
          1615680000000,
          15000
        ],
        [
          1615766400000,
          15000
        ],
        [
          1615852800000,
          15000
        ],
        [
          1615939200000,
          15000
        ],
        [
          1616025600000,
          15000
        ],
        [
          1616112000000,
          15000
        ],
        [
          1616198400000,
          15000
        ],
        [
          1616284800000,
          15000
        ],
        [
          1616371200000,
          15000
        ],
        [
          1616457600000,
          15000
        ],
        [
          1616544000000,
          15000
        ],
        [
          1616630400000,
          15000
        ],
        [
          1616716800000,
          15000
        ],
        [
          1616803200000,
          15000
        ],
        [
          1616889600000,
          15000
        ],
        [
          1616976000000,
          15000
        ],
        [
          1617062400000,
          15000
        ],
        [
          1617148800000,
          15000
        ],
        [
          1617235200000,
          15000
        ],
        [
          1617321600000,
          15000
        ],
        [
          1617408000000,
          15000
        ],
        [
          1617494400000,
          15000
        ],
        [
          1617580800000,
          15000
        ],
        [
          1617667200000,
          15000
        ],
        [
          1617753600000,
          15000
        ],
        [
          1617840000000,
          15000
        ],
        [
          1617926400000,
          15000
        ],
        [
          1618012800000,
          15000
        ],
        [
          1618099200000,
          15000
        ],
        [
          1618185600000,
          15000
        ],
        [
          1618272000000,
          15000
        ],
        [
          1618358400000,
          15000
        ],
        [
          1618444800000,
          15000
        ],
        [
          1618531200000,
          15000
        ],
        [
          1618617600000,
          15000
        ],
        [
          1618704000000,
          15000
        ],
        [
          1618790400000,
          15000
        ],
        [
          1618876800000,
          15000
        ],
        [
          1618963200000,
          15000
        ],
        [
          1619049600000,
          15000
        ],
        [
          1619136000000,
          15000
        ],
        [
          1619222400000,
          15000
        ],
        [
          1619308800000,
          15000
        ],
        [
          1619395200000,
          15000
        ],
        [
          1619481600000,
          15000
        ],
        [
          1619568000000,
          15000
        ],
        [
          1619654400000,
          15000
        ],
        [
          1619740800000,
          15000
        ],
        [
          1619827200000,
          15000
        ],
        [
          1619913600000,
          15000
        ],
        [
          1620000000000,
          15000
        ],
        [
          1620086400000,
          15000
        ],
        [
          1620172800000,
          15000
        ],
        [
          1620259200000,
          15000
        ],
        [
          1620345600000,
          15000
        ],
        [
          1620432000000,
          15000
        ],
        [
          1620518400000,
          15000
        ],
        [
          1620604800000,
          15000
        ],
        [
          1620691200000,
          15000
        ],
        [
          1620777600000,
          15000
        ],
        [
          1620864000000,
          15000
        ],
        [
          1620950400000,
          15000
        ],
        [
          1621036800000,
          15000
        ],
        [
          1621123200000,
          15000
        ],
        [
          1621209600000,
          15000
        ],
        [
          1621296000000,
          15000
        ],
        [
          1621382400000,
          15000
        ],
        [
          1621468800000,
          15000
        ],
        [
          1621555200000,
          15000
        ],
        [
          1621641600000,
          15000
        ],
        [
          1621728000000,
          15000
        ],
        [
          1621814400000,
          15000
        ],
        [
          1621900800000,
          15000
        ],
        [
          1621987200000,
          15000
        ],
        [
          1622073600000,
          15000
        ],
        [
          1622160000000,
          15000
        ],
        [
          1622246400000,
          15000
        ],
        [
          1622332800000,
          15000
        ],
        [
          1622419200000,
          15000
        ],
        [
          1622505600000,
          15000
        ],
        [
          1622592000000,
          15000
        ],
        [
          1622678400000,
          15000
        ],
        [
          1622764800000,
          15000
        ],
        [
          1622851200000,
          15000
        ],
        [
          1622937600000,
          15000
        ],
        [
          1623024000000,
          15000
        ],
        [
          1623110400000,
          15000
        ],
        [
          1623196800000,
          15000
        ],
        [
          1623283200000,
          15000
        ],
        [
          1623369600000,
          15000
        ],
        [
          1623456000000,
          15000
        ],
        [
          1623542400000,
          15000
        ],
        [
          1623628800000,
          15000
        ],
        [
          1623715200000,
          15000
        ],
        [
          1623801600000,
          15000
        ],
        [
          1623888000000,
          15000
        ],
        [
          1623974400000,
          15000
        ],
        [
          1624060800000,
          15000
        ],
        [
          1624147200000,
          15000
        ],
        [
          1624233600000,
          15000
        ],
        [
          1624320000000,
          15000
        ],
        [
          1624406400000,
          15000
        ],
        [
          1624492800000,
          15000
        ],
        [
          1624579200000,
          15000
        ],
        [
          1624665600000,
          15000
        ],
        [
          1624752000000,
          15000
        ],
        [
          1624838400000,
          15000
        ],
        [
          1624924800000,
          15000
        ],
        [
          1625011200000,
          15000
        ],
        [
          1625097600000,
          15000
        ],
        [
          1625184000000,
          15000
        ],
        [
          1625270400000,
          15000
        ],
        [
          1625356800000,
          15000
        ],
        [
          1625443200000,
          15000
        ],
        [
          1625529600000,
          15000
        ],
        [
          1625616000000,
          15000
        ],
        [
          1625702400000,
          15000
        ],
        [
          1625788800000,
          15000
        ],
        [
          1625875200000,
          15000
        ],
        [
          1625961600000,
          15000
        ],
        [
          1626048000000,
          15000
        ],
        [
          1626134400000,
          15000
        ],
        [
          1626220800000,
          15000
        ],
        [
          1626307200000,
          15000
        ],
        [
          1626393600000,
          15000
        ],
        [
          1626480000000,
          15000
        ],
        [
          1626566400000,
          15000
        ],
        [
          1626652800000,
          15000
        ],
        [
          1626739200000,
          15000
        ],
        [
          1626825600000,
          15000
        ],
        [
          1626912000000,
          15000
        ],
        [
          1627084800000,
          15000
        ],
        [
          1627171200000,
          15000
        ],
        [
          1627257600000,
          15000
        ],
        [
          1627344000000,
          15000
        ],
        [
          1627430400000,
          15000
        ],
        [
          1627516800000,
          15000
        ],
        [
          1627603200000,
          15000
        ],
        [
          1627689600000,
          15000
        ],
        [
          1627776000000,
          15000
        ],
        [
          1627862400000,
          15000
        ],
        [
          1627948800000,
          15000
        ],
        [
          1628035200000,
          15000
        ],
        [
          1628121600000,
          15000
        ],
        [
          1628208000000,
          15000
        ],
        [
          1628294400000,
          15000
        ],
        [
          1628380800000,
          15000
        ],
        [
          1628467200000,
          15000
        ],
        [
          1628553600000,
          15000
        ],
        [
          1628640000000,
          15000
        ],
        [
          1628726400000,
          15000
        ],
        [
          1628812800000,
          15000
        ],
        [
          1628899200000,
          15000
        ],
        [
          1628985600000,
          15000
        ],
        [
          1629072000000,
          15000
        ],
        [
          1629158400000,
          15000
        ],
        [
          1629244800000,
          15000
        ],
        [
          1629331200000,
          15000
        ],
        [
          1629417600000,
          15000
        ],
        [
          1629504000000,
          515000
        ],
        [
          1629590400000,
          515000
        ],
        [
          1629676800000,
          515000
        ],
        [
          1629763200000,
          515000
        ],
        [
          1629849600000,
          515000
        ],
        [
          1629936000000,
          515000
        ],
        [
          1630022400000,
          515000
        ],
        [
          1630108800000,
          515000
        ],
        [
          1630195200000,
          515000
        ],
        [
          1630281600000,
          515000
        ],
        [
          1630368000000,
          515000
        ],
        [
          1630454400000,
          515000
        ],
        [
          1630540800000,
          515000
        ],
        [
          1630627200000,
          515000
        ],
        [
          1630713600000,
          515000
        ],
        [
          1630800000000,
          515000
        ],
        [
          1630886400000,
          515000
        ],
        [
          1630972800000,
          515000
        ],
        [
          1631059200000,
          515000
        ],
        [
          1631145600000,
          115000
        ],
        [
          1631232000000,
          115000
        ],
        [
          1631318400000,
          115000
        ],
        [
          1631404800000,
          115000
        ],
        [
          1631491200000,
          115000
        ],
        [
          1631577600000,
          115000
        ],
        [
          1631664000000,
          115000
        ],
        [
          1631750400000,
          115000
        ],
        [
          1631836800000,
          115000
        ],
        [
          1631923200000,
          115000
        ],
        [
          1632009600000,
          115000
        ],
        [
          1632096000000,
          115000
        ],
        [
          1632182400000,
          115000
        ],
        [
          1632268800000,
          115000
        ],
        [
          1632355200000,
          115000
        ],
        [
          1632441600000,
          115000
        ],
        [
          1632528000000,
          115000
        ],
        [
          1632614400000,
          115000
        ],
        [
          1632700800000,
          115000
        ],
        [
          1632787200000,
          115000
        ],
        [
          1632873600000,
          115000
        ],
        [
          1632960000000,
          115000
        ],
        [
          1633046400000,
          115000
        ],
        [
          1633132800000,
          115000
        ],
        [
          1633219200000,
          115000
        ],
        [
          1633305600000,
          115000
        ],
        [
          1633392000000,
          4700
        ],
        [
          1633478400000,
          4700
        ],
        [
          1633564800000,
          4700
        ],
        [
          1633651200000,
          4700
        ],
        [
          1633737600000,
          4700
        ],
        [
          1633824000000,
          4700
        ],
        [
          1633910400000,
          4700
        ],
        [
          1633996800000,
          4700
        ],
        [
          1634083200000,
          4700
        ],
        [
          1634169600000,
          4700
        ],
        [
          1634256000000,
          4700
        ],
        [
          1634342400000,
          4700
        ],
        [
          1634428800000,
          4700
        ],
        [
          1634515200000,
          4700
        ],
        [
          1634601600000,
          4700
        ],
        [
          1634688000000,
          4700
        ],
        [
          1634774400000,
          4700
        ],
        [
          1634860800000,
          4700
        ],
        [
          1634947200000,
          4700
        ],
        [
          1635033600000,
          4700
        ],
        [
          1635120000000,
          4700
        ],
        [
          1635206400000,
          4700
        ],
        [
          1635292800000,
          4700
        ],
        [
          1635379200000,
          4700
        ],
        [
          1635465600000,
          4700
        ],
        [
          1635552000000,
          4700
        ],
        [
          1635638400000,
          4700
        ],
        [
          1635724800000,
          4700
        ],
        [
          1635811200000,
          4700
        ],
        [
          1635897600000,
          4700
        ],
        [
          1635984000000,
          4700
        ],
        [
          1636070400000,
          4700
        ],
        [
          1636156800000,
          4700
        ],
        [
          1636243200000,
          4700
        ],
        [
          1636329600000,
          4700
        ],
        [
          1636416000000,
          4700
        ],
        [
          1636502400000,
          4700
        ],
        [
          1636588800000,
          4700
        ],
        [
          1636675200000,
          4700
        ],
        [
          1636761600000,
          4700
        ],
        [
          1636848000000,
          4700
        ],
        [
          1636934400000,
          4700
        ],
        [
          1637020800000,
          4700
        ],
        [
          1637107200000,
          4700
        ],
        [
          1637193600000,
          4700
        ],
        [
          1637280000000,
          4700
        ],
        [
          1637366400000,
          4700
        ],
        [
          1637452800000,
          4700
        ],
        [
          1637539200000,
          4700
        ],
        [
          1637625600000,
          4700
        ],
        [
          1637712000000,
          4700
        ],
        [
          1637798400000,
          4700
        ],
        [
          1637884800000,
          4700
        ],
        [
          1637971200000,
          4700
        ],
        [
          1638057600000,
          4700
        ],
        [
          1638144000000,
          4700
        ],
        [
          1638230400000,
          4700
        ],
        [
          1638316800000,
          4700
        ],
        [
          1638403200000,
          4700
        ],
        [
          1638489600000,
          4700
        ],
        [
          1638576000000,
          4700
        ],
        [
          1638662400000,
          4700
        ],
        [
          1638748800000,
          4700
        ],
        [
          1638835200000,
          4700
        ],
        [
          1638921600000,
          4700
        ],
        [
          1639008000000,
          4700
        ],
        [
          1639094400000,
          4700
        ],
        [
          1639180800000,
          4700
        ],
        [
          1639267200000,
          4700
        ],
        [
          1639353600000,
          4700
        ],
        [
          1639440000000,
          4700
        ],
        [
          1639526400000,
          4700
        ],
        [
          1639612800000,
          4700
        ],
        [
          1639699200000,
          4700
        ],
        [
          1639785600000,
          4700
        ],
        [
          1639872000000,
          4700
        ],
        [
          1639958400000,
          4700
        ],
        [
          1640044800000,
          4700
        ],
        [
          1640131200000,
          4700
        ],
        [
          1640217600000,
          4700
        ],
        [
          1640304000000,
          4700
        ],
        [
          1640390400000,
          4700
        ],
        [
          1640476800000,
          4700
        ],
        [
          1640563200000,
          4700
        ],
        [
          1640649600000,
          4700
        ],
        [
          1640736000000,
          4700
        ],
        [
          1640822400000,
          4700
        ],
        [
          1640908800000,
          4700
        ],
        [
          1640995200000,
          4700
        ],
        [
          1641081600000,
          4700
        ],
        [
          1641168000000,
          4700
        ],
        [
          1641254400000,
          4700
        ],
        [
          1641340800000,
          4700
        ],
        [
          1641427200000,
          4700
        ],
        [
          1641513600000,
          4700
        ],
        [
          1641600000000,
          4700
        ],
        [
          1641686400000,
          4700
        ],
        [
          1641772800000,
          4700
        ],
        [
          1641859200000,
          4700
        ],
        [
          1641945600000,
          4700
        ],
        [
          1642032000000,
          4700
        ],
        [
          1642118400000,
          4700
        ],
        [
          1642204800000,
          4700
        ],
        [
          1642291200000,
          4700
        ],
        [
          1642377600000,
          4700
        ],
        [
          1642464000000,
          4700
        ],
        [
          1642550400000,
          4700
        ],
        [
          1642636800000,
          4700
        ],
        [
          1642723200000,
          4700
        ],
        [
          1642809600000,
          4700
        ],
        [
          1642896000000,
          4700
        ],
        [
          1642982400000,
          4700
        ],
        [
          1643068800000,
          4700
        ],
        [
          1643155200000,
          4700
        ],
        [
          1643241600000,
          4700
        ],
        [
          1643328000000,
          4700
        ],
        [
          1643414400000,
          4700
        ],
        [
          1643500800000,
          4700
        ],
        [
          1643587200000,
          4700
        ],
        [
          1643673600000,
          4700
        ],
        [
          1643760000000,
          4700
        ],
        [
          1643846400000,
          4700
        ],
        [
          1643932800000,
          4700
        ],
        [
          1644019200000,
          4700
        ],
        [
          1644105600000,
          4700
        ],
        [
          1644192000000,
          4700
        ],
        [
          1644278400000,
          4700
        ],
        [
          1644364800000,
          4700
        ],
        [
          1644451200000,
          4700
        ],
        [
          1644537600000,
          4700
        ],
        [
          1644624000000,
          4700
        ],
        [
          1644710400000,
          4700
        ],
        [
          1644796800000,
          4700
        ],
        [
          1644883200000,
          4700
        ],
        [
          1644969600000,
          4700
        ],
        [
          1645056000000,
          4700
        ],
        [
          1645142400000,
          4700
        ],
        [
          1645228800000,
          4700
        ],
        [
          1645315200000,
          4700
        ],
        [
          1645401600000,
          4700
        ],
        [
          1645488000000,
          4700
        ],
        [
          1645574400000,
          4700
        ],
        [
          1645660800000,
          4700
        ],
        [
          1645747200000,
          4700
        ],
        [
          1645833600000,
          4700
        ],
        [
          1645920000000,
          4700
        ],
        [
          1646006400000,
          4700
        ],
        [
          1646092800000,
          4700
        ],
        [
          1646179200000,
          4700
        ],
        [
          1646265600000,
          4700
        ],
        [
          1646352000000,
          4700
        ],
        [
          1646438400000,
          4700
        ],
        [
          1646524800000,
          4700
        ],
        [
          1646611200000,
          4700
        ],
        [
          1646697600000,
          4700
        ],
        [
          1646784000000,
          4700
        ],
        [
          1646870400000,
          4700
        ],
        [
          1646956800000,
          4700
        ],
        [
          1647043200000,
          4700
        ],
        [
          1647129600000,
          4700
        ],
        [
          1647302400000,
          4700
        ],
        [
          1647388800000,
          4700
        ],
        [
          1647475200000,
          4700
        ],
        [
          1647561600000,
          4700
        ],
        [
          1647648000000,
          4700
        ],
        [
          1647734400000,
          4700
        ],
        [
          1647820800000,
          4700
        ],
        [
          1647907200000,
          4700
        ],
        [
          1647993600000,
          4700
        ],
        [
          1648080000000,
          4700
        ],
        [
          1648166400000,
          4700
        ],
        [
          1648252800000,
          4700
        ],
        [
          1648339200000,
          4700
        ],
        [
          1648425600000,
          4700
        ],
        [
          1648512000000,
          4700
        ],
        [
          1648598400000,
          4700
        ],
        [
          1648684800000,
          4700
        ],
        [
          1648771200000,
          4700
        ],
        [
          1648857600000,
          4700
        ],
        [
          1648944000000,
          4700
        ],
        [
          1649030400000,
          4700
        ],
        [
          1649116800000,
          4700
        ],
        [
          1649203200000,
          4700
        ],
        [
          1649289600000,
          4700
        ],
        [
          1649376000000,
          4700
        ],
        [
          1649462400000,
          4700
        ],
        [
          1649548800000,
          4700
        ],
        [
          1649635200000,
          4700
        ],
        [
          1649721600000,
          4700
        ],
        [
          1649808000000,
          4700
        ],
        [
          1649894400000,
          4700
        ],
        [
          1649980800000,
          4700
        ],
        [
          1650067200000,
          4700
        ],
        [
          1650153600000,
          4700
        ],
        [
          1650240000000,
          4700
        ],
        [
          1650326400000,
          4700
        ],
        [
          1650412800000,
          4700
        ],
        [
          1650499200000,
          4700
        ],
        [
          1650585600000,
          4700
        ],
        [
          1650672000000,
          4700
        ],
        [
          1650758400000,
          4700
        ],
        [
          1650844800000,
          4700
        ],
        [
          1650931200000,
          4700
        ],
        [
          1651017600000,
          4700
        ],
        [
          1651104000000,
          4700
        ],
        [
          1651190400000,
          4700
        ],
        [
          1651276800000,
          4700
        ],
        [
          1651363200000,
          4700
        ],
        [
          1651449600000,
          4700
        ],
        [
          1651536000000,
          4700
        ],
        [
          1651622400000,
          4700
        ],
        [
          1651708800000,
          4700
        ],
        [
          1651795200000,
          4700
        ],
        [
          1651881600000,
          4700
        ],
        [
          1651968000000,
          4700
        ],
        [
          1652054400000,
          4700
        ],
        [
          1652140800000,
          4700
        ],
        [
          1652227200000,
          4700
        ],
        [
          1652313600000,
          4700
        ],
        [
          1652400000000,
          4700
        ],
        [
          1652486400000,
          4700
        ],
        [
          1652572800000,
          4700
        ],
        [
          1652659200000,
          4700
        ],
        [
          1652745600000,
          4700
        ],
        [
          1652832000000,
          4700
        ],
        [
          1652918400000,
          4700
        ],
        [
          1653004800000,
          4700
        ],
        [
          1653091200000,
          4700
        ],
        [
          1653177600000,
          4700
        ],
        [
          1653264000000,
          4700
        ],
        [
          1653350400000,
          4700
        ],
        [
          1653436800000,
          4700
        ],
        [
          1653523200000,
          4700
        ],
        [
          1653609600000,
          4700
        ],
        [
          1653696000000,
          4700
        ],
        [
          1653782400000,
          4700
        ],
        [
          1653868800000,
          4700
        ],
        [
          1653955200000,
          4700
        ],
        [
          1654041600000,
          4700
        ],
        [
          1654128000000,
          4700
        ],
        [
          1654214400000,
          4700
        ],
        [
          1654300800000,
          4700
        ],
        [
          1654387200000,
          4700
        ],
        [
          1654473600000,
          4700
        ],
        [
          1654560000000,
          4700
        ],
        [
          1654646400000,
          4700
        ],
        [
          1654732800000,
          4700
        ],
        [
          1654819200000,
          4700
        ],
        [
          1654905600000,
          4700
        ],
        [
          1654992000000,
          4700
        ],
        [
          1655078400000,
          4700
        ],
        [
          1655164800000,
          4700
        ],
        [
          1655251200000,
          4700
        ],
        [
          1655337600000,
          4700
        ],
        [
          1655424000000,
          4700
        ],
        [
          1655510400000,
          4700
        ],
        [
          1655596800000,
          4700
        ],
        [
          1655683200000,
          4700
        ],
        [
          1655769600000,
          4700
        ],
        [
          1655856000000,
          4700
        ],
        [
          1655942400000,
          4700
        ],
        [
          1656028800000,
          4700
        ],
        [
          1656115200000,
          4700
        ],
        [
          1656201600000,
          4700
        ],
        [
          1656288000000,
          4700
        ],
        [
          1656374400000,
          4700
        ],
        [
          1656460800000,
          4700
        ],
        [
          1656547200000,
          4700
        ],
        [
          1656633600000,
          4700
        ],
        [
          1656720000000,
          4700
        ],
        [
          1656806400000,
          4700
        ],
        [
          1656892800000,
          4700
        ],
        [
          1656979200000,
          4700
        ],
        [
          1657065600000,
          4700
        ],
        [
          1657152000000,
          4700
        ],
        [
          1657238400000,
          4700
        ],
        [
          1657324800000,
          4700
        ],
        [
          1657411200000,
          4700
        ],
        [
          1657497600000,
          4700
        ],
        [
          1657584000000,
          4700
        ],
        [
          1657670400000,
          4700
        ],
        [
          1657756800000,
          4700
        ],
        [
          1657843200000,
          4700
        ],
        [
          1657929600000,
          4700
        ],
        [
          1658016000000,
          4700
        ],
        [
          1658102400000,
          4700
        ],
        [
          1658188800000,
          4700
        ],
        [
          1658275200000,
          4700
        ],
        [
          1658361600000,
          4700
        ],
        [
          1658448000000,
          4700
        ],
        [
          1658534400000,
          4700
        ],
        [
          1658620800000,
          4700
        ],
        [
          1658707200000,
          4700
        ],
        [
          1658793600000,
          4700
        ],
        [
          1658880000000,
          4700
        ],
        [
          1658966400000,
          4700
        ],
        [
          1659052800000,
          4700
        ],
        [
          1659139200000,
          4700
        ],
        [
          1659225600000,
          4700
        ],
        [
          1659312000000,
          4700
        ],
        [
          1659398400000,
          4700
        ],
        [
          1659484800000,
          4700
        ],
        [
          1659571200000,
          4700
        ],
        [
          1659657600000,
          4700
        ],
        [
          1659744000000,
          4700
        ],
        [
          1659830400000,
          4700
        ],
        [
          1659916800000,
          4700
        ],
        [
          1660003200000,
          4700
        ],
        [
          1660089600000,
          4700
        ],
        [
          1660176000000,
          4700
        ],
        [
          1660262400000,
          4700
        ],
        [
          1660348800000,
          4700
        ],
        [
          1660435200000,
          4700
        ],
        [
          1660521600000,
          4700
        ],
        [
          1660608000000,
          4700
        ],
        [
          1660694400000,
          4700
        ],
        [
          1660780800000,
          4700
        ],
        [
          1660867200000,
          4700
        ],
        [
          1660953600000,
          4700
        ],
        [
          1661040000000,
          4700
        ],
        [
          1661126400000,
          4700
        ],
        [
          1661212800000,
          4700
        ],
        [
          1661299200000,
          4700
        ],
        [
          1661385600000,
          4700
        ],
        [
          1661472000000,
          4700
        ],
        [
          1661558400000,
          4700
        ],
        [
          1661644800000,
          4700
        ],
        [
          1661731200000,
          4700
        ],
        [
          1661817600000,
          4700
        ],
        [
          1661904000000,
          4700
        ],
        [
          1661990400000,
          4700
        ],
        [
          1662076800000,
          4700
        ],
        [
          1662163200000,
          4700
        ],
        [
          1662249600000,
          4700
        ],
        [
          1662336000000,
          4700
        ],
        [
          1662422400000,
          4700
        ],
        [
          1662508800000,
          4700
        ],
        [
          1662595200000,
          4700
        ],
        [
          1662681600000,
          4700
        ],
        [
          1662768000000,
          4700
        ],
        [
          1662854400000,
          4700
        ],
        [
          1662940800000,
          4700
        ],
        [
          1663027200000,
          4700
        ],
        [
          1663113600000,
          4700
        ],
        [
          1663200000000,
          4700
        ],
        [
          1663286400000,
          4700
        ],
        [
          1663372800000,
          4700
        ],
        [
          1663459200000,
          4700
        ],
        [
          1663545600000,
          4700
        ],
        [
          1663632000000,
          4700
        ],
        [
          1663718400000,
          4700
        ],
        [
          1663804800000,
          4700
        ],
        [
          1663891200000,
          4700
        ],
        [
          1663977600000,
          4700
        ],
        [
          1664064000000,
          4700
        ],
        [
          1664150400000,
          4700
        ],
        [
          1664236800000,
          14700
        ],
        [
          1664323200000,
          14700
        ],
        [
          1664409600000,
          14700
        ],
        [
          1664496000000,
          14700
        ],
        [
          1664582400000,
          14700
        ],
        [
          1664668800000,
          14700
        ],
        [
          1664755200000,
          14700
        ],
        [
          1664841600000,
          14700
        ],
        [
          1664928000000,
          14700
        ],
        [
          1665014400000,
          14700
        ],
        [
          1665100800000,
          14700
        ],
        [
          1665187200000,
          14700
        ],
        [
          1665273600000,
          14700
        ],
        [
          1665360000000,
          14700
        ],
        [
          1665446400000,
          24700
        ],
        [
          1665532800000,
          24700
        ],
        [
          1665619200000,
          24700
        ],
        [
          1665705600000,
          24700
        ],
        [
          1665792000000,
          24700
        ],
        [
          1665878400000,
          24700
        ],
        [
          1665964800000,
          24700
        ],
        [
          1666051200000,
          24700
        ],
        [
          1666137600000,
          24700
        ],
        [
          1666224000000,
          24700
        ],
        [
          1666310400000,
          24700
        ],
        [
          1666396800000,
          24700
        ],
        [
          1666483200000,
          24700
        ],
        [
          1666569600000,
          24700
        ],
        [
          1666656000000,
          24700
        ],
        [
          1666742400000,
          124700
        ],
        [
          1666828800000,
          124700
        ],
        [
          1666915200000,
          124700
        ],
        [
          1667001600000,
          124700
        ],
        [
          1667088000000,
          124700
        ],
        [
          1667174400000,
          124700
        ],
        [
          1667260800000,
          124700
        ],
        [
          1667347200000,
          124700
        ],
        [
          1667433600000,
          124700
        ],
        [
          1667520000000,
          124700
        ],
        [
          1667606400000,
          124700
        ],
        [
          1667692800000,
          124700
        ],
        [
          1667779200000,
          124700
        ],
        [
          1667865600000,
          124700
        ],
        [
          1667952000000,
          124700
        ],
        [
          1668038400000,
          124700
        ],
        [
          1668124800000,
          124700
        ],
        [
          1668211200000,
          124700
        ],
        [
          1668297600000,
          124700
        ],
        [
          1668384000000,
          4699.99999
        ],
        [
          1668470400000,
          4699.99999
        ],
        [
          1668556800000,
          4699.99999
        ],
        [
          1668643200000,
          4699.99999
        ],
        [
          1668729600000,
          4699.99999
        ],
        [
          1668816000000,
          4699.99999
        ],
        [
          1668902400000,
          4699.99999
        ],
        [
          1668988800000,
          4699.99999
        ],
        [
          1669075200000,
          4699.99999
        ],
        [
          1669161600000,
          4699.99999
        ],
        [
          1669248000000,
          4699.99999
        ],
        [
          1669334400000,
          4699.99999
        ],
        [
          1669420800000,
          54699.99999
        ],
        [
          1669507200000,
          54699.99999
        ],
        [
          1669593600000,
          54699.99999
        ],
        [
          1669680000000,
          54699.99999
        ],
        [
          1669766400000,
          54699.99999
        ],
        [
          1669852800000,
          54699.99999
        ],
        [
          1669939200000,
          54699.99999
        ],
        [
          1670025600000,
          54699.99999
        ],
        [
          1670112000000,
          54699.99999
        ],
        [
          1670198400000,
          54699.99999
        ],
        [
          1670284800000,
          54699.99999
        ],
        [
          1670371200000,
          54699.99999
        ],
        [
          1670457600000,
          54699.99999
        ],
        [
          1670544000000,
          54699.99999
        ],
        [
          1670630400000,
          54699.99999
        ],
        [
          1670716800000,
          54699.99999
        ],
        [
          1670803200000,
          4699.99999
        ],
        [
          1670889600000,
          4699.99999
        ],
        [
          1670976000000,
          4699.99999
        ],
        [
          1671062400000,
          4699.99999
        ],
        [
          1671148800000,
          4699.99999
        ],
        [
          1671235200000,
          4699.99999
        ],
        [
          1671321600000,
          4699.99999
        ],
        [
          1671408000000,
          4699.99999
        ],
        [
          1671494400000,
          4699.99999
        ],
        [
          1671580800000,
          4699.99999
        ],
        [
          1671667200000,
          54699.99999
        ],
        [
          1671753600000,
          54699.99999
        ],
        [
          1671840000000,
          54699.99999
        ],
        [
          1671926400000,
          54699.99999
        ],
        [
          1672012800000,
          54699.99999
        ],
        [
          1672099200000,
          54699.99999
        ],
        [
          1672185600000,
          54699.99999
        ],
        [
          1672272000000,
          14699.99999
        ],
        [
          1672358400000,
          14699.99999
        ],
        [
          1672444800000,
          14699.99999
        ],
        [
          1672531200000,
          14699.99999
        ],
        [
          1672617600000,
          14699.99999
        ],
        [
          1672704000000,
          14699.99999
        ],
        [
          1672790400000,
          14699.99999
        ],
        [
          1672876800000,
          14699.99999
        ],
        [
          1672963200000,
          14699.99999
        ],
        [
          1673049600000,
          14699.99999
        ],
        [
          1673136000000,
          14699.99999
        ],
        [
          1673222400000,
          14699.99999
        ],
        [
          1673308800000,
          14699.99999
        ],
        [
          1673395200000,
          14699.99999
        ],
        [
          1673481600000,
          14699.99999
        ],
        [
          1673568000000,
          14699.99999
        ],
        [
          1673654400000,
          14699.99999
        ],
        [
          1673740800000,
          14699.99999
        ],
        [
          1673827200000,
          14699.99999
        ],
        [
          1673913600000,
          14699.99999
        ],
        [
          1674000000000,
          14699.99999
        ],
        [
          1674086400000,
          14699.99999
        ],
        [
          1674172800000,
          14699.99999
        ],
        [
          1674259200000,
          14699.99999
        ],
        [
          1674345600000,
          14699.99999
        ],
        [
          1674432000000,
          14699.99999
        ],
        [
          1674518400000,
          14699.99999
        ],
        [
          1674604800000,
          14699.99999
        ],
        [
          1674691200000,
          114699.99999
        ],
        [
          1674777600000,
          114699.99999
        ],
        [
          1674864000000,
          114699.99999
        ],
        [
          1674950400000,
          114699.99999
        ],
        [
          1675036800000,
          114699.99999
        ],
        [
          1675123200000,
          114699.99999
        ],
        [
          1675209600000,
          4699.99999
        ],
        [
          1675296000000,
          4699.99999
        ],
        [
          1675382400000,
          4699.99999
        ],
        [
          1675468800000,
          4699.99999
        ],
        [
          1675555200000,
          4699.99999
        ],
        [
          1675641600000,
          4699.99999
        ],
        [
          1675728000000,
          4699.99999
        ],
        [
          1675814400000,
          4699.99999
        ],
        [
          1675900800000,
          4699.99999
        ],
        [
          1675987200000,
          4699.99999
        ],
        [
          1676073600000,
          4699.99999
        ],
        [
          1676160000000,
          4699.99999
        ],
        [
          1676246400000,
          4699.99999
        ],
        [
          1676332800000,
          4699.99999
        ],
        [
          1676419200000,
          4699.99999
        ],
        [
          1676505600000,
          4699.99999
        ],
        [
          1676592000000,
          4699.99999
        ],
        [
          1676678400000,
          4699.99999
        ],
        [
          1676764800000,
          4699.99999
        ],
        [
          1676851200000,
          4699.99999
        ],
        [
          1676937600000,
          4699.99999
        ],
        [
          1677024000000,
          4699.99999
        ],
        [
          1677110400000,
          4699.99999
        ],
        [
          1677196800000,
          4699.99999
        ],
        [
          1677283200000,
          104699.99999
        ],
        [
          1677369600000,
          104699.99999
        ],
        [
          1677456000000,
          104699.99999
        ],
        [
          1677542400000,
          104699.99999
        ],
        [
          1677628800000,
          104699.99999
        ],
        [
          1677715200000,
          104699.99999
        ],
        [
          1677801600000,
          104699.99999
        ],
        [
          1677888000000,
          104699.99999
        ],
        [
          1677974400000,
          104699.99999
        ],
        [
          1678060800000,
          104699.99999
        ],
        [
          1678147200000,
          104699.99999
        ],
        [
          1678233600000,
          104699.99999
        ],
        [
          1678320000000,
          104699.99999
        ],
        [
          1678406400000,
          4699.99999
        ],
        [
          1678492800000,
          4699.99999
        ],
        [
          1678579200000,
          4699.99999
        ],
        [
          1678665600000,
          4699.99999
        ],
        [
          1678752000000,
          4699.99999
        ],
        [
          1678838400000,
          4699.99999
        ],
        [
          1678924800000,
          4699.99999
        ],
        [
          1679011200000,
          4699.99999
        ],
        [
          1679097600000,
          4699.99999
        ],
        [
          1679184000000,
          4699.99999
        ],
        [
          1679270400000,
          4699.99999
        ],
        [
          1679356800000,
          4699.99999
        ],
        [
          1679443200000,
          4699.99999
        ],
        [
          1679529600000,
          4699.99999
        ],
        [
          1679616000000,
          4699.99999
        ],
        [
          1679702400000,
          104699.99999
        ],
        [
          1679788800000,
          104699.99999
        ],
        [
          1679875200000,
          104699.99999
        ],
        [
          1679961600000,
          104699.99999
        ],
        [
          1680048000000,
          104699.99999
        ],
        [
          1680134400000,
          104699.99999
        ],
        [
          1680220800000,
          104699.99999
        ],
        [
          1680307200000,
          104699.99999
        ],
        [
          1680393600000,
          104699.99999
        ],
        [
          1680480000000,
          104699.99999
        ],
        [
          1680566400000,
          14699.99999
        ],
        [
          1680652800000,
          14699.99999
        ],
        [
          1680739200000,
          14699.99999
        ],
        [
          1680825600000,
          14699.99999
        ],
        [
          1680912000000,
          14699.99999
        ],
        [
          1680998400000,
          14699.99999
        ],
        [
          1681084800000,
          14699.99999
        ],
        [
          1681171200000,
          14699.99999
        ],
        [
          1681257600000,
          14699.99999
        ],
        [
          1681344000000,
          14699.99999
        ],
        [
          1681430400000,
          14699.99999
        ],
        [
          1681516800000,
          14699.99999
        ],
        [
          1681603200000,
          14699.99999
        ],
        [
          1681689600000,
          14699.99999
        ],
        [
          1681776000000,
          14699.99999
        ],
        [
          1681862400000,
          14699.99999
        ],
        [
          1681948800000,
          14699.99999
        ],
        [
          1682035200000,
          14699.99999
        ],
        [
          1682121600000,
          14699.99999
        ],
        [
          1682208000000,
          14699.99999
        ],
        [
          1682294400000,
          14699.99999
        ],
        [
          1682380800000,
          14699.99999
        ],
        [
          1682467200000,
          14699.99999
        ],
        [
          1682553600000,
          114699.99999
        ],
        [
          1682640000000,
          114699.99999
        ],
        [
          1682726400000,
          114699.99999
        ],
        [
          1682812800000,
          114699.99999
        ],
        [
          1682899200000,
          114699.99999
        ],
        [
          1682985600000,
          114699.99999
        ],
        [
          1683072000000,
          114699.99999
        ],
        [
          1683158400000,
          11699.99999
        ],
        [
          1683244800000,
          11699.99999
        ],
        [
          1683331200000,
          11699.99999
        ],
        [
          1683417600000,
          11699.99999
        ],
        [
          1683504000000,
          11699.99999
        ],
        [
          1683590400000,
          11699.99999
        ],
        [
          1683676800000,
          11699.99999
        ],
        [
          1683763200000,
          11699.99999
        ],
        [
          1683849600000,
          11699.99999
        ],
        [
          1683936000000,
          11699.99999
        ],
        [
          1684022400000,
          11699.99999
        ],
        [
          1684108800000,
          11699.99999
        ],
        [
          1684195200000,
          11699.99999
        ],
        [
          1684281600000,
          11699.99999
        ],
        [
          1684368000000,
          11699.99999
        ],
        [
          1684454400000,
          11699.99999
        ],
        [
          1684540800000,
          11699.99999
        ],
        [
          1684627200000,
          11699.99999
        ],
        [
          1684713600000,
          11699.99999
        ],
        [
          1684800000000,
          11699.99999
        ],
        [
          1684886400000,
          11699.99999
        ],
        [
          1684972800000,
          11699.99999
        ],
        [
          1685059200000,
          11699.99999
        ],
        [
          1685145600000,
          111699.99999
        ],
        [
          1685232000000,
          111699.99999
        ],
        [
          1685318400000,
          111699.99999
        ],
        [
          1685404800000,
          111699.99999
        ],
        [
          1685491200000,
          111699.99999
        ],
        [
          1685577600000,
          111699.99999
        ],
        [
          1685664000000,
          111699.99999
        ],
        [
          1685750400000,
          111699.99999
        ],
        [
          1685836800000,
          111699.99999
        ],
        [
          1685923200000,
          111699.99999
        ],
        [
          1686009600000,
          111699.99999
        ],
        [
          1686096000000,
          111699.99999
        ],
        [
          1686182400000,
          111699.99999
        ],
        [
          1686268800000,
          111699.99999
        ],
        [
          1686355200000,
          111699.99999
        ],
        [
          1686441600000,
          111699.99999
        ],
        [
          1686528000000,
          111699.99999
        ],
        [
          1686614400000,
          111699.99999
        ],
        [
          1686700800000,
          111699.99999
        ],
        [
          1686787200000,
          111699.99999
        ],
        [
          1686873600000,
          111699.99999
        ],
        [
          1686960000000,
          111699.99999
        ],
        [
          1687046400000,
          111699.99999
        ],
        [
          1687132800000,
          111699.99999
        ],
        [
          1687219200000,
          111699.99999
        ],
        [
          1687305600000,
          111699.99999
        ],
        [
          1687392000000,
          111699.99999
        ],
        [
          1687478400000,
          111699.99999
        ],
        [
          1687564800000,
          111699.99999
        ],
        [
          1687651200000,
          111699.99999
        ],
        [
          1687737600000,
          111699.99999
        ],
        [
          1687824000000,
          111699.99999
        ],
        [
          1687910400000,
          111699.99999
        ],
        [
          1687996800000,
          111699.99999
        ],
        [
          1688083200000,
          111699.99999
        ],
        [
          1688169600000,
          111699.99999
        ],
        [
          1688256000000,
          111699.99999
        ],
        [
          1688342400000,
          111699.99999
        ],
        [
          1688428800000,
          111699.99999
        ],
        [
          1688515200000,
          111699.99999
        ],
        [
          1688601600000,
          111699.99999
        ],
        [
          1688688000000,
          111699.99999
        ],
        [
          1688774400000,
          111699.99999
        ],
        [
          1688860800000,
          111699.99999
        ],
        [
          1688947200000,
          111699.99999
        ],
        [
          1689033600000,
          111699.99999
        ],
        [
          1689120000000,
          111699.99999
        ],
        [
          1689206400000,
          111699.99999
        ],
        [
          1689292800000,
          111699.99999
        ],
        [
          1689379200000,
          111699.99999
        ],
        [
          1689465600000,
          111699.99999
        ],
        [
          1689552000000,
          111699.99999
        ],
        [
          1689638400000,
          111699.99999
        ],
        [
          1689724800000,
          111699.99999
        ],
        [
          1689811200000,
          111699.99999
        ],
        [
          1689897600000,
          111699.99999
        ],
        [
          1689984000000,
          111699.99999
        ],
        [
          1690070400000,
          111699.99999
        ],
        [
          1690156800000,
          111699.99999
        ],
        [
          1690243200000,
          111699.99999
        ],
        [
          1690329600000,
          111699.99999
        ],
        [
          1690416000000,
          111699.99999
        ],
        [
          1690502400000,
          111699.99999
        ],
        [
          1690588800000,
          111699.99999
        ],
        [
          1690675200000,
          111699.99999
        ],
        [
          1690761600000,
          111699.99999
        ],
        [
          1690848000000,
          111699.99999
        ],
        [
          1690934400000,
          111699.99999
        ],
        [
          1691020800000,
          111699.99999
        ],
        [
          1691107200000,
          111699.99999
        ],
        [
          1691193600000,
          111699.99999
        ],
        [
          1691280000000,
          111699.99999
        ],
        [
          1691366400000,
          111699.99999
        ],
        [
          1691452800000,
          111699.99999
        ],
        [
          1691539200000,
          111699.99999
        ],
        [
          1691625600000,
          111699.99999
        ],
        [
          1691712000000,
          111699.99999
        ],
        [
          1691798400000,
          111699.99999
        ],
        [
          1691884800000,
          111699.99999
        ],
        [
          1691971200000,
          111699.99999
        ],
        [
          1692057600000,
          111699.99999
        ],
        [
          1692144000000,
          111699.99999
        ],
        [
          1692230400000,
          111699.99999
        ],
        [
          1692316800000,
          111699.99999
        ],
        [
          1692403200000,
          111699.99999
        ],
        [
          1692489600000,
          111699.99999
        ],
        [
          1692576000000,
          111699.99999
        ],
        [
          1692662400000,
          111699.99999
        ],
        [
          1692748800000,
          111699.99999
        ],
        [
          1692835200000,
          111699.99999
        ],
        [
          1692921600000,
          111699.99999
        ],
        [
          1693008000000,
          111699.99999
        ],
        [
          1693094400000,
          111699.99999
        ],
        [
          1693180800000,
          111699.99999
        ],
        [
          1693267200000,
          111699.99999
        ],
        [
          1693353600000,
          111699.99999
        ],
        [
          1693440000000,
          111699.99999
        ],
        [
          1693526400000,
          111699.99999
        ],
        [
          1693612800000,
          111699.99999
        ],
        [
          1693699200000,
          111699.99999
        ],
        [
          1693785600000,
          111699.99999
        ],
        [
          1693872000000,
          111699.99999
        ],
        [
          1693958400000,
          111699.99999
        ],
        [
          1694044800000,
          111699.99999
        ],
        [
          1694131200000,
          111699.99999
        ],
        [
          1694217600000,
          111699.99999
        ],
        [
          1694304000000,
          111699.99999
        ],
        [
          1694390400000,
          111699.99999
        ],
        [
          1694476800000,
          111699.99999
        ],
        [
          1694563200000,
          111699.99999
        ],
        [
          1694649600000,
          111699.99999
        ],
        [
          1694736000000,
          111699.99999
        ],
        [
          1694822400000,
          111699.99999
        ],
        [
          1694908800000,
          111699.99999
        ],
        [
          1694995200000,
          111699.99999
        ],
        [
          1695081600000,
          111699.99999
        ],
        [
          1695168000000,
          111699.99999
        ],
        [
          1695254400000,
          111699.99999
        ],
        [
          1695340800000,
          111699.99999
        ],
        [
          1695427200000,
          111699.99999
        ],
        [
          1695513600000,
          111699.99999
        ],
        [
          1695600000000,
          111699.99999
        ],
        [
          1695686400000,
          111699.99999
        ],
        [
          1695772800000,
          111699.99999
        ],
        [
          1695859200000,
          111699.99999
        ],
        [
          1695945600000,
          111699.99999
        ],
        [
          1696032000000,
          111699.99999
        ],
        [
          1696118400000,
          111699.99999
        ],
        [
          1696204800000,
          111699.99999
        ],
        [
          1696291200000,
          111699.99999
        ],
        [
          1696377600000,
          111699.99999
        ],
        [
          1696464000000,
          111699.99999
        ],
        [
          1696550400000,
          111699.99999
        ],
        [
          1696636800000,
          111699.99999
        ],
        [
          1696723200000,
          111699.99999
        ],
        [
          1696809600000,
          111699.99999
        ],
        [
          1696896000000,
          111699.99999
        ],
        [
          1696982400000,
          111699.99999
        ],
        [
          1697068800000,
          111699.99999
        ],
        [
          1697155200000,
          111699.99999
        ],
        [
          1697241600000,
          111699.99999
        ],
        [
          1697328000000,
          111699.99999
        ],
        [
          1697414400000,
          111699.99999
        ],
        [
          1697500800000,
          111699.99999
        ],
        [
          1697587200000,
          111699.99999
        ],
        [
          1697673600000,
          111699.99999
        ],
        [
          1697760000000,
          111699.99999
        ],
        [
          1697846400000,
          111699.99999
        ],
        [
          1697932800000,
          111699.99999
        ],
        [
          1698019200000,
          111699.99999
        ],
        [
          1698105600000,
          111699.99999
        ],
        [
          1698192000000,
          111699.99999
        ],
        [
          1698278400000,
          111699.99999
        ],
        [
          1698364800000,
          111699.99999
        ],
        [
          1698451200000,
          111699.99999
        ],
        [
          1698537600000,
          111699.99999
        ],
        [
          1698624000000,
          111699.99999
        ],
        [
          1698710400000,
          111699.99999
        ],
        [
          1698796800000,
          111699.99999
        ],
        [
          1698883200000,
          111699.99999
        ],
        [
          1698969600000,
          111699.99999
        ],
        [
          1699056000000,
          111699.99999
        ],
        [
          1699142400000,
          111699.99999
        ],
        [
          1699228800000,
          111699.99999
        ],
        [
          1699315200000,
          111699.99999
        ],
        [
          1699401600000,
          111699.99999
        ],
        [
          1699488000000,
          111699.99999
        ],
        [
          1699574400000,
          111699.99999
        ],
        [
          1699660800000,
          111699.99999
        ],
        [
          1699747200000,
          111699.99999
        ],
        [
          1699833600000,
          111699.99999
        ],
        [
          1699920000000,
          111699.99999
        ],
        [
          1700006400000,
          111699.99999
        ],
        [
          1700092800000,
          111699.99999
        ],
        [
          1700179200000,
          111699.99999
        ],
        [
          1700265600000,
          111699.99999
        ],
        [
          1700352000000,
          111699.99999
        ],
        [
          1700438400000,
          111699.99999
        ],
        [
          1700524800000,
          111699.99999
        ],
        [
          1700611200000,
          111699.99999
        ],
        [
          1700697600000,
          111699.99999
        ],
        [
          1700784000000,
          111699.99999
        ],
        [
          1700870400000,
          111699.99999
        ],
        [
          1700956800000,
          111699.99999
        ],
        [
          1701043200000,
          111699.99999
        ],
        [
          1701129600000,
          111699.99999
        ],
        [
          1701216000000,
          111699.99999
        ],
        [
          1701302400000,
          111699.99999
        ],
        [
          1701388800000,
          111699.99999
        ],
        [
          1701475200000,
          111699.99999
        ],
        [
          1701561600000,
          111699.99999
        ],
        [
          1701648000000,
          111699.99999
        ],
        [
          1701734400000,
          111699.99999
        ],
        [
          1701820800000,
          111699.99999
        ],
        [
          1701907200000,
          111699.99999
        ],
        [
          1701993600000,
          111699.99999
        ],
        [
          1702080000000,
          111699.99999
        ],
        [
          1702166400000,
          111699.99999
        ],
        [
          1702252800000,
          111699.99999
        ],
        [
          1702339200000,
          111699.99999
        ],
        [
          1702425600000,
          111699.99999
        ],
        [
          1702512000000,
          111699.99999
        ],
        [
          1702598400000,
          111699.99999
        ],
        [
          1702684800000,
          111699.99999
        ],
        [
          1702771200000,
          111699.99999
        ],
        [
          1702857600000,
          111699.99999
        ],
        [
          1702944000000,
          111699.99999
        ],
        [
          1703030400000,
          111699.99999
        ],
        [
          1703116800000,
          111699.99999
        ],
        [
          1703203200000,
          111699.99999
        ],
        [
          1703289600000,
          111699.99999
        ],
        [
          1703376000000,
          111699.99999
        ],
        [
          1703462400000,
          111699.99999
        ],
        [
          1703548800000,
          111699.99999
        ],
        [
          1703635200000,
          111699.99999
        ],
        [
          1703721600000,
          111699.99999
        ],
        [
          1703808000000,
          111699.99999
        ],
        [
          1703894400000,
          111699.99999
        ],
        [
          1703980800000,
          111699.99999
        ],
        [
          1704067200000,
          111699.99999
        ],
        [
          1704153600000,
          111699.99999
        ],
        [
          1704240000000,
          111699.99999
        ],
        [
          1704326400000,
          111699.99999
        ],
        [
          1704412800000,
          111699.99999
        ],
        [
          1704499200000,
          111699.99999
        ],
        [
          1704585600000,
          111699.99999
        ],
        [
          1704672000000,
          111699.99999
        ],
        [
          1704758400000,
          111699.99999
        ],
        [
          1704844800000,
          111699.99999
        ],
        [
          1704931200000,
          111699.99999
        ],
        [
          1705017600000,
          111699.99999
        ],
        [
          1705104000000,
          111699.99999
        ],
        [
          1705190400000,
          111699.99999
        ],
        [
          1705276800000,
          111699.99999
        ],
        [
          1705363200000,
          111699.99999
        ],
        [
          1705449600000,
          111699.99999
        ],
        [
          1705536000000,
          111699.99999
        ],
        [
          1705622400000,
          111699.99999
        ],
        [
          1705708800000,
          111699.99999
        ],
        [
          1705795200000,
          111699.99999
        ],
        [
          1705881600000,
          111699.99999
        ],
        [
          1705968000000,
          111699.99999
        ],
        [
          1706054400000,
          111699.99999
        ],
        [
          1706140800000,
          111699.99999
        ],
        [
          1706227200000,
          111699.99999
        ],
        [
          1706313600000,
          111699.99999
        ],
        [
          1706400000000,
          111699.99999
        ],
        [
          1706486400000,
          111699.99999
        ],
        [
          1706572800000,
          111699.99999
        ],
        [
          1706659200000,
          111699.99999
        ],
        [
          1706745600000,
          111699.99999
        ],
        [
          1706832000000,
          111699.99999
        ],
        [
          1706918400000,
          111699.99999
        ],
        [
          1707004800000,
          111699.99999
        ],
        [
          1707091200000,
          111699.99999
        ],
        [
          1707177600000,
          111699.99999
        ],
        [
          1707264000000,
          111699.99999
        ],
        [
          1707350400000,
          111699.99999
        ],
        [
          1707436800000,
          111699.99999
        ],
        [
          1707523200000,
          111699.99999
        ],
        [
          1707609600000,
          111699.99999
        ],
        [
          1707696000000,
          111699.99999
        ],
        [
          1707782400000,
          111699.99999
        ],
        [
          1707868800000,
          111699.99999
        ],
        [
          1707955200000,
          111699.99999
        ],
        [
          1708041600000,
          111699.99999
        ],
        [
          1708128000000,
          111699.99999
        ],
        [
          1708214400000,
          111699.99999
        ],
        [
          1708300800000,
          111699.99999
        ],
        [
          1708387200000,
          111699.99999
        ],
        [
          1708473600000,
          111699.99999
        ],
        [
          1708560000000,
          111699.99999
        ],
        [
          1708646400000,
          111699.99999
        ],
        [
          1708732800000,
          111699.99999
        ],
        [
          1708819200000,
          111699.99999
        ],
        [
          1708905600000,
          111699.99999
        ],
        [
          1708992000000,
          111699.99999
        ],
        [
          1709078400000,
          111699.99999
        ],
        [
          1709164800000,
          111699.99999
        ],
        [
          1709251200000,
          111699.99999
        ],
        [
          1709337600000,
          111699.99999
        ],
        [
          1709424000000,
          111699.99999
        ],
        [
          1709510400000,
          111699.99999
        ],
        [
          1709596800000,
          111699.99999
        ],
        [
          1709683200000,
          111699.99999
        ],
        [
          1709769600000,
          111699.99999
        ],
        [
          1709856000000,
          111699.99999
        ],
        [
          1709942400000,
          111699.99999
        ],
        [
          1710028800000,
          111699.99999
        ],
        [
          1710115200000,
          111699.99999
        ],
        [
          1710201600000,
          111699.99999
        ],
        [
          1710288000000,
          111699.99999
        ],
        [
          1710374400000,
          111699.99999
        ],
        [
          1710460800000,
          111699.99999
        ]
      ]
    ];
  }

  ngAfterViewInit() {
    this.processGraphInfo();
  }

  private drawChat() {
    Highcharts.stockChart("performanceLineGraph", this.options);
  }


  processGraphInfo() {
    console.log(JSON.stringify(`tes: ${this.performanceData}`))
    this.performanceData.forEach((data, i) => {
      this.seriesOptions[i] = {
        name: namesX[i].name,
        color: namesX[i].color,
        type: namesX[i].type,
        fillColor: namesX[i].fillColor,
        data: data,
        lineWidth: namesX[i].lineWidth,
      };
      // As we're loading the data asynchronously, we don't know what order it will arrive. So
      // we keep a counter and create the chart when all the data is loaded.
      this.seriesCounter += 1;

      // if (this.seriesCounter === this.performanceData.length) {
        this.drawChat();
      // }
    });
  }

}
