<app-single-goal (closeGoal)="closeGoalDetails()" *ngIf="singleGoal" [accountId]="accountId"
  [profileDetails]="profileDetails" [accountType]="accountType" [accountGoalId]="accountGoalId" [zIndex]="2">
</app-single-goal>

<app-edit-visual-id (close)="editIDView = false" *ngIf="editIDView"
  [names]="profileDetails.firstName + ' ' + profileDetails.lastName" [personalInfo]="profileDetails"
  [userName]="profileDetails.email"></app-edit-visual-id>
<edit-profile (close)="editProfileView = false" *ngIf="editProfileView"></edit-profile>
<app-edit-profile-income-source (close)="editProfileIncomeSource = false" *ngIf="editProfileIncomeSource"
  [profileEmail]="profileDetails.email" [profileInformation]="profileDetails">
</app-edit-profile-income-source>
<app-edit-profile-address (close)="editProfileAddress = false" *ngIf="editProfileAddress"
  [profileInformation]="profileDetails">

</app-edit-profile-address>

<app-file-preview (closeWindow)="closeFilePreview()" *ngIf="idPreview" [details]="profileDetails" [fileType]="fileType"
  [imageType]="imageType" [profileEmail]="profileDetails.email">
</app-file-preview>
<app-personal-information (closeWindow)="closePersonalEditWindow()" *ngIf="editPersonalView"
  [profileInformation]="profileDetails">
</app-personal-information>

<app-edit-profile-bank-details (close)="editProfileBank = false" *ngIf="editProfileBank" [accountId]="accountId"
  [profileBankInformation]="bankDetails" [userId]="profileDetails.email">
</app-edit-profile-bank-details>

<app-edit-profile-account-beneficiary (close)="editProfileAccountBeneficiary = false"
  *ngIf="editProfileAccountBeneficiary" [accountId]="accountId"
  [profileAccountBeneficiaryInformation]="beneficiaryDetails" [userId]="profileDetails.email">

</app-edit-profile-account-beneficiary>

<app-create-advisor (close)="reloadProfilesTable()" *ngIf="createProfileAdvisor" [advisorInfo]="advisorDetails"
  [userId]="profileDetails.email">
</app-create-advisor>

<app-edit-advisor (close)="reloadProfilesTable()" *ngIf="editProfileAdvisor" [advisorInfo]="advisorDetails"
  [userId]="profileDetails.email">
</app-edit-advisor>

<upload-advisors (close)="closeWindow()" *ngIf="uploadAdvisor">
</upload-advisors>

<app-kyc-revert (close)="kycRevert = false" *ngIf="kycRevert" [profileId]="profileDetails.id">
</app-kyc-revert>

<!-- Reset PIN Modal -->
<app-reset-pin (closeWindow)="closeResetPINWindow()" *ngIf="ResetPINView"
  [profileInformation]="profileDetails"></app-reset-pin>


<slideover (closeWindow)="closeWindow()" [zIndex]="zIndex">
  <div *ngIf="isLoading" class="spinner-container fill-container">
    <mat-spinner [diameter]="70" color="accent"></mat-spinner>
  </div>


  <mat-tab-group animationDuration="0ms" class="xn-mat-tab-label-container mat-tab-header">
    <mat-tab aria-label="profile" label="PROFILE">
      <div class="mat-tab-body-content">


        <mat-card>
          <div class="xn-row xn-n11">
            <div>
                <img (click)="showFilePreview('passport', 'Image')" [src]="passportUrl" alt="Selfie"
                     class="profile-img pointer" />

                <pdf-viewer (click)="showFilePreview('passport', 'PDF')" *ngIf="passportFileType == 'PDF'"
                            [autoresize]="true" [external-link-target]="'blank'" [original-size]="false" [render-text]="true"
                            [show-all]="true" [show-borders]="false" [src]="passportUrl"
                            style="width: 100%; height: 200px;"></pdf-viewer>
            </div>
            <div class="xn-column">
              <div class="d-flex">
                <div class="pt-3">
                     <span class="xn-title xn-black">
                    {{ profileDetails?.firstName | titlecase }}
                       {{ profileDetails?.lastName | titlecase }}
                  </span>
                </div>
                <div class="ms-auto"> <ng-container  *appSecAccess="['ROLE_COMPLIANCE', 'ROLE_SUPER_ADMIN']">
                  <ng-container *ngIf="profileDetails?.kycStatus?.toUpperCase() === 'SUBMITTED' ||
                      profileDetails?.kycStatus?.toUpperCase() === 'TODO' ||
                      profileDetails?.kycStatus?.toUpperCase() === 'EXTERNAL' ||
                      profileDetails?.kycStatus?.toUpperCase() === 'VERIFIED'">
                    <button mat-raised-button color="primary" class="pointer xd-spacing " [matMenuTriggerFor]="actions">Actions
                      <img src="assets/icons/down-chevron.svg" class="dropdown-chevron"></button>
                    <mat-menu #actions="matMenu">
                      <button mat-menu-item (click)="activate(profileDetails?.id)">Activate</button>
                      <button mat-menu-item (click)="deactivate(profileDetails?.id)">Deactivate</button>
                      <button mat-menu-item (click)="openRevertKyc(profileDetails?.id)">Reject</button>
                      <button mat-menu-item [matMenuTriggerFor]="edit">Edit</button>
                    </mat-menu>
                    <mat-menu #edit="matMenu">
                      <button mat-menu-item (click)="openEditIdView()">Identification</button>
                      <button mat-menu-item (click)="openPersonalEditWindow()">Personal Info</button>
                      <button mat-menu-item (click)="openProfileAddressEditWindow()">Address</button>
                      <ng-container *ngIf="profileDetails?.ussdPinActionRequest === true">
                        <ng-container>
                          <button mat-menu-item (click)="openResetPINView()">Reset PIN</button>
                        </ng-container>
                      </ng-container>
                    </mat-menu>

                  </ng-container>

                </ng-container></div>
              </div>

              <div class="xn-container pt-xxl-5 pt-xl-5 pt-lg-5 pt-md-5 pt-sm-5">
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">Created</p>
                  <p class="ntopx-12 xn-grey"> <span (click)="singleProfileAccountView = true"
                      *ngIf="profileDetails?.dateCreated">{{ profileDetails?.dateCreated | date }}</span>
                    <span *ngIf="profileDetails?.dateCreated == null"></span>
                  </p>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">Source</p>
                  <p class="ntopx-12 xn-grey"> <span *ngIf="profileDetails?.source">{{ profileDetails?.source }}</span>
                    <span *ngIf="profileDetails?.source == null"></span>
                  </p>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">KYC Status</p>
                  <p class="ntopx-12 xn-grey x-text"> <span *ngIf="profileDetails?.kycStatus" class="status-label {{
                         profileDetails?.kycStatus | lowercase
                  }}">{{profileDetails?.kycStatus | lowercase}}</span>
                    <span *ngIf="profileDetails?.kycStatus == null"></span>
                  </p>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">Can access dashboard</p>
                  <p class="ntopx-12 xn-grey x-text">
                    <span *ngIf="profileDetails?.status === true" class="status-label active">true</span>
                    <span *ngIf="profileDetails?.status === false" class="status-label disabled">false</span>

                  </p>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">Can login</p>
                  <p class="ntopx-12 xn-grey x-text">
                    <span *ngIf="profileDetails?.userStatus === true" class="status-label active">true</span>
                    <span *ngIf="profileDetails?.userStatus === false" class="status-label disabled">false</span>

                  </p>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">KYC Revert</p>
                  <p class="ntopx-12 xn-grey x-text">
                    <span *ngIf="profileDetails?.kycReverted === true" class="status-label active">true</span>
                    <span *ngIf="profileDetails?.kycReverted === false" class="status-label disabled">false</span>

                  </p>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">Funded</p>
                  <p class="ntopx-12 xn-grey x-text"> <span *ngIf="profileDetails?.transactionTotal > 0"
                      class="status-label funded">funded</span>
                    <span *ngIf="profileDetails?.transactionTotal <= 0" class="status-label not-funded">not
                      funded</span>
                  </p>
                </div>
              </div>


              <div>
                <hr class="xn-hr" />
              </div>
              <div class="xn-container">
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">Discovery details</p>
                  <p class="ntopx-12 xn-grey">
                    <span *ngIf="profileDetails?.discovery" class="xn-grey">{{ profileDetails?.discovery}}</span>
                    <span *ngIf="profileDetails?.discovery == null" class="xn-grey">N/a</span>
                  </p>
                  <br/>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">Network names</p>
                  <p class="ntopx-12 xn-grey">
                    <span *ngIf="networkName?.firstName && networkName?.lastName" class="xn-grey">{{
                      networkName?.firstName | titlecase }} {{networkName?.lastName | titlecase}}</span>
                    <span *ngIf="isCheckingNames" class="xn-grey">Loading...</span>
                    <span *ngIf="isCheckingNames == false && networkName?.firstName == null" class="xn-grey">N/a</span>
                  </p>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">Verified By</p>
                  <p class="ntopx-12 xn-grey">{{profileDetails?.verifiedBy}}</p>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm">Verified On</p>
                  <p class="ntopx-12 xn-grey">{{profileDetails?.verifiedOn | date}}</p>
                </div>

                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm"></p>
                  <p></p>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm"></p>
                  <p></p>
                </div>
                <div class="flex-item">
                  <p class="xn-title-sm u-text page-title-sm"></p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </mat-card>


        <br />
        <br />
        <div class="x-container">
          <div class="column">
            <div class="page-section">
              <div class="d-flex">
                <div class="p-2"><h2 class="page-subtitle">
                  <span>Identification</span>

                </h2></div>
                <div class="ms-auto p-2">
                   <span class="xn-left-16 x-text">
                  <span
                    class="status-label {{ setStatus(profileDetails?.itemKycStatus?.idStatus) | lowercase }} xn-font-size">
                      {{ setStatus(profileDetails?.itemKycStatus?.idStatus) | lowercase }}
                    </span></span>
                </div>
              </div>

              <table class="xn-table mat-elevation-z1 is-summary xn-mtop mat-radius xn-table-height367">
                <tbody>
                  <tr>
                    <td class="u-text">ID Type</td>
                    <td class="text-end">
                      <span *ngIf="profileDetails?.identificationType">{{ profileDetails?.identificationType }}</span>
                      <span *ngIf="profileDetails?.identificationType == null"></span>
                    </td>
                  </tr>
                  <tr>
                    <td class="u-text">ID Number</td>
                    <td *ngIf="profileDetails?.identificationNumber"  class="text-end">{{ profileDetails?.identificationNumber }}</td>
                    <td *ngIf="profileDetails?.identificationNumber == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">ID File</td>
                    <td>
                      <p>
                        <button (click)="showFilePreview('id', 'Image')" *ngIf="idFileType == 'Image'"
                          class="image-button id-img-container">
                          <img [src]="idUrl" alt="Id File" class="img"/>
                        </button>
                        <pdf-viewer (click)="showFilePreview('id', 'PDF')" *ngIf="idFileType == 'PDF'"
                          [autoresize]="true" [external-link-target]="'blank'" [original-size]="false"
                          [render-text]="true" [show-all]="true" [show-borders]="false" [src]="idUrl"
                          style="width: 100%; height: 200px;"></pdf-viewer>
                      </p>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
          </div>
          <div class="column">
            <div class="page-section">
              <div class="d-flex">
                <div class="p-2"><h2 class="page-subtitle">
                  <span>Personal Information</span>

                </h2></div>
                <div class="ms-auto p-2">
                   <span class="xn-left-16 x-text"><span
                     class="status-label {{ setStatus(profileDetails?.itemKycStatus?.bioStatus) | lowercase }} xn-font-size">
                      {{ setStatus(profileDetails?.itemKycStatus?.bioStatus) | lowercase }}
                    </span></span>
                </div>
              </div>


              <table class="xn-table mat-elevation-z1 is-summary xn-mtop mat-radius xn-table-height367">
                <tbody>
                  <tr>
                    <td class="u-text">Gender</td>
                    <td *ngIf="profileDetails?.gender"  class="text-end">{{ profileDetails?.gender }}</td>
                    <td *ngIf="profileDetails?.gender == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">Email</td>
                    <td *ngIf="profileDetails?.email"  class="text-end">{{ profileDetails?.email }}</td>
                    <td *ngIf="profileDetails?.email == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">TIN</td>
                    <td *ngIf="profileDetails?.tinNumber"  class="text-end">{{ profileDetails?.tinNumber }}</td>
                    <td *ngIf="profileDetails?.tinNumber == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">Nationality</td>
                    <td *ngIf="profileDetails?.nationality"  class="text-end">{{ profileDetails?.nationality }}</td>
                    <td *ngIf="profileDetails?.nationality == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">Country Code</td>
                    <td *ngIf="profileDetails?.countryCode"  class="text-end">{{ profileDetails?.countryCode }}</td>
                    <td *ngIf="profileDetails?.countryCode == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">Phone Number</td>
                    <td *ngIf="profileDetails?.phoneNumber"  class="text-end">{{ profileDetails?.phoneNumber }}</td>
                    <td *ngIf="profileDetails?.phoneNumber == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">Profile Phone Number</td>
                    <td *ngIf="profileDetails?.profilePhoneNumber"  class="text-end">{{ profileDetails?.profilePhoneNumber }}</td>
                    <td *ngIf="profileDetails?.profilePhoneNumber == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">Date of Birth</td>
                    <td *ngIf="profileDetails?.dateOfBirth"  class="text-end">{{ profileDetails?.dateOfBirth | date }}</td>
                    <td *ngIf="profileDetails?.dateOfBirth == null"></td>
                  </tr>


                </tbody>
              </table>
            </div>
          </div>
        </div>

        <br />
        <div class="x-container">
          <div class="column">
            <div class="page-section">

              <div class="d-flex">
                <div class="p-2"><h2 class="page-subtitle">
                  <span>Address</span>

                </h2></div>
                <div class="ms-auto p-2">
                   <span class="xn-left-16">
                    <span
                      class="status-label {{ setStatus(profileDetails?.itemKycStatus?.addressStatus) | lowercase }} xn-font-size x-text">
                      {{ setStatus(profileDetails?.itemKycStatus?.addressStatus) | lowercase }}
                    </span></span>
                </div>
              </div>

              <table class="xn-table mat-elevation-z1 is-summary xn-top-9n mat-radius">
                <tbody>
                  <tr>
                    <td class="u-text">Country</td>
                    <td *ngIf="profileDetails?.address?.country"  class="text-end">{{ profileDetails?.address?.country }}</td>
                    <td *ngIf="profileDetails?.address?.country == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">Region / Province / State</td>
                    <td *ngIf="profileDetails?.address?.region"  class="text-end">{{ profileDetails?.address?.region }}</td>
                    <td *ngIf="profileDetails?.address?.region == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">City / District</td>
                    <td *ngIf="profileDetails?.address?.district"  class="text-end">{{ profileDetails?.address?.district }}</td>
                    <td *ngIf="profileDetails?.address?.district == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">Street / Road</td>
                    <td *ngIf="profileDetails?.address?.street"  class="text-end">{{ profileDetails?.address?.street }}</td>
                    <td *ngIf="profileDetails?.address?.street == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">Apartment / Suite / Plot Number</td>
                    <td *ngIf="profileDetails?.address?.houseNumber"  class="text-end">{{ profileDetails?.address?.houseNumber }}</td>
                    <td *ngIf="profileDetails?.address?.houseNumber == null"></td>
                  </tr>
                  <tr>
                    <td class="u-text">ZIP / Post</td>
                    <td *ngIf="profileDetails?.address?.boxNumber"  class="text-end">{{ profileDetails?.address?.boxNumber }}</td>
                    <td *ngIf="profileDetails?.address?.boxNumber == null"></td>
                  </tr>

                </tbody>
              </table>


            </div>
          </div>
          <div class="column">
            <div class="page-section">
              <div class="d-flex">
                <div class="p-2"><h2 class="page-subtitle">
                  <span>Income Source</span>

                </h2></div>
                <div class="ms-auto p-2">
                   <span class="xn-left-16 x-text"><span
                     class="status-label {{ setStatus(profileDetails?.itemKycStatus?.incomeStatus) | lowercase }} xn-font-size">
                      {{ setStatus(profileDetails?.itemKycStatus?.incomeStatus) | lowercase }}
                    </span></span>
                </div>
              </div>
              <!-- income sources -->
              <mat-card class="xn-n9">
                <table class="xn-table mat-elevation-z1 no-table-hover xn-top-9n">
                  <thead>
                    <tr>
                      <th class="u-text">Type</th>
                      <th class="u-text">Name</th>
                      <th class="u-text">Amount ({{tenantCurrency}})</th>
                      <th class="u-text">Monthly Investment ({{tenantCurrency}})</th>
                    </tr>
                  </thead>

                  <tbody>
                    <ng-container *ngIf="profileDetails?.incomeSources">
                      <tr *ngFor="let income of profileDetails?.incomeSources">
                        <td>
                          {{ income?.source }}
                        </td>
                        <td>{{ income?.employer }}</td>
                        <td>{{tenantCurrency}} {{ income?.monthlyIncome }}</td>
                        <td>{{tenantCurrency}} {{ income?.expectedMonthlyIncome }}</td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="profileDetails?.incomeSources?.length == 0 || profileDetails?.incomeSources == null">
                      <td colspan="4">
                        <div class="xn-center-content mt-3">
                          <div>
                            <div align="center">
                              <img alt="warning" src="./assets/icons/file-warning.svg" />
                            </div>

                            <p>
                              No income source found
                            </p>
                          </div>



                        </div>

                      </td>
                    </tr>

                  </tbody>
                </table>
              </mat-card>
            </div>

          </div>
        </div>
        <br />

        <!--    Toggle Email Subscription-->
        <div *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']" class="page-section">
          <table class="xn-table mat-elevation-z8 is-summary">
            <tbody>
              <tr>
                <td> <b>Email Subscription</b></td>
                <td>
                  <mat-slide-toggle (change)="onEmailSubscriptionChange($event)"
                    (click)="onEmailSubscriptionClick($event)" (keyup.space)="onEmailSubscriptionChange($event)"
                    [(ngModel)]="emailSubscriptionCheck">
                  </mat-slide-toggle>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />
        <div class="xn-x-container xn-top10n">
          <div class="page-section xn-width xn-top10n">
            <div class="u-justify is-table-header">
              <h2 class="page-subtitle xn-b33">XENO Advisor
              </h2>
              <div *appSecAccess="['ROLE_SUPERVISOR', 'ROLE_SUPER_ADMIN']">
                <button [matMenuTriggerFor]="menu" aria-expanded="false" aria-label="xeno menu dropdown"
                  class="btn btn-primary dropdown-toggle" data-bs-auto-close="false" data-bs-toggle="dropdown"
                  type="button">
                  Actions
                </button>
                <mat-menu #menu="matMenu">
                  <ng-container>
                    <button (click)="assignAdvisor(profileDetails.email)"
                      *appSecAccess="['ROLE_SUPERVISOR', 'ROLE_SUPER_ADMIN']" mat-menu-item>
                      <span>Assign Advisor</span>
                    </button>
                    <!-- <button (click)="uploadAdvisors()" *appSecAccess="['ROLE_SUPER_ADMIN']" mat-menu-item>
                      <span>Upload Advisor</span>
                    </button> -->
                  </ng-container>
                </mat-menu>
              </div>
            </div>

            <table class="xn-table mat-elevation-z1 no-table-hover">
              <thead>
                <tr>
                  <th class="u-text">Advisor Name</th>
                  <th class="u-text">Roles</th>
                  <th class="u-text">Departments</th>
                  <th class="u-text">Actions</th>
                </tr>
              </thead>

              <tbody *ngFor="let advisor of profileDetails?.advisors">
                <tr>
                  <td>
                    <p>
                      <span class="u-block">{{ advisor?.firstName | titlecase }}
                        {{ advisor?.lastName | titlecase }}</span>
                    </p>
                  </td>
                  <td>{{ advisor?.roles.join(", ")}}</td>
                  <td>{{ advisor?.departments.join(", ") }}</td>
                  <td>
                    <ng-container *appSecAccess="['ROLE_SUPERVISOR', 'ROLE_SUPER_ADMIN']">
                      <button [matMenuTriggerFor]="menu" mat-button>
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button (click)="openAdvisorWindow(advisor, profileDetails?.email)" mat-menu-item>Edit</button>
                      </mat-menu>
                    </ng-container>
                  </td>
                </tr>
              </tbody>

              <tbody *ngIf="isVisible">
                <tr>
                  <td colspan="4">
                    <div class="xn-center-content">
                      <div>
                        <div align="center">
                          <img alt="warning" class="xn-icon-size" src="./assets/icons/file-warning.svg" />

                        </div>
                        <div>
                          <p>
                            No advisors available
                          </p>
                        </div>

                      </div>



                    </div>

                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

      </div>
    </mat-tab>
    <mat-tab label="ACCOUNTS">
      <div class="mat-tab-body-content">
        <!-- accounts -->
        <div class="page-section">
          <ng-container>
            <ng-container>

              <xn-tabs format="underline width-auto">
                <xn-tab label="Active Accounts">
                  <div *ngFor="let account of activeAccounts" class="u-ptb-24 u-fadeInY">
                    <div *ngIf="account.hide === false" class="account-wrap">
                      <div class="u-justify">
                        <div class="account-header">
                          <h3>
                            <span>{{ account.accountName | titlecase }}</span>
                          </h3>
                          <div *ngIf="account.accountType === 'POOLED' ||
             account.accountType === 'SPONSOR' || account.accountType === 'LINKED'">
                            <p><span style="font-weight: bold">Institution
                                Name:</span>{{account.institutionName}}&nbsp;&nbsp;<span
                                style="font-weight: bold">Sponsor Code:</span>{{account.sponsorCode}}&nbsp;&nbsp;
                              <span style="font-weight: bold">Type:</span>{{ account.accountType |
                              titlecase}}&nbsp;&nbsp;
                              <span style="font-weight: bold">No.</span>{{ account.accountNumber}}&nbsp;&nbsp;
                            </p>
                          </div>
                          <p *ngIf="account.accountType === 'PERSONAL' ||
       account.accountType === 'JOINT'" class="pointer">
                            {{ account.accountType | titlecase }}, {{ account.accountNumber }}
                            <span *ngIf="account.hide === false"> Status: <span class="status-label active">
                                ACTIVE</span>
                            </span>
                            <span *ngIf="account.hide === true">Status: <span class="status-label inactive">
                                INACTIVE</span>
                            </span>
                            <span *ngIf="account.accountType === 'JOINT'">Members:<span>[{{ account.jointAccountMembers
                                }}]</span></span>
                          </p>
                        </div>

                        <div *appSecAccess="['ROLE_COMPLIANCE', 'ROLE_SUPER_ADMIN']">
                          <div class="float-right">
                            <div aria-label="Button group with nested dropdown" class="btn-group" role="group">
                              <div class="btn-group" role="group">
                                <button aria-expanded="false" class="btn btn-primary dropdown-toggle xd-spacing "
                                  data-bs-toggle="dropdown" type="button">
                                  Actions
                                </button>
                                <ul class="dropdown-menu">
                                  <ng-container *ngIf="account.hide == true">
                                    <li><a (click)="activateProfileAccount(account.accountId)"
                                        class="dropdown-item pointer">Enable Account</a></li>
                                  </ng-container>
                                  <ng-container *ngIf="account.hide == false">
                                    <li><a (click)="deactivateProfileAccount(account.accountId)"
                                        class="dropdown-item pointer">Disable Account</a></li>
                                  </ng-container>
                                  <ng-container *ngIf="account.canManageBankAccounts == true">
                                    <li><a (click)="canManageBankAccounts(account.accountId, false)"
                                        class="dropdown-item pointer">Disable Manage Bank Accounts</a></li>
                                  </ng-container>
                                  <ng-container *ngIf="account.canManageBankAccounts == false">
                                    <li><a (click)="canManageBankAccounts(account.accountId, true)"
                                        class="dropdown-item pointer">Enable Manage Bank Accounts</a></li>
                                  </ng-container>
                                  <li *ngIf="account.accountType ==='JOINT'">
                                    <a class="dropdown-item pointer" (click)="removePartner(profileDetails.id, account.accountId)">Remove Partner</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <table class="xn-table mat-elevation-z8 is-fixed">
                        <thead>
                          <tr>
                            <th>Goal</th>
                            <th>Number</th>
                            <th>Net Invested</th>
                            <th>Current Value</th>
                            <!--                <th>Last Deposit Date</th>-->
                            <th>Status</th>
                            <th>Withdraw Lock</th>
                            <th>Active AutoSaves</th>
                            <th *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let accountGoal of account.accountGoals">
                            <td>
                              <a (click)="showGoalDetails(accountGoal.id, account.accountId, account.accountType)">{{
                                accountGoal.goalTitle | titlecase }}</a>
                            </td>
                            <td>{{ accountGoal.goalNumber }}</td>
                            <td>{{tenantCurrency}} {{ accountGoal.netInvested | number: "1.0-0" }}</td>
                            <td>{{tenantCurrency}} {{ accountGoal.currentValue | number: "1.0-0" }}</td>
                            <!--                <td><span *ngIf="accountGoal.lastDepositDate">{{accountGoal.lastDepositDate | date}}</span>-->
                            <!--                <span *ngIf="accountGoal.lastDepositDate == null"></span>-->
                            <!--                </td>-->
                            <td>
                              <span *ngIf="accountGoal.disabled === true" class="status-label disabled">Disabled</span>
                              <span *ngIf="accountGoal.disabled === false" class="status-label active">Enabled</span>
                            </td>
                            <td>
                              <span *ngIf="accountGoal.isWithdrawalLocked === true"
                                class="status-label active">True</span>
                              <span *ngIf="accountGoal.isWithdrawalLocked === false"
                                class="status-label disabled">False</span>
                            </td>
                            <td>
                              {{accountGoal.autosaveCount}}
                            </td>
                            <td *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                              <ng-container>
                                <button [matMenuTriggerFor]="menu" mat-button>
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                  <button (click)="enableAccountGoal(accountGoal.id)" mat-menu-item>Enable</button>
                                  <button (click)="disableAccountGoal(accountGoal.id)" mat-menu-item>Disable</button>
                                  <button (click)="setWithdrawLockForGoal(accountGoal.id)"
                                    *ngIf="accountGoal.isWithdrawalLocked === false" mat-menu-item>Set Withdraw Lock
                                  </button>
                                  <button (click)="removeWithdrawLockForGoal(accountGoal.id)"
                                    *ngIf="accountGoal.isWithdrawalLocked === true" mat-menu-item>Remove Withdraw Lock
                                  </button>
                                </mat-menu>
                              </ng-container>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </xn-tab>
                <xn-tab label="Inactive Accounts" style="padding-bottom: 6px;">
                  <div *ngFor="let account of inActiveAccounts" class="u-ptb-24 u-fadeInY">
                    <div *ngIf="account.hide === true" class="account-wrap">
                      <div class="u-justify">
                        <div class="account-header">
                          <h3>
                            <span>{{ account.accountName | titlecase }}</span>
                          </h3>
                          <div *ngIf="account.accountType === 'POOLED' ||
             account.accountType === 'SPONSOR'  || account.accountType === 'LINKED'">
                            <p (click)="goToAccount()" class="pointer"><span style="font-weight: bold">Institution
                                Name:</span> {{account.institutionName}}<span style="font-weight: bold">Sponsor
                                Code:</span>
                              &nbsp;&nbsp;&nbsp; {{account.sponsorCode}}
                              <span style="font-weight: bold">Type:</span> &nbsp;&nbsp;&nbsp; {{ account.accountType |
                              titlecase }}

                              <span style="font-weight: bold">No.</span> {{ account.accountNumber }}
                            </p>
                          </div>
                          <p *ngIf="account.accountType === 'PERSONAL' ||
             account.accountType === 'JOINT'" class="pointer">
                            {{ account.accountType | titlecase }}, {{ account.accountNumber }}
                          </p>
                          <p *ngIf="account.hide === false" class="pointer">
                            Status: <span class="status-label active"> ACTIVE</span>
                          </p>
                          <p *ngIf="account.hide === true" class="pointer">
                            Status: <span class="status-label inactive"> INACTIVE</span>
                          </p>
                        </div>
                        <div *ngIf="account.hide == true">
                          <div *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                            <button (click)="activateProfileAccount(account.accountId)" color="primary" mat-button
                              mat-flat-button>
                              Enable
                            </button>
                          </div>
                        </div>
                        <div *ngIf="account.hide == false">
                          <div *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                            <button (click)="deactivateProfileAccount(account.accountId)" color="primary" mat-button
                              mat-flat-button>
                              Disable
                            </button>
                          </div>
                        </div>

                      </div>
                      <table class="xn-table mat-elevation-z8 is-fixed">
                        <thead>
                          <tr>
                            <th>Goal</th>
                            <th>Number</th>
                            <th>Net Invested</th>
                            <th>Current Value</th>
                            <!--                <th>Last Deposit Date</th>-->
                            <th>Status</th>
                            <th *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let accountGoal of account.accountGoals">
                            <td>
                              <a (click)="showGoalDetails(accountGoal.id, account.accountId, account.accountType)">{{
                                accountGoal.goalTitle | titlecase }}</a>
                            </td>
                            <td>{{ accountGoal.goalNumber }}</td>
                            <td>{{tenantCurrency}} {{ accountGoal.netInvested | number: "1.0-0" }}</td>
                            <td>{{tenantCurrency}} {{ accountGoal.currentValue | number: "1.0-0" }}</td>
                            <!--                <td><span *ngIf="accountGoal.lastDepositDate">{{accountGoal.lastDepositDate | date}}</span>-->
                            <!--                <span *ngIf="accountGoal.lastDepositDate == null"></span>-->
                            <!--                </td>-->
                            <td>
                              <span *ngIf="accountGoal.disabled === true" class="status-label disabled">Disabled</span>
                              <span *ngIf="accountGoal.disabled === false" class="status-label active">Enabled</span>
                            </td>
                            <td *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                              <ng-container>
                                <button [matMenuTriggerFor]="menu" mat-button>
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                  <button (click)="enableAccountGoal(accountGoal.id)" mat-menu-item>Enable</button>
                                  <button (click)="disableAccountGoal(accountGoal.id)" mat-menu-item>Disable</button>
                                </mat-menu>
                              </ng-container>

                            </td>
                          </tr>


                        </tbody>
                      </table>
                    </div>
                  </div>
                </xn-tab>
              </xn-tabs>
            </ng-container>
          </ng-container>
        </div>

        <!-- beneficiary -->
        <div class="page-section x-topn_66">
          <h2 class="page-subtitle">Beneficiaries</h2>
          <table class="xn-table mat-elevation-z8 no-table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Relationship</th>
                <th>Percentage</th>
                <th *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">Actions</th>
              </tr>
            </thead>
            <ng-container *ngFor="let account of profileDetails?.xenoAccounts">
              <tbody>
                <ng-container *ngFor="let beneficiary of account?.beneficiaries">
                  <tr>
                    <td>{{ beneficiary?.firstName }} {{ beneficiary?.lastName }}</td>
                    <td>{{ beneficiary?.telephone }}</td>
                    <td>{{ beneficiary?.email }}</td>
                    <td>{{ beneficiary?.relationShip }}</td>
                    <td>{{ beneficiary?.percentage }}%</td>
                    <td>
                      <ng-container *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                        <button [matMenuTriggerFor]="menu" mat-button>
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button (click)="openAccountBeneficiaryEditWindow(beneficiary, account?.accountId)"
                            mat-menu-item>
                            Edit
                          </button>
                          <button (click)="deleteBeneficiary(beneficiary?.id)" mat-menu-item>Delete</button>
                        </mat-menu>
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </ng-container>

            <ng-container>
              <tr *ngIf="isNoBeneficiary">
                <td colspan="6">
                  <div class="xn-center-content">
                    <div>
                      <div align="center">
                        <img alt="warning" class="xn-icon-size" src="./assets/icons/file-warning.svg" />
                      </div>
                      <div>
                        <p>
                          No beneficiaries available
                        </p>
                      </div>

                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>

          </table>
          <div *ngIf="isLoading" class="spinner-container fill-container">
            <mat-spinner [diameter]="70" color="accent"></mat-spinner>
          </div>
        </div>

        <!-- banking -->
        <div class="page-section">
          <h2 class="page-subtitle">Bank Accounts</h2>

          <table class="xn-table mat-elevation-z8 no-table-hover">
            <thead>
              <tr>
                <th>XENO Account</th>
                <th>Bank Name</th>
                <th>Bank Account Name</th>
                <th>Bank Account Number</th>
                <th>SWIFT/IBAN Code</th>
                <th>Branch</th>
                <th>Actions</th>
              </tr>
            </thead>
            <ng-container *ngFor="let account of profileDetails?.xenoAccounts">
              <tbody>
                <ng-container *ngFor="let bank of account?.bankAccounts">
                  <tr>
                    <td>
                      <p>
                        <span class="u-block">{{ account?.accountName }}</span>
                        <span class="u-block u-txt-small">{{
                          account?.accountNumber
                          }}</span>
                      </p>
                    </td>
                    <td>{{ bank?.bankName }}</td>
                    <td>{{ bank?.accountName }}</td>
                    <td>{{ bank?.accountNumber }}</td>
                    <td>{{ bank.bankCode }}
                      <span *ngIf="bank?.bankCode == null"></span>
                    </td>
                    <td>{{ bank?.branchName }}</td>
                    <td>
                      <ng-container *appSecAccess="['ROLE_SUPER_ADMIN', 'ROLE_COMPLIANCE']">
                        <button [matMenuTriggerFor]="menu" mat-button>
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button (click)="openBankEditWindow(bank, account?.accountId)" mat-menu-item>Edit</button>
                        </mat-menu>
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </ng-container>
            <ng-container *ngIf="isNoBankAccount">
              <tr>
                <td colspan="7">
                  <div class="xn-center-content">
                    <div>
                      <div align="center">
                        <img alt="warning" class="xn-icon-size" src="./assets/icons/file-warning.svg" />

                      </div>
                      <div>
                        <p>
                          No bank accounts available
                        </p>
                      </div>

                    </div>


                  </div>

                </td>
              </tr>
            </ng-container>


          </table>
        </div>



      </div>
    </mat-tab>
  </mat-tab-group>


</slideover>