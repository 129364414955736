import {Component, OnInit, Input, OnChanges} from '@angular/core';

@Component({
  selector: "app-progress",
  templateUrl: "./progress.component.html",
  styleUrls: ["./progress.component.scss"],
})
export class ProgressComponent implements OnInit, OnChanges {
  @Input() class: string;
  @Input() progress: string = "";

  width = 70;
  strokeWidth = 6;
  radius = this.width / 2 - this.strokeWidth * 2;
  circumference = this.radius * 2 * Math.PI;
  dashArray = "" + this.circumference + " " + this.circumference;
  dashOffset = this.circumference;
  viewBox = "0 0 " + this.width + " " + this.width;

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      this.dashOffset =
        this.circumference - (parseFloat(this.progress)) * this.circumference;
    }, 250);


  }




  ngOnChanges(){
    this.dashOffset =
      this.circumference - (parseFloat(this.progress)) * this.circumference;
    console.log(`dashoffset: ${this.dashOffset}`)
  }
}
