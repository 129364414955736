import {Injectable} from "@angular/core";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DisableProfileService {

  private apiUrl = `${environment.serverUrl}/api/v1/accounts`;
  private apiUrlGuest = `${environment.serverUrl}/api/v1/guest`;
  private apiUrlRoot = `${environment.serverUrl}/api/v1`;

  constructor(private http: HttpClient) {}


  disableProfile(profileId: string): Observable<any> {
    return this.http.post(`${this.apiUrlRoot}/users/deactivate/${profileId}`, {});
  //   return of({
  //     "msg": "mutyabakrista2016@gmail.com"
  //   });
  }
}
