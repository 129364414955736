<slideover [zIndex]="zIndex" (closeWindow)="closeWindow()">
  <div>
    <h1 class="page-title">Advisor Assignment</h1>
    <br />
    <div class=" xn-fadein-up xn-delay-2">
      <mat-card>
        <mat-card-content>
            <div class="batch-trans-form">
                <h2 *ngIf="!isFileSelected">Upload CSV File</h2>
                <div class="choosen-file" *ngIf="isFileSelected">
                  <span class="file-name">Filename:</span>
                  <span>{{fileName}}</span>
                </div>
                <div *ngIf="!isFileSelected" class="uploadfilecontainer" (click)="getFile.click()" appDragDrop
                  (onFileDropped)="onFileChange($event)">
                  <div class="upload-text">
                    <div class="upload-icon-bg">
                      <mat-icon [inline]="true">cloud_upload</mat-icon>
                    </div>
                    <div>Drag and Drop</div>
                    <div>or <span class="browse-text">Browse</span></div>
  
                  </div>
                  <input hidden type="file" #getFile (change)="onFileChange($event)" />
                </div>
              </div>
  
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</slideover>
  <!-- Checking Validating loader -->
  <div class="loading-spinner-container" *ngIf="isLoading">
    <div class="spinner-container">
      <mat-spinner color="accent"></mat-spinner>
      <span class="spinner-text">
        Validating data...
      </span>
    </div>
  </div>
  <!-- Updating transactions loader -->
  <div class="loading-spinner-container" *ngIf="isUpdating">
    <div class="spinner-container">
      <mat-spinner color="accent"></mat-spinner>
      <span class="spinner-text">
        Assigning advisors...
      </span>
    </div>
    </div>
