import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrl: './country-selector.component.scss',
})
export class CountrySelectorComponent implements OnInit{

  @Input() zIndex: number;
  @Input() size: string;
  @Output() closeWindow = new EventEmitter();

  countries = [
    { "name": "Uganda", "cName":"Ugandan", "code": "UG" },
    { "name": "Kenya", "cName":"Kenyan", "code": "KE" }
  ]

  constructor(
    private keycloakService: KeycloakService
  ){}

  ngOnInit(){

  }

  closeCenterModal() {
    this.closeWindow.emit();
  }

  switchCountry(countryCode) {
    localStorage.removeItem('tenantId');
    localStorage.setItem('roles', JSON.stringify(this.keycloakService.getKeycloakInstance().realmAccess.roles))
    localStorage.setItem('tenantId', countryCode);
    location.reload();
  }
 }
