import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import {catchError, finalize, map, mergeMap, tap} from "rxjs/operators";
import {UserAdvisor} from "@orion/models/user_advisor";
import {DashboardService} from "@orion/services/dashboard.service";
import {ProfilesService} from "@orion/services/profiles.service";
import {ProfileService} from "@orion/services/profile.service";

export class DashboardDataSource implements DataSource<UserAdvisor> {
  private userAdvisorSubject = new BehaviorSubject<UserAdvisor[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private totalAUMSubject = new BehaviorSubject(0);
  private averageAumSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();
  public totalAUM = this.totalAUMSubject.asObservable();
  public averageAum = this.averageAumSubject.asObservable();

  constructor(private dashboardService: DashboardService, private profileService: ProfileService) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<UserAdvisor[] | ReadonlyArray<UserAdvisor>> {
    return this.userAdvisorSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.userAdvisorSubject.complete();
    this.loadingSubject.complete();
  }

  loadDashboardData(
    offset: any,
    max: any,
    sort: string,
    order: string,
    page: number,
    filter = ''
  ) {
    this.loadingSubject.next(true);
    this.dashboardService
      .getAll(offset, max, sort, order, page, filter)
      .pipe(
        catchError(() => of({ data: [], totalCount: 0, totalAum: 0, averageAum: 0 })),
        finalize(() => this.loadingSubject.next(false)),
        tap((resp) => {
          this.userAdvisorSubject.next(resp.data);
          this.resultsLengthSubject.next(resp.totalCount);
          this.totalAUMSubject.next(resp.totalAum);
          this.averageAumSubject.next(resp.averageAum);
        }),
        mergeMap((resp) => {
          const avatarRequests = resp.data.map(user => this.fetchUserAvatar(user.email, this.userAdvisorSubject));
          return combineLatest([of(resp.data), combineLatest(avatarRequests)]);
        })
      )
      .subscribe(([userData, avatarUrls]) => {
        userData.forEach((user: any, index: any) => {
          user.avatarUrl = avatarUrls[index];
        });
        this.userAdvisorSubject.next(userData);
      });
  }

  private fetchUserAvatar(email: string, subject: BehaviorSubject<any[]>): Observable<string> {
    const usersDataSnapshot = subject.value;
    const userIndex = usersDataSnapshot.findIndex((e) => e.email === email);
    if (userIndex !== -1) {
      usersDataSnapshot[userIndex].loadingAvatar = true;
      subject.next(usersDataSnapshot);
    }

    return this.profileService.getPassportImage(email).pipe(
      map(resp => URL.createObjectURL(resp)),
      tap((imageUrl) => {
        if (userIndex !== -1) {
          usersDataSnapshot[userIndex].avatarUrl = imageUrl;
          usersDataSnapshot[userIndex].loadingAvatar = false;
          subject.next(usersDataSnapshot);
        }
      }),
      catchError(error => {
        console.error('Error fetching avatar:', error);
        if (userIndex !== -1) {
          usersDataSnapshot[userIndex].loadingAvatar = false;
          subject.next(usersDataSnapshot);
        }
        return of(null); // Return null if avatar fetch fails
      })
    );
  }

}
