<slideover [zIndex]="2" (closeWindow)="closeResetPINWindow()">
    <div class="page-inner-container">

        <div class="page-section">
            <h2 class="page-subtitle huge">Reset USSD PIN - {{profileInformation.lastName}}
                {{profileInformation.firstName}}</h2>
            <div class="error" *ngIf="isError">
                <p>{{message}}</p>
            </div>
            <form class="edit-user-form" [formGroup]="resetPINForm">
                <div class="fields_PIN">
                    <span>Incident number: </span>
                    <div>
                        <div>
                            <mat-form-field appearance="outline">
                                <input type="text" formControlName="ticketNumber" placeholder="Incident #14321" required matInput />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="fields_PIN">
                    <span>Client's reason:</span>

                    <mat-form-field appearance="outline">
                        <textarea formControlName="comments" rows="10" required placeholder="The reason given by the client for USSD PIN reset" matInput>

          </textarea>
                    </mat-form-field>
                </div>
                <div class="u-justify actions_PIN">
                    <span class="active_user">USSD PIN generated by: {{currentUser.lastName}} {{currentUser.firstName}}</span>
                    <button type="button" [disabled]="resetPINForm.invalid" mat-button mat-flat-button (click)="generateUSSDPIN()" color="primary">
                        Generate USSD PIN
                    </button>
                </div>
            </form>
        </div>
    </div>
</slideover>
