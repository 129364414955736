import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Account} from "@orion/models";

@Component({
  selector: 'app-edit-representatives',
  templateUrl: './edit-representatives.component.html',
  styleUrls: ['./edit-representatives.component.scss']
})
export class EditRepresentativesComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() accountDetails: Account;
  constructor() { }

  ngOnInit(): void {
  }

}
