import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AccountService} from '../../services/account.service';
import {Account, LinkedAccountMember, Profile} from '../../models';
import {ProfileService} from '../../services/profile.service';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {AuthenticationService} from '@orion/services/authentication.service';


@Component({
  selector: 'app-single-account',
  templateUrl: './single-account.component.html',
  styleUrls: ['./single-account.component.scss'],
})
export class SingleAccountComponent implements OnInit {
  constructor(private router: Router,
    private accountService: AccountService,
    private profileService: ProfileService,
    private authenticationService: AuthenticationService,
    private utilities: UtilitiesService) {
  }

  @Input() id: string;
  goalDetailsView = false;
  singleProfile = false;
  idPreview = false;
  accountDetails: Account;
  profileDetails: Profile;
  isLoading = true;
 @Input() accountType: string;
  idImageUrl: any;
  passportImageUrl: any;
  imageType: string;
  accountGoalId: string;
  isFunded = false;
  @Output() closeWindow = new EventEmitter();
  editDocView: boolean;
  editAccountSummary: boolean;
  editRepresentatives: boolean;
  uploadMembersView: boolean;
  uploadRepresentaivesView: boolean;
  uploadMembersPhoneNumberView: boolean;
  institutionDocumentUrl: any;
  fileType: string;
  manageRights: boolean;
  checkSponsorLinkedErrors: boolean;
  totalCurrentValue = 0;
  totalNetInvested = 0;
  transactionsWithErrorsCount: any;
  showProfile: boolean;
  profileId: string;
  tenantCurrency: String;
  coverUrl: string = "";


  ngOnInit() {
    this.tenantCurrency = this.utilities.xnCurrencyLabel(localStorage.getItem('tenantID'))
    this.getAccountData();

  }

  getSingleIndividualAccount() {
    this.isLoading = true;
    this.accountService.getIndividualAccountById(this.id).subscribe(resp => {
      this.accountDetails = resp;
      this.accountType = this.accountDetails.accountType;
      this.accountDetails.representatives.map((representative) => {
        if (representative.role === 'Representative') {
          this.profileId = representative.id;
          this.getSingleProfile(this.profileId);
        }
      });
      this.isLoading = false;
      // check if account is funded
      if (this.accountDetails.currentValue > 0) {
        this.totalCurrentValue = this.accountDetails.currentValue;
      }
      if (this.accountDetails.netInvested > 0) {
        this.totalNetInvested = this.accountDetails.netInvested;
        this.isFunded = true;
      }
    });
  }

  getSingleInstitutionAccount() {
    this.isLoading = true;
    this.accountService.getInstitutionAccountById(this.id).subscribe(resp => {
      this.accountDetails = resp;
      this.accountType = this.accountDetails.accountType;
      this.accountDetails.representatives.map((representative) => {
        if (representative.role === 'Representative') {
          this.profileId = representative.id;
        }
        console.log(`username: ${representative.username}`);
      //  this.fetchUserAvatar(representative.username);
        this.getSingleProfile(representative.id);
      });

      this.isLoading = false;
      // check if account is funded
      if (this.accountDetails.currentValue > 1) {
        this.totalCurrentValue = this.accountDetails.currentValue;
      }
      if (this.accountDetails.netInvested > 1) {
        this.totalNetInvested = this.accountDetails.netInvested;
        this.isFunded = true;
      }

      if (this.accountDetails.certificateFile != null) {
      //  this.institutionDocumentUrl = `${environment.serverUrl}/api/v1/guest/orion-institutional-document?accountId=${this.id}`;
        this.profileService.getInstitutionDocument(this.id).subscribe({
          next: (resp) => {
            this.institutionDocumentUrl = URL.createObjectURL(resp);
          }
        });
        if (this.accountDetails.certificateFile.includes('.pdf')) {
          this.fileType = 'PDF';
        } else {
          this.fileType = 'Image';
        }
      }

    }, error => {
      console.log(`ERROR: ${JSON.stringify(error)}`);
      this.utilities.errorAlert(error);
      this.isLoading = false;
    });
  }

  getSingleProfile(profileId: string): void {
    this.profileService.getProfileById(profileId).subscribe(resp => {
      this.profileDetails = resp;
      let userIndex = this.accountDetails.representatives.findIndex((e:any) => e['username'] === this.profileDetails.email);
      this.profileService.getPassportImage(this.profileDetails.email).subscribe({
        next: (resp) => {
          let imageUrl = URL.createObjectURL(resp);
          if (userIndex !== -1) {
           // this.passportImageUrl = imageUrl;
            this.accountDetails.representatives[userIndex].avatarUrl = imageUrl;
          }
        }
      });

      this.profileService.getIdImage(this.profileDetails.email).subscribe({
        next: (resp) => {
          this.idImageUrl = URL.createObjectURL(resp);
          console.log(this.idImageUrl);
        }
      });
      this.isLoading = false;
    }, error => {
      this.utilities.errorAlert(error);
      this.isLoading = false;
    });
  }

  imageUrl(email: string): any {
    this.profileService.getPassportImage(email).subscribe({
      next: (resp) => {
        return  URL.createObjectURL(resp);
      }
    });
  }

  showFilePreview(imageType: string, fileType: string) {
    this.imageType = imageType;
    this.fileType = fileType;
    this.idPreview = true;
  }

  showGoalDetails(accountGoalId: string) {
    this.accountGoalId = accountGoalId;
    this.goalDetailsView = true;
  }

  closeFilePreview() {
    this.idPreview = false;
  }


  filterLinkedActiveMembers(linkedAccountStatus: LinkedAccountMember) {
    return linkedAccountStatus.enabled === true;
  }

  filterLinkedInActiveMembers(linkedAccountStatus: LinkedAccountMember) {
    return linkedAccountStatus.enabled === false;
  }

  getAccountData() {
    if (this.router.url.includes('/individuals')) {
      this.getSingleIndividualAccount();
    } else if (this.router.url.includes('/institutions')) {
      this.isLoading = false;
      this.getSingleInstitutionAccount();
    } else if (this.router.url.includes('/single-account')) {
      this.id = history.state.navigationId;
      this.getSingleInstitutionAccount();
    }
  }

  removeMemberFromGroup(member: any) {
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.accountService.removeMemberFromInstitutionGroup(member)
          .subscribe((response) => {
            if (response.code === 200) {
              this.utilities.successAlert('Successfully removed the member');
              this.closeAccountWindow();
            }
          },
            error => {
              this.utilities.errorAlert('Sorry, this action cannot be completed, Please try again');
            }
          );
      }
    });
  }

  closeAccountWindow() {
    if (this.router.url.includes('/single-account')) {
      // @ts-ignore
      this.router.navigateByUrl('/single-profile', { state: this.id });

    } else {
      this.closeWindow.emit();
    }
  }


  filterInLinkedActiveMembers(linkedAccountStatus: LinkedAccountMember) {
    return linkedAccountStatus.enabled === false;
  }

  downloadCertificateFile(accountId, institutionName, certificateFile) {
    this.accountService.getInstitutionalImage(accountId).subscribe((data) => {

      const extension = this.getExtension(certificateFile);

      this.downloadFile(data, institutionName, certificateFile);
    }, error => {
    });
  }

  downloadFile(data: Blob, institutionName: string, certificateFile: string) {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(data);
    a.href = objectUrl;
    a.download = certificateFile;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  getExtension(str) {
    return str.split('.')[1];
  }

  setStatus(status: string) {
    let s = '';

    switch (status) {
      case 'active':
        s = 'verified';
        break;

      default:
        s = status;
        break;
    }
    return s;
  }

  showProfileDetails(id: string) {
    this.showProfile = true;
    this.profileId = id;
  }

  hideProfile() {
    this.showProfile = false;
    this.profileId = null;
  }

  fetchUserAvatar(username: string){
    let userIndex = this.accountDetails.representatives.findIndex((e:any) => e['username'] === username);
     this.profileService.getPassportImage(username).subscribe({
      next: (resp) => {
        let imageUrl = URL.createObjectURL(resp);
          if (userIndex !== -1) {
            this.accountDetails.representatives[userIndex].avatarUrl = imageUrl;
          }
      }
    })
      // tap((imageUrl) => {
      //   console.log(`image_url: ${imageUrl}`)
      //   if (userIndex !== -1) {
      //     this.accountDetails.representatives[userIndex].avatarUrl = imageUrl;
      //   }
      // }),
      // catchError(error => {
      //   return of(null); // Return null if avatar fetch fails
      // })

  }


}
