import { CollectionViewer, DataSource } from '@angular/cdk/collections';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {Job} from '@orion/models/job';
import {JobsService} from '@orion/services/jobs.service';

export class TasksDataSource implements DataSource<Job> {

  private jobsSubject = new BehaviorSubject<Job[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private jobService: JobsService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Job[] | ReadonlyArray<Job>> {
    return this.jobsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.jobsSubject.complete();
    this.loadingSubject.complete();
  }

  loadTasks(offset: any, max: any, sort: string, order: string, page: number, filter = '') {
    this.loadingSubject.next(true);
    this.jobService.getAll(offset, max, sort, order, page, filter).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(resp => {
      this.jobsSubject.next(resp.tasks);
      this.resultsLengthSubject.next(resp.taskDefInstanceCount);
    });
  }

  // loadScheduledJobs(offset: any, max: any, sort: string, order: string, page: number, filter = '') {
  //   this.loadingSubject.next(true);
  //   this.jobService.getAll(offset, max, sort, order, page, filter).pipe(
  //     catchError(() => of([])),
  //     finalize(() => this.loadingSubject.next(false))
  //   ).subscribe(resp => {
  //     this.jobsSubject.next(resp.scheduledJobs);
  //     this.resultsLengthSubject.next(resp.taskDefInstanceCount);
  //     console.log('All Scheduled Jobs: ', resp.scheduledJobs);
  //   });
  // }

}
