import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {User} from '../../models';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {UserService} from '../../services';
import {catchError, finalize} from 'rxjs/operators';

export class UsersDataSource implements DataSource<User>{
  private usersSubject = new BehaviorSubject<User[]>([]);
  private resultsLengthSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public resultsLength = this.resultsLengthSubject.asObservable();

  constructor(private userService: UserService) {
  }
  connect(collectionViewer: CollectionViewer): Observable<User[] | ReadonlyArray<User>> {
    return this.usersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.usersSubject.complete();
    this.loadingSubject.complete();
  }
  loadUsers(offset: any, max: any, sort: string, order: string, page: number, filter = '') {
    this.loadingSubject.next(true);
    this.userService.list(offset, max, sort, order, page, filter).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(resp => {
      this.usersSubject.next(resp.data);
      this.resultsLengthSubject.next(resp.totalCount);
    });
  }
}
