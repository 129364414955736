<slideover [zIndex]="3" (closeWindow)="close.emit()">
  <div class="create-note-form">
    <h1>New Note</h1>
    <form [formGroup]="noteForm" class="" (ngSubmit)="submitNote()">
      <div>
        <mat-form-field appearance="fill">
          <mat-label class="user-name">To do</mat-label>
          <mat-select formControlName="subject">
            <mat-option value="Call">Call</mat-option>
            <mat-option value="Email">Email</mat-option>
            <mat-option value="Meeting">Meeting</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill">
          <mat-label class="user-name">Note</mat-label>
          <textarea matInput matTextareaAutosize rows="10" placeholder="Enter notes..."
            formControlName="note"></textarea>
        </mat-form-field>
      </div>
      <div class="u-justify ">
        <div></div>
        <button [disabled]="!(noteForm.value.subject && noteForm.value.note)" type="submit" mat-button mat-flat-button
          color="primary">
          Save
        </button>
      </div>
    </form>



  </div>


</slideover>
