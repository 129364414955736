<div class="bkg index-{{ zIndex }}">
    <div class="content-box {{ size }}">
        <div class="close-exp">
            <div class="prof-name">
                <figure class="u-profile-photo" [style.backgroundImage]="
                'url(' + thumbNailBaseUrl + profileDetails?.email + ')'
              "></figure>
              <span>
                {{profileDetails?.firstName ? (profileDetails?.firstName + ' ' +
                                            profileDetails?.lastName) : '...' }}
              </span>
            </div>
            <button class="close-button" (click)="closeSlideover()"></button>
        </div>
      
      <h1 class="page-title">{{ title }}</h1>
  
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>