import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountMergeService {

  private apiUrl = `${environment.serverUrl}/api/v1`;

  constructor(private http: HttpClient) {
  }

  approveMergeAccount(id: string, mergeStatus: string = 'PENDING', reason: string = null): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/merge-account/approve/${id}/${mergeStatus}?reason=${reason}`);
  }

  list(offset: any, max: any, sort: string, order: string, page: number, filter = ''): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('offset', offset)
      .set('max', max)
      .set('sort', sort)
      .set('order', order)
      .set('filter', filter);
    return this.http.get<any>(`${this.apiUrl}/merge-account/index`, {params});
  }
}

export const MergeStatus = {PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED'};
