import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {UserService} from '../../services';
import {User, UserRole} from '../../models';
import {UsersDataSource} from './users-data-source';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";



@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, AfterViewInit  {
  constructor(private formBuilder: UntypedFormBuilder,
              private userService: UserService) {}

  get accountFilter() {
    return this.filtersForm.get('accountFilter');
  }

  get startDate() {
    return this.filtersForm.get('startDate');
  }

  get endDate() {
    return this.filtersForm.get('startDate');
  }


  // table
  displayedColumns: string[] = [
    'name',
    'role'
  ];
  users: User[] = [];
  isLoading = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // filters
  filtersForm: UntypedFormGroup;
  dataSource: UsersDataSource;
  // detail views
  singleUserView = false;
  userId: string;
  user: User;
  userRole: UserRole;

  createUserView = false;

  ngOnInit() {
    this.dataSource = new UsersDataSource(this.userService);
    this.dataSource.loadUsers(0, 50, 'dateCreated', 'desc', 0, '');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
   /* this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadUsersPage())
      ).subscribe();*/
  }

  loadUsersPage() {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadUsers(offset, this.paginator.pageSize, this.sort.active, this.sort.direction, this.paginator.pageIndex, '');
  }

  applyFilter(event: MatSelectChange) {
    const filterValue = event.value;

    this.paginator.pageIndex = 0;

    this.dataSource.loadUsers(0, 50, 'dateCreated', 'desc', 0, JSON.stringify(this.filtersForm.value));
  }

  showUserDetails(userId: string) {
    this.singleUserView = true;
    this.userId = userId;
  }

  hideUserDetails() {
    this.singleUserView = false;
  }

  hideCreateUser(){
    this.createUserView = false;
  }

  showCreateUser(){
    this.createUserView = true;
  }

}
