<slideover [zIndex]="2" (closeWindow)="close.emit()">
  <div class="page-inner-container">
    <div class="page-section">
      <div class="error" *ngIf="isError">
        <p>{{message}}</p>
      </div>
      <h2 class="page-subtitle">Edit Identification: <span>{{userName}}</span></h2>
      <form [formGroup]="IDForm" class="edit-user-form xn-fadein-up">
        <div class="fields">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>ID Type</mat-label>
              <mat-select formControlName="idType">
                <mat-option value="National ID">National ID</mat-option>
                <mat-option value="Passport">Passport</mat-option>
                <mat-option value="Driver License">Driver License</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>ID Number</mat-label>
              <input type="text" formControlName="idNumber" [ngModel]="personalInfo.identificationNumber" matInput />
            </mat-form-field>
          </div>

          <div>
            <div class="xn-input-file">
              <label class="control-label">Upload ID file (Image)</label>
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                formControlName="idFile"
                id="idFile"
                (change)="onIDUpload($event)"
              />
              <label class="file-label" #idFileLabel for="idFile"></label>
              <figure class="icon"></figure>
            </div>
          </div>

          <div>
            <div class="xn-input-file">
              <label class="control-label"
                >Upload Selfie or Photo (Image)</label
              >
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                formControlName="photoFile"
                id="photoFile"
                name="photoFile"
                (change)="onPhotoUpload($event)"
              />
              <label class="file-label" #photoFileLabel for="photoFile"></label>
              <figure class="icon"></figure>
            </div>
          </div>
        </div>

        <div class="u-justify actions">
          <button type="button" (click)="close.emit()" mat-button mat-flat-button color="warn">
            Cancel
          </button>
          <button
            type="button"
            [disabled]="!IDForm.valid"
            mat-button
            mat-flat-button
            color="primary"
            (click)="submitForm()"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</slideover>
