import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup,  Validators} from '@angular/forms';
import { AuthenticationService } from '../../services';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { User } from '@orion/models';

interface Countries {
  value: number;
  viewValue: string;
  countryCode: string;
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  countries: Countries[] = [
    { value: 1, viewValue: 'Uganda', countryCode: 'UG' },
    { value: 2, viewValue: 'Kenya', countryCode: 'KE' },
    // { value: 3, viewValue: 'Nigeria', countryCode: 'NG' },
  ];

  loginForm: FormGroup;
  selectedCountryImg: string;
  selectedCountryCode: string;
  submitted = false;
  isLoading = false;
  isError = false;
  errorMessage: string;
  orionUser: User;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    // tslint:disable-next-line:variable-name
    private _snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      country: [null, Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    const selectedCountry = this.countries.find(selected => selected.viewValue == 'Uganda');
    this.loginForm.get('country').setValue(selectedCountry),
      this.selectedCountryCode = selectedCountry.countryCode,
      localStorage.setItem('tenantId', this.selectedCountryCode);
      this.authenticationService.orionUser.subscribe((x) => {
        this.orionUser = x;
        if((this.orionUser.isAdvisor) && this.selectedCountryCode == 'UG'){
          this.router.navigate(['/assigned-users']);
        } else{
           this.router.navigate(['/user-dashboard']);
        }
        // if (x) {
        //   // this.authenticationService.logout();
        //   this.router.navigate(['/user-dashboard']);
        // }
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  selectChangeHandler(e) {
    const selectedCountry = this.countries.find(selected => selected.viewValue == e.value.viewValue);
    this.loginForm.get('country').setValue(selectedCountry);
    this.selectedCountryCode = selectedCountry.countryCode;
    localStorage.removeItem('tenantId');
    localStorage.setItem('tenantId', this.selectedCountryCode);
  }

  onSubmit() {
    this.isError = false;
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.isError = false;
      return;
    }
    this.isLoading = true;
    // this.authenticationService.login(this.selectedCountryCode, this.f.username.value, this.f.password.value)
    //   .pipe(first())
    //   .subscribe(
    //     data => {
    //       this.authenticationService.orionUser.subscribe(x => { this.orionUser = x; });
    //       if(this.orionUser.isAdvisor && this.selectedCountryCode == 'UG'){
    //         this.router.navigate(['/assigned-users']);
    //       } else{
    //          this.router.navigate(['/user-dashboard']);
    //       }
    //     }, error => {
    //       this.isLoading = false;

    //     }
    //   );

  }

  // openSnackBar() {
  //   this._snackBar.open('Cannonball!!', 'Splash', {
  //     horizontalPosition: this.horizontalPosition,
  //     verticalPosition: this.verticalPosition,
  //   });
  // }

}
