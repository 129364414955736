import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Account } from '@orion/models';
import { AccountService } from '@orion/services/account.service';
import { UtilitiesService } from '@orion/utilities/utilities.service';

@Component({
  selector: 'app-upload-representatives',
  templateUrl: './upload-representatives.component.html',
  styleUrls: ['./upload-representatives.component.scss']
})
export class UploadRepresentativesComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() accountDetails: Account;
  arrayBuffer: any;
  uploaded: any;
  isUpload: boolean;
  isResult: boolean;


  fileToUpload: File;
  @ViewChild('representativesDocLabel', { static: false }) representativesDocLabel: ElementRef;


  constructor(private accountService: AccountService,
    private utilities: UtilitiesService) {
  }

  ngOnInit(): void {
    this.isUpload = true;
    this.isResult = false;
  }

  onRepFileSelect(e: any) {
    this.representativesDocLabel.nativeElement.innerHTML = e.target.files[0].name;
    this.fileToUpload = e.target.files.item(0);
  }

  uploadRepresentatives() {
    const formData = new FormData();
    formData.append('file', this.fileToUpload);
    this.utilities.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.accountService.addRepresentatives(formData, this.accountDetails.id).subscribe((response) => {
          if (response.code === 200) {
            this.utilities.successAlert('Successfully added the representative');
            this.isResult = true;
            this.uploaded = response.representatives;
          }
          else {
            this.utilities.errorAlert('Failed to add the representatives to this group');
          }
        }, error => { this.utilities.errorAlert('Failed to add the representatives to this group'); });
      }
    });

  }

}
