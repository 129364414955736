import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {AccountMergeDataSource} from '@orion/pages/account-merge/account-merge-data-source';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AccountMergeService} from '@orion/services/account-merge.service';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LctrDashboardDataSource} from '@orion/pages/lctr-dashboard/lctr-dashboard-datasource';
import {LctrDashboardService} from '@orion/services/lctr-dashboard.service';
import {environment} from '../../../environments/environment';
import {saveAs} from 'file-saver';
import {MatPaginator} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";
import {ProfileService} from "@orion/services/profile.service";

@Component({
  selector: 'app-lctr-dashboard',
  templateUrl: './lctr-dashboard.component.html',
  styleUrls: ['./lctr-dashboard.component.scss']
})
export class LctrDashboardComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    'position',
    'transactionDate',
    'firstName',
    'amount',
    'transactionType',
    'country',
    'gender',
  ];
  resultsLength = 0;
  isLoading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: LctrDashboardDataSource;
  filtersForm: UntypedFormGroup;
  showProfile = false;
  profileId: string;
  thumbNailBaseUrl = `${environment.serverUrl}/api/v1/guest/passportphoto/`;
  tenantCurrency: string;

  constructor(private lctrDashboardService: LctrDashboardService,
              private utilitiesService: UtilitiesService,
              private formBuilder: UntypedFormBuilder,
              private profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.tenantCurrency = this.utilitiesService.xnCurrencyLabel(localStorage.getItem('tenantId'))
    this.filtersForm = this.formBuilder.group({
      startDate: [''],
      endDate: [''],
    });
    this.dataSource = new LctrDashboardDataSource(this.lctrDashboardService, this.profileService);
    this.dataSource.loadLctrDashboard(0, 50, 'transactionDate', 'desc', 0, '');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadLctrDashboardData()))
      .subscribe();
  }

  loadLctrDashboardData() {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadLctrDashboard(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      JSON.stringify(this.filtersForm.value)
    );
  }

  applyDateRangeFilter(event: MatSelectChange) {
    const filters: Record<string, any> = {};
    if (this.filtersForm.value.startDate) {
      filters.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      filters.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    this.paginator.pageIndex = 0;
    this.dataSource.loadLctrDashboard(
      0,
      50,
      'transactionDate',
      'desc',
      0,
      JSON.stringify(filters)
    );
  }

  showProfileDetail(profileId: string) {
    this.showProfile = true;
    this.profileId = profileId;
  }

  hideProfile() {
    this.showProfile = false;
    this.profileId = null;
  }

  downloadXMlFile() {
    const filters: Record<string, any> = {};
    let filterParams = '';
    if (this.filtersForm.value.startDate) {
      filters.startDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.startDate);
    }
    if (this.filtersForm.value.endDate) {
      filters.endDate = this.utilitiesService.formatDateWithTime(this.filtersForm.value.endDate);
    }
    if (this.filtersForm.value.startDate && this.filtersForm.value.endDate) {
      filterParams = JSON.stringify(filters);
    } else {
      filterParams = '';
    }
    const today = this.utilitiesService.formatDate(new Date());
    this.lctrDashboardService.downloadGoAMLXMLFile(filterParams).subscribe((data) => {
      const blob: any = new Blob([data], {type: 'text/xml'});
      saveAs(blob, 'XENO_Uganda_FIA_LCTR_goAML_' + today + '.xml');
    }, error => {
      alert('Error downloading the file.');
    });
  }
}
