import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ActivateUssdClientService {
  private apiUrl = `${environment.serverUrl}/api/v2/ussd`;
  constructor(private http: HttpClient) { }

  activateUssdAccount(providerId: string): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('providerId', providerId);
    return this.http.post<any>(`${this.apiUrl}/activate-account`, params);
  }

}
