import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AccountMergeService} from '@orion/services/account-merge.service';
import {AccountMerge} from '@orion/models/account-merge';
import {AccountMergeDataSource} from '@orion/pages/account-merge/account-merge-data-source';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UtilitiesService} from '@orion/utilities/utilities.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-account-merge',
  templateUrl: './account-merge.component.html',
  styleUrls: ['./account-merge.component.scss']
})
export class AccountMergeComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    'position',
    'mergeRequestDate',
    'mergeAccount',
    // 'mergeReplyDate',
    'mergeStatus',
    'newProfile',
    'previousProfile',
    // 'mergeRejectionReason',
    'actions',
  ];

  rejectionReason: string;
  resultsLength = 0;
  isLoading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: AccountMergeDataSource;
  rejectMergeForm = false;
  accountMergeId: string;
  filtersForm: UntypedFormGroup;
  showProfile = false;
  profileId: string;

  constructor(private accountMergeService: AccountMergeService,
              private utilitiesService: UtilitiesService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.filtersForm = this.formBuilder.group({
      status: [''],
    });
    this.dataSource = new AccountMergeDataSource(this.accountMergeService);
    this.dataSource.loadAccountMergeRequests(0, 50, 'dateCreated', 'desc', 0, '');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadAccountMergePage()))
      .subscribe();
  }

  loadAccountMergePage() {
    const offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.dataSource.loadAccountMergeRequests(
      offset,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      JSON.stringify(this.filtersForm.value)
    );
  }

  applyStatusFilter(event: MatSelectChange) {
    this.paginator.pageIndex = 0;
    this.dataSource.loadAccountMergeRequests(
      0,
      50,
      'dateCreated',
      'desc',
      0,
      JSON.stringify(this.filtersForm.value)
    );
  }

  approve(id: string, status: string): void {
    this.utilitiesService.confirmAlert().then((result) => {
      if (result.isConfirmed) {
        this.accountMergeService
          .approveMergeAccount(id, status, this.rejectionReason != null ? this.rejectionReason : null)
          .subscribe({
            next: (response) => {
              if (response) {
                if (response.code === 200) {
                  this.utilitiesService.successAlert('Successfully merged the account');
                  this.ngOnInit();
                } else {
                  this.utilitiesService.errorAlert('Failed to merge account');
                  this.ngOnInit();
                }
              }
            },
            error: (error) => {

            }
          });
      }
    });
  }

  rejectMerge(accountMerge: AccountMerge) {
    this.rejectMergeForm = true;
    this.accountMergeId = accountMerge.id;
  }

  closeRejectWindow() {
    this.rejectMergeForm = false;
    this.ngOnInit();
  }

  showProfileDetails(profileId: string) {
    this.showProfile = true;
    this.profileId = profileId;
  }

  hideProfile() {
    this.showProfile = false;
    this.profileId = null;
  }
}
