import { Component, Input, OnInit } from '@angular/core';
import { AdvisorUserBandSummary } from '@orion/models/advisor-users';
import * as Highcharts from 'highcharts';

let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-band-summary',
  templateUrl: './band-summary.component.html',
  styleUrls: ['./band-summary.component.scss'],
})
export class BandSummaryComponent implements OnInit {
  @Input() bandSummary: AdvisorUserBandSummary;

  public options: any;

  ngOnInit() {
    this.drawPie(this.bandSummary);
  }

  drawPie(bandSummaryData) {
    this.options = {
      chart: {
        type: 'column',
        height: 300
      },
      title: {
        text: null
      },
      tooltip: {
        shared: true,
        useHTML: true,
        headerFormat: '<table><tr><th style="font-weight: 900; color: #4352af; border-bottom: 1px solid #D3D3D3" colspan="2">{point.key}</th></tr>',
        pointFormat: '<tr><td >Count: </td>' +
          '<td style="text-align: left; font-weight: 600;"><b> {point.count}</td></tr>' +
          '<tr><td >Percentage: </td>' +
          '<td style="text-align: left; font-weight: 600;"><b> {point.y:.1f}%</b></td></tr>',
        footerFormat: '</table>',
        backgroundColor: '#e8f4f8',
        borderColor: '#4352af',
        borderRadius: 10,
        borderWidth: 0.5
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%'
          }
        }
      },
      xAxis: {
        title: {
          text: 'Bands'
        },
        scrollbar: {
          enabled: true
        },
        tickLength: 0,
        type: 'Bands',
        categories: [
          bandSummaryData[1]?.label,
          bandSummaryData[0]?.label,
          bandSummaryData[2]?.label,
          bandSummaryData[3]?.label,
          bandSummaryData[4]?.label,
          bandSummaryData[5]?.label,
          bandSummaryData[6]?.label,
          bandSummaryData[7]?.label,
          bandSummaryData[8]?.label,
          bandSummaryData[9]?.label,
        ],
      },
      yAxis: {
        title: {
          text: 'Percentage'
        }

      },
      legend: {
        enabled: false
      },
      series: [{
        colorByPoint: true,
        data: [
          {
            y: bandSummaryData[1]?.percentage,
            name: bandSummaryData[1]?.label,
            color: 'rgb(255, 162, 154)',
            count: bandSummaryData[1]?.count,
          },
          {
            y: bandSummaryData[0]?.percentage,
            name: bandSummaryData[0]?.label,
            color: 'rgb(255, 206, 201)',
            count: bandSummaryData[0]?.count,
          },
          {
            y: bandSummaryData[2]?.percentage,
            name: bandSummaryData[2]?.label,
            color: 'rgb(250, 219, 216)',
            count: bandSummaryData[2]?.count,
          },
          {
            y: bandSummaryData[3]?.percentage,
            name: bandSummaryData[3]?.label,
            color: 'rgb(250, 213, 165)',
            count: bandSummaryData[3]?.count,
          },
          {
            y: bandSummaryData[4]?.percentage,
            name: bandSummaryData[4]?.label,
            color: 'rgb(255, 228, 181)',
            count: bandSummaryData[4]?.count,
          },
          {
            y: bandSummaryData[5]?.percentage,
            name: bandSummaryData[5]?.label,
            color: 'rgb(255, 239, 213)',
            count: bandSummaryData[5]?.count,
          },
          {
            y: bandSummaryData[6]?.percentage,
            name: bandSummaryData[6]?.label,
            color: 'rgb(255, 248, 220)',
            count: bandSummaryData[6]?.count,
          },
          {
            y: bandSummaryData[7]?.percentage,
            name: bandSummaryData[7]?.label,
            color: 'rgb(219, 252, 245)',
            count: bandSummaryData[7]?.count,
          },
          {
            y: bandSummaryData[8]?.percentage,
            name: bandSummaryData[8]?.label,
            color: 'rgb(195, 255, 242)',
            count: bandSummaryData[8]?.count,
          },
          {
            y: bandSummaryData[9]?.percentage,
            name: bandSummaryData[9]?.label,
            color: 'rgb(144, 255, 231)',
            count: bandSummaryData[9]?.count,
          }
        ]
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 767
          },
          chartOptions: {
            plotOptions: {
              pie: {
                size: "100%"
              }
            }
          }
        }]
      }

    };
    Highcharts.chart("planPieChart", this.options);
  }
}
